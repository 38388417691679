/* eslint-disable */
import React from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { BasicInput } from '../../Input/index';
import { updateInfoQuestionUnique } from '../../../actions/test';

const Unique = ({
  dispatch,
  itemIndex,
  indicatorIndex,
  subAxeIndex,
  axeIndex,
  axes_attributes
}) => {
  const currentValue =
    axes_attributes[axeIndex].sub_axes_attributes[subAxeIndex]
      .indicators_attributes[indicatorIndex].questions_attributes[itemIndex];
  return (
    <Col md={2} className="align-col">
      <BasicInput
        type="number"
        placeholder="Puntaje"
        className="secondary special-m-t"
        value={currentValue.score}
        onChange={e =>
          dispatch(
            updateInfoQuestionUnique({
              value: e.target.value,
              itemIndex,
              indicatorIndex,
              subAxeIndex,
              axeIndex
            })
          )
        }
      />
    </Col>
  );
};

const mapStateToProps = state => {
  const {
    quiz: {
      quiz: { axes_attributes }
    }
  } = state.test;
  return {
    axes_attributes
  };
};

export default connect(mapStateToProps)(Unique);
