import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import {
  SubTitleH2,
  NavbarLeft,
  DefaultButton,
  ComponentDataTable,
  ModalConfirm,
  Reports,
  ModalAction,
  LoadSpinner
} from '../../components';
import { downloadFile } from '../../services/utils';
import { clearTest } from '../../actions/test';
import {
  deleteDocuments,
  getDocuments,
  clearDataChart,
  downloadDocument
} from '../../actions/reports';

import { columns, columnsAdmin } from './columns';
import { AbilityContext } from '../../config/ability-context';

class Report extends Component {
  constructor() {
    super();
    this.state = {
      modalShowConfirm: false,
      modalData: {},
      isChecked: false,
      modalShow: false,
      loader: false
    };
  }

  componentDidUpdate(prevProps) {
    const { onLoadUtils: prevLoad, ongoingRequest: loadFile } = prevProps;
    const { onLoadUtils: load, report, ongoingRequest, document } = this.props;
    if (
      prevLoad.getReport &&
      prevLoad.getReport !== load.getReport &&
      report !== ''
    ) {
      downloadFile(
        `reporte-${report.data.nameQuiz} ${report.data.year}`,
        'xlsx',
        report.file
      );
    }
    if (
      loadFile.downloadDocumentRequest &&
      loadFile.downloadDocumentRequest !==
        ongoingRequest.downloadDocumentRequest
    ) {
      downloadFile(
        `${document.data.name}`,
        document.data.extension,
        document.file
      );
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state,
        loader: false
      });
    }
  }

  queryDispatch = data => {
    const { dispatch, signedIn } = this.props;
    if (signedIn) dispatch(getDocuments(data));
  };

  setModalShow = state => {
    this.setState({
      modalShow: state
    });
  };

  handleCreate = () => {
    this.setState({
      modalShow: true,
      modalData: {
        create: true,
        form: 'upload-file',
        title: 'SUBIR ARCHIVO'
      }
    });
  };

  setModalShowConfirm = state => {
    this.setState({
      modalShowConfirm: state
    });
  };

  handleDelete = documentId => {
    const { dispatch } = this.props;
    dispatch(deleteDocuments({ id: documentId }));
    this.setModalShowConfirm(false);
  };

  createTest = () => {
    const { history, dispatch } = this.props;
    dispatch(clearTest());
    history.push('/new-tests');
  };

  handleButtonClick = (item, action) => {
    const { dispatch } = this.props;
    if (action === 'delete') {
      this.setState({
        modalShowConfirm: true,
        modalData: {
          id: item.id,
          action: 'delete',
          title: 'ELIMINAR INFORME',
          message: '¿Estás seguro que deseas eliminar este informe?'
        }
      });
    } else if (action === 'upload') {
      this.setState({
        modalShow: true,
        modalData: {
          id: item.id,
          create: true,
          form: 'upload-file',
          title: 'SUBIR ARCHIVO'
        }
      });
    } else if (action === 'download') {
      if (item.original_filename) {
        let extension = item.original_filename.split('.');
        extension = extension[extension.length - 1];
        let name = item.original_filename.split('.');
        // eslint-disable-next-line prefer-destructuring
        name = name[0];
        dispatch(
          downloadDocument({
            id: item.id,
            file: true,
            name,
            extension
          })
        );
      }
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state,
        loader: true
      });
    }
  };

  render() {
    const {
      ongoingRequest,
      totalRows,
      documents,
      history,
      dispatch,
      onLoadUtils
    } = this.props;
    const {
      modalShowConfirm,
      modalData,
      isChecked,
      modalShow,
      loader
    } = this.state;
    const { context } = this;
    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            {loader && <LoadSpinner position="absolute" height="100%" />}
            <div className="section">
              <Row style={{ justifyContent: 'flex-end' }}>
                <Col>
                  <SubTitleH2 text="Estadísticas" />
                </Col>
                <Col xs={4} xm={3} md={3} lg={2}>
                  <Reports ongoingRequest={onLoadUtils} />
                </Col>
                <Col xs={4} xm={3} md={3} lg={2}>
                  <DefaultButton
                    text="Informe"
                    onClick={() => {
                      history.push('/report-preview');
                      dispatch(clearDataChart());
                    }}
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
              <ComponentDataTable
                columns={
                  context.can('generate_report', 'Quiz')
                    ? columnsAdmin(this.handleButtonClick)
                    : columns(this.handleButtonClick)
                }
                onRequest={ongoingRequest.getDocuments}
                data={documents}
                totalRows={totalRows}
                reduxDispatch={this.queryDispatch}
                moreData={{ finished: isChecked }}
              />
            </div>
            <ModalConfirm
              show={modalShowConfirm}
              data={modalData}
              onHide={() => this.setModalShowConfirm(false)}
              action={this.handleDelete}
            />
            <ModalAction
              onLoad={ongoingRequest.uploadDocumentRequest}
              show={modalShow}
              data={modalData}
              onHide={() => this.setModalShow(false)}
              min="0px"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

Report.contextType = AbilityContext;

const mapStateToProps = state => {
  const { ongoingRequest, documents, totalRows, document } = state.reports;
  const { user, signedIn } = state.auth;
  const { report, ongoingRequest: onLoadUtils } = state.utils;
  return {
    ongoingRequest,
    documents,
    document,
    totalRows,
    signedIn,
    user,
    onLoadUtils,
    report
  };
};

export default connect(mapStateToProps)(Report);
