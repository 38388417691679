/* eslint-disable no-useless-escape */
import React from 'react';
import { pickTextColorBasedOnBgColorSimple } from '../../services/utils';

function toFixed(num, fixed) {
  const re = new RegExp(`^-?\\d+(?:\.\\d{0,${fixed || -1}})?`);
  if (num.toString().match(re)) return num.toString().match(re)[0];
  return num.toString();
}

const CustomizedLabel = props => {
  const { x, y, width, height, value, color } = props;
  if (value === 0 || !value) {
    return (
      <g>
        <text
          x={x + width / 2}
          y={y + height / 2}
          fill={pickTextColorBasedOnBgColorSimple(color, '#FFFFFF', '#000000')}
          textAnchor="middle"
          dominantBaseline="middle"
        />
      </g>
    );
  }
  return (
    <g>
      <text
        className={
          // eslint-disable-next-line no-nested-ternary
          width > 35 ? (width > 40 ? 'big-text' : 'small-text') : 'smaller-text'
        }
        x={x + width / 2}
        y={y + height / 2}
        fill={pickTextColorBasedOnBgColorSimple(color, '#FFFFFF', '#000000')}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {`${toFixed(value, 2)}%`}
      </text>
    </g>
  );
};

export default CustomizedLabel;
