import React from 'react';
import { memoize } from 'react-data-table-component';
import { Edit2, Trash2 } from 'react-feather';
import { Button } from 'react-bootstrap';

const columns = memoize(
  (
    clickHandler,
    canUpdate,
    canDestroy,
    fromEstablishmentForm,
    yearSelected = null
  ) => [
    {
      name: 'NOMBRE',
      selector: 'names'
    },
    {
      name: 'APELLIDO',
      selector: 'last_names',
      sortable: true
    },
    {
      name: 'CURSO',
      cell: item => item.grade_by_year?.name
    },
    {
      name: 'SECCIÓN',
      cell: item => item.section_by_year?.name
    },
    {
      name: 'GENERO',
      selector: 'gender',
      right: true
    },
    {
      name: 'ACTIVO',
      selector: 'active',
      right: true
    },
    {
      name: 'ESTABLECIMIENTO',
      cell: item =>
        yearSelected
          ? item.establishment_by_year?.name
          : item.current_establishment?.name
    },
    {
      cell: infoItem => (
        <div>
          {!fromEstablishmentForm && (
            <>
              {canUpdate && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="mr-2"
                  onClick={() => clickHandler(infoItem, 'edit')}
                >
                  <Edit2 className="small-svg" />
                </Button>
              )}
              {canDestroy && (
                <Button
                  variant="outline-danger"
                  size="sm"
                  className="mr-2"
                  onClick={() => clickHandler(infoItem, 'remove')}
                >
                  <Trash2 className="small-svg" />
                </Button>
              )}
            </>
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ]
);

export default columns;
