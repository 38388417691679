export const AMOUNT_ESTABLISHMENT_REQUEST = 'AMOUNT_ESTABLISHMENT_REQUEST';
export const AMOUNT_ESTABLISHMENT_SUCCESS = 'AMOUNT_ESTABLISHMENT_SUCCESS';
export const AMOUNT_ESTABLISHMENT_FAILURE = 'AMOUNT_ESTABLISHMENT_FAILURE';

export const getAmountEstablishment = () => ({
  type: AMOUNT_ESTABLISHMENT_REQUEST
});

export const AMOUNT_SECTIONS_REQUEST = 'AMOUNT_SECTIONS_REQUEST';
export const AMOUNT_SECTIONS_SUCCESS = 'AMOUNT_SECTIONS_SUCCESS';
export const AMOUNT_SECTIONS_FAILURE = 'AMOUNT_SECTIONS_FAILURE';

export const getAmountSections = () => ({
  type: AMOUNT_SECTIONS_REQUEST
});

export const AMOUNT_STUNDETS_REQUEST = 'AMOUNT_STUNDETS_REQUEST';
export const AMOUNT_STUNDETS_SUCCESS = 'AMOUNT_STUNDETS_SUCCESS';
export const AMOUNT_STUNDETS_FAILURE = 'AMOUNT_STUNDETS_FAILURE';

export const getAmountStudents = () => ({
  type: AMOUNT_STUNDETS_REQUEST
});
