import {
  GET_COMMUNE_REQUEST,
  GET_COMMUNE_SUCCESS,
  GET_COMMUNE_FAILURE,
  GET_REGIONS_REQUEST,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_FAILURE,
  GET_GRADES_REQUEST,
  GET_GRADES_SUCCESS,
  GET_GRADES_FAILURE,
  GET_UTILS_ESTABLISHMENTS_REQUEST,
  GET_UTILS_ESTABLISHMENTS_SUCCESS,
  GET_UTILS_ESTABLISHMENTS_FAILURE,
  GET_SECTIONS_REQUEST,
  GET_SECTIONS_SUCCESS,
  GET_SECTIONS_FAILURE,
  GET_GRADE_TRANSITION_REQUEST,
  GET_GRADE_TRANSITION_SUCCESS,
  GET_GRADE_TRANSITION_FAILURE,
  RESET_STUDENTS,
  POST_GRADE_TRANSITION_REQUEST,
  POST_GRADE_TRANSITION_SUCCESS,
  POST_GRADE_TRANSITION_FAILURE,
  GET_YEARS_ESTABLISHMENT_REQUEST,
  GET_YEARS_ESTABLISHMENT_SUCCESS,
  GET_YEARS_ESTABLISHMENT_FAILURE,
  GET_AVAILABLE_YEAR_REQUEST,
  GET_AVAILABLE_YEAR_SUCCESS,
  GET_AVAILABLE_YEAR_FAILURE,
  GET_QUIZ_SECTION_REQUEST,
  GET_QUIZ_SECTION_SUCCESS,
  GET_QUIZ_SECTION_FAILURE,
  GET_AVAILABLE_GRADE_REQUEST,
  GET_AVAILABLE_GRADE_SUCCESS,
  GET_AVAILABLE_GRADE_FAILURE,
  GET_AVAILABLE_GUIDELINE_REQUEST,
  GET_AVAILABLE_GUIDELINE_SUCCESS,
  GET_AVAILABLE_GUIDELINE_FAILURE,
  GET_AVAILABLE_QUIZ_REQUEST,
  GET_AVAILABLE_QUIZ_SUCCESS,
  GET_AVAILABLE_QUIZ_FAILURE,
  GET_AVAILABLE_REGION_REQUEST,
  GET_AVAILABLE_REGION_SUCCESS,
  GET_AVAILABLE_REGION_FAILURE,
  GET_AVAILABLE_COMMUNE_REQUEST,
  GET_AVAILABLE_COMMUNE_SUCCESS,
  GET_AVAILABLE_COMMUNE_FAILURE,
  GET_AVAILABLE_ESTABLISHMENT_REQUEST,
  GET_AVAILABLE_ESTABLISHMENT_SUCCESS,
  GET_AVAILABLE_ESTABLISHMENT_FAILURE,
  GET_AVAILABLE_SECTION_REQUEST,
  GET_AVAILABLE_SECTION_SUCCESS,
  GET_AVAILABLE_SECTION_FAILURE,
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILURE,
  GET_ESTABLISHMENT_TYPES_REQUEST,
  GET_ESTABLISHMENT_TYPES_SUCCESS,
  GET_ESTABLISHMENT_TYPES_FAILURE,
  GET_PROJECT_TYPES_REQUEST,
  GET_PROJECT_TYPES_SUCCESS,
  GET_PROJECT_TYPES_FAILURE
} from '../actions/utils';

const initialState = {
  ongoingRequest: {
    getCommune: false,
    getRegions: false,
    getGrades: false,
    getGuideline: false,
    getUtilsEstablishments: false,
    getSections: false,
    getGradeTransition: false,
    postGradeTransition: false,
    getQuizzes: false,
    getAvailableYear: false,
    getAvailableGrade: false,
    getAvailableGuideline: false,
    getAvailableQuiz: false,
    getAvailableRegion: false,
    getAvailableCommune: false,
    getAvailableEstablishment: false,
    getAvailableSection: false,
    getReport: false,
    getEstablishmentTypes: false,
    getProjectTypes: false
  },
  selectQuizzes: [],
  selectCommunes: [],
  selectRegions: [],
  selectGrades: [],
  selectSections: [],
  selectEstablishments: [],
  selectYears: [],
  quizzes: [],
  communes: [],
  regions: [],
  grades: [],
  gradesNew: [],
  sections: [],
  establishments: [],
  studentsTransition: {},
  availableYears: [],
  availableGrades: [],
  availableGuidelines: [],
  availableQuizzes: [],
  availableRegions: [],
  availableCommunes: [{ key: null, id: null, title: 'Todos' }],
  availableEstablishments: [{ key: null, id: null, title: 'Todos' }],
  availableSections: [{ key: null, id: null, title: 'Todos' }],
  yearsEstablishment: [],
  establishmentTypes: [],
  projectTypes: [],
  report: ''
};

const setData = data => {
  const arrayList = data.map(d => {
    return { title: d.name, key: d.id, id: d.id };
  });
  return arrayList;
};

const setDataWithoutId = (data, state = true) => {
  const arrayList = data.map(d => {
    return { title: String(d), key: d, id: d };
  });
  if (state) {
    return [{ key: 0, title: 'Seleccione', id: 0 }, ...arrayList];
  }
  return arrayList;
};

const setGrades = data => {
  return data.map(d => {
    return { title: d.name, key: d.id, id: d.id };
  });
};

const setDataAll = (data, state = true) => {
  const arrayList = data.map(d => {
    return { title: d.name, key: d.id, id: d.id };
  });
  if (state) {
    return [{ title: 'Todos', key: null, id: null }, ...arrayList];
  }
  return arrayList;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STUDENTS:
      return {
        ...state,
        studentsTransition: {}
      };
    case GET_COMMUNE_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getCommune: true
        }
      };
    case GET_COMMUNE_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getCommune: false
        },
        communes: setData(action.result),
        selectCommunes: action.property
      };
    case GET_COMMUNE_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getCommune: false
        }
      };
    case GET_REGIONS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getRegions: true
        }
      };
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getRegions: false
        },
        regions: setData(action.result),
        selectRegions: action.property
      };
    case GET_REGIONS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getRegions: false
        }
      };
    case GET_GRADES_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getGrades: true
        }
      };
    case GET_GRADES_SUCCESS:
      return {
        ...state,
        grades: action.select
          ? setData(action.result)
          : setGrades(action.result),
        selectGrades: action.property,
        ongoingRequest: {
          ...state.ongoingRequest,
          getGrades: false
        }
      };
    case GET_GRADES_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getGrades: false
        }
      };

    case GET_UTILS_ESTABLISHMENTS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getUtilsEstablishments: true
        }
      };
    case GET_UTILS_ESTABLISHMENTS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getUtilsEstablishments: false
        },
        establishments: setData(action.result),
        selectEstablishments: action.property
      };
    case GET_UTILS_ESTABLISHMENTS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getUtilsEstablishments: false
        }
      };
    case GET_SECTIONS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getSections: true
        }
      };
    case GET_SECTIONS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getSections: false
        },
        sections: setData(action.result),
        selectSections: action.property
      };
    case GET_SECTIONS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getSections: false
        }
      };
    case GET_GRADE_TRANSITION_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getGradeTransition: true
        }
      };
    case GET_GRADE_TRANSITION_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getGradeTransition: false
        },
        studentsTransition: action.result
      };
    case GET_GRADE_TRANSITION_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getGradeTransition: false
        }
      };
    case POST_GRADE_TRANSITION_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          postGradeTransition: true
        }
      };
    case POST_GRADE_TRANSITION_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          postGradeTransition: false
        },
        studentsTransition: action.result
      };
    case POST_GRADE_TRANSITION_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          postGradeTransition: false
        }
      };
    case GET_YEARS_ESTABLISHMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getYearsEstablishment: true
        }
      };
    case GET_YEARS_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getYearsEstablishment: false
        },
        yearsEstablishment: setDataWithoutId(action.result),
        selectYears: action.property
      };
    case GET_YEARS_ESTABLISHMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getYearsEstablishment: false
        }
      };
    case GET_AVAILABLE_YEAR_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableYear: true
        }
      };
    case GET_AVAILABLE_YEAR_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableYear: false
        },
        availableYears: setDataWithoutId(action.result, false)
      };
    case GET_AVAILABLE_YEAR_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableYear: false
        }
      };
    case GET_AVAILABLE_GRADE_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableGrade: true
        }
      };
    case GET_AVAILABLE_GRADE_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableGrade: false
        },
        availableGrades: setData(action.result.data)
      };
    case GET_AVAILABLE_GRADE_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableGrade: false
        }
      };

    case GET_AVAILABLE_GUIDELINE_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableGuideline: true
        }
      };
    case GET_AVAILABLE_GUIDELINE_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableGuideline: false
        },
        availableGuidelines: setData(action.result.data)
      };
    case GET_AVAILABLE_GUIDELINE_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableGuideline: false
        }
      };
    case GET_AVAILABLE_QUIZ_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableQuiz: true
        }
      };
    case GET_AVAILABLE_QUIZ_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableQuiz: false
        },
        availableQuizzes: setData(action.result.data)
      };
    case GET_AVAILABLE_QUIZ_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableQuiz: false
        }
      };
    case GET_AVAILABLE_REGION_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableRegion: true
        }
      };
    case GET_AVAILABLE_REGION_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableRegion: false
        },
        availableRegions: setDataAll(action.result.data, true)
      };
    case GET_AVAILABLE_REGION_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableRegion: false
        }
      };

    case GET_AVAILABLE_COMMUNE_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableCommune: true
        }
      };
    case GET_AVAILABLE_COMMUNE_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableCommune: false
        },
        availableCommunes: setDataAll(action.result.data, true)
      };
    case GET_AVAILABLE_COMMUNE_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableCommune: false
        }
      };

    case GET_AVAILABLE_ESTABLISHMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableEstablishment: true
        }
      };
    case GET_AVAILABLE_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableEstablishment: false
        },
        availableEstablishments: setDataAll(action.result.data, true)
      };
    case GET_AVAILABLE_ESTABLISHMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableEstablishment: false
        }
      };

    case GET_AVAILABLE_SECTION_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableSection: true
        }
      };
    case GET_AVAILABLE_SECTION_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableSection: false
        },
        availableSections: setDataAll(action.result.data, true)
      };
    case GET_AVAILABLE_SECTION_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAvailableSection: false
        }
      };
    case GET_QUIZ_SECTION_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuizzes: true
        }
      };
    case GET_QUIZ_SECTION_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuizzes: false
        },
        quizzes: setData(action.result),
        selectQuizzes: action.property
      };
    case GET_QUIZ_SECTION_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuizzes: false
        }
      };

    case GET_REPORT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getReport: true
        }
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getReport: false
        },
        report: {
          file: action.result,
          data: action.params
        }
      };
    case GET_REPORT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getReport: false
        }
      };

    case GET_ESTABLISHMENT_TYPES_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getEstablishmentTypes: true
        }
      };
    case GET_ESTABLISHMENT_TYPES_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getEstablishmentTypes: false
        },
        establishmentTypes: action.result
      };
    case GET_ESTABLISHMENT_TYPES_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getEstablishmentTypes: false
        }
      };

    case GET_PROJECT_TYPES_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getProjectTypes: true
        }
      };
    case GET_PROJECT_TYPES_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getProjectTypes: false
        },
        projectTypes: action.result
      };
    case GET_PROJECT_TYPES_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getProjectTypes: false
        }
      };
    default:
      return state;
  }
};

export default reducer;
