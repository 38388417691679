import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY_REQUEST,
  PASSWORD_RECOVERY_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_SUCCESS,
  CLEAR_AUTH_INFO,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  requestSignIn,
  SET_REDIRECT
} from '../actions/auth';
import API from '../services/api';
import runDefaultSaga from './default';

import { SET_NOTICE } from '../actions/alert';

// SIGN IN
const signInRequest = params => API.post('/login', params);
function* signInSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: SIGN_IN_SUCCESS, result, response });
    yield put(push('/dashboard'));
  }
}
function* signInFailureCallback() {
  yield put({
    type: SIGN_IN_FAILURE
  });
}
function* signIn(action) {
  yield runDefaultSaga(
    { request: signInRequest, params: action.params },
    signInSuccessCallback,
    signInFailureCallback
  );
}

// CREATE ACCOUNT

const signUpRequest = params => API.post('/signup', params);
function* signUpSuccessCallback(result, response, params) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: SIGN_UP_SUCCESS, result, response });
    const user = params;
    delete user.password_confirmation;
    yield put(requestSignIn(user));
  }
}
function* signUpFailureCallback() {
  yield put({
    type: SIGN_UP_FAILURE
  });
}
function* signUp(action) {
  yield runDefaultSaga(
    { request: signUpRequest, params: action.params },
    signUpSuccessCallback,
    signUpFailureCallback
  );
}

// SIGN OUT
const signOutRequest = () => API.delete('/logout');
function* signOutSuccessCallback(result) {
  if (result.success) {
    yield localStorage.removeItem('jwt');
    yield put({ type: SIGN_OUT_SUCCESS });
  } else {
    throw new Error(result);
  }
}
function* signOutFailureCallback() {
  yield put({ type: SIGN_OUT_FAILURE });
}
function* signOut() {
  yield runDefaultSaga(
    { request: signOutRequest },
    signOutSuccessCallback,
    signOutFailureCallback
  );
}

// VALIDATE TOKENS
const validateTokenRequest = () => API.get('/me');
function* validateTokensSuccessCallback(result, response) {
  // if (result.ok) {
  yield put({
    type: VALIDATE_TOKEN_SUCCESS,
    result,
    response,
    navigateTo: 'App'
  });
  // } else {
  //   throw new Error(response.errors.join('\n'));
  // }
}
function* validateTokensFailureCallback() {
  yield put({ type: CLEAR_AUTH_INFO, navigateTo: 'Auth' });
}
function* validateToken() {
  yield runDefaultSaga(
    { request: validateTokenRequest },
    validateTokensSuccessCallback,
    validateTokensFailureCallback
  );
}

// RECOVER PASSWORD
const recoverPasswordRequest = params => API.post('/password/forgot', params);
function* recoverPasswordSuccessCallback(result) {
  yield put({ type: PASSWORD_RECOVERY_SUCCESS });
  if (result.message) {
    yield put({
      type: SET_NOTICE,
      title: 'Éxito',
      message: result.message,
      kind: 'success',
      timeout: 2000
    });
    yield put(push('/login'));
  }
}
function* recoverPasswordFailureCallback() {
  // Error handled by runDefaultSaga
  yield put({ type: PASSWORD_RECOVERY_FAILURE });
}
function* recoverPassword(action) {
  yield runDefaultSaga(
    { request: recoverPasswordRequest, params: action.params },
    recoverPasswordSuccessCallback,
    recoverPasswordFailureCallback
  );
}

// RESET PASSWORD
const resetPasswordRequest = params => {
  return API.put('/profile', {
    user: params
  });
};
function* resetPasswordSuccessCallback() {
  yield put({ type: PASSWORD_RESET_SUCCESS });
  yield put({
    type: SET_REDIRECT,
    data: { path: '/guidelines', state: true }
  });
  yield put({
    type: SET_NOTICE,
    title: 'Éxito',
    message: 'Contraseña cambiada exitosamente',
    kind: 'success',
    timeout: 2000
  });
}
function* resetPasswordFailureCallback() {
  // Error handled by runDefaultSaga
  yield put({ type: PASSWORD_RESET_FAILURE });
}
function* resetPassword(action) {
  yield runDefaultSaga(
    { request: resetPasswordRequest, params: action.params },
    resetPasswordSuccessCallback,
    resetPasswordFailureCallback
  );
}

// DEFINE authSagas
export default function* authSagas() {
  yield takeEvery(SIGN_IN_REQUEST, signIn);
  yield takeEvery(SIGN_UP_REQUEST, signUp);
  yield takeEvery(SIGN_OUT_REQUEST, signOut);
  yield takeEvery(VALIDATE_TOKEN_REQUEST, validateToken);
  yield takeEvery(PASSWORD_RECOVERY_REQUEST, recoverPassword);
  yield takeEvery(PASSWORD_RESET_REQUEST, resetPassword);
}
