/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { Row, Col, Card, Nav, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withFormik, Field, FastField, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { BasicInput, CheckBoxM } from '../Input/index';
import Check from '../Input/Check';
import SelectAlt from '../Input/SelectAlt';

import {
  getCommunes,
  getEstablishmentTypes,
  getProjectTypes
} from '../../actions/utils';
import {
  updateEstablishment,
  createEstablishment
} from '../../actions/establishment';
// eslint-disable-next-line
import { Dialog } from '../Modal';
// eslint-disable-next-line
import SectionItemFom from '../Input/SectionItemForm';
import { createArrayYears, setDataYear } from '../../services/utils';
import {
  setDataGrades,
  validateSections,
  getyearsProgramStart,
  charRange,
  fullArray,
  generateObjectGrade,
  checkActiveGrades
} from './utils';
import { getTeachers } from '../../actions/teacher';
import {
  getDirectors,
  getCommunalCoordinators,
  getTutors
} from '../../actions/users';
import './style.scss';

class EstablishmentForm extends Component {
  constructor() {
    super();

    this.state = {
      tabSelected: 1,
      showDialog: false
    };
  }

  componentDidMount() {
    const { dispatch, data, values } = this.props;
    const dataCurrentYear = values.grades.find(
      item => item.year === values.year
    );

    dispatch(getEstablishmentTypes());
    dispatch(getProjectTypes());

    const sectionsSelected = dataCurrentYear
      ? dataCurrentYear.sections_info.filter(grad => grad.sections)
      : 1;
    if (sectionsSelected && sectionsSelected.length) {
      this.setState({
        tabSelected: sectionsSelected[0].grade_id || 1
      });
    }

    if (data.item.id !== undefined) {
      dispatch(getCommunes({ region_id: data.item.region.id }));
    }
  }

  componentDidUpdate() {
    const { values } = this.props;
    const { tabSelected } = this.state;
    const Sections = values.grades
      .find(item => Number(item.year) === Number(values.year))
      .sections_info.filter(grad => grad.sections);

    const statesections = Sections.find(sec => sec.grade_id === tabSelected);
    if (statesections === undefined && Sections.length > 0) {
      this.handleSelect(Sections[0].grade_id);
    }
  }

  requestTeachers = data => {
    const { dispatch } = this.props;
    dispatch(
      getTeachers({
        ...data,
        display_start: 0,
        // display_length: 5,
        sort_direction: 'desc'
      })
    );
  };

  requestTutors = data => {
    const { dispatch } = this.props;
    dispatch(
      getTutors({
        ...data,
        display_start: 0,
        // display_length: 5,
        sort_direction: 'desc',
        roles: [0, 1, 3]
      })
    );
  };

  requestDirectors = data => {
    const { dispatch } = this.props;
    dispatch(
      getDirectors({
        ...data,
        display_start: 0,
        // display_length: 5,
        sort_direction: 'desc',
        role: 2 // cambiar, poner el real
      })
    );
  };

  requestCommunalCoordinators = data => {
    const { dispatch } = this.props;
    dispatch(
      getCommunalCoordinators({
        ...data,
        display_start: 0,
        // display_length: 5,
        sort_direction: 'desc',
        roles: [1, 3]
      })
    );
  };

  requestEstablishmentTypes = () => {
    const { dispatch } = this.props;
    dispatch(getEstablishmentTypes());
  };

  requestProjectTypes = () => {
    const { dispatch } = this.props;
    dispatch(getProjectTypes());
  };

  handleSelect = eventKey => {
    this.setState({
      tabSelected: Number(eventKey)
    });
  };

  onChangeCheckBox = (value, gradeId, setFieldValue, year, index) => {
    setFieldValue(`grades[${year}].sections_info[${index}].sections`, value);
  };

  handleChangeprogramStart = (value, grades) => {
    const { setFieldValue, grades: gradesD, currentYear } = this.props;
    const chars = fullArray(6).map(charRange('A'));

    setFieldValue(`programStart`, value);

    const grad = createArrayYears(6, Number(value), currentYear).map(
      (item, index) => {
        const yearG = grades[index] !== undefined ? grades[index].year : 0;
        if (item === Number(yearG)) {
          return grades[index];
        }
        const gradesAux = gradesD.map(sec => ({
          state: false,
          grade_id: sec.id,
          sections: 0,
          sections_details: chars.map((detail, i) => ({
            gradeId: sec.id,
            position: i,
            guidance: 0,
            teacher_id: null,
            material_type: 0,
            state: false
          }))
        }));
        return { year: item, sections_info: gradesAux };
      }
    );
    setFieldValue(`grades`, grad);
  };

  validateRegion = (value, action) => {
    const { dispatch } = this.props;
    dispatch(getCommunes({ region_id: value }));
    action('region', value);
    action('commune', 0);
  };

  renderTabs = () => {
    const { values, grades } = this.props;
    const { tabSelected } = this.state;
    let gradesSelected = values.grades
      .find(item => Number(item.year) === Number(values.year))
      .sections_info.filter(grad => grad.state);

    gradesSelected = gradesSelected.filter(item => item.sections);

    if (gradesSelected !== null) {
      return gradesSelected.map(item => {
        const grade = grades.find(g => g.id === item.grade_id);
        return (
          <Nav.Item key={item.grade_id}>
            <Nav.Link
              eventKey={item.grade_id}
              active={tabSelected === item.grade_id}
              className="text-primary"
            >
              {grade.title}
            </Nav.Link>
          </Nav.Item>
        );
      });
    }
    return null;
  };

  onChangeSection = (value, nameItem, position) => {
    const { tabSelected } = this.state;
    const { values, setFieldValue } = this.props;
    const year = values.grades.findIndex(
      item => Number(item.year) === Number(values.year)
    );
    if (nameItem === 'Teacher') {
      setFieldValue(
        `grades[${year}].sections_info[${tabSelected -
          1}].sections_details[${position}].teacher_id`,
        value.value
      );
      setFieldValue(
        `grades[${year}].sections_info[${tabSelected -
          1}].sections_details[${position}].teacher.name`,
        value.label
      );
      setFieldValue(
        `grades[${year}].sections_info[${tabSelected -
          1}].sections_details[${position}].validate`,
        false
      );
    } else if (nameItem === 'Guidance') {
      setFieldValue(
        `grades[${year}].sections_info[${tabSelected -
          1}].sections_details[${position}].guidance`,
        Number(value)
      );
    } else if (nameItem === 'materialType') {
      setFieldValue(
        `grades[${year}].sections_info[${tabSelected -
          1}].sections_details[${position}].material_type`,
        Number(value)
      );
    }
  };

  renderSections = () => {
    const { values, teachers, onLoadTeacher, onLoadUser, tutors } = this.props;
    const { tabSelected } = this.state;
    const gradeSelected = values.grades
      .find(item => Number(item.year) === Number(values.year))
      .sections_info.filter(grad => grad.state)
      .find(grade => grade.grade_id === tabSelected);
    const sections = gradeSelected !== undefined ? gradeSelected.sections : 0;

    return gradeSelected !== undefined
      ? gradeSelected.sections_details.map(section => {
          if (sections > section.position) {
            return (
              <Row
                key={`${section.position}-${section.gradeId}`}
                className="mb-2"
              >
                <Col>
                  <SectionItemFom
                    data={section}
                    teachers={teachers || [{ key: 0, title: 'Cargando...' }]}
                    tutors={tutors || [{ key: 0, title: 'Cargando...' }]}
                    onChangeSection={this.onChangeSection}
                    requestTeachers={this.requestTeachers}
                    onLoadTeacher={onLoadTeacher.getTeacher}
                    requestTutors={this.requestTutors}
                    onLoadTutors={onLoadUser.getTutors}
                  />
                  <hr />
                </Col>
              </Row>
            );
          }
          return null;
        })
      : null;
  };

  onChangeGrades = (yearSelected, index, form, grade) => {
    const { setFieldValue } = this.props;
    const section = form.values.grades[yearSelected].sections_info[index];

    setFieldValue(`grades[${yearSelected}].sections_info[${index}]`, {
      state: !section.state,
      grade_id: grade.id,
      sections:
        grade.sections !== undefined ? grade.sections : section.sections,
      sections_details: section.sections_details,
      validate: false
    });

    if (section.state) {
      const chars = fullArray(6).map(charRange('A'));

      setFieldValue(
        `grades[${yearSelected}].sections_info[${index}].sections`,
        0
      );

      setFieldValue(
        `grades[${yearSelected}].sections_info[${index}].sections_details`,
        chars.map((detail, i) => ({
          gradeId: grade.id,
          position: i,
          guidance: 0,
          teacher_id: null,
          material_type: 0,
          state: false
        }))
      );
    }
  };

  handleShowDialog = state => {
    this.setState({
      showDialog: state
    });
  };

  requestGetCommune = () => {
    const { dispatch, values } = this.props;
    dispatch(getCommunes({ region_id: values.region }));
  };

  textMenssageEmail = (err, touch) => {
    if (err && touch) {
      return 'Introduce un correo válido';
    }
    if (!err && touch) {
      return 'Correo aceptado';
    }
    return '';
  };

  render() {
    const {
      communes,
      establishmentTypes,
      projectTypes,
      regions,
      ongoingRequest,
      isSubmitting,
      setFieldValue,
      grades,
      values,
      errors,
      isValid,
      data,
      redirect,
      touched,
      onLoadTeacher,
      directors,
      communalCoordinators,
      onLoadUser,
      onLoad
    } = this.props;
    const { showDialog } = this.state;
    const yearsProgramStart = getyearsProgramStart(values.grades).sort();

    const DateAux = values.grades.findIndex(
      item => Number(item.year) === Number(values.year)
    );
    const yearSelected = DateAux !== -1 ? DateAux : 0;

    return (
      (values.commune || data.action === 'create') && (
        <Form>
          <Row>
            <Col>
              <Row>
                <Col xs={6}>
                  <FastField
                    name="name"
                    render={({ field }) => (
                      <BasicInput
                        className={
                          errors.name && touched.name ? 'is-invalid' : ''
                        }
                        {...field}
                        disabled={isSubmitting}
                        placeholder="Nombre"
                        title="Nombre"
                      />
                    )}
                  />
                </Col>
                <Col>
                  <Field
                    name="director_user_id"
                    render={({ field }) => (
                      <SelectAlt
                        {...field}
                        placeholder="Seleccione"
                        data={directors}
                        initialValue={
                          values.director_user && {
                            value: values.director_user_id,
                            label: values.director_user
                          }
                        }
                        server
                        isSearchable
                        dispatchRequest={this.requestDirectors}
                        onChangeSelect={e => {
                          setFieldValue('director_user_id', e.value);
                          setFieldValue('director_user', e.label);
                        }}
                        onLoad={onLoadUser.getDirectors}
                        title="Director"
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  {ongoingRequest.getRegions && regions.length === 0 ? (
                    <BasicInput
                      placeholder="Cargando Regiones..."
                      title="Región"
                    />
                  ) : (
                    <Field
                      name="region"
                      render={() => (
                        <SelectAlt
                          placeholder="Región"
                          value={values.region}
                          text={
                            errors.region !== undefined
                              ? errors.region
                              : 'Campo requerido'
                          }
                          onChangeSelect={e =>
                            this.validateRegion(e.value, setFieldValue)
                          }
                          disabled={isSubmitting}
                          data={regions}
                          title="Región"
                        />
                      )}
                    />
                  )}
                </Col>

                <Col>
                  <Field
                    name="commune"
                    render={({ field }) => (
                      <SelectAlt
                        {...field}
                        placeholder="Seleccione"
                        data={communes}
                        initialValue={
                          values.commune && {
                            value: values.commune,
                            label: values.commune_name
                          }
                        }
                        server
                        isSearchable
                        dispatchRequest={this.requestGetCommune}
                        onChangeSelect={e => {
                          setFieldValue('commune', e.value);
                          setFieldValue('commune_name', e.label);
                        }}
                        reset={values.commune !== 0}
                        disabled={values.region === 0 || values.region === ''}
                        onLoad={
                          ongoingRequest.getCommune || ongoingRequest.getRegions
                        }
                        title="Comuna"
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FastField
                    name="address"
                    render={({ field }) => (
                      <BasicInput
                        {...field}
                        type="text"
                        text={errors.address}
                        placeholder="Escriba la dirección"
                        title="Dirección"
                      />
                    )}
                  />
                </Col>
                <Col>
                  <FastField
                    name="phone"
                    render={({ field }) => (
                      <BasicInput
                        {...field}
                        type="number"
                        text={errors.phone}
                        placeholder="999999999"
                        title="Telefono"
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FastField
                    name="utp"
                    render={({ field }) => (
                      <BasicInput
                        {...field}
                        type="text"
                        text={errors.utp}
                        placeholder="Nombre del Jefe de UTP"
                        title="Jefe de UTP"
                      />
                    )}
                  />
                </Col>

                <Col>
                  <Field
                    name="communal_coordinator_id"
                    render={({ field }) => (
                      <SelectAlt
                        {...field}
                        placeholder="Seleccione"
                        data={communalCoordinators}
                        initialValue={
                          values.communal_coordinator && {
                            value: values.communal_coordinator_id,
                            label: values.communal_coordinator
                          }
                        }
                        server
                        isSearchable
                        dispatchRequest={this.requestCommunalCoordinators}
                        onChangeSelect={e => {
                          setFieldValue('communal_coordinator_id', e.value);
                          setFieldValue('communal_coordinator', e.label);
                        }}
                        onLoad={onLoadUser.getCommunalCoordinators}
                        title="Mentora"
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="inputRow">
                <Col>
                  {ongoingRequest.getProjectTypes &&
                  projectTypes.length === 0 ? (
                    <BasicInput
                      placeholder="Cargando Tipos de Proyecto..."
                      title="Tipo de Proyecto"
                    />
                  ) : (
                    <Field
                      name="project_type"
                      render={({ field }) => (
                        <SelectAlt
                          {...field}
                          placeholder="Seleccione"
                          // value={values.project_type}
                          reset={values.project_type !== 0}
                          onChangeSelect={e => {
                            setFieldValue('project_type', e.value);
                            setFieldValue('project_type_name', e.label);
                          }}
                          initialValue={
                            values.project_type && {
                              value: values.project_type,
                              label: values.project_type_name
                            }
                          }
                          data={projectTypes}
                          server
                          // isSearchable
                          dispatchRequest={this.requestProjectTypes}
                          onLoad={ongoingRequest.getProjectTypes}
                          title="Tipo de proyecto"
                        />
                      )}
                    />
                  )}
                </Col>
                <Col>
                  <FastField
                    name="email"
                    render={({ field }) => (
                      <BasicInput
                        {...field}
                        type="text"
                        text={this.textMenssageEmail(
                          errors.email,
                          touched.email
                        )}
                        className={
                          errors.email && touched.email ? 'is-invalid' : ''
                        }
                        placeholder="ejemplo@email.com"
                        title="Correo"
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FastField
                    name="programStart"
                    render={() => (
                      <SelectAlt
                        value={values.programStart}
                        onChangeSelect={e =>
                          this.handleChangeprogramStart(e.value, values.grades)
                        }
                        data={setDataYear(yearsProgramStart)}
                        title="Inicio del programa"
                      />
                    )}
                  />
                </Col>
                <Col>
                  <FastField
                    name="rbd"
                    render={({ field }) => (
                      <BasicInput
                        {...field}
                        type="text"
                        className={
                          errors.rbd && touched.rbd ? 'is-invalid' : ''
                        }
                        placeholder="12312312"
                        title="RBD"
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  {ongoingRequest.getEstablishmentTypes &&
                  establishmentTypes.length === 0 ? (
                    <BasicInput
                      placeholder="Cargando Tipos de Establecimiento..."
                      title="Tipo de Establecimiento"
                    />
                  ) : (
                    <Field
                      name="establishment_type"
                      render={({ field }) => (
                        <SelectAlt
                          {...field}
                          placeholder="Seleccione"
                          // value={values.establishment_type}
                          onChangeSelect={e => {
                            setFieldValue('establishment_type', e.value);
                            setFieldValue('establishment_type_name', e.label);
                          }}
                          initialValue={
                            values.establishment_type && {
                              value: values.establishment_type,
                              label: values.establishment_type_name
                            }
                          }
                          data={establishmentTypes}
                          title="Tipo de establecimiento"
                          server
                          // isSearchable
                          dispatchRequest={this.requestEstablishmentTypes}
                          onLoad={ongoingRequest.getEstablishmentTypes}
                        />
                      )}
                    />
                  )}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>
                  <Field
                    name="year"
                    render={() => (
                      <SelectAlt
                        value={values.year}
                        onChangeSelect={e => setFieldValue('year', e.value)}
                        data={setDataYear(
                          createArrayYears(
                            5,
                            Number(values.programStart),
                            new Date().getFullYear()
                          )
                        )}
                        title="Año"
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <FieldArray
                    name="grades"
                    render={() => (
                      <div>
                        <Row className="mt-4">
                          <Col xs={4}>Cursos</Col>
                          <Col xs={8}>Secciones</Col>
                        </Row>
                        {grades.map((grade, index) => {
                          return (
                            // eslint-disable-next-line
                            <Row key={index} className="mb-2">
                              <Col xs={4} className="mt-1">
                                <Field
                                  name={`grades[${yearSelected}].sections_info[${index}]`}
                                  render={({ field, form }) => (
                                    <CheckBoxM
                                      custom
                                      {...field}
                                      controlId={index}
                                      text={grade.title}
                                      checked={
                                        form.values.grades[yearSelected]
                                          .sections_info[index].state || false
                                      }
                                      onChange={() =>
                                        this.onChangeGrades(
                                          yearSelected,
                                          index,
                                          form,
                                          grade
                                        )
                                      }
                                    />
                                  )}
                                />
                              </Col>
                              <Col xs={8}>
                                <Field
                                  name={`grades[${yearSelected}].sections_info[${index}]`}
                                  render={({ form }) => (
                                    <Check
                                      name={index}
                                      checkSelected={
                                        form.values.grades[yearSelected]
                                          .sections_info[index].sections
                                      }
                                      amount={6}
                                      onChangeCheckBox={(a, b) =>
                                        this.onChangeCheckBox(
                                          a,
                                          b,
                                          setFieldValue,
                                          yearSelected,
                                          index
                                        )
                                      }
                                      disabled={
                                        !form.values.grades[yearSelected]
                                          .sections_info[index].state
                                      }
                                    />
                                  )}
                                />
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <Row>
                <Col>
                  <Button
                    onClick={() => this.handleShowDialog(true)}
                    block
                    variant="light"
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="submit"
                    block
                    disabled={
                      !isValid
                      // ||
                      // onLoad.createEstablishmentRequest ||
                      // onLoad.createGradesEstablishment
                    }
                  >
                    {data.action === 'edit' ? 'Guardar' : 'Crear'}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {checkActiveGrades(values.grades, values.year) &&
            !onLoadTeacher.getTeachers && (
              <Field
                name="sections"
                render={() => (
                  <Card>
                    <Card.Header>
                      <Nav variant="tabs" onSelect={this.handleSelect}>
                        {this.renderTabs()}
                      </Nav>
                    </Card.Header>
                    <Card.Body>{this.renderSections()}</Card.Body>
                  </Card>
                )}
              />
            )}

          <Row style={{ marginBottom: 130, marginTop: 50 }}>
            <Col xs={{ span: 3, offset: 6 }}>
              <Button
                onClick={() => this.handleShowDialog(true)}
                block
                variant="light"
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={3}>
              <Button
                type="submit"
                block
                disabled={
                  !isValid ||
                  onLoad.createEstablishmentRequest ||
                  onLoad.createGradesEstablishment
                }
              >
                {data.action === 'edit' ? 'Guardar' : 'Crear'}
              </Button>
            </Col>
          </Row>
          <Dialog
            show={showDialog}
            data={{
              title: 'Confirma para salir',
              message: '¿Seguro abandonar esta pantalla?'
            }}
            onHide={() => this.handleShowDialog(false)}
            action={() => redirect('/establishment-and-courses')}
          />
        </Form>
      )
    );
  }
}

const mapStateToProps = state => {
  const {
    ongoingRequest,
    communes,
    establishmentTypes,
    projectTypes,
    regions,
    grades
  } = state.utils;
  const {
    indexTeachers: teachers,
    ongoingRequest: onLoadTeacher
  } = state.teacher;
  const { ongoingRequest: onLoad, errors: errorsEst } = state.establishment;
  const {
    directors,
    communalCoordinators,
    ongoingRequest: onLoadUser,
    tutors
  } = state.user;
  return {
    ongoingRequest,
    communes,
    establishmentTypes,
    projectTypes,
    regions,
    grades,
    teachers,
    onLoad,
    errorsEst,
    onLoadTeacher,
    directors,
    communalCoordinators,
    onLoadUser,
    tutors
  };
};

EstablishmentForm.defaultProps = {};

export default connect(mapStateToProps)(
  withFormik({
    mapPropsToValues(props) {
      const { currentYear, data } = props;
      const chars = fullArray(6).map(charRange('A'));
      const years = data.create
        ? createArrayYears(6, currentYear)
        : createArrayYears(5, Number(data.item.beginning_year), currentYear);
      if (props.data.item.id) {
        const {
          name,
          region: { id: region },

          commune: { id: commune },
          // eslint-disable-next-line camelcase
          project_type: { id: project_type },
          establishment_type: { id: establishment_type },
          rbd,
          // eslint-disable-next-line
          grades_info,
          // eslint-disable-next-line
          beginning_year,
          // eslint-disable-next-line
          // eslint-disable-next-line
          director_user_id,
          // eslint-disable-next-line
          director_user,
          // eslint-disable-next-line
          communal_coordinator_id,
          // eslint-disable-next-line
          communal_coordinator,
          // eslint-disable-next-line
          utp,
          address,
          email,
          phone
        } = props.data.item;
        // eslint-disable-next-line
        const commune_name = props.data.item.commune
          ? props.data.item.commune.name
          : '';
        // eslint-disable-next-line
        const project_type_name = props.data.item.project_type
          ? props.data.item.project_type.name
          : '';
        // eslint-disable-next-line
        const establishment_type_name = props.data.item.establishment_type
          ? props.data.item.establishment_type.name
          : '';
        const gradesData = years.map(gradeYear => {
          const sectionsInfo = props.grades.map(grade => {
            const gradeInfo = grades_info.find(y => y.year === gradeYear)
              ? grades_info
                  .find(y => y.year === gradeYear)
                  .sections_info.find(g => g.grade_id === grade.id)
              : undefined;
            return {
              state: gradeInfo !== undefined,
              grade_id: grade.id,
              sections: gradeInfo !== undefined ? gradeInfo.sections : 0,
              sections_details: chars.map((detail, i) => {
                if (gradeInfo !== undefined) {
                  if (gradeInfo.sections_details[i] !== undefined) {
                    return {
                      ...gradeInfo.sections_details[i],
                      position: i,
                      state: true,
                      gradeId: grade.id,
                      validate: false
                    };
                  }
                }
                return {
                  gradeId: grade.id,
                  position: i,
                  guidance: 0,
                  teacher_id: null,
                  material_type: 0,
                  state: false,
                  validate: false
                };
              })
            };
          });

          return { sections_info: sectionsInfo, year: gradeYear };
        });

        return {
          name: name !== undefined ? name : '',
          region: region !== undefined ? region : '',
          commune: commune !== undefined ? commune : '',
          commune_name,
          establishment_type:
            establishment_type !== undefined ? establishment_type : '',
          establishment_type_name,

          project_type: project_type !== undefined ? project_type : '',
          project_type_name,
          grades: gradesData,
          year: currentYear,
          // eslint-disable-next-line camelcase
          programStart: beginning_year || currentYear,
          rbd: rbd || '',
          // eslint-disable-next-line camelcase
          // eslint-disable-next-line camelcase
          director_user_id: director_user_id || '',
          // eslint-disable-next-line camelcase
          director_user: director_user ? director_user.name : '',
          utp: utp || '',
          address: address || '',
          // eslint-disable-next-line camelcase
          email: email || '',
          phone: phone || '',
          // eslint-disable-next-line
          communal_coordinator_id: communal_coordinator_id || '',
          // eslint-disable-next-line camelcase
          communal_coordinator: communal_coordinator
            ? communal_coordinator.name
            : ''
        };
      }
      const gradesInfo = generateObjectGrade(years, props.grades, chars);

      return {
        name: '',
        region: '',
        commune: '',
        year: currentYear,
        programStart: currentYear,
        grades: gradesInfo,
        rbd: '',
        project_type: '',
        project_type_name: '',
        establishment_type: '',
        establishment_type_name: '',
        director_user_id: '',
        director_user: '',
        utp: '',
        address: '',
        email: '',
        communal_coordinator_id: '',
        communal_coordinator: '',
        commune_name: ''
      };
    },
    validate(values) {
      const errors = {};
      if (!validateSections(values.grades)) {
        errors.grades = 'Te faltan datos en las secciones';
      }
      return errors;
    },

    validateOnMount: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Este campo es requerido'),
      commune: Yup.number().required('Comuna requerida'),
      programStart: Yup.string().required('Inicio del programa es requerido'),
      rbd: Yup.string().required('Este campo es requerido'),
      email: Yup.string().email('Introduce un correo válido')
      // establishment_type: Yup.number().required(
      //   'Tipo de establecimiento es obligatorio'
      // )
    }),
    handleSubmit(values, { props, setSubmitting }) {
      setSubmitting(false);
      const { dispatch, data } = props;
      const dataEstablishment = {
        name: values.name,
        commune_id: Number(values.commune),
        rbd: values.rbd,
        beginning_year: values.programStart,
        grades_info: setDataGrades(values),
        project_type: values.project_type,
        director_user_id: values.director_user_id,
        utp: values.utp,
        phone: String(values.phone),
        email: values.email,
        address: values.address,
        communal_coordinator_id: values.communal_coordinator_id,
        establishment_type: values.establishment_type
      };
      if (data.create) {
        dispatch(
          createEstablishment({
            establishment: dataEstablishment
          })
        );
      } else {
        dispatch(
          updateEstablishment({
            establishment: dataEstablishment,
            id: data.item.id
          })
        );
      }
    }
  })(EstablishmentForm)
);
