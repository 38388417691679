import React from 'react';
import { Spinner, Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './style.scss';

const LoaderText = props => {
  const { name, download } = props;
  return (
    <div>
      {name ? (
        <Container className="containerSpinnerText">
          <Row className="containertext">
            <Col>
              <div className="mt-1">
                <b style={{ color: 'gray', fontSize: '16px' }}>
                  {download ? 'Descargando' : `Generando ${name}`}
                </b>
                <br />
                <span>
                  <p className="mt-2 text-muted">
                    Esto puede tardar unos minutos, no cierres tu navegador.
                  </p>
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Spinner {...props} />
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="containerSpinnerText">
          <Spinner {...props} />
        </div>
      )}
    </div>
  );
};

LoaderText.propTypes = {
  animation: PropTypes.string,
  variant: PropTypes.string,
  position: PropTypes.string,
  height: PropTypes.string
};
LoaderText.defaultProps = {
  animation: 'border',
  variant: 'primary',
  position: 'absolute',
  height: '100vh'
};

export default LoaderText;
