import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { saveFirstTest, updateFirstStep } from '../../actions/test';
import { BasicInput } from '../Input/index';
import { Select } from '../Dropdowm/index';
import { DefaultButton } from '../Button/index';
import { getGrades } from '../../actions/utils';
import { getGuidelines } from '../../actions/guideline';

class TypeTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guideline_id: props.quiz.guideline_id,
      name: props.quiz.name,
      grade_id: props.quiz.grade_id,
      year: props.quiz.year,
      in_step: props.currentStep,
      axes_attributes: props.quiz.axes_attributes,
      default_axis: true
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(getGuidelines({ text: '', start: '', length: '' }));
    dispatch(getGrades({ select: true }));
  }

  nextStep = () => {
    const { dispatch, nextStep, quiz } = this.props;
    if (!quiz.id) {
      dispatch(saveFirstTest(this.state));
    } else {
      dispatch(updateFirstStep({ id: quiz.id, ...this.state }));
    }
    nextStep();
  };

  render() {
    // eslint-disable-next-line
    const { guideline_id, name, grade_id, year } = this.state;
    const { guidelines, grades, quiz } = this.props;
    return (
      <div>
        <Row>
          <Col xs={8}>
            <Row>
              <Col xs={5}>
                <Select
                  items={guidelines}
                  // eslint-disable-next-line
                  value={guideline_id}
                  className="secondary"
                  onChange={e =>
                    this.setState({ guideline_id: Number(e.target.value) })
                  }
                  disabled={quiz.with_reviews}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
                <BasicInput
                  placeholder="Nombre de Evaluación"
                  value={name}
                  className="secondary"
                  onChange={e => this.setState({ name: e.target.value })}
                />
                <p className="text-muted">Ingrese el nombre que corresponda</p>
              </Col>
              <Col xs={5}>
                <Select
                  items={grades}
                  className="secondary"
                  // eslint-disable-next-line
                  value={grade_id}
                  onChange={e =>
                    this.setState({ grade_id: Number(e.target.value) })
                  }
                  disabled={quiz.with_reviews}
                />
              </Col>
              <Col>
                <BasicInput
                  type="number"
                  placeholder="Año"
                  width="8%"
                  className="secondary"
                  value={year === 0 ? '' : year}
                  onChange={e =>
                    this.setState({
                      year: Number(e.target.value)
                    })
                  }
                  disabled={quiz.with_reviews}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 3, offset: 9 }}>
            <DefaultButton
              text="Siguiente"
              variant="secondary"
              onClick={this.nextStep}
              block
              disabled={
                // eslint-disable-next-line
                guideline_id === 0 ||
                Number.isNaN(guideline_id) ||
                name === '' ||
                year === '' ||
                year === 0
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}
// && (name && year !== '')
const mapStateToProps = state => {
  const {
    currentStep,
    dataTypeTest,
    quiz: { quiz }
  } = state.test;
  const { grades } = state.utils;
  const { guidelines } = state.guideline;
  return {
    currentStep,
    quiz,
    dataTypeTest,
    grades,
    guidelines
  };
};

export default connect(mapStateToProps)(TypeTest);
