import { createArrayYears } from '../../services/utils';

// set data grades to the backend
const setDataGrades = values => {
  let gradesAux = values.grades;
  gradesAux = gradesAux.filter(item => item.sections_info.length > 0);
  gradesAux = gradesAux.map(years => {
    return {
      sections_info: years.sections_info.map(grad => ({
        ...grad,
        sections_details: grad.sections_details.slice(0, grad.sections)
      })),
      year: years.year
    };
  });
  return gradesAux;
};

const validateSections = grades => {
  let state = true;
  grades.forEach(year => {
    year.sections_info.forEach(grade => {
      if (grade.state) {
        grade.sections_details.forEach(section => {
          if (section.validate && section.position < grade.sections) {
            state = false;
          }
        });
      }
    });
  });

  return state;
};

const getyearsProgramStart = grades => {
  // get min date
  let minDate = grades.filter(item => {
    if (item.sections_info.some(i => i.state)) {
      return item;
    }
    return false;
  });
  minDate = minDate.map(item => item.year).sort();

  return minDate[0] < 2019
    ? createArrayYears(6, minDate[0] - 4)
    : createArrayYears(6);
};

const charRange = start => {
  const base = start.charCodeAt(0);
  // eslint-disable-next-line func-names
  return function(_, i) {
    return String.fromCharCode(i + base);
  };
};

const fullArray = len => {
  return [].concat(...Array(len));
};

const generateObjectGrade = (years, grades, chars) => {
  return years.map(year => {
    const gradesAux = grades.map(item => ({
      state: false,
      grade_id: item.id,
      sections: 0,
      sections_details: chars.map((details, i) => ({
        gradeId: item.id,
        position: i,
        guidance: 0,
        teacher_id: null,
        material_type: 0,
        state: false,
        validate: false
      }))
    }));
    return { year, sections_info: gradesAux };
  });
};

const checkActiveGrades = (grades, yearSelected) => {
  let state = false;
  grades.forEach(year => {
    if (year.year === Number(yearSelected)) {
      year.sections_info.forEach(grade => {
        if (grade.state && grade.sections > 0) {
          state = true;
        }
      });
    }
  });

  return state;
};

// -- Form Student --

const setDataFormStudent = (student, Years) => {
  const currentYear = new Date().getFullYear();

  const sections = Years.map((item, i) => {
    const section = student.sections_detail
      ? student.sections_detail.find(sec => sec.year === item.id)
      : 0;
    return {
      yearSelected: i,
      year: item.id,
      establishment: section ? section.establishment_id : 0,
      establishmentName: section ? section.establishment.name : 0,
      grade: section ? section.grade_id : 0,
      section: section ? section.id : 0,
      description: student.description
      // establishment_name: section ? section.establishment.name : ' '
    };
  });
  return {
    ...student,
    year: currentYear,
    sections_detail: sections
  };
};

const getYearStudent = student => {
  const currentYear = new Date().getFullYear();
  let firstYear =
    student.sections_detail && student.sections_detail.length !== 0
      ? student.sections_detail[0].year
      : currentYear;
  firstYear = firstYear > currentYear ? currentYear : firstYear;
  const years = createArrayYears(3, firstYear, currentYear).sort();
  return years.map((item, i) => ({
    key: i,
    id: item,
    title: item
  }));
};

const getIndexYear = (years, currentYear) => {
  return years.findIndex(item => item.id === currentYear);
};

const setDataStudentRequest = data => {
  // console.log(data.sections_detail);
  let sections = data.sections_detail.filter(item => item.section !== 0);
  sections = sections.map(item => Number(item.section));
  // console.log(sections);
  return {
    ...data,
    sections_detail: data.sections_detail,
    section_ids: sections
  };
};

// --------- SCORE --------

const validateState = values => {
  if (values) {
    return values.some(item => {
      return item.question_reviews_attributes.some(question => {
        if (question.question_type === 'choices') {
          return !question.answer;
        }

        if (question.question_type === 'long') {
          if (question.answer === 0) {
            return false;
          }
          return !(question.answer || question.percentage);
        }

        return false;
      });
    });
  }
  return false;
};

const successString = 'Alumnos cargados con éxito.';

export {
  setDataGrades,
  validateSections,
  getyearsProgramStart,
  charRange,
  fullArray,
  generateObjectGrade,
  checkActiveGrades,
  setDataFormStudent,
  getYearStudent,
  getIndexYear,
  setDataStudentRequest,
  validateState,
  successString
};
