import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Bar, LabelList } from 'recharts';
import { ChartStackedBar, AgroupedChart } from '../Charts';
import { NoImage, CustomizedLabel } from '../Utils';
import './style.scss';

class NacionalReport extends Component {
  constructor() {
    super();
    this.state = {};
  }

  formatNumber = data => {
    if (data === 0) {
      return '';
    }
    return `${data}%`;
  };

  renderTypeOfChart = (chart, styleChart, data) => {
    if (data.graph_type === 0) {
      return data ? (
        <ChartStackedBar dataChart={chart} ticks={[0, 50, 100]} name={75}>
          {styleChart.map(style => {
            return (
              <Bar
                key={style.key}
                dataKey={style.key}
                stackId="a"
                fill={style.colour}
                maxBarSize={40}
                isAnimationActive={false}
              >
                <LabelList
                  dataKey={style.key}
                  position="top"
                  angle={data.data.length >= 15 ? 90 : undefined}
                  formatter={value => this.formatNumber(value)}
                />
              </Bar>
            );
          })}
        </ChartStackedBar>
      ) : (
        <NoImage />
      );
    }
    if (data.graph_type === 1 || data.graph_type === 2) {
      return data ? (
        <ChartStackedBar dataChart={chart} ticks={[0, 50, 100]} name={75}>
          {styleChart.map(style => {
            return (
              <Bar
                key={style.key}
                dataKey={style.key}
                stackId="a"
                fill={style.colour}
                maxBarSize={40}
                isAnimationActive={false}
              >
                <LabelList
                  dataKey={style.key}
                  position="center"
                  formatter={value => this.formatNumber(value)}
                  content={<CustomizedLabel color={style.colour} />}
                />
              </Bar>
            );
          })}
        </ChartStackedBar>
      ) : (
        <NoImage />
      );
    }
    if (data.graph_type === 5) {
      return data ? (
        <ChartStackedBar dataChart={chart} ticks={[0, 50, 100]} name={75}>
          {styleChart.map(style =>
            style.map(styleChartt => {
              return (
                <Bar
                  key={style.key}
                  dataKey={styleChartt.key}
                  stackId="a"
                  fill={styleChartt.colour}
                  maxBarSize={40}
                  isAnimationActive={false}
                >
                  <LabelList
                    dataKey={styleChartt.key}
                    position="center"
                    formatter={value => this.formatNumber(value)}
                    content={<CustomizedLabel color={style.colour} />}
                  />
                </Bar>
              );
            })
          )}
        </ChartStackedBar>
      ) : (
        <NoImage />
      );
    }
    if (data.graph_type === 6 || data.graph_type === 7) {
      return data ? (
        <AgroupedChart dataChart={chart} ticks={[0, 50, 100]} name={85}>
          {styleChart.map(style => {
            return (
              <Bar
                key={style.key}
                dataKey={style.key}
                stackId="a"
                fill={style.colour}
                maxBarSize={40}
                isAnimationActive={false}
              >
                <LabelList
                  dataKey={style.key}
                  position={data.graph_type === 7 ? 'center' : 'top'}
                  angle={
                    data.graph_type === 6 && data.data.length >= 10
                      ? 90
                      : undefined
                  }
                  formatter={value => this.formatNumber(value)}
                  content={
                    data.graph_type === 7 && (
                      <CustomizedLabel color={style.colour} />
                    )
                  }
                />
              </Bar>
            );
          })}
        </AgroupedChart>
      ) : (
        <NoImage />
      );
    }
    return <NoImage />;
  };

  renderChart = () => {
    const { data } = this.props;
    return data ? (
      data.map((chart, index) => {
        const dataForChart = chart.data;
        const styleForChart = chart.styles;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div id={`chart-${index + 1}`} className="div-chart" key={index}>
            <p className="p-chart">
              <span className="title-chart"> {chart.title}</span>
              <br />
              <span className="subtitle-chart">{chart.subtitle}</span>
            </p>
            {this.renderTypeOfChart(dataForChart, styleForChart, chart)}
            <hr className="myhrline" />
          </div>
        );
      })
    ) : (
      <center>
        <NoImage />
      </center>
    );
  };

  render() {
    return (
      <Container className="container-charts mt-4">
        <h2 className="title-report">Informe Nacional</h2>
        {this.renderChart()}
      </Container>
    );
  }
}

export default NacionalReport;
