const guidances = [
  { key: 0, id: 0, title: 'Sin seguimiento' },
  { key: 1, id: 1, title: 'Semanal' },
  { key: 2, id: 2, title: 'Quincenal' },
  { key: 3, id: 3, title: 'Mensual' }
];

const materialTypes = [
  { key: 0, id: 0, title: 'Nuevo' },
  { key: 1, id: 1, title: 'Antiguo' }
];
export { guidances, materialTypes };
