import React from 'react';
import { Image } from 'react-bootstrap';

const logo = require('../../assets/images/logo.png');
const logoWhite = require('../../assets/images/logo-w.png');

const MainLogo = ({ width, style }) => {
  return (
    <Image
      src={logo}
      alt="Fundación Crecer"
      className="logo"
      width={width}
      style={style}
    />
  );
};

const MainLogoWhite = () => {
  return <Image src={logoWhite} alt="Fundación Crecer" />;
};

export { MainLogo, MainLogoWhite };
