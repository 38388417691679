import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SET_REDIRECT,
  GET_DIRECTORS_REQUEST,
  GET_DIRECTORS_SUCCESS,
  GET_DIRECTORS_FAILURE,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  GET_COMMUNAL_COORDINATORS_REQUEST,
  GET_COMMUNAL_COORDINATORS_SUCCESS,
  GET_COMMUNAL_COORDINATORS_FAILURE,
  GET_TUTORS_REQUEST,
  GET_TUTORS_SUCCESS,
  GET_TUTORS_FAILURE
} from '../actions/users';

const initialState = {
  ongoingRequest: {
    getUsers: false,
    getCommunalCoordinators: false,
    getDirectors: false,
    getTutors: false,
    getProjects: false
  },
  totalRows: 0,
  users: [],
  usersTranslate: [],
  directors: [],
  projects: [],
  communalCoordinators: [],
  tutors: [],
  redirect: {
    path: '',
    state: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REDIRECT:
      return {
        ...state,
        redirect: {
          path: action.data.path,
          state: action.data.state
        }
      };
    case GET_USERS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getUsers: true
        }
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getUsers: false
        },
        users: action.result.data,
        totalRows: action.result.metadata.amount,
        usersTranslate: action.property
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getUsers: false
        }
      };
    case CREATE_USER_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createUser: true
        }
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createUser: false
        }
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createUser: false
        }
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateUser: true
        }
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateUser: false
        }
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateUser: false
        }
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteUser: true
        }
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteUser: false
        }
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteUser: false
        }
      };
    case GET_DIRECTORS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getDirectors: true
        }
      };
    case GET_DIRECTORS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getDirectors: false
        },
        directors: action.result.data
      };

    case GET_DIRECTORS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getDirectors: false
        }
      };
    case GET_PROJECTS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getProjects: true
        }
      };
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getProjects: false
        },
        projects: action.result.data
      };

    case GET_PROJECTS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getProjects: false
        }
      };
    case GET_COMMUNAL_COORDINATORS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          getCommunalCoordinators: true
        }
      };
    case GET_COMMUNAL_COORDINATORS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          getCommunalCoordinators: false
        },
        communalCoordinators: action.result.data
      };
    case GET_COMMUNAL_COORDINATORS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          getCommunalCoordinators: false
        }
      };
    case GET_TUTORS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          getTutors: true
        }
      };
    case GET_TUTORS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          getTutors: false
        },
        tutors: action.result.data
      };
    case GET_TUTORS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          getTutors: false
        }
      };
    default:
      return state;
  }
};

export default reducer;
