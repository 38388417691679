import {
  AMOUNT_ESTABLISHMENT_REQUEST,
  AMOUNT_ESTABLISHMENT_SUCCESS,
  AMOUNT_ESTABLISHMENT_FAILURE,
  AMOUNT_SECTIONS_REQUEST,
  AMOUNT_SECTIONS_SUCCESS,
  AMOUNT_SECTIONS_FAILURE,
  AMOUNT_STUNDETS_REQUEST,
  AMOUNT_STUNDETS_SUCCESS,
  AMOUNT_STUNDETS_FAILURE
} from '../actions/dashboard';

const initialState = {
  amount: {
    students: 0,
    establishments: 0,
    sections: 0
  },
  ongoingRequest: {
    students: false,
    establishments: false,
    sections: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AMOUNT_ESTABLISHMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, establishments: true }
      };
    case AMOUNT_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, establishments: false },
        amount: { ...state.amount, establishments: action.result }
      };
    case AMOUNT_ESTABLISHMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, establishments: false }
      };
    case AMOUNT_SECTIONS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, sections: true }
      };
    case AMOUNT_SECTIONS_SUCCESS:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, sections: false },
        amount: { ...state.amount, sections: action.result }
      };
    case AMOUNT_SECTIONS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, sections: false }
      };
    case AMOUNT_STUNDETS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, students: true }
      };
    case AMOUNT_STUNDETS_SUCCESS:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, students: false },
        amount: { ...state.amount, students: action.result }
      };
    case AMOUNT_STUNDETS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, students: false }
      };
    default:
      return state;
  }
};

export default reducer;
