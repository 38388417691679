import React from 'react';
import { Row, Col, Tab, Tabs, TabPane } from 'react-bootstrap';
import './styles.scss';
import ShowTestType from '../Quiz/TestType/ShowTestType';
import { SubTitleH2 } from '../Text';
import { ArrowLeftIndex, LoadSpinner } from '../Utils';

const axes = axesAttributes =>
  axesAttributes.map((axe, axeIndex) => (
    <Tab
      eventKey={axeIndex}
      key={axe.id}
      title={<p className="text-dark mb-0"> {axe.name}</p>}
    >
      <TabPane>
        {axe.sub_axes_attributes.map(subAxe => {
          // let itemNumber = 0;
          return (
            <div>
              <p className="font-weight-bold mt-3 mb-1">{subAxe.name}</p>
              {subAxe.indicators_attributes.map(indicator =>
                indicator.questions_attributes.map(question => {
                  // itemNumber += 1;
                  return (
                    <div className="box-line">
                      <Row>
                        <Col md={6}>
                          <p className="mb-0">
                            {/* {itemNumber}. */}
                            Item {question.position}
                          </p>
                        </Col>
                        <Col md={3}>
                          <p className="text-center mb-0">
                            {question.question_type_id === 1
                              ? `${question.question_type.name} ${question.answer_as_letter}`
                              : `${question.question_type.name}`}
                          </p>
                        </Col>
                        <Col md={3}>
                          <p className="text-center mb-0">
                            {question.question_type_id === 3 ||
                            question.question_type_id === 4
                              ? question.question_performances_attributes.map(
                                  (performance, performIndex) =>
                                    performIndex + 1 ===
                                    question.question_performances_attributes
                                      .length
                                      ? `${performance.score} `
                                      : `${performance.score} / `
                                )
                              : `${question.score} `}
                            ptos
                          </p>
                        </Col>
                      </Row>
                    </div>
                  );
                })
              )}
            </div>
          );
        })}
      </TabPane>
    </Tab>
  ));

const ShowFinish = ({ quiz, ongoingRequest }) => {
  return (
    <div>
      {ongoingRequest.getQuiz && <LoadSpinner />}
      <div>
        <ArrowLeftIndex className="mb-5 text-light" path="/tests" />
        <SubTitleH2 text="Detalles de Evaluación" />
      </div>
      <Row>
        <Col md={5}>
          <ShowTestType quiz={quiz} />
        </Col>
        <Col md={{ span: 3, offset: 2 }}>
          <p className="mb-0 ml-6">Puntaje Total Prueba:</p>
          <p className="mb-2 ml-6">Porcentaje para logrado:</p>
          <p className="mb-0 ml-6 font-weight-bold">Porcentajes Desempeño:</p>
          {quiz.guideline.guideline_performances.map(perform => (
            <p className="mb-0 ml-3 gray-400">{`- ${perform.tag}`}</p>
          ))}
        </Col>
        <Col md={2}>
          <p className="mb-0 text-left">{quiz.total_score} ptos</p>
          <p className="mb-0 text-left">{quiz.guideline.minimum}%</p>
          <p className="mb-4 text-left" />
          <p className="mb-0 text-left"> </p>
          {quiz.guideline.guideline_performances.map(perform => (
            <div>
              <p className="mb-0 text-left">
                {`${perform.minimum}%`} / {`${perform.maximum}%`}
              </p>
            </div>
          ))}
        </Col>
      </Row>
      <Tabs defaultActiveKey="0" variant="tabs" className="tabs-axis text">
        {axes(quiz.axes_attributes)}
      </Tabs>
    </div>
  );
};

export default ShowFinish;
