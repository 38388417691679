import React from 'react';
import PropTypes from 'prop-types';

const Subtitle = ({ text, width, color }) => (
  <p style={{ fontSize: width, color }}>{text}</p>
);

Subtitle.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.string,
  color: PropTypes.string
};

Subtitle.defaultProps = {
  width: '80%',
  color: 'gray'
};

export default Subtitle;
