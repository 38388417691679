import React from 'react';
import PropTypes from 'prop-types';

const SubTitleH2 = ({ text, color }) => (
  <h2 className="mb-3" style={{ color }}>
    {text}
  </h2>
);

SubTitleH2.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string
};

SubTitleH2.defaultProps = {
  color: '$dark'
};

export default SubTitleH2;
