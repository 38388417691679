import React, { Component } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { Trash } from 'react-feather';
// eslint-disable-next-line import/no-cycle
import { ModalConfirm } from '../../Modal';

class AxesTab extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  componentDidMount() {
    this.textInput.current.click();
  }

  render() {
    const {
      axe,
      index,
      quiz,
      updateAxeName,
      actualTab,
      setModalShowConfirm,
      handleButtonDelete,
      modalShowConfirm,
      modalData,
      handleDelete
    } = this.props;
    return (
      <InputGroup>
        <FormControl
          ref={this.textInput}
          placeholder="Nuevo Eje"
          className="secondary"
          value={axe.name}
          onKeyDown={e => e.stopPropagation()}
          onClick={() => actualTab(index)}
          onFocus={e => e.stopPropagation()}
          onMouseOver={e => e.stopPropagation()}
          onChange={e => updateAxeName(e.target.value, index)}
          autoFocus={quiz.axes_attributes.length}
        />
        {!quiz.with_reviews && (
          <InputGroup.Append>
            <Trash onClick={() => handleButtonDelete(index, axe)} />
          </InputGroup.Append>
        )}
        <ModalConfirm
          show={modalShowConfirm}
          data={modalData}
          onHide={() => setModalShowConfirm(false)}
          action={handleDelete}
        />
      </InputGroup>
    );
  }
}

export default AxesTab;
