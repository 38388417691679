import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { closeAlert } from '../../actions/alert';

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { message, show, kind, title } = this.props;
    const notify = () => {
      if (kind === 'info') {
        toast.info(message);
      } else if (kind === 'warning') {
        toast.warn(message);
      } else if (kind === 'success') {
        toast.success(message ? title : message);
      } else if (kind === 'error') {
        toast.error(message);
      } else {
        toast(message);
      }
    };
    if (show) {
      if (message !== prevProps.message) {
        notify();
        this.silenceToast();
      }
    }
  }

  notify = ({ message }) => {
    toast(message);
  };

  silenceToast = () => {
    const { dispatch } = this.props;
    dispatch(closeAlert());
  };

  render() {
    return <ToastContainer />;
  }
}

const mapStateToProps = state => {
  const { show, position, message, kind, title } = state.alert;
  return {
    show,
    position,
    message,
    kind,
    title
  };
};

export default withRouter(connect(mapStateToProps)(Notification));
