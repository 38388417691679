// ---------  GET TEACHERS FOR ESTABLISHMENT --------

export const GET_ALL_TEACHERS_REQUEST = 'GET_ALL_TEACHERS_REQUEST';
export const GET_ALL_TEACHERS_SUCCESS = 'GET_ALL_TEACHERS_SUCCESS';
export const GET_ALL_TEACHERS_FAILURE = 'GET_ALL_TEACHERS_FAILURE';

export const getAllTeachers = idEstablishment => ({
  type: GET_ALL_TEACHERS_REQUEST,
  idEstablishment
});

// ---------  GET TEACHERS FOR INDEX --------

export const GET_TEACHERS_REQUEST = 'GET_TEACHERS_REQUEST';
export const GET_TEACHERS_SUCCESS = 'GET_TEACHERS_SUCCESS';
export const GET_TEACHERS_FAILURE = 'GET_TEACHERS_FAILURE';

export const getTeachers = data => ({
  type: GET_TEACHERS_REQUEST,
  data
});

export const SET_REDIRECT = 'SET_REDIRECT';

export const setRedirect = data => ({
  type: SET_REDIRECT,
  data
});

// // ---------  POST CREATE TEACHERS  --------

export const POST_TEACHER_REQUEST = 'POST_TEACHER_REQUEST';
export const POST_TEACHER_SUCCESS = 'POST_TEACHER_SUCCESS';
export const POST_TEACHER_FAILURE = 'POST_TEACHER_FAILURE';

export const postTeacher = data => ({
  type: POST_TEACHER_REQUEST,
  data
});

// // ---------  UPDATE  TEACHER  --------

export const UPDATE_TEACHER_REQUEST = 'UPDATE_TEACHER_REQUEST';
export const UPDATE_TEACHER_SUCCESS = 'UPDATE_TEACHER_SUCCESS';
export const UPDATE_TEACHER_FAILURE = 'UPDATE_TEACHER_FAILURE';

export const updateTeacher = data => ({
  type: UPDATE_TEACHER_REQUEST,
  data
});

// // ---------  DELETE  TEACHER  --------

export const DELETE_TEACHER_REQUEST = 'DELETE_TEACHER_REQUEST';
export const DELETE_TEACHER_SUCCESS = 'DELETE_TEACHER_SUCCESS';
export const DELETE_TEACHER_FAILURE = 'DELETE_TEACHER_FAILURE';

export const deleteTeacher = data => ({
  type: DELETE_TEACHER_REQUEST,
  data
});

// // ---------  GET SECTIONS TEACHER --------

export const GET_SECTIONS_TEACHER_REQUEST = 'GET_SECTIONS_TEACHER_REQUEST';
export const GET_SECTIONS_TEACHER_SUCCESS = 'GET_SECTIONS_TEACHER_SUCCESS';
export const GET_SECTIONS_TEACHER_FAILURE = 'GET_SECTIONS_TEACHER_FAILURE';

export const getSectionsTeacher = data => ({
  type: GET_SECTIONS_TEACHER_REQUEST,
  data
});

export const CLEAN_SECTIONS_TEACHER = 'CLEAN_SECTIONS_TEACHER';
export const cleanSectionsTeacher = () => ({
  type: CLEAN_SECTIONS_TEACHER
});
