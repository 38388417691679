import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import rootSaga from '../sagas';
import createRootReducer from '../reducers';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const routerMw = routerMiddleware(history);
const middleware = [routerMw, sagaMiddleware];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  createRootReducer(history),
  composeEnhancer(applyMiddleware(...middleware))
);
sagaMiddleware.run(rootSaga);

export default store;
