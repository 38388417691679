import {
  GET_ESTABLISHMENT_REQUEST,
  GET_ESTABLISHMENT_SUCCESS,
  GET_ESTABLISHMENT_FAILURE,
  GET_ONE_ESTABLISHMENT_REQUEST,
  GET_ONE_ESTABLISHMENT_SUCCESS,
  GET_ONE_ESTABLISHMENT_FAILURE,
  UPDATE_ESTABLISHMENT_REQUEST,
  UPDATE_ESTABLISHMENT_SUCCESS,
  UPDATE_ESTABLISHMENT_FAILURE,
  CREATE_ESTABLISHMENT_REQUEST,
  CREATE_ESTABLISHMENT_SUCCESS,
  CREATE_ESTABLISHMENT_FAILURE,
  DELETE_ESTABLISHMENT_REQUEST,
  DELETE_ESTABLISHMENT_SUCCESS,
  DELETE_ESTABLISHMENT_FAILURE,
  CREATE_GRADE_REQUEST,
  CREATE_GRADE_SUCCESS,
  CREATE_GRADE_FAILURE,
  CHANGE_ERRORS,
  UPDATE_GRADE_REQUEST,
  UPDATE_GRADE_SUCCESS,
  UPDATE_GRADE_FAILURE
} from '../actions/establishment';

const initialState = {
  ongoingRequest: {
    getEstablishmentRequest: false,
    createGradesEstablishment: false,
    createEstablishmentRequest: false,
    updateEstablishmentRequest: false,
    deleteEstablishmentRequest: false
  },
  errors: {
    createGrades: false
  },

  establishmentSelected: {},
  establishment: [],
  setEstablishment: [],
  totalRows: 0
};

const setData = data => {
  return data.map(item => ({ value: item.id, label: item.name }));
};

const onChangeErrors = (data, errors) => {
  switch (data.error) {
    case 'CreateGrades':
      return {
        ...errors,
        createGrades: data.state
      };
    default:
      return errors;
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ESTABLISHMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getEstablishmentRequest: true
        }
      };
    case GET_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getEstablishmentRequest: false
        },
        errors: {
          ...state.errors,
          createGrades: false
        },
        establishment: action.result.data,
        setEstablishment: setData(action.result.data),
        totalRows: action.result.metadata.amount,
        establishmentSelected: {}
      };
    case GET_ESTABLISHMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getEstablishmentRequest: false
        }
      };
    case GET_ONE_ESTABLISHMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getOneEstablishmentRequest: true
        }
      };
    case GET_ONE_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getOneEstablishmentRequest: false
        },
        establishmentSelected: action.result
      };
    case GET_ONE_ESTABLISHMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getOneEstablishmentRequest: false
        }
      };
    case UPDATE_ESTABLISHMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateEstablishmentRequest: true
        }
      };
    case UPDATE_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateEstablishmentRequest: false
        },
        establishmentSelected: {}
      };
    case UPDATE_ESTABLISHMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateEstablishmentRequest: false
        }
      };

    case UPDATE_GRADE_REQUEST:
      return {
        ...state
      };
    case UPDATE_GRADE_SUCCESS:
      return {
        ...state
      };
    case UPDATE_GRADE_FAILURE:
      return {
        ...state
      };
    case CREATE_ESTABLISHMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createEstablishmentRequest: true
        }
      };
    case CREATE_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createEstablishmentRequest: false
        }
      };
    case CREATE_ESTABLISHMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createEstablishmentRequest: false
        }
      };
    case DELETE_ESTABLISHMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteEstablishmentRequest: true
        }
      };
    case DELETE_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteEstablishmentRequest: false
        }
      };
    case DELETE_ESTABLISHMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteEstablishmentRequest: false
        }
      };
    case CREATE_GRADE_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createGradesEstablishment: true
        }
      };
    case CREATE_GRADE_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createGradesEstablishment: false
        }
      };
    case CREATE_GRADE_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createGradesEstablishment: false
        },
        errors: {
          ...state.errors,
          createGrades: true
        }
      };
    case CHANGE_ERRORS:
      return {
        ...state,
        errors: onChangeErrors(action.data, state.errors)
      };

    default:
      return state;
  }
};

export default reducer;
