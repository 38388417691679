import React from 'react';

const ShowTestType = ({ quiz }) => {
  return (
    <div>
      <h3 className="font-weight-bold mb-0">{quiz.name}</h3>
      <p className="mb-0">{quiz.guideline.name}</p>
      <p className="mb-0">{quiz.grade.name}</p>
      <p>{quiz.year}</p>
    </div>
  );
};

export default ShowTestType;
