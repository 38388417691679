import {
  GET_YEAR_CHART_REQUEST,
  GET_YEAR_CHART_SUCCESS,
  GET_YEAR_CHART_FAILURE,
  GET_REGION_CHART_REQUEST,
  GET_REGION_CHART_SUCCESS,
  GET_REGION_CHART_FAILURE,
  GET_COMMUNE_CHART_REQUEST,
  GET_COMMUNE_CHART_SUCCESS,
  GET_COMMUNE_CHART_FAILURE,
  GET_ESTABLISHMENT_CHART_REQUEST,
  GET_ESTABLISHMENT_CHART_SUCCESS,
  GET_ESTABLISHMENT_CHART_FAILURE,
  GET_GRADE_CHART_REQUEST,
  GET_GRADE_CHART_SUCCESS,
  GET_GRADE_CHART_FAILURE,
  GET_SECTION_CHART_REQUEST,
  GET_SECTION_CHART_SUCCESS,
  GET_SECTION_CHART_FAILURE,
  GET_QUIZ_CHART_REQUEST,
  GET_QUIZ_CHART_SUCCESS,
  GET_QUIZ_CHART_FAILURE,
  GET_UNIT_CHART_REQUEST,
  GET_UNIT_CHART_SUCCESS,
  GET_UNIT_CHART_FAILURE,
  CREATE_DOCUMENT_REQUEST,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_FAILURE,
  CHECK_DOCUMENT_REQUEST,
  CHECK_DOCUMENT_SUCCESS,
  CHECK_DOCUMENT_FAILURE,
  CLEAR_DATA_CHART,
  GET_DOCUMENTS_REQUEST,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAILURE,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE,
  GENERATE_DOCUMENT_REQUEST,
  GENERATE_DOCUMENT_SUCCESS,
  GENERATE_DOCUMENT_FAILURE,
  SHOW_DOCUMENT_REQUEST,
  SHOW_DOCUMENT_SUCCESS,
  SHOW_DOCUMENT_FAILURE,
  DOWNLOAD_DOCUMENT_REQUEST,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DOWNLOAD_DOCUMENT_FAILURE,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  SET_REDIRECT
} from '../actions/reports';

const initialState = {
  ongoingRequest: {
    createDocument: false,
    getYearChart: false,
    getRegionChart: false,
    getCommuneChart: false,
    getEstablishmentChart: false,
    getGradeChart: false,
    getSectionChart: false,
    getQuizChart: false,
    getUnitChart: false,
    getDocuments: false,
    deleteDocumentRequest: false,
    checkDocument: false,
    downloadDocumentRequest: false,
    uploadDocumentRequest: false
  },
  yearsChart: [],
  regionsChart: [],
  communesChart: [],
  establishmentsChart: [],
  gradesChart: [],
  sectionsChart: [],
  quizzesChart: [],
  unitsChart: [],
  documents: [],
  document: [], // document que se imprimirá
  result: [],
  totalRows: 0,
  id: 0,
  redirect: {
    path: '',
    state: false
  }
};

const getDataDocument = data => {
  return {
    result: data.graph_data,
    id: data.id
  };
};
const setData = data => {
  const arrayList = data.map(d => {
    return { label: d.name, value: d.id };
  });
  return arrayList;
};

const setDataWithoutId = data => {
  const arrayList = data.map(d => {
    return { label: String(d), value: d };
  });
  return arrayList;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REDIRECT:
      return {
        ...state,
        redirect: {
          path: action.data.path,
          state: action.data.state
        }
      };
    case GET_YEAR_CHART_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getYearChart: true
        }
      };
    case GET_YEAR_CHART_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getYearChart: false
        },
        yearsChart: setDataWithoutId(action.result)
      };
    case GET_YEAR_CHART_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getYearChart: false
        }
      };

    case GET_REGION_CHART_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getRegionChart: true
        }
      };
    case GET_REGION_CHART_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getRegionChart: false
        },
        regionsChart: setData(action.result.data)
      };
    case GET_REGION_CHART_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getRegionChart: false
        }
      };

    case GET_COMMUNE_CHART_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getCommuneChart: true
        }
      };
    case GET_COMMUNE_CHART_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getCommuneChart: false
        },
        communesChart: setData(action.result.data)
      };
    case GET_COMMUNE_CHART_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getCommuneChart: false
        }
      };

    case GET_ESTABLISHMENT_CHART_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getEstablishmentChart: true
        }
      };
    case GET_ESTABLISHMENT_CHART_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getEstablishmentChart: false
        },
        establishmentsChart: setData(action.result.data)
      };
    case GET_ESTABLISHMENT_CHART_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getEstablishmentChart: false
        }
      };

    case GET_GRADE_CHART_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getGradeChart: true
        }
      };
    case GET_GRADE_CHART_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getGradeChart: false
        },
        gradesChart: setData(action.result.data)
      };
    case GET_GRADE_CHART_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getGradeChart: false
        }
      };

    case GET_SECTION_CHART_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getSectionChart: true
        }
      };
    case GET_SECTION_CHART_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getSectionChart: false
        },
        sectionsChart: setData(action.result.data)
      };
    case GET_SECTION_CHART_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getSectionChart: false
        }
      };

    case GET_QUIZ_CHART_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuizChart: true
        }
      };
    case GET_QUIZ_CHART_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuizChart: false
        },
        quizzesChart: setData(action.result.data)
      };
    case GET_QUIZ_CHART_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuizChart: false
        }
      };
    case GET_UNIT_CHART_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getUnitChart: true
        }
      };
    case GET_UNIT_CHART_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getUnitChart: false
        },
        unitsChart: setData(action.result.data)
      };
    case GET_UNIT_CHART_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getUnitChart: false
        }
      };
    case CREATE_DOCUMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createDocument: true
        }
      };
    case CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createDocument: false
        },
        ...getDataDocument(action.result)
      };
    case CREATE_DOCUMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createDocument: false
        },
        result: []
      };
    case CLEAR_DATA_CHART:
      return {
        ...state,
        regionsChart: [],
        communesChart: [],
        establishmentsChart: [],
        gradesChart: [],
        sectionsChart: [],
        quizzesChart: [],
        unitsChart: []
      };

    case GET_DOCUMENTS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getDocuments: true
        }
      };
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getDocuments: false
        },
        documents: action.result.data,
        totalRows: action.result.metadata.amount
      };
    case GET_DOCUMENTS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getDocuments: false
        }
      };

    case DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteDocumentRequest: true
        }
      };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteDocumentRequest: false
        }
      };
    case DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteDocumentRequest: false
        }
      };
    case GENERATE_DOCUMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          generateDocumentRequest: true
        }
      };
    case GENERATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          generateDocumentRequest: false
        },
        document: {
          file: action.document,
          data: action.params
        }
      };
    case GENERATE_DOCUMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          generateDocumentRequest: false
        },
        document: []
      };
    case DOWNLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          downloadDocumentRequest: true
        }
      };
    case DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          downloadDocumentRequest: false
        },
        document: {
          file: action.document,
          data: action.params
        }
      };
    case DOWNLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          downloadDocumentRequest: false
        }
      };
    case UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          uploadDocumentRequest: true
        }
      };
    case UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          uploadDocumentRequest: false
        }
      };
    case UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          uploadDocumentRequest: false
        }
      };
    case CHECK_DOCUMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          checkDocument: true
        }
      };
    case CHECK_DOCUMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          checkDocument: false
        }
      };
    case CHECK_DOCUMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          checkDocument: false
        },
        result: []
      };
    case SHOW_DOCUMENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          showDocument: true
        }
      };
    case SHOW_DOCUMENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          showDocument: false
        },
        ...getDataDocument(action.result, action.check)
      };
    case SHOW_DOCUMENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          showDocument: false
        },
        result: []
      };
    default:
      return state;
  }
};

export default reducer;
