import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  GET_COMMUNE_REQUEST,
  GET_COMMUNE_SUCCESS,
  GET_COMMUNE_FAILURE,
  GET_REGIONS_REQUEST,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_FAILURE,
  GET_GRADES_REQUEST,
  GET_GRADES_SUCCESS,
  GET_GRADES_FAILURE,
  GET_UTILS_ESTABLISHMENTS_REQUEST,
  GET_UTILS_ESTABLISHMENTS_SUCCESS,
  GET_UTILS_ESTABLISHMENTS_FAILURE,
  GET_SECTIONS_REQUEST,
  GET_SECTIONS_SUCCESS,
  GET_SECTIONS_FAILURE,
  GET_GRADE_TRANSITION_REQUEST,
  GET_GRADE_TRANSITION_SUCCESS,
  GET_GRADE_TRANSITION_FAILURE,
  POST_GRADE_TRANSITION_REQUEST,
  POST_GRADE_TRANSITION_SUCCESS,
  POST_GRADE_TRANSITION_FAILURE,
  GET_YEARS_ESTABLISHMENT_REQUEST,
  GET_YEARS_ESTABLISHMENT_SUCCESS,
  GET_YEARS_ESTABLISHMENT_FAILURE,
  GET_AVAILABLE_YEAR_REQUEST,
  GET_AVAILABLE_YEAR_SUCCESS,
  GET_AVAILABLE_YEAR_FAILURE,
  GET_QUIZ_SECTION_REQUEST,
  GET_QUIZ_SECTION_SUCCESS,
  GET_QUIZ_SECTION_FAILURE,
  GET_AVAILABLE_GRADE_REQUEST,
  GET_AVAILABLE_GRADE_SUCCESS,
  GET_AVAILABLE_GRADE_FAILURE,
  GET_AVAILABLE_GUIDELINE_REQUEST,
  GET_AVAILABLE_GUIDELINE_SUCCESS,
  GET_AVAILABLE_GUIDELINE_FAILURE,
  GET_AVAILABLE_QUIZ_REQUEST,
  GET_AVAILABLE_QUIZ_SUCCESS,
  GET_AVAILABLE_QUIZ_FAILURE,
  GET_AVAILABLE_REGION_REQUEST,
  GET_AVAILABLE_REGION_SUCCESS,
  GET_AVAILABLE_REGION_FAILURE,
  GET_AVAILABLE_COMMUNE_REQUEST,
  GET_AVAILABLE_COMMUNE_SUCCESS,
  GET_AVAILABLE_COMMUNE_FAILURE,
  GET_AVAILABLE_ESTABLISHMENT_REQUEST,
  GET_AVAILABLE_ESTABLISHMENT_SUCCESS,
  GET_AVAILABLE_ESTABLISHMENT_FAILURE,
  GET_AVAILABLE_SECTION_REQUEST,
  GET_AVAILABLE_SECTION_SUCCESS,
  GET_AVAILABLE_SECTION_FAILURE,
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILURE,
  GET_ESTABLISHMENT_TYPES_REQUEST,
  GET_ESTABLISHMENT_TYPES_SUCCESS,
  GET_ESTABLISHMENT_TYPES_FAILURE,
  GET_PROJECT_TYPES_REQUEST,
  GET_PROJECT_TYPES_SUCCESS,
  GET_PROJECT_TYPES_FAILURE
} from '../actions/utils';
import API from '../services/api';
import { SET_NOTICE } from '../actions/alert';

import runDefaultSaga from './default';
import { serializeJsonToString, setData } from '../services/utils';

const getCommuneRequest = data => {
  const params = serializeJsonToString(data);
  return API.get(`/communes?${params}`);
};

function* getCommuneSuccessCallback(result) {
  yield put({
    type: GET_COMMUNE_SUCCESS,
    result: result.data,
    property: setData(result.data)
  });
}

function* getCommuneFailureCallback() {
  yield put({
    type: GET_COMMUNE_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getCommune(action) {
  yield runDefaultSaga(
    { request: getCommuneRequest, params: action.data },
    getCommuneSuccessCallback,
    getCommuneFailureCallback
  );
}

// GET YEARS

const getYearsEstablishmentRequest = data => {
  return API.get(`/establishments/${data}/available_years`);
};

function* getYearsEstablishmentSuccessCallback(result) {
  yield put({
    type: GET_YEARS_ESTABLISHMENT_SUCCESS,
    result,
    property: setData(result)
  });
}

function* getYearsEstablishmentFailureCallback() {
  yield put({
    type: GET_YEARS_ESTABLISHMENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getYearsEstablishment(action) {
  yield runDefaultSaga(
    { request: getYearsEstablishmentRequest, params: action.data },
    getYearsEstablishmentSuccessCallback,
    getYearsEstablishmentFailureCallback
  );
}

// GET AVAILABLE YEARS

const getAvailableYearRequest = () => {
  return API.get(`/quiz_reviews/available_years`);
};

function* getAvailableYearRequestSuccessCallback(result) {
  yield put({
    type: GET_AVAILABLE_YEAR_SUCCESS,
    result
  });
}

function* getAvailableYearRequestFailureCallback() {
  yield put({
    type: GET_AVAILABLE_YEAR_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getAvailableYear() {
  yield runDefaultSaga(
    { request: getAvailableYearRequest },
    getAvailableYearRequestSuccessCallback,
    getAvailableYearRequestFailureCallback
  );
}

// GET AVAILABLE GRADES

const getAvailableGradeRequest = data => {
  return API.get(`/grades`, data);
};

function* getAvailableGradeSuccessCallback(result) {
  yield put({
    type: GET_AVAILABLE_GRADE_SUCCESS,
    result
  });
}

function* getAvailableGradeFailureCallback() {
  yield put({
    type: GET_AVAILABLE_GRADE_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getAvailableGrade(action) {
  yield runDefaultSaga(
    { request: getAvailableGradeRequest, params: action.data },
    getAvailableGradeSuccessCallback,
    getAvailableGradeFailureCallback
  );
}

// GET AVAILABLE GUIDELINE

const getAvailableGuidelineRequest = data => {
  return API.get(`/guidelines`, data);
};

function* getAvailableGuidelineSuccessCallback(result) {
  yield put({
    type: GET_AVAILABLE_GUIDELINE_SUCCESS,
    result
  });
}

function* getAvailableGuidelineFailureCallback() {
  yield put({
    type: GET_AVAILABLE_GUIDELINE_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getAvailableGuideline(action) {
  yield runDefaultSaga(
    { request: getAvailableGuidelineRequest, params: action.data },
    getAvailableGuidelineSuccessCallback,
    getAvailableGuidelineFailureCallback
  );
}

// GET AVAILABLE QUIZ

const getAvailableQuizRequest = data => {
  return API.get(`/quizzes`, data);
};

function* getAvailableQuizSuccessCallback(result) {
  yield put({
    type: GET_AVAILABLE_QUIZ_SUCCESS,
    result
  });
}

function* getAvailableQuizFailureCallback() {
  yield put({
    type: GET_AVAILABLE_QUIZ_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getAvailableQuiz(action) {
  yield runDefaultSaga(
    { request: getAvailableQuizRequest, params: action.data },
    getAvailableQuizSuccessCallback,
    getAvailableQuizFailureCallback
  );
}

// GET AVAILABLE REGION

const getAvailableRegionRequest = data => {
  return API.get(`/regions`, data);
};

function* getAvailableRegionSuccessCallback(result) {
  yield put({
    type: GET_AVAILABLE_REGION_SUCCESS,
    result
  });
}

function* getAvailableRegionFailureCallback() {
  yield put({
    type: GET_AVAILABLE_REGION_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getAvailableRegion(action) {
  yield runDefaultSaga(
    { request: getAvailableRegionRequest, params: action.data },
    getAvailableRegionSuccessCallback,
    getAvailableRegionFailureCallback
  );
}

// GET AVAILABLE COMMUNE

const getAvailableCommuneRequest = data => {
  return API.get(`/communes`, data);
};

function* getAvailableCommuneSuccessCallback(result) {
  yield put({
    type: GET_AVAILABLE_COMMUNE_SUCCESS,
    result
  });
}

function* getAvailableCommuneFailureCallback() {
  yield put({
    type: GET_AVAILABLE_COMMUNE_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getAvailableCommune(action) {
  yield runDefaultSaga(
    { request: getAvailableCommuneRequest, params: action.data },
    getAvailableCommuneSuccessCallback,
    getAvailableCommuneFailureCallback
  );
}

// GET AVAILABLE ESTABLISHMENT

const getAvailableEstablishmentRequest = data => {
  return API.get(`/establishments`, data);
};

function* getAvailableEstablishmentSuccessCallback(result) {
  yield put({
    type: GET_AVAILABLE_ESTABLISHMENT_SUCCESS,
    result
  });
}

function* getAvailableEstablishmentFailureCallback() {
  yield put({
    type: GET_AVAILABLE_ESTABLISHMENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getAvailableEstablishment(action) {
  yield runDefaultSaga(
    { request: getAvailableEstablishmentRequest, params: action.data },
    getAvailableEstablishmentSuccessCallback,
    getAvailableEstablishmentFailureCallback
  );
}

// GET AVAILABLE SECTION

const getAvailableSectionRequest = data => {
  return API.get(`/sections`, data);
};

function* getAvailableSectionSuccessCallback(result) {
  yield put({
    type: GET_AVAILABLE_SECTION_SUCCESS,
    result
  });
}

function* getAvailableSectionFailureCallback() {
  yield put({
    type: GET_AVAILABLE_SECTION_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getAvailableSection(action) {
  yield runDefaultSaga(
    { request: getAvailableSectionRequest, params: action.data },
    getAvailableSectionSuccessCallback,
    getAvailableSectionFailureCallback
  );
}

// GET REPORT

const getReportRequest = data => {
  return API.post(`/quizzes/${data.quiz}/generate_report.xlsx`, data);
};

function* getReportSuccessCallback(result, _, params) {
  yield put({
    type: GET_REPORT_SUCCESS,
    result,
    params
  });
}

function* getReportFailureCallback() {
  yield put({
    type: GET_REPORT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getReport(action) {
  yield runDefaultSaga(
    { request: getReportRequest, params: action.data },
    getReportSuccessCallback,
    getReportFailureCallback
  );
}

// get studentTransition

const getGradeTransitionRequest = data => {
  return API.get(`/sections/${data}/grade_transition`);
};

function* getGradeTransitionSuccessCallback(result) {
  yield put({
    type: GET_GRADE_TRANSITION_SUCCESS,
    result
  });
}

function* getGradeTransitionFailureCallback() {
  yield put({
    type: GET_GRADE_TRANSITION_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getGradeTransition(action) {
  yield runDefaultSaga(
    { request: getGradeTransitionRequest, params: action.data },
    getGradeTransitionSuccessCallback,
    getGradeTransitionFailureCallback
  );
}

// ---- POST studentTransition

const postGradeTransitionRequest = data => {
  return API.post(`/sections/${data.id}/grade_transition`, {
    transition: { students_destination: data.body }
  });
};

function* postGradeTransitionSuccessCallback(result) {
  yield put({
    type: POST_GRADE_TRANSITION_SUCCESS,
    result
  });
  yield put(push('/students'));

  yield put({
    type: SET_NOTICE,
    title: 'Se ha avanzado de curso con exito',
    message: `Se ha avanzado de curso exitósamente`,
    kind: 'success',
    timeout: 2000
  });
}

function* postGradeTransitionFailureCallback() {
  yield put({
    type: POST_GRADE_TRANSITION_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* postGradeTransition(action) {
  yield runDefaultSaga(
    { request: postGradeTransitionRequest, params: action.data },
    postGradeTransitionSuccessCallback,
    postGradeTransitionFailureCallback
  );
}

//   REGION
const getRegionsRequest = data => {
  const params = data.data ? serializeJsonToString(data.data) : '';

  return API.get(`/regions?${params}`);
};
function* getRegionsSuccessCallback(result) {
  yield put({
    type: GET_REGIONS_SUCCESS,
    result: result.data,
    property: setData(result.data)
  });
}

function* getRegionsFailureCallback() {
  yield put({
    type: GET_REGIONS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getRegions(action) {
  yield runDefaultSaga(
    { request: getRegionsRequest, params: action },
    getRegionsSuccessCallback,
    getRegionsFailureCallback
  );
}

// GRADES

const getGradesRequest = data => {
  if (data.establishment_id !== undefined && data.select) {
    return API.get(
      `/grades?year=${data.year}&establishment_id=${data.establishment_id}`
    );
  }
  return API.get(`/grades`);
};

function* getGradesSuccessCallback(result, _, params) {
  yield put({
    type: GET_GRADES_SUCCESS,
    result: result.data,
    select: params !== undefined && params.select,
    property: setData(result.data)
  });
}

function* getGradesFailureCallback() {
  yield put({
    type: GET_GRADES_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getGrades(action) {
  yield runDefaultSaga(
    { request: getGradesRequest, params: action.data },
    getGradesSuccessCallback,
    getGradesFailureCallback
  );
}

// --- ESTABLISHMENT COMMUNES

const getUtilsEstablishmentsRequest = data => {
  const params = serializeJsonToString(data);
  return API.get(`/establishments?${params}`);
};

function* getUtilsEstablishmentsSuccessCallback(result) {
  yield put({
    type: GET_UTILS_ESTABLISHMENTS_SUCCESS,
    result: result.data,
    property: setData(result.data)
  });
}

function* getUtilsEstablishmentsFailureCallback() {
  yield put({
    type: GET_UTILS_ESTABLISHMENTS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getUtilsEstablishments(action) {
  yield runDefaultSaga(
    { request: getUtilsEstablishmentsRequest, params: action.data },
    getUtilsEstablishmentsSuccessCallback,
    getUtilsEstablishmentsFailureCallback
  );
}

// --- ESTABLISHMENT COMMUNES

const getSectionsRequest = data => {
  return API.get(
    `/sections?establishment_id=${data.establishmentId}&year=${data.year}&grade_id=${data.gradeId}`
  );
};

function* getSectionsSuccessCallback(result) {
  yield put({
    type: GET_SECTIONS_SUCCESS,
    result: result.data,
    property: setData(result.data)
  });
}

function* getSectionsFailureCallback() {
  yield put({
    type: GET_SECTIONS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getSections(action) {
  yield runDefaultSaga(
    { request: getSectionsRequest, params: action.data },
    getSectionsSuccessCallback,
    getSectionsFailureCallback
  );
}

// --- QUIZZES

const getQuizzesSectionRequest = data => {
  return API.get(`/quizzes`, data);
};

function* getQuizzesSectionSuccessCallback(result) {
  yield put({
    type: GET_QUIZ_SECTION_SUCCESS,
    result: result.data,
    property: setData(result.data)
  });
}

function* getQuizzesSectionFailureCallback() {
  yield put({
    type: GET_QUIZ_SECTION_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getQuizzesSection(action) {
  yield runDefaultSaga(
    { request: getQuizzesSectionRequest, params: action.data },
    getQuizzesSectionSuccessCallback,
    getQuizzesSectionFailureCallback
  );
}

// --- ESTABLISHMENT TYPES

const getEstablishmentTypesRequest = () => {
  return API.get(`/establishments/establishment_types`);
};

function* getEstablishmentTypesSuccessCallback(result) {
  yield put({
    type: GET_ESTABLISHMENT_TYPES_SUCCESS,
    result
  });
}

function* getEstablishmentTypesFailureCallback() {
  yield put({
    type: GET_ESTABLISHMENT_TYPES_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getEstablishmentTypes() {
  yield runDefaultSaga(
    { request: getEstablishmentTypesRequest },
    getEstablishmentTypesSuccessCallback,
    getEstablishmentTypesFailureCallback
  );
}

// --- PROJECT TYPES

const getProjectTypesRequest = () => {
  return API.get(`/establishments/project_types`);
};

function* getProjectTypesSuccessCallback(result) {
  yield put({
    type: GET_PROJECT_TYPES_SUCCESS,
    result
  });
}

function* getProjectTypesFailureCallback() {
  yield put({
    type: GET_PROJECT_TYPES_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getProjectTypes() {
  yield runDefaultSaga(
    { request: getProjectTypesRequest },
    getProjectTypesSuccessCallback,
    getProjectTypesFailureCallback
  );
}

// DEFINE utilsSagas
export default function* utilsSagas() {
  yield takeEvery(GET_COMMUNE_REQUEST, getCommune);
  yield takeEvery(GET_REGIONS_REQUEST, getRegions);
  yield takeEvery(GET_GRADES_REQUEST, getGrades);
  yield takeEvery(GET_UTILS_ESTABLISHMENTS_REQUEST, getUtilsEstablishments);
  yield takeEvery(GET_SECTIONS_REQUEST, getSections);
  yield takeEvery(GET_GRADE_TRANSITION_REQUEST, getGradeTransition);

  yield takeEvery(POST_GRADE_TRANSITION_REQUEST, postGradeTransition);
  yield takeEvery(GET_YEARS_ESTABLISHMENT_REQUEST, getYearsEstablishment);
  yield takeEvery(GET_QUIZ_SECTION_REQUEST, getQuizzesSection);
  yield takeEvery(GET_AVAILABLE_YEAR_REQUEST, getAvailableYear);
  yield takeEvery(GET_AVAILABLE_GRADE_REQUEST, getAvailableGrade);
  yield takeEvery(GET_AVAILABLE_GUIDELINE_REQUEST, getAvailableGuideline);
  yield takeEvery(GET_AVAILABLE_QUIZ_REQUEST, getAvailableQuiz);
  yield takeEvery(GET_AVAILABLE_REGION_REQUEST, getAvailableRegion);
  yield takeEvery(GET_AVAILABLE_COMMUNE_REQUEST, getAvailableCommune);
  yield takeEvery(
    GET_AVAILABLE_ESTABLISHMENT_REQUEST,
    getAvailableEstablishment
  );
  yield takeEvery(GET_AVAILABLE_SECTION_REQUEST, getAvailableSection);
  yield takeEvery(GET_REPORT_REQUEST, getReport);
  yield takeEvery(GET_ESTABLISHMENT_TYPES_REQUEST, getEstablishmentTypes);
  yield takeEvery(GET_PROJECT_TYPES_REQUEST, getProjectTypes);
}
