import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  GET_QUIZ_REVIEWS_REQUEST,
  GET_QUIZ_REVIEWS_SUCCESS,
  GET_QUIZ_REVIEWS_FAILURE,
  GET_ONE_QUIZ_REVIEWS_REQUEST,
  GET_ONE_QUIZ_REVIEWS_SUCCESS,
  GET_ONE_QUIZ_REVIEWS_FAILURE,
  UPDATE_QUIZ_REVIEW_REQUEST,
  UPDATE_QUIZ_REVIEW_SUCCESS,
  UPDATE_QUIZ_REVIEW_FAILURE,
  CREATE_QUIZ_REVIEW_REQUEST,
  CREATE_QUIZ_REVIEW_SUCCESS,
  CREATE_QUIZ_REVIEW_FAILURE,
  UPDATE_ONE_QUIZ_REVIEW_REQUEST,
  UPDATE_ONE_QUIZ_REVIEW_SUCCESS,
  UPDATE_ONE_QUIZ_REVIEW_FAILURE,
  DELETE_QUIZ_REVIEW_REQUEST,
  DELETE_QUIZ_REVIEW_SUCCESS,
  DELETE_QUIZ_REVIEW_FAILURE,
  IMPORT_SCORES_REQUEST,
  IMPORT_SCORES_SUCCESS,
  IMPORT_SCORES_FAILURE,
  DOWNLOAD_TEMPLATE_SCORES_REQUEST,
  DOWNLOAD_TEMPLATE_SCORES_SUCCESS,
  DOWNLOAD_TEMPLATE_SCORES_FAILURE
} from '../actions/score';
import { SET_NOTICE, CLEAR_NOTICE } from '../actions/alert';
import API from '../services/api';
import runDefaultSaga from './default';

// ----- QUIZ REVIEWS -------

const getQuizReviewsRequest = data => API.get(`/quiz_reviews`, data);

function* getQuizReviewsSuccessCallback(result) {
  yield put({
    type: GET_QUIZ_REVIEWS_SUCCESS,
    result
  });
}

function* getQuizReviewsFailureCallback() {
  yield put({
    type: GET_QUIZ_REVIEWS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getQuizReviews(action) {
  yield runDefaultSaga(
    { request: getQuizReviewsRequest, params: action.data },
    getQuizReviewsSuccessCallback,
    getQuizReviewsFailureCallback
  );
}

// ----- GET ONE QUIZ REVIEWS -------

const getOneQuizReviewsRequest = data => API.get(`/quiz_reviews/${data}`);

function* getOneQuizReviewsSuccessCallback(result) {
  yield put({
    type: GET_ONE_QUIZ_REVIEWS_SUCCESS,
    result
  });
}

function* getOneQuizReviewsFailureCallback() {
  yield put({
    type: GET_ONE_QUIZ_REVIEWS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getOneQuizReviews(action) {
  yield runDefaultSaga(
    { request: getOneQuizReviewsRequest, params: action.data },
    getOneQuizReviewsSuccessCallback,
    getOneQuizReviewsFailureCallback
  );
}

// ----- UPDATE QUIZ REVIEW -------

const updateQuizReviewRequest = data =>
  API.put(`/quiz_reviews/${data.id}`, { quiz_review: data.review });

function* updateQuizReviewSuccessCallback(result) {
  yield put({
    type: UPDATE_QUIZ_REVIEW_SUCCESS,
    result
  });

  yield put({
    type: SET_NOTICE,
    title: 'Creacion exitosa',
    message: `Ingreso de notas guardado`,
    kind: 'success',
    timeout: 2000
  });
}

function* updateQuizReviewFailureCallback() {
  yield put({
    type: UPDATE_QUIZ_REVIEW_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* updateQuizReview(action) {
  yield runDefaultSaga(
    { request: updateQuizReviewRequest, params: action.data },
    updateQuizReviewSuccessCallback,
    updateQuizReviewFailureCallback
  );
}

// ----- UPDATE QUIZ REVIEW -------

const updateOneQuizReviewRequest = data =>
  API.patch(`/student_reviews/${data.review.id}`, {
    student_review: data.review
  });

function* updateOneQuizReviewSuccessCallback(result) {
  yield put({
    type: UPDATE_ONE_QUIZ_REVIEW_SUCCESS,
    result
  });
}

function* updateOneQuizReviewFailureCallback() {
  yield put({
    type: UPDATE_ONE_QUIZ_REVIEW_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* updateOneQuizReview(action) {
  yield runDefaultSaga(
    { request: updateOneQuizReviewRequest, params: action.data },
    updateOneQuizReviewSuccessCallback,
    updateOneQuizReviewFailureCallback
  );
}

// ----- CREATE QUIZ REVIEW -------

const createQuizReviewRequest = data =>
  API.post(`/quiz_reviews`, { quiz_review: data });

function* createQuizReviewSuccessCallback(result) {
  yield put({
    type: CREATE_QUIZ_REVIEW_SUCCESS,
    result
  });
  yield put(push(`/score/add/${result.id}`));
}

function* createQuizReviewFailureCallback() {
  yield put({
    type: CREATE_QUIZ_REVIEW_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* createQuizReview(action) {
  yield runDefaultSaga(
    { request: createQuizReviewRequest, params: action.data, timeout: 50000 },
    createQuizReviewSuccessCallback,
    createQuizReviewFailureCallback
  );
}

// ----- DELETE QUIZ REVIEW -------

const deleteQuizReviewRequest = data => API.delete(`/quiz_reviews/${data.id}`);

function* deleteQuizReviewSuccessCallback() {
  yield put({
    type: DELETE_QUIZ_REVIEW_SUCCESS
  });
  yield put({
    type: SET_NOTICE,
    title: 'Revisión se borro exitosamente',
    message: 'Revisión se borro exitosamente',
    kind: 'success',
    timeout: 2000
  });
  yield put({
    type: GET_QUIZ_REVIEWS_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
}

function* deleteQuizReviewFailureCallback() {
  yield put({
    type: DELETE_QUIZ_REVIEW_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* deleteQuizReview(action) {
  yield runDefaultSaga(
    { request: deleteQuizReviewRequest, params: action.data },
    deleteQuizReviewSuccessCallback,
    deleteQuizReviewFailureCallback
  );
}

// ------------------- UPLOAD DOCUMENT ---------------------

const SUCCESS_STRING = 'Puntajes cargados con éxito.';

const importScoresRequest = data =>
  API.post(`/quiz_reviews/${data.id}/import`, data.body, true);

function* importScoresSuccessCallBack(result) {
  if (result.message && result.message[0] === SUCCESS_STRING) {
    yield put({
      type: SET_NOTICE,
      title: 'Puntajes importados exitosamente',
      message: result.message,
      kind: 'success',
      timeout: 2000
    });
    yield put({
      type: CLEAR_NOTICE
    });
    yield put({
      type: GET_ONE_QUIZ_REVIEWS_REQUEST,
      data: result.quiz_review.id
    });
  } else {
    yield put({
      type: SET_NOTICE,
      title: 'Error',
      message: 'Hubo errores en importación',
      kind: 'error',
      timeout: 2000
    });

    yield put({
      type: CLEAR_NOTICE
    });
  }

  yield put({
    type: IMPORT_SCORES_SUCCESS,
    result
  });
}

function* importScoresFailureCallback() {
  yield put({ type: IMPORT_SCORES_FAILURE });
}

function* importScores(action) {
  yield runDefaultSaga(
    { request: importScoresRequest, params: action.data },
    importScoresSuccessCallBack,
    importScoresFailureCallback
  );
}

// ----- DOWNLOAD TEMPLATE -------
const downloadTemplateRequest = data =>
  API.getFile(`/quiz_reviews/${data.id}.xlsx`);

function* downloadTemplateSuccessCallback(result) {
  yield put({
    type: DOWNLOAD_TEMPLATE_SCORES_SUCCESS,
    result: result.metadata.amount
  });
}

function* downloadTemplateFailureCallback() {
  yield put({
    type: DOWNLOAD_TEMPLATE_SCORES_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* downloadTemplate(action) {
  yield runDefaultSaga(
    { request: downloadTemplateRequest, params: action.data },
    downloadTemplateSuccessCallback,
    downloadTemplateFailureCallback,
    true
  );
}

// DEFINE establishmentSagas
export default function* scoreSagas() {
  yield takeEvery(GET_QUIZ_REVIEWS_REQUEST, getQuizReviews);
  yield takeEvery(GET_ONE_QUIZ_REVIEWS_REQUEST, getOneQuizReviews);
  yield takeEvery(UPDATE_QUIZ_REVIEW_REQUEST, updateQuizReview);
  yield takeEvery(CREATE_QUIZ_REVIEW_REQUEST, createQuizReview);
  yield takeEvery(UPDATE_ONE_QUIZ_REVIEW_REQUEST, updateOneQuizReview);
  yield takeEvery(DELETE_QUIZ_REVIEW_REQUEST, deleteQuizReview);
  yield takeEvery(IMPORT_SCORES_REQUEST, importScores);
  yield takeEvery(DOWNLOAD_TEMPLATE_SCORES_REQUEST, downloadTemplate);
}
