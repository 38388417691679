import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import { DefaultButton } from '../Button';
import {
  getRegions,
  getCommunes,
  getGradeTransition,
  getUtilsEstablishments,
  getGrades,
  getSections,
  resetStudentsTransition,
  getYearsEstablishment,
  getQuizzesSection
} from '../../actions/utils';
import { getQuizReviews, createQuizReview } from '../../actions/score';

class SelectedQuizForm extends Component {
  constructor() {
    super();
    this.state = {
      region: 0,
      commune: 0,
      establishment: 0,
      year: 0,
      grade: 0,
      section: 0,
      quiz: 0,
      state: false
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getRegions({ at_least_one: 1 }));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetStudentsTransition());
  }

  onChangeSelects = (value, type) => {
    const { dispatch } = this.props;
    const { establishment, year, grade, section } = this.state;
    const state = Number(value) !== 0;
    switch (type) {
      case 'Region':
        if (state) {
          dispatch(getCommunes({ region_id: value, at_least_one: true }));
        }
        this.setState({
          region: state ? value : 0,
          commune: 0,
          establishment: 0,
          year: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        dispatch(resetStudentsTransition());

        break;
      case 'Commune':
        if (state) {
          dispatch(getUtilsEstablishments({ commune_id: value }));
        }
        this.setState({
          commune: state ? value : 0,
          establishment: 0,
          year: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        dispatch(resetStudentsTransition());

        break;
      case 'Establishment':
        if (state) {
          dispatch(getYearsEstablishment(value));
        }

        this.setState({
          establishment: state ? value : 0,
          year: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        dispatch(resetStudentsTransition());

        break;
      case 'Year':
        if (state) {
          dispatch(
            getGrades({
              establishment_id: establishment,
              year: value,
              select: true
            })
          );
        }
        this.setState({
          year: state ? value : 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        dispatch(resetStudentsTransition());

        break;
      case 'Grade':
        if (state) {
          dispatch(
            getSections({
              establishmentId: establishment,
              year,
              gradeId: value
            })
          );
        }
        this.setState({
          grade: state ? value : 0,
          section: 0,
          quiz: 0,
          state: false
        });
        dispatch(resetStudentsTransition());
        break;
      case 'Section':
        if (state) {
          dispatch(getGradeTransition(value));
          dispatch(
            getQuizzesSection({
              grade_id: grade,
              year,
              finished: true
            })
          );
        }
        this.setState({
          section: state ? value : 0,
          quiz: 0,
          state: false
        });
        dispatch(resetStudentsTransition());

        break;
      case 'Quiz':
        this.setState({
          quiz: state ? value : 0,
          state: true
        });
        dispatch(getQuizReviews({ section_id: section, quiz_id: value }));
        break;
      default:
        break;
    }
  };

  validateState = () => {
    const { commune, establishment, year, grade, section, quiz } = this.state;
    const { students } = this.props;
    const studentsLength = students.students ? students.students.length : 0;

    return !(
      commune === 0 ||
      establishment === 0 ||
      year === 0 ||
      grade === 0 ||
      section === 0 ||
      studentsLength === 0 ||
      quiz === 0
    );
  };

  onReload = ongoingRequest => {
    const {
      postGradeTransition: post,
      getGradeTransition: get
    } = ongoingRequest;
    return post || get;
  };

  handleCreateReview = () => {
    const { quiz, section } = this.state;
    const { dispatch } = this.props;

    dispatch(
      createQuizReview({
        section_id: section,
        quiz_id: quiz,
        quiz_date: moment().format('YYYY-MM-DD')
      })
    );
  };

  render() {
    const {
      selectRegions,
      selectCommunes,
      selectEstablishments,
      selectGrades,
      selectSections,
      ongoingRequest,
      selectYears,
      students,
      quizzes,
      selectQuizzes,
      onRequest,
      quizReviews
    } = this.props;
    const {
      region,
      commune,
      establishment,
      year,
      grade,
      section,
      state
    } = this.state;
    const status = this.validateState();
    return (
      <form>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Col xs={8}>
            <Row className="mb-4 mt-4">
              <Col>
                <p className="mb-1">Región</p>
                <Select
                  options={
                    ongoingRequest.getRegion
                      ? [{ value: 0, label: 'Cargando..' }]
                      : selectRegions
                  }
                  defaultValue={{ value: 0, label: 'Seleccione' }}
                  onChange={e => this.onChangeSelects(e.value, 'Region')}
                  placeholder="Region"
                />
              </Col>
              <Col>
                <p className="mb-1">Comuna</p>
                <Select
                  options={
                    ongoingRequest.getCommunes
                      ? [{ value: 0, label: 'Cargando..' }]
                      : selectCommunes
                  }
                  defaultValue={{ value: 0, label: 'Seleccione' }}
                  onChange={e => this.onChangeSelects(e.value, 'Commune')}
                  isDisabled={region === 0}
                  placeholder="Comuna"
                />
              </Col>
              <Col>
                <p className="mb-1">Establecimiento</p>
                <Select
                  options={
                    ongoingRequest.getUtilsEstablishments
                      ? [{ value: 0, label: 'Cargando..' }]
                      : selectEstablishments
                  }
                  onChange={e => this.onChangeSelects(e.value, 'Establishment')}
                  defaultValue={{ value: 0, label: 'Seleccione' }}
                  isDisabled={commune === 0}
                  placeholder="Establecimiento"
                />
              </Col>
              <Col>
                <p className="mb-1">Años</p>
                <Select
                  options={
                    ongoingRequest.getYearsEstablishment
                      ? [{ value: 0, label: 'Cargando..' }]
                      : selectYears
                  }
                  onChange={e => this.onChangeSelects(e.value, 'Year')}
                  defaultValue={{ value: 0, label: 'Seleccione' }}
                  isDisabled={establishment === 0}
                  placeholder="Años"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <p className="mb-1">Cursos</p>
                <Select
                  options={
                    ongoingRequest.getGrades
                      ? [{ value: 0, label: 'Cargando..' }]
                      : selectGrades
                  }
                  defaultValue={{ value: 0, label: 'Seleccione' }}
                  onChange={e => this.onChangeSelects(e.value, 'Grade')}
                  isDisabled={year === 0}
                  placeholder="Cursos"
                />
              </Col>
              <Col xs={3}>
                <p className="mb-1">Secciones</p>
                <Select
                  options={
                    ongoingRequest.getSections
                      ? [{ value: 0, label: 'Cargando..' }]
                      : selectSections
                  }
                  defaultValue={{ value: 0, label: 'Seleccione' }}
                  onChange={e => this.onChangeSelects(e.value, 'Section')}
                  isDisabled={grade === 0}
                  placeholder="Secciones"
                />
              </Col>
              <Col xs={6}>
                <p className="mb-1">Evaluaciones</p>
                <Select
                  options={
                    ongoingRequest.getQuizzes
                      ? [{ value: 0, label: 'Cargando..' }]
                      : selectQuizzes
                  }
                  defaultValue={{ value: 0, label: 'Seleccione' }}
                  onChange={e => this.onChangeSelects(e.value, 'Quiz')}
                  // isDisabled={
                  //   section === 0 ||
                  //   quizzes.length < 1 ||
                  //   (students.students && students.students.length === 0)
                  // }
                  placeholder="Evaluaciones"
                />
              </Col>
            </Row>
            {quizzes &&
              quizzes.length === 0 &&
              !ongoingRequest.getQuizzes &&
              section !== 0 && (
                <Row>
                  <Col>
                    <p style={{ color: 'red' }}>
                      No se encontraron evaluaciones disponibles
                    </p>
                  </Col>
                </Row>
              )}
            {students.students &&
              students.students.length === 0 &&
              !ongoingRequest.getGradeTransition && (
                <Row>
                  <Col>
                    <p style={{ color: 'red' }}>
                      No existen alumnos en esta sección
                    </p>
                  </Col>
                </Row>
              )}

            {quizReviews.data.length > 0 && !onRequest.getQuizReviews && state && (
              <Row>
                <Col>
                  Ya existe review, entrar
                  <Link
                    className="card-link"
                    to={`/score/add/${quizReviews.data[0].id}`}
                  >
                    Aquí
                  </Link>
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={{ span: 3, offset: 1 }}>
            <DefaultButton
              text="Comenzar"
              disabled={
                !status ||
                onRequest.getQuizReviews ||
                quizReviews.data.length > 0
              }
              onClick={() => this.handleCreateReview()}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
      </form>
    );
  }
}

const mapStateToProps = state => {
  const {
    ongoingRequest,
    selectRegions,
    selectCommunes,
    selectEstablishments,
    selectGrades,
    selectSections,
    selectYears,
    studentsTransition: students,
    selectQuizzes,
    quizzes,
    yearsEstablishment
  } = state.utils;
  const { quizReviews, ongoingRequest: onRequest } = state.score;
  return {
    selectRegions,
    selectCommunes,
    selectEstablishments,
    selectGrades,
    selectSections,
    selectYears,
    ongoingRequest,
    students,
    yearsEstablishment,
    selectQuizzes,
    quizReviews,
    quizzes,
    onRequest
  };
};

export default connect(mapStateToProps)(SelectedQuizForm);
