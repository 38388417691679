export const GET_ESTABLISHMENT_REQUEST = 'GET_ESTABLISHMENT_REQUEST';
export const GET_ESTABLISHMENT_SUCCESS = 'GET_ESTABLISHMENT_SUCCESS';
export const GET_ESTABLISHMENT_FAILURE = 'GET_ESTABLISHMENT_FAILURE';

export const getEstablishment = data => ({
  type: GET_ESTABLISHMENT_REQUEST,
  data
});

export const GET_ONE_ESTABLISHMENT_REQUEST = 'GET_ONE_ESTABLISHMENT_REQUEST';
export const GET_ONE_ESTABLISHMENT_SUCCESS = 'GET_ONE_ESTABLISHMENT_SUCCESS';
export const GET_ONE_ESTABLISHMENT_FAILURE = 'GET_ONE_ESTABLISHMENT_FAILURE';

export const getOneEstablishment = id => ({
  type: GET_ONE_ESTABLISHMENT_REQUEST,
  id
});

export const CREATE_ESTABLISHMENT_REQUEST = 'CREATE_ESTABLISHMENT_REQUEST';
export const CREATE_ESTABLISHMENT_SUCCESS = 'CREATE_ESTABLISHMENT_SUCCESS';
export const CREATE_ESTABLISHMENT_FAILURE = 'CREATE_ESTABLISHMENT_FAILURE';

export const createEstablishment = data => ({
  type: CREATE_ESTABLISHMENT_REQUEST,
  data
});

export const UPDATE_ESTABLISHMENT_REQUEST = 'UPDATE_ESTABLISHMENT_REQUEST';
export const UPDATE_ESTABLISHMENT_SUCCESS = 'UPDATE_ESTABLISHMENT_SUCCESS';
export const UPDATE_ESTABLISHMENT_FAILURE = 'UPDATE_ESTABLISHMENT_FAILURE';

export const updateEstablishment = data => ({
  type: UPDATE_ESTABLISHMENT_REQUEST,
  data
});

export const UPDATE_GRADE_REQUEST = 'UPDATE_GRADE_REQUEST';
export const UPDATE_GRADE_SUCCESS = 'UPDATE_GRADE_SUCCESS';
export const UPDATE_GRADE_FAILURE = 'UPDATE_GRADE_FAILURE';

export const updateGradeEstablishment = data => ({
  type: UPDATE_GRADE_REQUEST,
  data
});

export const DELETE_ESTABLISHMENT_REQUEST = 'DELETE_ESTABLISHMENT_REQUEST';
export const DELETE_ESTABLISHMENT_SUCCESS = 'DELETE_ESTABLISHMENT_SUCCESS';
export const DELETE_ESTABLISHMENT_FAILURE = 'DELETE_ESTABLISHMENT_FAILURE';

export const deleteEstablishment = data => ({
  type: DELETE_ESTABLISHMENT_REQUEST,
  data
});

export const CREATE_GRADE_REQUEST = 'CREATE_GRADE_REQUEST';
export const CREATE_GRADE_SUCCESS = 'CREATE_GRADE_SUCCESS';
export const CREATE_GRADE_FAILURE = 'CREATE_GRADE_FAILURE';

export const createGradeEstablishment = data => ({
  type: CREATE_GRADE_REQUEST,
  data
});

export const CHANGE_ERRORS = 'CHANGE_ERRORS';
export const changeErrors = data => ({
  type: CHANGE_ERRORS,
  data
});
