import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
// import './style.scss';

const BasicDropdown = ({ variant, titleDrop, items, size, actionOnClick }) => (
  <Dropdown>
    <Dropdown.Toggle variant={variant} id="dropdown-basic" size={size}>
      {titleDrop}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {items.map(item => (
        <Dropdown.Item href={item.path} key={item.key} onClick={actionOnClick}>
          {item.title}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

BasicDropdown.propTypes = {
  variant: PropTypes.string,
  titleDrop: PropTypes.string.isRequired,
  size: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired
};

BasicDropdown.defaultProps = {
  variant: 'outline-secondary',
  size: ''
};

const Select = ({
  items,
  title,
  text,
  size,
  width,
  onChange,
  placeholder,
  ...props
}) => (
  <Form.Group controlId="exampleForm.ControlSelect1">
    {title ? <Form.Label>{title}</Form.Label> : false}

    <Form.Control
      as="select"
      size={size}
      className="longBorderRadious"
      onChange={onChange}
      placeholder={placeholder}
      {...props}
    >
      <option value="" disabled>
        Seleccione...
      </option>
      {items.map(item => (
        <option key={item.key} value={item.id}>
          {item.title}
        </option>
      ))}
    </Form.Control>
    {text ? (
      <Form.Text className="input-group-append">{text}</Form.Text>
    ) : (
      false
    )}
  </Form.Group>
);

Select.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string
};

Select.defaultProps = {
  size: '',
  title: '',
  text: ''
};

const SelectPrependRight = ({
  items,
  title,
  text,
  size,
  width,
  onChange,
  ...props
}) => {
  return (
    <Form.Group>
      {title ? <Form.Label>{title}</Form.Label> : false}
      <InputGroup>
        <Form.Control as="select" size={size} onChange={onChange} {...props}>
          <option value="" disabled>
            Seleccione...
          </option>
          {items.map(item => (
            <option key={item.key} value={item.id}>
              {item.title}
            </option>
          ))}
        </Form.Control>
        <InputGroup.Append>
          <InputGroup.Text>{text}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  );
};

SelectPrependRight.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string
};

SelectPrependRight.defaultProps = {
  size: '',
  title: '',
  text: ''
};

export { BasicDropdown, Select, SelectPrependRight };
