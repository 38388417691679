import React from 'react';
import Subtitle from '../Subtitle';

const SubEje = () => (
  <div>
    <Subtitle text="Ingrese el nombre que corresponda" />
  </div>
);

export default SubEje;
