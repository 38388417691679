import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
// eslint-disable-next-line import/no-cycle
import { BasicInput } from '../index';

const Project = ({
  clearSelectData,
  clearState,
  typeOfTest,
  name,
  years,
  units,
  ongoingRequest,
  sendData,
  values,
  user,
  users,
  usersOngoingRequest
}) => {
  const { year, userId } = values;

  useEffect(() => {
    clearSelectData();
    clearState();
    typeOfTest();
  }, []);

  const isUserAdmin = user?.role === 'superadmin' || user?.role === 'admin';

  const usersOptions = users.map(muser => ({
    label: muser.name,
    value: muser.id
  }));

  const isUnitDisabled = () => {
    if (isUserAdmin) {
      return userId === 0;
    }

    return year === 0;
  };

  return (
    <Container>
      <Row>
        <Col>
          <p className="mb-2">Nombre Informe</p>
          <BasicInput
            onChange={e => sendData(e.target.value, 'Name')}
            placeholder="Nombre Informe"
          />
        </Col>
        <Col>
          <p className="mb-2">Año</p>
          <Select
            options={
              ongoingRequest.getYearChart
                ? [{ value: 0, label: 'Cargando..' }]
                : years
            }
            defaultValue={{ value: 0, label: 'Seleccione' }}
            isDisabled={name.length === 0 || name === 0}
            onChange={e => sendData(e.value, 'Project-Year')}
            placeholder="Seleccione"
          />
        </Col>
        {isUserAdmin && (
          <Col>
            <p className="mb-2">Usuario</p>
            <Select
              options={
                usersOngoingRequest.getProjects
                  ? [{ value: 0, label: 'Cargando..' }]
                  : usersOptions
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={year === 0}
              onChange={e => sendData(e.value, 'Project-User')}
              placeholder="Seleccione"
            />
          </Col>
        )}
        <Col>
          <p className="mb-2">Unidad</p>
          <Select
            options={
              ongoingRequest.getUnitChart
                ? [{ value: 0, label: 'Cargando..' }]
                : units
            }
            defaultValue={{ value: 0, label: 'Seleccione' }}
            isDisabled={isUnitDisabled()}
            onChange={e => sendData(e.value, 'Project-Unit')}
            placeholder="Seleccione"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Project;
