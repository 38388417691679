import {
  GET_OBJETIVES_LEARNING_REQUEST,
  GET_OBJETIVES_LEARNING_SUCCESS,
  GET_OBJETIVES_LEARNING_FAILURE,
  GET_SKILLS_REQUEST,
  GET_SKILLS_SUCCESS,
  GET_SKILLS_FAILURE,
  GET_QUESTION_TYPE_REQUEST,
  GET_QUESTION_TYPE_SUCCESS,
  GET_QUESTION_TYPE_FAILURE,
  GET_QUESTION_TYPE_PER_QUESTION_REQUEST,
  GET_QUESTION_TYPE_PER_QUESTION_SUCCESS,
  GET_QUESTION_TYPE_PER_QUESTION_FAILURE
} from '../actions/quiz';

const initialState = {
  ongoingRequest: {
    getObjectivesLearning: false,
    getSkills: false,
    getQuestionType: false,
    getQuestionTypePerQuestion: false
  },
  objectivesLearning: [],
  skills: [],
  skillsOptions: [],
  questionTypes: [],
  questions: []
};

const setData = (data, state) => {
  const arrayList = data.map(d => {
    return { title: d.name, key: d.id, id: d.id };
  });
  if (state) {
    return arrayList;
  }
  return [{ title: 'Seleccione', key: 0, id: 0 }, ...arrayList];
};

const setData2 = data => {
  const arrayList = data.map(d => {
    return { label: d.name, value: d.id };
  });
  return arrayList;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OBJETIVES_LEARNING_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getObjectivesLearning: true
        }
      };
    case GET_OBJETIVES_LEARNING_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getObjectivesLearning: false
        },
        objectivesLearning: setData(action.result, true)
      };
    case GET_OBJETIVES_LEARNING_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getObjectivesLearning: false
        }
      };
    case GET_SKILLS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getSkills: true
        }
      };
    case GET_SKILLS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getSkills: false
        },
        skills: setData(action.result),
        skillsOptions: setData2(action.result)
      };
    case GET_SKILLS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getSkills: false
        }
      };
    case GET_QUESTION_TYPE_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuestionType: true
        }
      };
    case GET_QUESTION_TYPE_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuestionType: false
        },
        questionTypes: setData(action.result)
      };
    case GET_QUESTION_TYPE_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuestionType: false
        }
      };
    case GET_QUESTION_TYPE_PER_QUESTION_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuestionTypePerQuestion: true
        }
      };
    case GET_QUESTION_TYPE_PER_QUESTION_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuestionTypePerQuestion: false
        },
        questions: setData(action.result)
      };
    case GET_QUESTION_TYPE_PER_QUESTION_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getQuestionTypePerQuestion: false
        }
      };
    default:
      return state;
  }
};

export default reducer;
