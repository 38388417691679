import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './app/config/store';
import routes from './app/config/routes';
import './index.scss';
import { AbilityContext } from './app/config/ability-context';
import ability from './app/config/ability';

ReactDOM.render(
  <Provider store={store}>
    <AbilityContext.Provider value={ability}>
      <ConnectedRouter history={history}>{routes}</ConnectedRouter>
    </AbilityContext.Provider>
  </Provider>,
  document.getElementById('root')
);
