import React, { Component } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
// import { DefaultButton, BasicInput, Subtitle, Title } from "../../components";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { restoreAuthInfo, validateToken } from '../../actions/auth';

class Loader extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.bootstrapAsync();
  }

  componentWillReceiveProps(nextProps) {
    this.navigate(nextProps);
  }

  navigate = nextProps => {
    const { history } = this.props;
    if (nextProps.requestSignUp !== null) {
      history.push('/dashboard');
    }
  };

  // Fetch the token from storage then navigate into correct navigator
  bootstrapAsync = async () => {
    const accessToken = await localStorage.getItem('access-token');
    const client = await localStorage.getItem('client');
    const expiry = await localStorage.getItem('expiry');
    const tokenType = await localStorage.getItem('token-type');
    const uid = await localStorage.getItem('uid');
    const { dispatch, history } = this.props;
    if (accessToken && client && expiry && tokenType && uid) {
      dispatch(
        restoreAuthInfo({
          accessToken,
          client,
          expiry,
          tokenType,
          uid
        })
      );
      dispatch(validateToken());
    } else {
      history.push('/login');
    }
  };

  render() {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { ongoingRequest, signedIn, requestSignUp } = state.auth;
  return {
    ongoingRequest,
    signedIn,
    requestSignUp
  };
};

export default withRouter(connect(mapStateToProps)(Loader));
