import React, { Component } from 'react';
import 'floatthead';
import { connect } from 'react-redux';
import ScoreRow from '../Form/ScoreRow';
import { setValuesReviews, updateOneQuizReview } from '../../actions/score';
import './style.scss';

class ScoreTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: this.setDataValues(props.data.student_reviews_attributes)
    };
    this.myTable = React.createRef();
  }

  setDataValues = data => {
    if (data) {
      return data.map(studentReviews => {
        return {
          ...studentReviews,
          question_reviews_attributes: studentReviews.question_reviews_attributes.sort(
            (a, b) => a.position - b.position
          )
        };
      });
    }
    return null;
  };

  updateData = (id, data) => {
    const { dispatch } = this.props;
    dispatch(updateOneQuizReview({ id, review: data }));
  };

  renderRow = () => {
    const { values } = this.state;
    const { data, onRequestOneReview } = this.props;
    return values.map((student, index) => (
      <ScoreRow
        key={student.student_id}
        student={student}
        index={index}
        questions={data.questions}
        handleSetValues={this.handleSetValues}
        totalScore={data.quiz.total_score}
        updateData={this.updateData}
        quizReviewId={data.id}
        onRequest={
          onRequestOneReview.student === student.student_id
            ? onRequestOneReview.state
            : false
        }
      />
    ));
  };

  renderHeadItem = questions => {
    return questions.map(question => (
      <th
        key={question.id}
        // style={{ width: widthTd }}
        className={`${question.question_type} fixed`}
      >
        {question.position}
      </th>
    ));
  };

  handleSetValues = (value, index) => {
    const { dispatch } = this.props;

    const { values } = this.state;
    values[index] = value;

    this.setState({
      values
    });
    dispatch(setValuesReviews(values));
  };

  render() {
    const { data: review } = this.props;
    const { values } = this.state;

    return values ? (
      <div className="container-table">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th
                className="col1 fixed-important z-max"
                style={{ zIndex: 99999 }}
              >
                PRESENTE
              </th>
              <th className="col2 fixed2 z-max">APELLIDO</th>
              <th className="col3 fixed3 z-max">NOMBRE</th>
              <th className="col3 fixed">Lee+</th>
              <th className="col3 fixed">Rl</th>
              {this.renderHeadItem(review.questions)}
              <th className="col5 fixed-right3 z-max">TOTAL</th>
              <th className="col6 z-max fixed-right2">%</th>
              <th className="col7 z-max fixed-right">-</th>
            </tr>
          </thead>
          <tbody>{this.renderRow()}</tbody>
        </table>
      </div>
    ) : // </div>
    null;
  }
}

const mapStateTuProps = state => {
  const { updateOneQuizReview: onRequestOneReview } = state.score;

  return {
    onRequestOneReview
  };
};

export default connect(mapStateTuProps)(ScoreTable);
