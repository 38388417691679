import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getAmountEstablishment,
  getAmountSections,
  getAmountStudents
} from '../../actions/dashboard';
import {
  SubTitleH2,
  BasicCard,
  NavbarLeft,
  LoadSpinner
} from '../../components';
import { AbilityContext } from '../../config/ability-context';

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAmountEstablishment());
    dispatch(getAmountSections());

    dispatch(getAmountStudents());
  }

  render() {
    const { onRequestAmount, amount } = this.props;
    const { context } = this;

    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            {onRequestAmount.establishments &&
              onRequestAmount.students &&
              onRequestAmount.sections && <LoadSpinner />}

            <div className="section">
              <SubTitleH2 text="Vista General" />
              <Row>
                <Col>
                  <BasicCard
                    text="Establecimientos adheridos"
                    title={String(amount.establishments)}
                    textLink={
                      context.can('create', 'Establishment') && 'Agregar'
                    }
                    href="/create-establishment"
                  />
                </Col>
                <Col>
                  <BasicCard
                    text="Cursos adheridos"
                    title={String(amount.sections)}
                    textLink={
                      context.can('create', 'Establishment') && 'Agregar'
                    }
                    href="/establishment-and-courses"
                  />
                </Col>
                <Col>
                  <BasicCard
                    text="Estudiantes activos"
                    title={String(amount.students)}
                    textLink="Ver más"
                    href="/students"
                  />
                </Col>
              </Row>
            </div>
            <div className="section">
              <SubTitleH2 text="Informes de Evaluaciones" />
              <Row>
                <Col xs={6}>
                  <BasicCard text="Establecimientos" />
                </Col>
                <Col xs={6}>
                  <BasicCard text="Comuna 1 - Tutores de Acompañamiento" />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

Dashboard.contextType = AbilityContext;
const mapStateToProps = state => {
  const {
    ongoingRequest,
    signedIn,
    user: { role }
  } = state.auth;
  const { amount, ongoingRequest: onRequestAmount } = state.dashboard;
  return {
    ongoingRequest,
    signedIn,
    amount,
    onRequestAmount,
    role
  };
};

export default withRouter(connect(mapStateToProps)(Dashboard));
