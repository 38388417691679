import { all } from 'redux-saga/effects';
import authSagas from './auth';
import establishmentSagas from './establishment';
import utilsSagas from './utils';
import testsSagas from './test';
import guidelinesSagas from './guideline';
import quizzesSagas from './quiz';
import teachersSagas from './teacher';
import studentSagas from './student';
import dashboardSagas from './dashboard';
import scoreSagas from './score';
import userSagas from './users';
import reportsSagas from './reports';

export default function* rootSaga() {
  yield all([
    authSagas(),
    establishmentSagas(),
    utilsSagas(),
    testsSagas(),
    guidelinesSagas(),
    quizzesSagas(),
    teachersSagas(),
    studentSagas(),
    dashboardSagas(),
    scoreSagas(),
    userSagas(),
    reportsSagas()
  ]);
}
