import React from 'react';
import { ArrowLeft } from 'react-feather';
import { Button } from 'react-bootstrap';
import { history } from '../../config/store';

const ArrowLeftIndex = ({ path, ...props }) => (
  <Button
    variant="link"
    size="sm"
    {...props}
    onClick={() => history.push(path)}
  >
    <ArrowLeft />
  </Button>
);

export default ArrowLeftIndex;
