import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Check } from 'react-feather';
import { guidances, materialTypes } from './data';
import SelectAlt from './SelectAlt';
import './style.scss';

const SectionItemForm = ({
  data,
  teachers,
  onChangeSection,
  requestTeachers,
  onLoadTeacher
}) => {
  const guidance = guidances.find(i => i.id === data.guidance);
  const materialType = materialTypes.find(i => i.id === data.material_type);
  return (
    <Row>
      <Col xs={1.8}>
        <div>
          <h3 className="title_section_item">
            Sección {String.fromCharCode(65 + data.position)}
          </h3>
        </div>
      </Col>
      <Col>
        <SelectAlt
          dispatchRequest={requestTeachers}
          data={teachers}
          initialValue={
            data.teacher
              ? { value: data.teacher.id, label: data.teacher.name }
              : 0
          }
          placeholder="Seleccione"
          onLoad={onLoadTeacher}
          title="Profesor"
          onChangeSelect={value =>
            onChangeSection(value, 'Teacher', data.position)
          }
          server
          isSearchable
        />
      </Col>
      <Col>
        <SelectAlt
          data={guidances}
          value={guidance.id}
          title="Seguimiento"
          onChangeSelect={value =>
            onChangeSection(value.value, 'Guidance', data.position)
          }
        />
      </Col>
      <Col>
        <SelectAlt
          data={materialTypes}
          value={materialType.id}
          title="Tipo de Material"
          onChangeSelect={value =>
            onChangeSection(value.value, 'materialType', data.position)
          }
        />
      </Col>

      <Col xs={1} style={{ margin: 'auto' }}>
        <div>{data.teacher_id && data.tutor_id ? <Check /> : null}</div>
      </Col>
    </Row>
  );
};

SectionItemForm.defaultProps = {};
export default SectionItemForm;
