import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import StepProgressBar from '../../components/StepProgressBar/StepProgressBar';
import { dataStepProgress } from './data';
import { NavbarLeft } from '../../components';
import { LoadSpinner } from '../../components/Utils';

const createQuiz = 'Crear Evaluación';

const CreateTest = ({ history, ongoingRequest }) => (
  <Container fluid>
    <Row>
      <Col md={2} className="d-none d-md-block sidebar">
        <NavbarLeft />
      </Col>
      <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
        {ongoingRequest.editQuiz ||
          (ongoingRequest.updateThirdStep && <LoadSpinner />)}
        <div className="section">
          <StepProgressBar
            history={history}
            steps={dataStepProgress}
            title={createQuiz}
          />
        </div>
      </Col>
    </Row>
  </Container>
);

const mapStateToProps = state => {
  const { ongoingRequest } = state.test;
  return {
    ongoingRequest
  };
};

export default connect(mapStateToProps)(CreateTest);
