import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { DefaultButton } from '../Button/index';
import ShowTestType from '../Quiz/TestType/ShowTestType';
// eslint-disable-next-line import/no-cycle
import { TypesOfQuestion } from '../index';
import { updateThirdStep } from '../../actions/test';
// import { LoadSpinner } from '../Utils';

class Score extends Component {
  validateAlternative = childData => {
    // eslint-disable-next-line
    this.setState({ validateAlternatives: childData });
  };

  nextStep = () => {
    const { dispatch, quiz } = this.props;
    dispatch(
      updateThirdStep({
        id: quiz.id,
        quiz: { axes_attributes: quiz.axes_attributes, in_step: 3 }
      })
    );
  };

  prevStep = () => {
    const { prevStep } = this.props;
    prevStep();
  };

  render() {
    const { quiz } = this.props;
    const items = [];
    quiz.axes_attributes.map((axe, axeIndex) =>
      axe.sub_axes_attributes.map((subAxe, subAxeIndex) =>
        subAxe.indicators_attributes.map((indicator, indicatorIndex) =>
          indicator.questions_attributes.map((item, itemIndex) =>
            items.push({
              ...item,
              itemIndex,
              indicator,
              indicatorIndex,
              subAxeIndex,
              axeIndex
            })
          )
        )
      )
    );

    const itemsToOrder = [];
    quiz.axes_attributes.map(axe =>
      axe.sub_axes_attributes.map(subAxe =>
        subAxe.indicators_attributes.map(indicator =>
          indicator.questions_attributes.map(item =>
            itemsToOrder.push(item.position)
          )
        )
      )
    );

    const renderItemsInfo = (
      <div className="shadow box">
        {items
          .sort((a, b) => (a.position > b.position ? 1 : -1))
          .map(item => (
            <TypesOfQuestion
              parentCallback={this.validateAlternative}
              key={item.itemIndex}
              itemIndex={item.itemIndex}
              indicatorIndex={item.indicatorIndex}
              item={item.indicator}
              subAxeIndex={item.subAxeIndex}
              axeIndex={item.axeIndex}
              position={item.position}
              // eslint-disable-next-line
              with_reviews={quiz.with_reviews}
            />
          ))}
      </div>
    );

    return (
      <div>
        <ShowTestType quiz={quiz} />
        <Row>
          <Col xs={{ span: 3, offset: 6 }}>
            <DefaultButton
              variant="light"
              text="Atrás"
              onClick={this.prevStep}
              block
            />
          </Col>
          <Col xs={{ span: 3 }}>
            <DefaultButton
              text="Siguiente"
              variant="secondary"
              onClick={this.nextStep}
              block
            />
          </Col>
        </Row>
        {renderItemsInfo}
        <Row className="mt-4">
          <Col xs={{ span: 3, offset: 6 }}>
            <DefaultButton
              variant="light"
              text="Atrás"
              onClick={this.prevStep}
              block
            />
          </Col>
          <Col xs={{ span: 3 }}>
            <DefaultButton
              text="Siguiente"
              variant="secondary"
              onClick={this.nextStep}
              block
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    ongoingRequest,
    currentStep,
    dataTypeTest,
    quiz: { quiz }
  } = state.test;
  return {
    ongoingRequest,
    currentStep,
    dataTypeTest,
    quiz
  };
};

export default connect(mapStateToProps)(Score);
