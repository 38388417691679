import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Select } from '../../Dropdowm';
import Unique from './Unique';
import Alternative from './Alternative';
import Open from './Open';
import { changeQuestionType } from '../../../actions/test';
import { getQuestionTypePerQuestion } from '../../../actions/quiz';

class TypesOfQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validatePlus: false,
      validateAlternatives: false
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    const { dispatch, guideline_id } = this.props;
    dispatch(getQuestionTypePerQuestion(guideline_id));
  }

  validatePlus = childData => {
    this.setState({ validatePlus: childData });
  };

  validateAlternatives = childData => {
    this.setState({ validateAlternatives: childData });
  };

  sendData = () => {
    const { validateAlternatives } = this.state;
    const { parentCallback } = this.props;
    if (validateAlternatives !== false) {
      return parentCallback(true);
    }
    return parentCallback(false);
  };

  renderTypeQuestion = () => {
    const {
      // eslint-disable-next-line
      axes_attributes,
      itemIndex,
      indicatorIndex,
      subAxeIndex,
      axeIndex
    } = this.props;
    const currentItem =
      axes_attributes[axeIndex].sub_axes_attributes[subAxeIndex]
        .indicators_attributes[indicatorIndex].questions_attributes[itemIndex];
    switch (currentItem.question_type_id) {
      case 1:
        return (
          <Alternative
            parentCallback={this.validateAlternatives}
            itemIndex={itemIndex}
            indicatorIndex={indicatorIndex}
            subAxeIndex={subAxeIndex}
            axeIndex={axeIndex}
          />
        );
      case 2:
        return (
          <Unique
            itemIndex={itemIndex}
            indicatorIndex={indicatorIndex}
            subAxeIndex={subAxeIndex}
            axeIndex={axeIndex}
          />
        );
      case 3:
        return (
          <Open
            parentCallback={this.validatePlus}
            itemIndex={itemIndex}
            indicatorIndex={indicatorIndex}
            subAxeIndex={subAxeIndex}
            axeIndex={axeIndex}
          />
        );
      case 4:
        return (
          <Open
            parentCallback={this.validatePlus}
            itemIndex={itemIndex}
            indicatorIndex={indicatorIndex}
            subAxeIndex={subAxeIndex}
            axeIndex={axeIndex}
          />
        );
      default:
        return null;
    }
  };

  changeQuestionType = value => {
    const {
      dispatch,
      position,
      indicatorIndex,
      subAxeIndex,
      axeIndex
    } = this.props;
    dispatch(
      changeQuestionType({
        value: Number(value),
        position,
        indicatorIndex,
        subAxeIndex,
        axeIndex
      })
    );
  };

  render() {
    this.sendData();
    const {
      itemIndex,
      indicatorIndex,
      subAxeIndex,
      axeIndex,
      parentCallback,
      // eslint-disable-next-line
      axes_attributes,
      position,
      questions,
      item,
      // eslint-disable-next-line
      with_reviews
    } = this.props;
    const { validatePlus } = this.state;
    parentCallback(validatePlus);

    const currentItem =
      axes_attributes[axeIndex].sub_axes_attributes[subAxeIndex]
        .indicators_attributes[indicatorIndex].questions_attributes;
    return (
      <div className="hr-last">
        <Row>
          <Col md={6}>
            <p className="text-dark font-weight-bold mb-2">
              Ítem nº {position}
              <span className="mb-1 ml-1 text-muted-indicator">
                ({item.name})
              </span>
            </p>
          </Col>
        </Row>
        <Row className="row-f">
          <Col md={3}>
            <p className="mb-1 text-muted">Tipo de pregunta</p>
            <Select
              items={questions}
              className="secondary"
              value={currentItem[itemIndex].question_type_id}
              onChange={e => this.changeQuestionType(e.target.value)}
              // eslint-disable-next-line
              disabled={with_reviews}
            />
          </Col>
          {this.renderTypeQuestion()}
        </Row>
        <hr />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    quiz: {
      // eslint-disable-next-line
      quiz: { axes_attributes, guideline_id }
    }
  } = state.test;
  const { questions } = state.quiz;
  return {
    // eslint-disable-next-line
    guideline_id,
    axes_attributes,
    questions
  };
};

export default connect(mapStateToProps)(TypesOfQuestion);
