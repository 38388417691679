import {
  GET_STUDENTS_REQUEST,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAILURE,
  CREATE_STUDENT_REQUEST,
  CREATE_STUDENT_SUCCESS,
  CREATE_STUDENT_FAILURE,
  UPDATE_STUDENT_REQUEST,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_FAILURE,
  DELETE_STUDENT_REQUEST,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_FAILURE,
  RESET_STUDENTS,
  GET_ONE_STUDENTS_REQUEST,
  GET_ONE_STUDENTS_SUCCESS,
  GET_ONE_STUDENTS_FAILURE,
  SET_REDIRECT,
  STUDENT_DESCRIPTION_REQUEST,
  STUDENT_DESCRIPTION_SUCCESS,
  STUDENT_DESCRIPTION_FAILURE,
  IMPORT_STUDENTS_REQUEST,
  IMPORT_STUDENTS_SUCCESS,
  IMPORT_STUDENTS_FAILURE,
  CLOSE_IMPORT_WINDOW
} from '../actions/student';

const initialState = {
  ongoingRequest: {
    getStudents: false,
    createStudent: false,
    updateStudent: false,
    deleteStudent: false,
    getStudentDescription: false
  },
  students: [],
  oneStudent: [],
  totalRows: 0,
  transitionStudent: {},
  redirect: {
    path: '',
    state: false
  },
  studentDescription: [],
  importMessage: [],
  loaderDocuments: false
};

const setData = data => {
  return data.map(d => {
    return { title: d.name, key: d.key, id: d.id };
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENTS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getStudents: true
        }
      };
    case GET_STUDENTS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getStudents: false
        },
        students: action.result.data,
        totalRows: action.result.metadata.amount
      };
    case GET_STUDENTS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getStudents: false
        }
      };
    case CREATE_STUDENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createStudent: true
        }
      };
    case CREATE_STUDENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createStudent: false
        }
      };
    case CREATE_STUDENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createStudent: false
        }
      };
    case UPDATE_STUDENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateStudent: true
        }
      };
    case UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateStudent: false
        }
      };
    case UPDATE_STUDENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateStudent: false
        }
      };
    case DELETE_STUDENT_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteStudent: true
        }
      };
    case DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteStudent: false
        }
      };
    case DELETE_STUDENT_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteStudent: false
        }
      };
    case RESET_STUDENTS:
      return {
        ...state,
        students: []
      };
    case GET_ONE_STUDENTS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getOneStudent: true
        }
      };
    case GET_ONE_STUDENTS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getOneStudent: false
        },
        transitionStudent: action.result,
        oneStudent: action.property
      };
    case GET_ONE_STUDENTS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getOneStudent: false
        }
      };
    case SET_REDIRECT:
      return {
        ...state,
        redirect: action.data
      };
    case STUDENT_DESCRIPTION_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getStudentDescription: true
        }
      };
    case STUDENT_DESCRIPTION_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getStudentDescription: false
        },
        studentDescription: setData(action.result)
      };
    case STUDENT_DESCRIPTION_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getStudentDescription: false
        }
      };
    case IMPORT_STUDENTS_REQUEST:
      return {
        ...state,
        loaderDocuments: true
      };
    case IMPORT_STUDENTS_SUCCESS:
      return {
        ...state,
        importMessage: action.result.message,
        loaderDocuments: false
      };
    case IMPORT_STUDENTS_FAILURE:
      return {
        ...state,
        importFailure: true,
        loaderDocuments: false
      };
    case CLOSE_IMPORT_WINDOW:
      return {
        ...state,
        importMessage: []
      };
    default:
      return state;
  }
};

export default reducer;
