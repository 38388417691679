import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import {
  SubTitleH2,
  NavbarLeft,
  SelectedQuizForm,
  LoadSpinner,
  ArrowLeftIndex
} from '../../components';

class AddScore extends Component {
  componentDidMount() {
    console.log('component');
  }

  onReload = load => {
    const { getRegions, createScore, createQuizReview } = load;
    if (getRegions || createScore || createQuizReview) {
      return <LoadSpinner />;
    }
    return null;
  };

  render() {
    const { onRequest } = this.props;
    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            {this.onReload(onRequest)}
            <div className="section">
              <ArrowLeftIndex className="mb-4 text-light" path="/score" />
              <SubTitleH2 text="Ingreso puntajes" />
              Completa las selecciones siguientes para comenzar a ingresar
              puntajes
              <Row>
                <Col>
                  <SelectedQuizForm />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { ongoingRequest } = state.utils;
  const { ongoingRequest: onRequest } = state.score;
  return {
    ongoingRequest,
    onRequest
  };
};

export default connect(mapStateToProps)(AddScore);
