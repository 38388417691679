const dataStepProgress = [
  {
    step: 'Tipo de Prueba'
  },
  {
    step: 'Ejes y Preguntas'
  },
  {
    step: 'Puntajes'
  },
  {
    step: 'Finalizar'
  }
];

const dataTypeTest = [
  {
    title: 'Tipo de evaluación ',
    key: 1,
    variant: 'link',
    id: 0
  },
  {
    title: 'Tipo de evaluación 1',
    key: 2,
    variant: 'link',
    id: 1
  },
  {
    title: 'Tipo de evaluación 2',
    key: 3,
    variant: 'link',
    id: 2
  }
];

const dataCourses = [
  {
    title: 'Curso',
    key: 1
  },
  {
    title: '1A',
    key: 2
  },
  {
    title: '1B',
    key: 3
  },
  {
    title: '2B',
    key: 4
  }
];

const dataEje = [
  {
    title: 'Nombre Eje 1',
    key: 1
  },
  {
    title: 'Nombre Eje 2',
    key: 2
  },
  {
    title: 'Nombre Eje 3',
    key: 3
  },
  {
    title: 'Nombre Eje 4',
    key: 4
  },
  {
    title: 'Nombre Eje 5',
    key: 5
  }
];

const dataEjeNav = [
  {
    title: 'Nombre Eje ',
    key: 1
  }
];

const dataObjectives = [
  {
    title: 'Objetivo de Aprendizaje 1',
    key: 1,
    id: 1
  },
  {
    title: 'Objetivo de Aprendizaje 2',
    key: 2,
    id: 2
  },
  {
    title: 'Objetivo de Aprendizaje 3',
    key: 3,
    id: 3
  },
  {
    title: 'Objetivo de Aprendizaje 4',
    key: 4,
    id: 4
  }
];

const dataItems = [
  {
    title: 'Cantidad de Items',
    key: 0
  },
  {
    title: '1',
    key: 1
  },
  {
    title: '2',
    key: 2
  },
  {
    title: '3',
    key: 3
  },
  {
    title: '4',
    key: 4
  },
  {
    title: '5',
    key: 5
  },
  {
    title: '6',
    key: 6
  },
  {
    title: '7',
    key: 7
  },
  {
    title: '8',
    key: 8
  },
  {
    title: '9',
    key: 9
  },
  {
    title: '10',
    key: 10
  }
];

const dataAbility = [
  {
    title: 'Habilidad',
    key: 0,
    id: 0
  },
  {
    title: 'Habilidad 1',
    key: 1,
    id: 1
  },
  {
    title: 'Habilidad 2',
    key: 2,
    id: 2
  },
  {
    title: 'Habilidad 3',
    key: 3,
    id: 3
  }
];

export {
  dataStepProgress,
  dataCourses,
  dataTypeTest,
  dataEje,
  dataEjeNav,
  dataObjectives,
  dataItems,
  dataAbility
};
