import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import {
  SubTitleH2,
  NavbarLeft,
  ComponentDataTable,
  ModalConfirm
} from '../../components';
import { columns, columnsAdmin } from './columns';
import { getQuizReviews, deleteQuizReview } from '../../actions/score';
import { AbilityContext, Can } from '../../config/ability-context';

class ScoreScreen extends Component {
  constructor() {
    super();
    this.state = {
      modalShowConfirm: false,
      modalData: {}
    };
  }

  handleButtonClick = (item, action) => {
    if (action === 'edit') {
      const { history } = this.props;
      history.push(`/score/add/${item.id}`);
    } else {
      this.setState({
        modalShowConfirm: true,
        modalData: {
          id: item.id,
          action: 'delete',
          title: 'ELIMINAR REVISIÓN',
          message: '¿Estás seguro que deseas eliminar esta revisión?'
        }
      });
    }
  };

  setModalShowConfirm = state => {
    this.setState({
      modalShowConfirm: state
    });
  };

  queryDispatch = data => {
    const { dispatch, signedIn } = this.props;
    if (signedIn) {
      dispatch(getQuizReviews(data));
    }
  };

  handleDelete = reviewId => {
    const { dispatch } = this.props;
    dispatch(deleteQuizReview({ id: reviewId }));
    this.setModalShowConfirm(false);
  };

  render() {
    const { history, ongoingRequest, quizReviews } = this.props;
    const { modalShowConfirm, modalData } = this.state;
    const { context } = this;

    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            <div className="section">
              <Row>
                <Col md={7}>
                  <SubTitleH2 text="Puntajes" />
                </Col>
                <Can I="transition" a="Section">
                  <Col md={5} style={{ textAlign: 'right' }}>
                    <Row>
                      <Col>
                        <Button onClick={() => history.push('/score/add')}>
                          Ingresar Puntajes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Can>
              </Row>
              <ComponentDataTable
                columns={
                  context.can('manage', 'QuizReview')
                    ? columnsAdmin(this.handleButtonClick)
                    : columns(this.handleButtonClick)
                }
                onRequest={
                  ongoingRequest.getQuizReviews ||
                  ongoingRequest.deleteQuizReview
                }
                data={quizReviews.data}
                totalRows={quizReviews.amount}
                reduxDispatch={this.queryDispatch}
              />
              <ModalConfirm
                show={modalShowConfirm}
                data={modalData}
                onHide={() => this.setModalShowConfirm(false)}
                action={this.handleDelete}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
ScoreScreen.contextType = AbilityContext;

const mapStateToProps = state => {
  const { ongoingRequest, quizReviews } = state.score;

  const { user, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn,
    quizReviews,
    user
  };
};
export default connect(mapStateToProps)(ScoreScreen);
