import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_GUIDELINE_REQUEST,
  GET_GUIDELINE_SUCCESS,
  GET_GUIDELINE_FAILURE,
  DELETE_GUIDELINE_REQUEST,
  DELETE_GUIDELINE_SUCCESS,
  DELETE_GUIDELINE_FAILURE,
  CREATE_GUIDELINE_REQUEST,
  CREATE_GUIDELINE_SUCCESS,
  CREATE_GUIDELINE_FAILURE,
  UPDATE_GUIDELINE_REQUEST,
  UPDATE_GUIDELINE_SUCCESS,
  UPDATE_GUIDELINE_FAILURE,
  SET_REDIRECT
} from '../actions/guideline';
import { SET_NOTICE } from '../actions/alert';
import API from '../services/api';
import runDefaultSaga from './default';
import { serializeJsonToString } from '../services/utils';
// ------ SHOW ALL GUIDELINES --------

const getGuidelinesRequest = data => {
  const params = serializeJsonToString(data);

  return API.get(`/guidelines?${params}&sort_direction=desc`);
};

function* getGuidelinesSuccessCallback(result) {
  yield put({
    type: GET_GUIDELINE_SUCCESS,
    result
  });
}

function* getGuidelinesFailureCallback() {
  yield put({
    type: GET_GUIDELINE_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getGuidelines(action) {
  yield runDefaultSaga(
    { request: getGuidelinesRequest, params: action.data },
    getGuidelinesSuccessCallback,
    getGuidelinesFailureCallback
  );
}

// ----- CREATE GUIDELINE ------

const createGuidelinesRequest = data => {
  return API.post('/guidelines', data);
};

function* createGuidelinesSuccessCallback(result) {
  yield put({
    type: CREATE_GUIDELINE_SUCCESS,
    result
  });
  yield put({
    type: SET_REDIRECT,
    data: { path: '/guidelines', state: true }
  });
  yield put({
    type: SET_NOTICE,
    title: 'Creacion exitosa',
    message: 'El tipo de evaluación se ha creado exitosamente',
    kind: 'success',
    timeout: 2000
  });
  yield put({
    type: GET_GUIDELINE_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
}

function* createGuidelinesFailureCallback() {
  yield put({
    type: CREATE_GUIDELINE_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* createGuidelines(action) {
  yield runDefaultSaga(
    { request: createGuidelinesRequest, params: action.data },
    createGuidelinesSuccessCallback,
    createGuidelinesFailureCallback
  );
}

// ----- UPDATE GUIDELINE ------

const updateGuidelinesRequest = data => {
  return API.put(`/guidelines/${data.id}`, { guideline: data.guideline });
};

function* updateGuidelinesSuccessCallback(result) {
  yield put({
    type: UPDATE_GUIDELINE_SUCCESS,
    result
  });
  yield put({
    type: SET_REDIRECT,
    data: { path: '/guidelines', state: true }
  });
  yield put({
    type: SET_NOTICE,
    title: 'Creacion exitosa',
    message: 'El tipo de evaluación se ha creado exitosamente',
    kind: 'success',
    timeout: 2000
  });
  yield put({
    type: GET_GUIDELINE_REQUEST,
    data: {
      display_start: 0,
      display_length: 10
    }
  });
}

function* updateGuidelinesFailureCallback() {
  yield put({
    type: UPDATE_GUIDELINE_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* updateGuidelines(action) {
  yield runDefaultSaga(
    { request: updateGuidelinesRequest, params: action.data },
    updateGuidelinesSuccessCallback,
    updateGuidelinesFailureCallback
  );
}

// ------ DELETE GUIDELINE --------

const deleteGuidelineRequest = data => {
  return API.delete(`/guidelines/${data.id}`);
};

function* deleteGuidelineSuccessCallback(result) {
  yield put({
    type: DELETE_GUIDELINE_SUCCESS,
    result
  });
  yield put({
    type: SET_NOTICE,
    title: 'Exito',
    message: 'El tipo de evaluación se ha borrado exitosamente',
    kind: 'success',
    timeout: 2000
  });
  yield put({
    type: GET_GUIDELINE_REQUEST,
    data: { text: '', start: 0, length: 5 }
  });
}

function* deleteGuidelineFailureCallback() {
  yield put({
    type: DELETE_GUIDELINE_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* deleteGuideline(action) {
  yield runDefaultSaga(
    { request: deleteGuidelineRequest, params: action.data },
    deleteGuidelineSuccessCallback,
    deleteGuidelineFailureCallback
  );
}

// DEFINE guidelines
export default function* guidelinesSagas() {
  yield takeEvery(GET_GUIDELINE_REQUEST, getGuidelines);
  yield takeEvery(DELETE_GUIDELINE_REQUEST, deleteGuideline);
  yield takeEvery(CREATE_GUIDELINE_REQUEST, createGuidelines);
  yield takeEvery(UPDATE_GUIDELINE_REQUEST, updateGuidelines);
}
