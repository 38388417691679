import React, { Component } from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { DefaultButton } from '../Button/index';
import ShowTestType from '../Quiz/TestType/ShowTestType';
// eslint-disable-next-line import/no-cycle
import Axes from '../Quiz/Axes/Axes';
import AddAxes from '../Quiz/Axes/AddAxes';
// eslint-disable-next-line import/no-cycle
import AddSubAxes from '../Quiz/SubAxes/AddSubAxes';
import { updateSecondStep, addAxes } from '../../actions/test';

class Questions extends Component {
  constructor() {
    super();
    this.state = {
      validateAxe: false,
      validateSubAxe: false
    };
  }

  validateAxe = childData => {
    this.setState({ validateAxe: childData });
  };

  validateSubAxe = childData => {
    this.setState({ validateSubAxe: childData });
  };

  updtSecondStep = () => {
    const { dispatch, quiz, currentStep } = this.props;
    dispatch(
      updateSecondStep({
        id: quiz.id,
        quiz: { axes_attributes: quiz.axes_attributes, in_step: currentStep }
      })
    );
  };

  addAxe = () => {
    const { dispatch, quiz } = this.props;
    dispatch(
      addAxes({
        key: quiz.axes_attributes.length,
        name: '',
        sub_axes_attributes: []
      })
    );
  };

  nextStepBtn = () => {
    this.updtSecondStep();
  };

  prevStepBtn = () => {
    const { prevStep } = this.props;
    prevStep();
  };

  render() {
    const { activeTab, quiz, ongoingRequest } = this.props;
    const { validateAxe, validateSubAxe } = this.state;
    return (
      <div>
        {!ongoingRequest.getFirstStep ? (
          <div>
            <ShowTestType quiz={quiz} />
            <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
              <Nav variant="tabs" className="tabs-axis" activeKey={activeTab}>
                <Axes parentCallback={this.validateAxe} />
                {!quiz.with_reviews && (
                  <AddAxes quiz={quiz} addAxe={this.addAxe} />
                )}
              </Nav>
              {quiz.axes_attributes ? (
                <AddSubAxes parentCallback={this.validateSubAxe} />
              ) : (
                <p className="disclaimer">*Crea un eje para comenzar</p>
              )}
            </Tab.Container>
            <Row>
              <Col xs={{ span: 3, offset: 6 }}>
                <DefaultButton
                  variant="light"
                  text="Atrás"
                  onClick={this.prevStepBtn}
                  block
                />
              </Col>
              <Col xs={{ span: 3 }}>
                <DefaultButton
                  text="Siguiente"
                  variant="secondary"
                  onClick={this.nextStepBtn}
                  block
                  disabled={!(validateAxe && validateSubAxe)}
                />
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    currentStep,
    activeTab,
    ongoingRequest,
    quiz: { quiz }
  } = state.test;
  return {
    ongoingRequest,
    currentStep,
    activeTab,
    quiz
  };
};

export default connect(mapStateToProps)(Questions);
