export const GET_YEAR_CHART_REQUEST = 'GET_YEAR_CHART_REQUEST';
export const GET_YEAR_CHART_SUCCESS = 'GET_YEAR_CHART_SUCCESS';
export const GET_YEAR_CHART_FAILURE = 'GET_YEAR_CHART_FAILURE';

export const getYearChart = data => ({ type: GET_YEAR_CHART_REQUEST, data });

export const GET_REGION_CHART_REQUEST = 'GET_REGION_CHART_REQUEST';
export const GET_REGION_CHART_SUCCESS = 'GET_REGION_CHART_SUCCESS';
export const GET_REGION_CHART_FAILURE = 'GET_REGION_CHART_FAILURE';

export const getRegionChart = data => ({
  type: GET_REGION_CHART_REQUEST,
  data
});

export const GET_COMMUNE_CHART_REQUEST = 'GET_COMMUNE_CHART_REQUEST';
export const GET_COMMUNE_CHART_SUCCESS = 'GET_COMMUNE_CHART_SUCCESS';
export const GET_COMMUNE_CHART_FAILURE = 'GET_COMMUNE_CHART_FAILURE';

export const getCommuneChart = data => ({
  type: GET_COMMUNE_CHART_REQUEST,
  data
});

export const GET_ESTABLISHMENT_CHART_REQUEST =
  'GET_ESTABLISHMENT_CHART_REQUEST';
export const GET_ESTABLISHMENT_CHART_SUCCESS =
  'GET_ESTABLISHMENT_CHART_SUCCESS';
export const GET_ESTABLISHMENT_CHART_FAILURE =
  'GET_ESTABLISHMENT_CHART_FAILURE';

export const getEstablishmentChart = data => ({
  type: GET_ESTABLISHMENT_CHART_REQUEST,
  data
});

export const GET_GRADE_CHART_REQUEST = 'GET_GRADE_CHART_REQUEST';
export const GET_GRADE_CHART_SUCCESS = 'GET_GRADE_CHART_SUCCESS';
export const GET_GRADE_CHART_FAILURE = 'GET_GRADE_CHART_FAILURE';

export const getGradeChart = data => ({
  type: GET_GRADE_CHART_REQUEST,
  data
});

export const GET_SECTION_CHART_REQUEST = 'GET_SECTION_CHART_REQUEST';
export const GET_SECTION_CHART_SUCCESS = 'GET_SECTION_CHART_SUCCESS';
export const GET_SECTION_CHART_FAILURE = 'GET_SECTION_CHART_FAILURE';

export const getSectionChart = data => ({
  type: GET_SECTION_CHART_REQUEST,
  data
});

export const GET_QUIZ_CHART_REQUEST = 'GET_QUIZ_CHART_REQUEST';
export const GET_QUIZ_CHART_SUCCESS = 'GET_QUIZ_CHART_SUCCESS';
export const GET_QUIZ_CHART_FAILURE = 'GET_QUIZ_CHART_FAILURE';

export const getQuizChart = data => ({
  type: GET_QUIZ_CHART_REQUEST,
  data
});

export const GET_UNIT_CHART_REQUEST = 'GET_UNIT_CHART_REQUEST';
export const GET_UNIT_CHART_SUCCESS = 'GET_UNIT_CHART_SUCCESS';
export const GET_UNIT_CHART_FAILURE = 'GET_UNIT_CHART_FAILURE';

export const getUnitChart = data => ({
  type: GET_UNIT_CHART_REQUEST,
  data
});

export const SHOW_DOCUMENT_REQUEST = 'SHOW_DOCUMENT_REQUEST';
export const SHOW_DOCUMENT_SUCCESS = 'SHOW_DOCUMENT_SUCCESS';
export const SHOW_DOCUMENT_FAILURE = 'SHOW_DOCUMENT_FAILURE';

export const showDocument = id => ({
  type: SHOW_DOCUMENT_REQUEST,
  id
});

export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE';

export const createDocument = data => ({
  type: CREATE_DOCUMENT_REQUEST,
  data
});

export const CHECK_DOCUMENT_REQUEST = 'CHECK_DOCUMENT_REQUEST';
export const CHECK_DOCUMENT_SUCCESS = 'CHECK_DOCUMENT_SUCCESS';
export const CHECK_DOCUMENT_FAILURE = 'CHECK_DOCUMENT_FAILURE';

export const checkDocument = data => ({
  type: CHECK_DOCUMENT_REQUEST,
  data
});

export const POST_CHARTS_IMAGES_REQUEST = 'POST_CHARTS_IMAGES_REQUEST';
export const POST_CHARTS_IMAGES_SUCCESS = 'POST_CHARTS_IMAGES_SUCCESS';
export const POST_CHARTS_IMAGES_FAILURE = 'POST_CHARTS_IMAGES_FAILURE';

export const postChartsImages = data => ({
  type: POST_CHARTS_IMAGES_REQUEST,
  data
});

export const GENERATE_DOCUMENT_REQUEST = 'GENERATE_DOCUMENT_REQUEST';
export const GENERATE_DOCUMENT_SUCCESS = 'GENERATE_DOCUMENT_SUCCESS';
export const GENERATE_DOCUMENT_FAILURE = 'GENERATE_DOCUMENT_FAILURE';

export const generateDocument = data => ({
  type: GENERATE_DOCUMENT_REQUEST,
  data
});

export const CLEAR_DATA_CHART = 'CLEAR_DATA_CHART';

export const clearDataChart = data => ({ type: CLEAR_DATA_CHART, data });

export const GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAILURE = 'GET_DOCUMENTS_FAILURE';

export const getDocuments = data => ({
  type: GET_DOCUMENTS_REQUEST,
  data
});

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';

export const deleteDocuments = data => ({
  type: DELETE_DOCUMENT_REQUEST,
  data
});

export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';

export const uploadDocument = data => ({
  type: UPLOAD_DOCUMENT_REQUEST,
  data
});

export const SET_REDIRECT = 'SET_REDIRECT';
export const setRedirect = data => ({
  type: SET_REDIRECT,
  data
});

export const DOWNLOAD_DOCUMENT_REQUEST = 'DOWNLOAD_DOCUMENT_REQUEST';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';
export const DOWNLOAD_DOCUMENT_FAILURE = 'DOWNLOAD_DOCUMENT_FAILURE';
export const downloadDocument = data => ({
  type: DOWNLOAD_DOCUMENT_REQUEST,
  data
});
