import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  SubTitleH2,
  NavbarLeft,
  DefaultButton,
  StudentItemForm,
  LoadSpinner,
  SelectAlt
} from '../../components';
import {
  getRegions,
  getCommunes,
  getGradeTransition,
  getUtilsEstablishments,
  getGrades,
  getSections,
  resetStudentsTransition,
  postGradeTransition,
  getYearsEstablishment
} from '../../actions/utils';

class AdvanceCourse extends Component {
  constructor() {
    super();
    this.state = {
      region: 0,
      commune: 0,
      establishment: 0,
      year: 0,
      grade: 0,
      section: 0,
      studentsDestination: []
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getRegions({ at_least_one: 1 }));
  }

  componentWillReceiveProps(nextProps) {
    const { students, ongoingRequest } = nextProps;
    const { studentsDestination } = this.state;
    if (
      students.id &&
      !ongoingRequest.getGradeTransition &&
      studentsDestination.length === 0
    ) {
      this.initDataTransition(
        students.students,
        students.next_grade_sections,
        students.name
      );
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetStudentsTransition());
  }

  onChangeSelects = (value, type) => {
    const { dispatch } = this.props;
    const { establishment, year } = this.state;
    const state = Number(value) !== 0;
    switch (type) {
      case 'Region':
        if (state) {
          dispatch(getCommunes({ region_id: value, at_least_one: true }));
        }
        this.setState({
          region: state ? value : 0,
          commune: 0,
          establishment: 0,
          year: 0,
          grade: 0,
          section: 0
        });
        dispatch(resetStudentsTransition());

        break;
      case 'Commune':
        if (state) {
          dispatch(getUtilsEstablishments({ commune_id: value }));
        }
        this.setState({
          commune: state ? value : 0,
          establishment: 0,
          year: 0,
          grade: 0,
          section: 0
        });
        dispatch(resetStudentsTransition());

        break;
      case 'Establishment':
        if (state) {
          dispatch(getYearsEstablishment(value));
        }

        this.setState({
          establishment: state ? value : 0,
          year: 0,
          grade: 0,
          section: 0
        });
        dispatch(resetStudentsTransition());

        break;
      case 'Year':
        if (state) {
          dispatch(
            getGrades({
              establishment_id: establishment,
              year: value,
              select: true
            })
          );
        }
        this.setState({
          year: state ? value : 0,
          grade: 0,
          section: 0
        });
        dispatch(resetStudentsTransition());

        break;
      case 'Grade':
        if (state) {
          dispatch(
            getSections({
              establishmentId: establishment,
              year,
              gradeId: value
            })
          );
        }
        this.setState({
          grade: state ? value : 0,
          section: 0
        });
        dispatch(resetStudentsTransition());
        break;
      case 'Section':
        if (state) {
          dispatch(getGradeTransition(value));
        }
        this.setState({
          section: state ? value : 0,
          studentsDestination: []
        });
        break;
      default:
        break;
    }
  };

  renderItemStudent = data => {
    const { studentsDestination } = this.state;

    return data.students.map((student, i) => {
      return (
        <StudentItemForm
          position={i}
          student={student}
          studentsDestination={studentsDestination.find(
            item => item.studentId === student.id
          )}
          currentSections={data.current_grade_sections}
          nextSections={data.next_grade_sections}
          nextName={`${data.next_grade_name} ${data.year + 1}`}
          currentName={`${data.current_grade_name} ${data.year + 1}`}
          handleSelect={this.handleChangeSelect}
        />
      );
    });
  };

  handleChangeSelect = (sectionId, studentId, position) => {
    const { studentsDestination } = this.state;
    studentsDestination[position].sectionId = Number(sectionId);
    this.setState({
      studentsDestination
    });
  };

  initDataTransition = (students, nextGrade, nameSection) => {
    const stateGrade = nextGrade.length !== 0;
    let nextGradeId = 0;
    if (stateGrade) {
      const findIndex = nextGrade.find(item => item.name === nameSection);
      nextGradeId = findIndex ? findIndex.id : nextGrade[0].id;
    }
    const data = students.map(item => ({
      studentId: item.id,
      sectionId: nextGradeId
    }));
    this.setState({ studentsDestination: data });
  };

  handleSubmmit = () => {
    const { dispatch } = this.props;
    const { section, studentsDestination } = this.state;
    dispatch(postGradeTransition({ body: studentsDestination, id: section }));
  };

  validateState = () => {
    const { commune, establishment, year, grade, section } = this.state;
    const { students } = this.props;
    const studentsLength = students.students ? students.students.length : 0;

    return !(
      commune === 0 ||
      establishment === 0 ||
      year === 0 ||
      grade === 0 ||
      section === 0 ||
      studentsLength === 0
    );
  };

  renderAlertText = (students, status, establishment) => {
    const nextState = students.next_grade_sections.length === 0;
    const currentState = students.current_grade_sections.length === 0;
    const nextText = nextState ? students.next_grade_name : '';
    const currentText = currentState ? students.current_grade_name : '';
    const state = nextState === true && currentState === true ? 'ni' : '';
    if (status && (nextState || currentState)) {
      return (
        <p>
          {`No tienes ${nextText} ${state} ${currentText} para el año ${students.year +
            1}, agrega nuevos cursos `}
          <Link to={`/create-establishment/${establishment}`}> aquí</Link>
        </p>
      );
    }
    return null;
  };

  renderStudent = (students, status) => {
    const { establishment } = this.state;
    if (students.id) {
      if (students.students.length !== 0 && status) {
        return (
          <div>
            <Row>
              <Col>
                <h3>Detalle de movimiento</h3>
                {this.renderAlertText(students, status, establishment)}
              </Col>
            </Row>
            <hr />

            <Row>
              <Col>
                <h3>APELLIDO</h3>
              </Col>
              <Col>
                <h3>NOMBRE</h3>
              </Col>
              <Col>
                <h3>RUT</h3>
              </Col>
              <Col className="text-center">
                <h3>REPROBADO</h3>
              </Col>
              <Col className="text-center">
                <h3>APROBADO</h3>
              </Col>
              <Col>
                <h3>PRÓXIMO CURSO</h3>
              </Col>
              <Col>
                <h3>PRÓXIMA SECCIÓN</h3>
              </Col>
            </Row>
            <hr />
            {status && this.renderItemStudent(students)}
          </div>
        );
      }
      return <h3>No hay estudiantes en este curso</h3>;
    }
    return null;
  };

  onReload = ongoingRequest => {
    const {
      postGradeTransition: post,
      getGradeTransition: get
    } = ongoingRequest;
    return post || get;
  };

  render() {
    const {
      history,
      regions,
      communes,
      grades,
      establishments,
      ongoingRequest,
      sections,
      students,
      yearsEstablishment
    } = this.props;
    const {
      region,
      commune,
      establishment,
      year,
      grade,
      section,
      studentsDestination
    } = this.state;
    const status = this.validateState();
    const statusDestination = studentsDestination.some(
      item => item.sectionId === 0
    );
    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            {this.onReload(ongoingRequest) && <LoadSpinner />}

            <div className="section">
              <SubTitleH2 text="Avanzar de curso" />
              <Row style={{ justifyContent: 'flex-end' }}>
                <Col xs={4} xm={3} md={3} lg={2}>
                  <DefaultButton
                    style={{ width: '100%' }}
                    text="Volver"
                    className="btn-light"
                    onClick={() => history.push('/students')}
                  />
                </Col>
                <Col xs={4} xm={3} md={3} lg={2}>
                  <DefaultButton
                    text="Confirmar"
                    disabled={!status || statusDestination}
                    onClick={() => this.handleSubmmit()}
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3>Selecciona un curso</h3>
                </Col>
              </Row>
              <Row className="mb-5 mt-5">
                <Col>
                  <SelectAlt
                    data={
                      ongoingRequest.getRegion
                        ? [{ key: 0, title: 'Cargando..' }]
                        : regions
                    }
                    onChangeSelect={e =>
                      this.onChangeSelects(e.value, 'Region')
                    }
                    title="Region"
                  />
                </Col>
                <Col>
                  <SelectAlt
                    data={
                      ongoingRequest.getCommunes
                        ? [{ key: 0, title: 'Cargando..' }]
                        : communes
                    }
                    value={region !== 0 ? commune : 0}
                    onChangeSelect={e =>
                      this.onChangeSelects(e.value, 'Commune')
                    }
                    disabled={region === 0}
                    title="Comuna"
                  />
                </Col>
                <Col>
                  <SelectAlt
                    data={
                      ongoingRequest.getUtilsEstablishments
                        ? [{ key: 0, title: 'Cargando..' }]
                        : establishments
                    }
                    onChangeSelect={e =>
                      this.onChangeSelects(e.value, 'Establishment')
                    }
                    value={commune !== 0 ? establishment : 0}
                    disabled={commune === 0}
                    title="Establecimiento"
                  />
                </Col>
                <Col>
                  <SelectAlt
                    data={
                      ongoingRequest.getYearsEstablishment
                        ? [{ key: 0, title: 'Cargando..' }]
                        : yearsEstablishment
                    }
                    onChangeSelect={e => this.onChangeSelects(e.value, 'Year')}
                    value={establishment !== 0 ? year : 0}
                    disabled={establishment === 0}
                    title="Años"
                  />
                </Col>
                <Col>
                  <SelectAlt
                    data={
                      ongoingRequest.getGrades
                        ? [{ key: 0, title: 'Cargando..' }]
                        : grades
                    }
                    value={year !== 0 ? grade : 0}
                    onChangeSelect={e => this.onChangeSelects(e.value, 'Grade')}
                    disabled={year === 0}
                    title="Cursos"
                  />
                </Col>
                <Col>
                  <SelectAlt
                    data={
                      ongoingRequest.getSections
                        ? [{ key: 0, title: 'Cargando..' }]
                        : sections
                    }
                    value={grade !== 0 ? section : 0}
                    onChangeSelect={e =>
                      this.onChangeSelects(e.value, 'Section')
                    }
                    disabled={grade === 0}
                    title="Secciones"
                  />
                </Col>
              </Row>
              {this.renderStudent(students, status)}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const {
    ongoingRequest,
    regions,
    communes,
    establishments,
    grades,
    sections,
    studentsTransition: students,

    yearsEstablishment
  } = state.utils;

  return {
    regions,
    communes,
    ongoingRequest,
    grades,
    establishments,
    sections,
    students,
    yearsEstablishment
  };
};

export default connect(mapStateToProps)(AdvanceCourse);
