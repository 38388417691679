import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Tab, Tabs, TabPane } from 'react-bootstrap';
import { DefaultButton } from '../Button/index';
import './styles.scss';
import ShowTestType from '../Quiz/TestType/ShowTestType';
import { updateThirdStep, activeQuestion } from '../../actions/test';
// eslint-disable-next-line import/no-cycle
import { Switch } from '../index';

class Finish extends Component {
  constructor() {
    super();
    this.state = {};
  }

  nextStep = () => {
    const { dispatch, quiz } = this.props;
    dispatch(
      updateThirdStep({
        id: quiz.id,
        quiz: { ...quiz, in_step: 4 }
      })
    );
  };

  prevStep = () => {
    const { prevStep } = this.props;
    prevStep();
  };

  handleChange = (checked, params) => {
    const { dispatch } = this.props;
    dispatch(
      activeQuestion({
        checked,
        ...params
      })
    );
  };

  render() {
    const { ongoingRequest, quiz } = this.props;
    const axes =
      !ongoingRequest.updateThirdStep && quiz.guideline
        ? quiz.axes_attributes.map((axe, axeIndex) => (
            <Tab
              eventKey={axeIndex}
              key={axe.id}
              title={<p className="text-dark mb-0"> {axe.name}</p>}
            >
              <TabPane>
                {axe.sub_axes_attributes.map((subAxe, subAxeIndex) => {
                  // let itemNumber = 0;
                  return (
                    <div>
                      <p className="font-weight-bold mt-3 mb-1">
                        {subAxe.name}
                      </p>
                      <Row
                        className="font-weight-bold mt-3 mb-1"
                        style={{ padding: '0 15px' }}
                      >
                        <Col md={4}>Posición</Col>
                        <Col className="text-center mb-0" md={3}>
                          Tipo de pregunta
                        </Col>
                        <Col className="text-center mb-0" md={3}>
                          Puntaje
                        </Col>
                        <Col className="text-center mb-0" md={2}>
                          Ítem permitido
                        </Col>
                      </Row>
                      {subAxe.indicators_attributes.map(
                        (indicator, indicatorIndex) =>
                          indicator.questions_attributes.map(
                            (question, questionIndex) => {
                              // itemNumber += 1;
                              return (
                                <div>
                                  <div className="box-line">
                                    <Row>
                                      <Col md={4}>
                                        <p className="mb-0">
                                          {/* {itemNumber}. */}
                                          Item {question.position}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <p className="text-center mb-0">
                                          {question.question_type_id === 1
                                            ? `${question.question_type.name} (${question.answer_as_letter})`
                                            : `${question.question_type.name}`}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <p className="text-center mb-0">
                                          {question.question_type_id === 3 ||
                                          question.question_type_id === 4
                                            ? question.question_performances_attributes.map(
                                                (performance, performIndex) =>
                                                  performIndex + 1 ===
                                                  question
                                                    .question_performances_attributes
                                                    .length
                                                    ? `${performance.score} `
                                                    : `${performance.score} / `
                                              )
                                            : `${question.score} `}
                                          ptos
                                        </p>
                                      </Col>
                                      <Col md={2} className="text-center">
                                        <Switch
                                          checked={question.active}
                                          handleChange={this.handleChange}
                                          params={{
                                            axeIndex,
                                            subAxeIndex,
                                            indicatorIndex,
                                            questionIndex
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              );
                            }
                          )
                      )}
                    </div>
                  );
                })}
              </TabPane>
            </Tab>
          ))
        : null;
    return (
      <div>
        {!ongoingRequest.updateThirdStep && quiz.guideline ? (
          <div>
            <Row>
              <Col md={5}>
                <ShowTestType quiz={quiz} />
              </Col>
              <Col md={{ span: 3, offset: 2 }}>
                <p className="mb-0 ml-6">Puntaje Total Prueba:</p>
                <p className="mb-2 ml-6">Porcentaje para logrado:</p>
                <p className="mb-0 ml-6 font-weight-bold">
                  Porcentajes Desempeño:
                </p>
                {quiz.guideline.guideline_performances.map(perform => (
                  <p className="mb-0 ml-3 gray-400">{`- ${perform.tag}`}</p>
                ))}
              </Col>
              <Col md={2}>
                <p className="mb-0 text-left">{quiz.total_score} ptos</p>
                <p className="mb-0 text-left">{quiz.guideline.minimum}%</p>
                <p className="mb-4 text-left" />
                <p className="mb-0 text-left"> </p>
                {quiz.guideline.guideline_performances.map(perform => (
                  <div>
                    <p className="mb-0 text-left">
                      {`${perform.minimum}%`} / {`${perform.maximum}%`}
                    </p>
                  </div>
                ))}
              </Col>
            </Row>

            <Tabs
              defaultActiveKey="0"
              variant="tabs"
              className="tabs-axis text"
            >
              {axes}
            </Tabs>
            <Row className="mt-4">
              <Col xs={{ span: 3, offset: 6 }}>
                <DefaultButton
                  variant="light"
                  text="Atrás"
                  onClick={this.prevStep}
                  block
                />
              </Col>
              <Col xs={{ span: 3 }}>
                <DefaultButton
                  text="Finalizar"
                  variant="secondary"
                  block
                  onClick={this.nextStep}
                  disabled={ongoingRequest.updateThirdStep && !quiz.ready}
                />
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    result,
    ongoingRequest,
    quiz: { quiz }
  } = state.test;
  return {
    result,
    ongoingRequest,
    quiz
  };
};

export default connect(mapStateToProps)(Finish);
