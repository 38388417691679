import React, { PureComponent } from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text
} from 'recharts';

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">
      {payload.value}
    </Text>
  );
};

export default class ChartBar extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';

  render() {
    const { dataChart, ticks, children } = this.props;
    return (
      <BarChart
        width={500}
        height={300}
        data={dataChart}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          interval={0}
          tick={<CustomizedAxisTick />}
          height={100}
        />
        <YAxis unit="%" ticks={ticks} />
        <Tooltip />
        <Legend />
        {children}
      </BarChart>
    );
  }
}
