import React, { Component } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { X } from 'react-feather';

const charRange = start => {
  const base = start.charCodeAt(0);
  // eslint-disable-next-line func-names
  return function(_, i) {
    return String.fromCharCode(i + base);
  };
};

const fullArray = len => {
  return [].concat(...Array(len));
};

class Check extends Component {
  constructor(props) {
    super(props);
    const chars = fullArray(props.amount).map(charRange('A'));

    this.state = {
      checkSelected: props.checkSelected || 0,
      name: props.name || 0,
      chars
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      checkSelected: nextProps.checkSelected
    });
  }

  handleChange = event => {
    const idSplit = event.target.id.split('-');
    const { onChangeCheckBox } = this.props;
    const { name } = this.state;
    this.setState({
      checkSelected: Number(idSplit[1]) + 1
    });

    onChangeCheckBox(Number(idSplit[1]) + 1, name);
  };

  renderCheckBox = name => {
    const { disabled } = this.props;
    const { checkSelected, chars } = this.state;

    return chars.map((check, index) => {
      const status = checkSelected > index;
      const status2 = disabled ? false : status;
      return (
        <Form.Check
          disabled={disabled}
          // eslint-disable-next-line
          key={index}
          checked={status2}
          inline
          type="checkbox"
          label={`${check}`}
          id={`${name}-${index}`}
          className="custom-box"
          onChange={this.handleChange}
        />
      );
    });
  };

  remove = () => {
    const { onChangeCheckBox } = this.props;
    const { name } = this.state;
    this.setState({
      checkSelected: 0
    });
    onChangeCheckBox(0, name);
  };

  render() {
    const { name, disabled } = this.props;
    return (
      <Row>
        <Col>{this.renderCheckBox(name)}</Col>
        <Col md={2}>
          <Button
            className="p-fix"
            variant="outline-danger"
            size="sm"
            disabled={disabled}
            block
            onClick={() => this.remove(name)}
          >
            <X className="small-svg" />
          </Button>
        </Col>
      </Row>
    );
  }
}

Check.defaultProps = {
  amount: 1
};
export default Check;
