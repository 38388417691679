export const ADD_AXES = 'ADD_AXES';
export const ADD_SUB_AXES = 'ADD_SUB_AXES';
export const ADD_INDICATOR = 'ADD_INDICATOR';
export const ADD_ITEMS_TO_INDICATOR = 'ADD_ITEMS_TO_INDICATOR';
export const ADD_CATEGORY_TO_QUESTION_PERFORMANCES =
  'ADD_CATEGORY_TO_QUESTION_PERFORMANCES';

export const DELETE_AXES = 'DELETE_AXES';
export const DELETE_SUB_AXES = 'DELETE_SUB_AXES';
export const DELETE_INDICATOR = 'DELETE_INDICATOR';

export const SAVE_FIRST_STEP_REQUEST = 'SAVE_FIRST_STEP_REQUEST';
export const SAVE_FIRST_STEP_SUCCESS = 'SAVE_FIRST_STEP_SUCCESS';
export const SAVE_FIRST_STEP_FAILURE = 'SAVE_FIRST_STEP_FAILURE';

export const UPDATE_FIRST_STEP_REQUEST = 'UPDATE_FIRST_STEP_REQUEST';
export const UPDATE_FIRST_STEP_SUCCESS = 'UPDATE_FIRST_STEP_SUCCESS';
export const UPDATE_FIRST_STEP_FAILURE = 'UPDATE_FIRST_STEP_FAILURE';

export const updateFirstStep = params => ({
  type: UPDATE_FIRST_STEP_REQUEST,
  params
});

export const UPDATE_SECOND_STEP_REQUEST = 'UPDATE_SECOND_STEP_REQUEST';
export const UPDATE_SECOND_STEP_SUCCESS = 'UPDATE_SECOND_STEP_SUCCESS';
export const UPDATE_SECOND_STEP_FAILURE = 'UPDATE_SECOND_STEP_FAILURE';

export const UPDATE_THIRD_STEP_REQUEST = 'UPDATE_THIRD_STEP_REQUEST';
export const UPDATE_THIRD_STEP_SUCCESS = 'UPDATE_THIRD_STEP_SUCCESS';
export const UPDATE_THIRD_STEP_FAILURE = 'UPDATE_THIRD_STEP_FAILURE';

export const UPDATE_AXES_NAME = 'UPDATE_AXES_NAME';
export const UPDATE_SUBAXES_INFO = 'UPDATE_SUBAXES_INFO';
export const UPDATE_INDICATORS_INFO = 'UPDATE_INDICATORS_INFO';
export const UPDATE_ITEMS_INFO = 'UPDATE_ITEMS_INFO';
export const UPDATE_INFO_QUESTION_UNIQUE = 'UPDATE_INFO_QUESTION_UNIQUE';
export const UPDATE_INFO_QUESTION_ALTERNATIVES =
  'UPDATE_SCORE_QUESTION_ALTERNATIVES';
export const UPDATE_INFO_QUESTION_OPEN = 'UPDATE_SCORE_QUESTION_OPEN';
export const UPDATE_PERFORMANCE_TYPE = 'UPDATE_PERFORMANCE_TYPE';

export const SAVE_QUIZ = 'SAVE_QUIZ';

export const CHANGE_CURRENT_STEP = 'CHANGE_CURRENT_STEP';
export const CURRENT_TAB_CLICKED = 'CURRENT_TAB_CLICKED';

export const CHANGE_QUESTION_TYPE = 'CHANGE_QUESTION_TYPE';

export const ACTIVE_QUESTION = 'ACTIVE_QUESTION';

export const activeQuestion = params => ({ type: ACTIVE_QUESTION, params });

export const SET_ERRORS = 'SET_ERRORS';

export const setErrors = params => ({ type: SET_ERRORS, params });

export const addAxes = params => ({ type: ADD_AXES, params });
export const addSubAxes = params => ({ type: ADD_SUB_AXES, params });
export const addIndicator = params => ({ type: ADD_INDICATOR, params });
export const addItemsToIndicator = params => ({
  type: ADD_ITEMS_TO_INDICATOR,
  params
});
export const addCategoryToQuestionPerformances = params => ({
  type: ADD_CATEGORY_TO_QUESTION_PERFORMANCES,
  params
});

export const deleteAxes = params => ({ type: DELETE_AXES, params });
export const deleteSubAxes = params => ({ type: DELETE_SUB_AXES, params });
export const deleteIndicator = params => ({ type: DELETE_INDICATOR, params });

export const saveFirstTest = params => ({
  type: SAVE_FIRST_STEP_REQUEST,
  params
});

export const updateAxesName = params => ({ type: UPDATE_AXES_NAME, params });
export const updateSubAxesInfo = params => ({
  type: UPDATE_SUBAXES_INFO,
  params
});
export const updateIndicatorsInfo = params => ({
  type: UPDATE_INDICATORS_INFO,
  params
});
export const updateItemsInfo = params => ({
  type: UPDATE_ITEMS_INFO,
  params
});
export const updateInfoQuestionUnique = params => ({
  type: UPDATE_INFO_QUESTION_UNIQUE,
  params
});
export const updateInfoQuestionAlternatives = params => ({
  type: UPDATE_INFO_QUESTION_ALTERNATIVES,
  params
});
export const updateInfoQuestionOpen = params => ({
  type: UPDATE_INFO_QUESTION_OPEN,
  params
});

export const changeCurrentStep = params => ({
  type: CHANGE_CURRENT_STEP,
  params
});

export const currentTabClicked = params => ({
  type: CURRENT_TAB_CLICKED,
  params
});

export const changeQuestionType = params => ({
  type: CHANGE_QUESTION_TYPE,
  params
});

export const updateSecondStep = data => ({
  type: UPDATE_SECOND_STEP_REQUEST,
  data
});

export const updateThirdStep = data => ({
  type: UPDATE_THIRD_STEP_REQUEST,
  data
});

export const updatePerformanceType = params => ({
  type: UPDATE_PERFORMANCE_TYPE,
  params
});

export const SUCCESS_QUIZ_REQUEST = 'SUCCESS_QUIZ_REQUEST';
export const SUCCESS_QUIZ_SUCCESS = 'SUCCESS_QUIZ_SUCCESS';
export const SUCCESS_QUIZ_FAILURE = 'SUCCESS_QUIZ_FAILURE';

export const successQuiz = data => ({
  type: SUCCESS_QUIZ_REQUEST,
  data
});

// ---------  GET TESTS FOR SHOW DATATABLE --------

export const GET_QUIZZES_REQUEST = 'GET_QUIZZES_REQUEST';
export const GET_QUIZZES_SUCCESS = 'GET_QUIZZES_SUCCESS';
export const GET_QUIZZES_FAILURE = 'GET_QUIZZES_FAILURE';

export const getQuizzesShow = data => ({
  type: GET_QUIZZES_REQUEST,
  data
});

// -------- DELETE TEST ------------

export const DELETE_QUIZ_REQUEST = 'DELETE_QUIZ_REQUEST';
export const DELETE_QUIZ_SUCCESS = 'DELETE_QUIZ_SUCCESS';
export const DELETE_QUIZ_FAILURE = 'DELETE_QUIZ_FAILURE';

export const deleteQuiz = id => ({
  type: DELETE_QUIZ_REQUEST,
  id
});

// ---------  GET TEST FOR SHOW --------

export const GET_QUIZ_REQUEST = 'GET_QUIZ_REQUEST';
export const GET_QUIZ_SUCCESS = 'GET_QUIZ_SUCCESS';
export const GET_QUIZ_FAILURE = 'GET_QUIZ_FAILURE';

export const getQuizShow = id => ({
  type: GET_QUIZ_REQUEST,
  id
});

// ---------  FINISH TEST --------

export const FINISH_TEST = 'FINISH_TEST';

export const finishTest = () => ({ type: FINISH_TEST });

// ---------  EDIT TEST --------

export const EDIT_QUIZ_REQUEST = 'EDIT_QUIZ_REQUEST';
export const EDIT_QUIZ_SUCCESS = 'EDIT_QUIZ_SUCCESS';
export const EDIT_QUIZ_FAILURE = 'EDIT_QUIZ_FAILURE';

export const editQuiz = id => ({ type: EDIT_QUIZ_REQUEST, id });

// ---------  CLEAR TEST --------

export const CLEAR_TEST = 'CLEAR_TEST';

export const clearTest = () => ({ type: CLEAR_TEST });

// ---------  CLONE TEST --------
export const CLONE_QUIZ_REQUEST = 'CLONE_QUIZ_REQUEST';
export const CLONE_QUIZ_SUCCESS = 'CLONE_QUIZ_SUCCESS';
export const CLONE_QUIZ_FAILURE = 'CLONE_QUIZ_FAILURE';

export const cloneQuiz = params => ({
  type: CLONE_QUIZ_REQUEST,
  params
});

export const SET_REDIRECT = 'SET_REDIRECT';
export const setRedirect = data => ({
  type: SET_REDIRECT,
  data
});