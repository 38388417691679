import React from 'react';
import PropTypes from 'prop-types';
import {
  InputGroup,
  FormControl,
  Button,
  DropdownButton,
  Dropdown,
  Form
} from 'react-bootstrap';
import Checkbox from 'react-three-state-checkbox';
import { Check, X, HelpCircle } from 'react-feather';
import './style.scss';

const checkStyles = {
  zIndex: '2',
  position: 'absolute',
  pointerEvents: 'none',
  alignSelf: 'center'
};

const BasicInput = ({
  size,
  styleClass,
  styles,
  text,
  title = '',
  ...props
}) => (
  <Form.Group className={styleClass}>
    {title ? <Form.Label>{title}</Form.Label> : false}
    <FormControl {...props} className={styles} />
    {text ? <Form.Text className="text-muted">{text}</Form.Text> : false}
  </Form.Group>
);

BasicInput.propTypes = {
  size: PropTypes.string,
  styleClass: PropTypes.string,
  styles: PropTypes.string
};

BasicInput.defaultProps = {
  size: 'md',
  styleClass: '',
  styles: ''
};

const InputPrepend = ({
  size,
  text,
  placeholder,
  ariaLabel,
  ariaDescribedby
}) => (
  <InputGroup size={size} className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Text id="basic-addon1">{text}</InputGroup.Text>
    </InputGroup.Prepend>
    <FormControl
      placeholder={placeholder}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedby}
    />
  </InputGroup>
);

InputPrepend.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaDescribedby: PropTypes.string
};

InputPrepend.defaultProps = {
  size: 'md',
  placeholder: null,
  ariaLabel: null,
  ariaDescribedby: null
};

const InputPrependRight = ({
  size,
  placeholder,
  ariaLabel,
  ariaDescribedby,
  text,
  width,
  value,
  title,
  type,
  ...props
}) => (
  <Form.Group>
    {title ? <Form.Label>{title}</Form.Label> : false}
    <InputGroup>
      <FormControl
        placeholder={placeholder}
        aria-label={ariaLabel}
        aria-describedby={ariaDescribedby}
        value={String(value)}
        type={type}
        {...props}
      />
      <InputGroup.Append>
        <InputGroup.Text>{text}</InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  </Form.Group>
);

InputPrependRight.propTypes = {
  size: PropTypes.string,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string
};

InputPrependRight.defaultProps = {
  size: 'sm',
  placeholder: null,
  ariaLabel: null,
  ariaDescribedby: null,
  title: '',
  type: 'input'
};

const InputCheckbox = ({ size, ariaLabelInp, ariaLabelForm }) => (
  <InputGroup size={size} className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Checkbox aria-label={ariaLabelInp} />
    </InputGroup.Prepend>
    <FormControl aria-label={ariaLabelForm} />
  </InputGroup>
);

InputCheckbox.propTypes = {
  size: PropTypes.string,
  ariaLabelInp: PropTypes.string,
  ariaLabelForm: PropTypes.string
};

InputCheckbox.defaultProps = {
  size: 'sm',
  ariaLabelInp: null,
  ariaLabelForm: null
};

const InputRadio = ({ size, ariaLabelInp, ariaLabelForm }) => (
  <InputGroup size={size}>
    <InputGroup.Prepend>
      <InputGroup.Radio aria-label={ariaLabelInp} />
    </InputGroup.Prepend>
    <FormControl aria-label={ariaLabelForm} />
  </InputGroup>
);

InputRadio.propTypes = {
  size: PropTypes.string,
  ariaLabelInp: PropTypes.string,
  ariaLabelForm: PropTypes.string
};

InputRadio.defaultProps = {
  size: 'sm',
  ariaLabelInp: null,
  ariaLabelForm: null
};

const InputMultiAddon = ({ addon1, addon2, placeholder, ariaLabel }) => (
  <InputGroup className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Text>{addon1}</InputGroup.Text>
      <InputGroup.Text>{addon2}</InputGroup.Text>
    </InputGroup.Prepend>
    <FormControl placeholder={placeholder} aria-label={ariaLabel} />
  </InputGroup>
);

InputMultiAddon.propTypes = {
  addon1: PropTypes.string.isRequired,
  addon2: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string
};

InputMultiAddon.defaultProps = {
  placeholder: null,
  ariaLabel: null
};

const InputMultiAddonRight = ({ placeholder, ariaLabel, addon1, addon2 }) => (
  <InputGroup className="mb-3">
    <FormControl placeholder={placeholder} aria-label={ariaLabel} />
    <InputGroup.Append>
      <InputGroup.Text>{addon1}</InputGroup.Text>
      <InputGroup.Text>{addon2}</InputGroup.Text>
    </InputGroup.Append>
  </InputGroup>
);

InputMultiAddonRight.propTypes = {
  addon1: PropTypes.string.isRequired,
  addon2: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string
};

InputMultiAddonRight.defaultProps = {
  placeholder: null,
  ariaLabel: null
};

const InputBtn = ({ variant, title, ariaDescribedby, placeholder }) => (
  <InputGroup className="mb-3">
    <InputGroup.Prepend>
      <Button variant={variant}>{title}</Button>
    </InputGroup.Prepend>
    <FormControl aria-describedby={ariaDescribedby} placeholder={placeholder} />
  </InputGroup>
);

InputBtn.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  ariaDescribedby: PropTypes.string,
  placeholder: PropTypes.string
};

InputBtn.defaultProps = {
  variant: 'info',
  ariaDescribedby: null,
  placeholder: null
};

const InputBtnRight = ({
  placeholder,
  ariaLabel,
  ariaDescribedby,
  variant,
  title,
  onClick,
  ...props
}) => (
  <InputGroup className="mb-3">
    <FormControl
      placeholder={placeholder}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedby}
      {...props}
    />
    <InputGroup.Append>
      <Button onClick={onClick} variant={variant}>
        {title}
      </Button>
    </InputGroup.Append>
  </InputGroup>
);

InputBtnRight.propTypes = {
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  variant: PropTypes.string,
  title: PropTypes.string.isRequired
};

InputBtnRight.defaultProps = {
  placeholder: null,
  ariaLabel: null,
  ariaDescribedby: null,
  variant: 'info'
};

const InputDropdown = ({ items, variant, title, ariaDescribedby, size }) => (
  <InputGroup className="mb-3" size={size}>
    <DropdownButton
      as={InputGroup.Prepend}
      variant={variant}
      title={title}
      id="input-group-dropdown-1"
    >
      {items.map(item => (
        <Dropdown.Item href={item.path} key={item.key}>
          {item.text}
        </Dropdown.Item>
      ))}
    </DropdownButton>
    <FormControl aria-describedby={ariaDescribedby} />
  </InputGroup>
);

InputDropdown.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  ariaDescribedby: PropTypes.string,
  size: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired
};

InputDropdown.defaultProps = {
  variant: 'info',
  ariaDescribedby: null,
  size: 'md'
};

const InputDropdownRight = ({
  items,
  placeholder,
  ariaLabel,
  ariaDescribedby,
  variant,
  title,
  size
}) => (
  <InputGroup size={size}>
    <FormControl
      placeholder={placeholder}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedby}
    />
    <DropdownButton
      as={InputGroup.Append}
      variant={variant}
      title={title}
      id="input-group-dropdown-2"
    >
      {items.map(item => (
        <Dropdown.Item href={item.path} key={item.key}>
          {item.text}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  </InputGroup>
);

InputDropdownRight.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  size: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      ariaDescribedby: PropTypes.string,
      key: PropTypes.string.isRequired
    })
  ).isRequired
};

InputDropdownRight.defaultProps = {
  variant: 'info',
  ariaDescribedby: null,
  ariaLabel: null,
  placeholder: null,
  size: 'md'
};

const CheckBox = ({ text, controlId, ...props }) => (
  <Form.Group controlId={controlId}>
    <Form.Check label={text} {...props} />
  </Form.Group>
);

const CheckBoxM = ({ text, controlId, ...props }) => (
  <Form.Group className="mb-0" controlId={controlId}>
    <Form.Check label={text} {...props} />
  </Form.Group>
);

const CheckBoxIcon = ({
  controlId,
  checked,
  indeterminate,
  disabled,
  ...props
}) => (
  <Form.Group
    controlId={controlId}
    className="three-state-box"
    style={{ textAlign: '-webkit-center', position: 'absolute' }}
  >
    <Checkbox
      type="checkbox"
      style={{ zIndex: '1', position: 'absolute' }}
      className="custom-box three-state"
      checked={checked}
      indeterminate={indeterminate}
      disabled={disabled}
      bsPrefix={disabled && 'checkBoxCustom'}
      {...props}
    >
      {' '}
    </Checkbox>
    {checked && !indeterminate ? (
      <Check color="white" style={checkStyles} />
    ) : null}

    {!checked && !indeterminate ? (
      <X color="white" style={checkStyles} />
    ) : null}

    {indeterminate ? <HelpCircle color="white" style={checkStyles} /> : null}
  </Form.Group>
);

CheckBoxIcon.defaultProps = {
  checked: false
};

export {
  BasicInput,
  InputPrepend,
  InputPrependRight,
  InputCheckbox,
  InputRadio,
  InputMultiAddon,
  InputMultiAddonRight,
  InputBtn,
  InputBtnRight,
  InputDropdown,
  InputDropdownRight,
  CheckBox,
  CheckBoxM,
  CheckBoxIcon
};
