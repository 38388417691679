import moment from 'moment';
import {
  GET_QUIZ_REVIEWS_REQUEST,
  GET_QUIZ_REVIEWS_SUCCESS,
  GET_QUIZ_REVIEWS_FAILURE,
  GET_ONE_QUIZ_REVIEWS_REQUEST,
  GET_ONE_QUIZ_REVIEWS_SUCCESS,
  GET_ONE_QUIZ_REVIEWS_FAILURE,
  UPDATE_QUIZ_REVIEW_REQUEST,
  UPDATE_QUIZ_REVIEW_SUCCESS,
  UPDATE_QUIZ_REVIEW_FAILURE,
  SET_VALUES_REVIEWS,
  CREATE_QUIZ_REVIEW_REQUEST,
  CREATE_QUIZ_REVIEW_SUCCESS,
  CREATE_QUIZ_REVIEW_FAILURE,
  UPDATE_ONE_QUIZ_REVIEW_REQUEST,
  UPDATE_ONE_QUIZ_REVIEW_SUCCESS,
  UPDATE_ONE_QUIZ_REVIEW_FAILURE,
  DELETE_QUIZ_REVIEW_REQUEST,
  DELETE_QUIZ_REVIEW_SUCCESS,
  DELETE_QUIZ_REVIEW_FAILURE,
  SET_DATE_QUIZ_REVIEW,
  IMPORT_SCORES_REQUEST,
  IMPORT_SCORES_SUCCESS,
  IMPORT_SCORES_FAILURE,
  CLOSE_IMPORT_WINDOW
} from '../actions/score';

const initialState = {
  ongoingRequest: {
    getQuizReviews: false,
    createQuizReview: false,
    deleteQuizReview: false
  },
  quizReviews: {
    selectArray: [],
    data: [],
    amount: 0
  },
  quizReview: {},
  updateOneQuizReview: {
    state: false,
    student: null
  },
  importMessage: [],
  loaderDocuments: false
};

const setData = data => {
  const arrayList = data.map(d => {
    return { title: d.name, key: d.id, id: d.id };
  });
  return [{ key: 0, title: 'Seleccione', id: 0 }, ...arrayList];
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUIZ_REVIEWS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getQuizReviews: true }
      };
    case GET_QUIZ_REVIEWS_SUCCESS:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getQuizReviews: false },
        quizReviews: {
          selectArray: setData(action.result.data),
          data: action.result.data,
          amount: action.result.metadata.amount
        }
      };

    case GET_QUIZ_REVIEWS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getQuizReviews: false }
      };

    case GET_ONE_QUIZ_REVIEWS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getOneQuizReviews: true }
      };
    case GET_ONE_QUIZ_REVIEWS_SUCCESS:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getOneQuizReviews: false },
        quizReview: action.result
      };
    case GET_ONE_QUIZ_REVIEWS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getOneQuizReviews: false }
      };
    case UPDATE_QUIZ_REVIEW_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, updateQuizReview: true }
      };
    case UPDATE_QUIZ_REVIEW_SUCCESS:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, updateQuizReview: false }
      };
    case UPDATE_QUIZ_REVIEW_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, updateQuizReview: false }
      };
    case UPDATE_ONE_QUIZ_REVIEW_REQUEST:
      return {
        ...state,
        updateOneQuizReview: {
          state: true,
          student: action.data.review.student_id
        },
        time: moment().format('HH:mm')
      };
    case UPDATE_ONE_QUIZ_REVIEW_SUCCESS:
      return {
        ...state,
        updateOneQuizReview: {
          state: false,
          student: null
        }
      };
    case UPDATE_ONE_QUIZ_REVIEW_FAILURE:
      return {
        ...state,
        updateOneQuizReview: {
          state: false,
          student: null
        }
      };
    case SET_VALUES_REVIEWS:
      return {
        ...state,
        quizReview: {
          ...state.quizReview,
          student_reviews_attributes: action.data
        }
      };
    case CREATE_QUIZ_REVIEW_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, createQuizReview: true }
      };
    case CREATE_QUIZ_REVIEW_SUCCESS:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, createQuizReview: false }
      };
    case CREATE_QUIZ_REVIEW_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, createQuizReview: false }
      };
    case DELETE_QUIZ_REVIEW_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, deleteQuizReview: true }
      };
    case DELETE_QUIZ_REVIEW_SUCCESS:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, deleteQuizReview: false }
      };
    case DELETE_QUIZ_REVIEW_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, deleteQuizReview: false }
      };
    case SET_DATE_QUIZ_REVIEW:
      return {
        ...state,
        quizReview: {
          ...state.quizReview,
          quiz_date: action.date
        }
      };
    case IMPORT_SCORES_REQUEST:
      return {
        ...state,
        loaderDocuments: true
      };
    case IMPORT_SCORES_SUCCESS: {
      return {
        ...state,
        importMessage: action.result.message,
        loaderDocuments: false
      };
    }
    case IMPORT_SCORES_FAILURE:
      return {
        ...state,
        importFailure: true,
        loaderDocuments: false
      };
    case CLOSE_IMPORT_WINDOW:
      return {
        ...state,
        importMessage: []
      };
    default:
      return state;
  }
};

export default reducer;
