import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Cell } from 'recharts';
import { Container } from 'react-bootstrap';
import { ChartPie } from '../Charts';
import { NoImage } from '../Utils';
import './style.scss';

class ReadingReport extends Component {
  constructor() {
    super();
    this.state = {};
  }

  renderTypeOfChart = (chart, styleChart, data) => {
    if (data.graph_type === 4) {
      return (
        <ChartPie dataChart={chart} ticks={[0, 50, 100]}>
          {styleChart.map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell key={`cell-${index}`} fill={entry.colour} />
          ))}
        </ChartPie>
      );
    }
    return <NoImage />;
  };

  renderChart = () => {
    const { data } = this.props;
    return data ? (
      data.map((chart, index) => {
        const dataForChart = chart.data;
        const styleForChart = chart.styles;
        return (
          <div
            id={`chart-${index + 1}`}
            className="div-chart-reading"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <p className="p-chart-reading">
              <span className="title-chart-reading">{chart.title}</span>
              <br />
              <span className="subtitle-chart-reading">{chart.subtitle}</span>
            </p>
            {this.renderTypeOfChart(dataForChart, styleForChart, chart)}
            <hr className="myhrline" />
          </div>
        );
      })
    ) : (
      <center>
        <NoImage />
      </center>
    );
  };

  render() {
    return (
      <Container className="container-charts mt-4">
        <h2 className="title-report">Informe de Lectura</h2>
        {this.renderChart()}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { availableYears, ongoingRequest } = state.utils;
  return {
    availableYears,
    ongoingRequest
  };
};

export default connect(mapStateToProps)(ReadingReport);
