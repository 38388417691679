import {
  GET_GUIDELINE_REQUEST,
  GET_GUIDELINE_SUCCESS,
  GET_GUIDELINE_FAILURE,
  DELETE_GUIDELINE_REQUEST,
  DELETE_GUIDELINE_SUCCESS,
  DELETE_GUIDELINE_FAILURE,
  CREATE_GUIDELINE_REQUEST,
  CREATE_GUIDELINE_SUCCESS,
  CREATE_GUIDELINE_FAILURE,
  UPDATE_GUIDELINE_REQUEST,
  UPDATE_GUIDELINE_SUCCESS,
  UPDATE_GUIDELINE_FAILURE,
  RESET_GUIDELINE_DATA,
  SET_REDIRECT
} from '../actions/guideline';

const initialState = {
  ongoingRequest: {
    getGuidelines: false,
    deleteGuidelines: false,
    createGuidelines: false,
    updateGuidelines: false
  },
  totalRows: 0,
  guidelines: [],
  redirect: {
    path: '',
    state: false
  }
};

const setData = data => {
  const arrayList = data.map(d => {
    return { title: d.name, key: d.id, ...d };
  });
  return [{ key: 0, title: 'Seleccione' }, ...arrayList];
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REDIRECT:
      return {
        ...state,
        redirect: {
          path: action.data.path,
          state: action.data.state
        }
      };
    case GET_GUIDELINE_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getGuidelines: true
        }
      };
    case GET_GUIDELINE_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getGuidelines: false
        },
        guidelines: setData(action.result.data),
        totalRows: action.result.metadata.amount
      };
    case GET_GUIDELINE_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getGuidelines: false
        }
      };
    case DELETE_GUIDELINE_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteGuidelines: true
        }
      };
    case DELETE_GUIDELINE_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteGuidelines: false
        }
      };
    case DELETE_GUIDELINE_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteGuidelines: false
        }
      };
    case CREATE_GUIDELINE_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createGuidelines: true
        }
      };
    case CREATE_GUIDELINE_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createGuidelines: false
        }
      };
    case CREATE_GUIDELINE_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          createGuidelines: false
        }
      };
    case UPDATE_GUIDELINE_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateGuidelines: true
        }
      };
    case UPDATE_GUIDELINE_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateGuidelines: false
        }
      };
    case UPDATE_GUIDELINE_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateGuidelines: false
        }
      };
    case RESET_GUIDELINE_DATA: {
      return {
        ...state,
        guidelines: []
      };
    }
    default:
      return state;
  }
};

export default reducer;
