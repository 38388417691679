import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
// eslint-disable-next-line import/no-cycle
import { BasicInput } from '../index';

class Teacher extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { clearSelectData, clearState, typeOfTest } = this.props;
    clearSelectData();
    clearState();
    typeOfTest();
  }

  render() {
    const {
      name,
      years,
      regions,
      communes,
      establishments,
      grades,
      sections,
      quizzes,
      ongoingRequest,
      sendData,
      values
    } = this.props;
    const { region, commune, establishment, section, grade, year } = values;
    return (
      <Container>
        <Row>
          <Col>
            <p className="mb-2">Nombre Informe</p>
            <BasicInput
              onChange={e => sendData(e.target.value, 'Name')}
              placeholder="Nombre Informe"
            />
          </Col>
          <Col>
            <p className="mb-2">Año</p>
            <Select
              options={
                ongoingRequest.getYearChart
                  ? [{ value: 0, label: 'Cargando..' }]
                  : years
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={name.length === 0 || name === 0}
              onChange={e => sendData(e.value, 'Year')}
              placeholder="Seleccione"
            />
          </Col>
          <Col>
            <p className="mb-2">Región</p>
            <Select
              options={
                ongoingRequest.getRegionChart
                  ? [{ value: 0, label: 'Cargando..' }]
                  : regions
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={year === 0}
              onChange={e => sendData(e.value, 'Region')}
              placeholder="Seleccione"
            />
          </Col>
          <Col>
            <p className="mb-2">Comuna</p>
            <Select
              options={
                ongoingRequest.getCommuneChart
                  ? [{ value: 0, label: 'Cargando..' }]
                  : communes
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={region === 0}
              onChange={e => sendData(e.value, 'Commune')}
              placeholder="Seleccione"
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <p className="mb-2">Establecimiento</p>
            <Select
              options={
                ongoingRequest.getEstablishmentChart
                  ? [{ value: 0, label: 'Cargando..' }]
                  : establishments
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={commune === 0}
              onChange={e => sendData(e.value, 'Establishment')}
              placeholder="Seleccione"
            />
          </Col>
          <Col>
            <p className="mb-2">Nivel</p>
            <Select
              options={
                ongoingRequest.getGradeChart
                  ? [{ value: 0, label: 'Cargando..' }]
                  : grades
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={establishment === 0}
              onChange={e => sendData(e.value, 'Grade')}
              placeholder="Seleccione"
            />
          </Col>
          <Col>
            <p className="mb-2">Sección</p>
            <Select
              options={
                ongoingRequest.getSectionChart
                  ? [{ value: 0, label: 'Cargando..' }]
                  : sections
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={grade === 0}
              onChange={e => sendData(e.value, 'Sections')}
              placeholder="Seleccione"
            />
          </Col>
          <Col>
            <p className="mb-2">Evaluación</p>
            <Select
              options={
                ongoingRequest.getQuizChart
                  ? [{ value: 0, label: 'Cargando..' }]
                  : quizzes
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={section === 0}
              onChange={e => sendData(e.value, 'Quiz')}
              placeholder="Seleccione"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Teacher;
