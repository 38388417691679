import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { NavbarLeft, LoadSpinner } from '../../components';
import StepProgressBar from '../../components/StepProgressBar/StepProgressBar';
import { dataStepProgress } from '../CreateTest/data';
import { getQuizShow } from '../../actions/test';

class QuizEditScore extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentWillMount() {
    const { dispatch, match } = this.props;
    dispatch(getQuizShow(match.params.id));
  }

  render() {
    const { history, ongoingRequest } = this.props;
    const editQuizScore = 'Editando Puntaje de Evaluación';
    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft history={history} />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            {(ongoingRequest.editQuiz ||
              ongoingRequest.getQuiz ||
              ongoingRequest.getObjectivesLearning ||
              ongoingRequest.getSkills ||
              ongoingRequest.getFirstStep ||
              ongoingRequest.updateThirdStep) && <LoadSpinner />}
            <div className="section">
              <StepProgressBar
                history={history}
                steps={dataStepProgress}
                title={editQuizScore}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { quiz, ongoingRequest } = state.test;
  return {
    quiz,
    ongoingRequest
  };
};

export default connect(mapStateToProps)(QuizEditScore);
