import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DefaultButton, BasicInput, Paragraph, Title } from '../../components';
import { requestPasswordRecovery } from '../../actions/auth';
import { history } from '../../config/store';
import '../../assets/stylesheets/styles.scss';
import { LoadSpinner } from '../../components/Utils';

const inicioImg = require('../../assets/images/Inicio.png');

class RecoverPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: ''
    };
  }

  handleSingIn = () => {
    const { email } = this.state;
    if (email === '') {
      alert('Ingrese email');
    } else {
      this.signInAsync(email);
    }
  };

  signInAsync = email => {
    const { dispatch } = this.props;
    dispatch(requestPasswordRecovery({ email }));
  };

  onFormSubmit = e => {
    e.preventDefault();
    this.handleSingIn();
  };

  render() {
    const { email } = this.state;
    const { ongoingRequest } = this.props;
    return (
      <Container fluid>
        <Row>
          <Col xs={6} className="bannerLogin flexFullHeight">
            <Image src={inicioImg} alt="Fundacion Crecer" />
          </Col>
          <Col xs={6} className="flexFullHeight">
            {ongoingRequest.passwordRecovery && <LoadSpinner />}
            <div style={{ width: '400px' }}>
              <form onSubmit={this.onFormSubmit}>
                <Title
                  title="REESTABLECER CONTRASEÑA"
                  style={{ textAlign: 'center' }}
                  className="text-primary"
                />
                <Paragraph
                  text="Te enviaremos un email con una nueva contraseña"
                  style={{ textAlign: 'center' }}
                />
                <BasicInput
                  placeholder="Correo"
                  size="md"
                  styleClass="borderInput"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                />

                <Row>
                  <Col md={{ span: 4, offset: 1 }}>
                    <DefaultButton
                      text="Cancelar"
                      size="md"
                      variant="light"
                      style={{ width: '140px', marginTop: '30px' }}
                      onClick={() => history.push('/login')}
                    />
                  </Col>
                  <Col md={{ span: 4, offset: 1 }}>
                    <DefaultButton
                      text="Confirmar"
                      size="md"
                      type="submit"
                      style={{ width: '140px', marginTop: '30px' }}
                      disabled={ongoingRequest.passwordRecovery}
                    />
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

export default withRouter(connect(mapStateToProps)(RecoverPassword));
