import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  Loader,
  Login,
  Dashboard,
  Profile,
  EstablishmentAndCourses,
  Report,
  AddScore,
  ScoreScreen,
  Users,
  CreateTest,
  Guidelines,
  Tests,
  QuizShow,
  CreateEstablishment,
  Students,
  AdvanceCourse,
  Teachers,
  QuizEdit,
  QuizEditScore,
  AddScorePlatform,
  RecoverPassword,
  NotFound,
  WordPreview,
  Tutorials
} from '../screens';
import { Notification } from '../components';

const routes = (
  <div>
    <Notification />
    <Switch>
      {/* NAVBAR */}
      <Route exact path="/" component={Loader} />
      <Route exact path="/login" component={Login} />
      <Route path="/dashboard" hash="asd" component={Dashboard} />
      <Route
        path="/establishment-and-courses"
        component={EstablishmentAndCourses}
      />
      <Route path="/guidelines" component={Guidelines} />
      <Route if path="/new-tests" component={CreateTest} />
      <Route exact path="/score/add/:id" component={AddScorePlatform} />
      <Route path="/score/add" component={AddScore} />
      <Route path="/score" component={ScoreScreen} />
      <Route path="/reports" component={Report} />
      <Route path="/profile" component={Profile} />
      <Route path="/tutorials" component={Tutorials} />
      <Route path="/users" component={Users} />
      <Route exact path="/tests" component={Tests} />
      <Route exact path="/tests/:id" component={QuizShow} />
      <Route exact path="/tests/:id/edit" component={QuizEdit} />
      <Route exact path="/tests/:id/edit-score" component={QuizEditScore} />
      <Route
        exact
        path="/create-establishment"
        component={CreateEstablishment}
      />
      <Route path="/create-establishment/:id" component={CreateEstablishment} />
      <Route path="/students" component={Students} />
      <Route path="/advance-course" component={AdvanceCourse} />
      <Route path="/teachers" component={Teachers} />
      <Route path="/password/forgot" component={RecoverPassword} />
      <Route path="/report-preview" component={WordPreview} />

      <Route component={NotFound} />
    </Switch>
  </div>
);

export default routes;
