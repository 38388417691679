import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_ALL_TEACHERS_REQUEST,
  GET_ALL_TEACHERS_SUCCESS,
  GET_ALL_TEACHERS_FAILURE,
  GET_TEACHERS_REQUEST,
  GET_TEACHERS_SUCCESS,
  GET_TEACHERS_FAILURE,
  POST_TEACHER_REQUEST,
  POST_TEACHER_SUCCESS,
  POST_TEACHER_FAILURE,
  SET_REDIRECT,
  UPDATE_TEACHER_REQUEST,
  UPDATE_TEACHER_SUCCESS,
  UPDATE_TEACHER_FAILURE,
  DELETE_TEACHER_REQUEST,
  DELETE_TEACHER_SUCCESS,
  DELETE_TEACHER_FAILURE,
  GET_SECTIONS_TEACHER_REQUEST,
  GET_SECTIONS_TEACHER_SUCCESS,
  GET_SECTIONS_TEACHER_FAILURE
} from '../actions/teacher';
import API from '../services/api';
import { SET_NOTICE } from '../actions/alert';
import runDefaultSaga from './default';

const getAllTeachersRequest = idEstablishment => {
  return API.get(`/teachers?establishment_id${idEstablishment}`);
};

function* getAllTeachersSuccessCallback(result) {
  yield put({
    type: GET_ALL_TEACHERS_SUCCESS,
    result
  });
}

function* getAllTeachersFailureCallback() {
  yield put({
    type: GET_ALL_TEACHERS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getAllTeachers(action) {
  yield runDefaultSaga(
    { request: getAllTeachersRequest, params: action.idEstablishment },
    getAllTeachersSuccessCallback,
    getAllTeachersFailureCallback
  );
}

// ----- GET TEACHERS INDEX CRUD -------
const getTeachersRequest = params => {
  return API.get('/teachers', params);
};

function* getTeachersSuccessCallback(result) {
  yield put({
    type: GET_TEACHERS_SUCCESS,
    result
  });
}

function* getTeachersFailureCallback() {
  yield put({
    type: GET_TEACHERS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getTeachers(action) {
  yield runDefaultSaga(
    { request: getTeachersRequest, params: action.data },
    getTeachersSuccessCallback,
    getTeachersFailureCallback
  );
}

// ----- POST TEACHER -------
const postTeacherRequest = params => {
  return API.post(`/teachers`, params);
};

function* postTeacherSuccessCallback(result) {
  yield put({
    type: POST_TEACHER_SUCCESS,
    result
  });
  yield put({
    type: SET_REDIRECT,
    data: { path: '', state: true }
  });

  yield put({
    type: GET_TEACHERS_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
  yield put({
    type: SET_NOTICE,
    title: 'Creacion exitosa',
    message: 'El profesor se ha creado exitosamente',
    kind: 'success',
    timeout: 2000
  });
}

function* postTeacherFailureCallback() {
  yield put({
    type: POST_TEACHER_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* postTeacher(action) {
  yield runDefaultSaga(
    { request: postTeacherRequest, params: action.data },
    postTeacherSuccessCallback,
    postTeacherFailureCallback
  );
}

// ----- UPDATE TEACHERS  -------
const updateTeacherRequest = params => {
  return API.put(`/teachers/${params.teacher.id}`, params);
};

function* updateTeacherSuccessCallback(result) {
  yield put({
    type: UPDATE_TEACHER_SUCCESS,
    result
  });
  yield put({
    type: SET_REDIRECT,
    data: { path: '', state: true }
  });

  yield put({
    type: GET_TEACHERS_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
  yield put({
    type: SET_NOTICE,
    title: 'Creacion exitosa',
    message: 'El profesor se ha modificado exitosamente',
    kind: 'success',
    timeout: 2000
  });
}

function* updateTeacherFailureCallback() {
  yield put({
    type: UPDATE_TEACHER_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* updateTeacher(action) {
  yield runDefaultSaga(
    { request: updateTeacherRequest, params: action.data },
    updateTeacherSuccessCallback,
    updateTeacherFailureCallback
  );
}

// ----- UPDATE TEACHERS  -------
const deleteTeacherRequest = params => {
  return API.delete(`/teachers/${params}`);
};

function* deleteTeacherSuccessCallback(result) {
  yield put({
    type: DELETE_TEACHER_SUCCESS,
    result
  });
  yield put({
    type: SET_REDIRECT,
    data: { path: '', state: true }
  });

  yield put({
    type: GET_TEACHERS_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
  yield put({
    type: SET_NOTICE,
    title: 'Eliminación exitosa',
    message: 'El profesor se ha eliminado exitosamente',
    kind: 'success',
    timeout: 2000
  });
}

function* deleteTeacherFailureCallback() {
  yield put({
    type: DELETE_TEACHER_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* deleteTeacher(action) {
  yield runDefaultSaga(
    { request: deleteTeacherRequest, params: action.data },
    deleteTeacherSuccessCallback,
    deleteTeacherFailureCallback
  );
}

// ----- GET SECTION TEACHER -------

const getSectionsTeacherRequest = params => {
  return API.get(`/teachers/${params}/sections`);
};

function* getSectionsTeacherSuccessCallback(result) {
  yield put({
    type: GET_SECTIONS_TEACHER_SUCCESS,
    result
  });
}

function* getSectionsTeacherFailureCallback() {
  yield put({
    type: GET_SECTIONS_TEACHER_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getSectionsTeacher(action) {
  yield runDefaultSaga(
    { request: getSectionsTeacherRequest, params: action.data },
    getSectionsTeacherSuccessCallback,
    getSectionsTeacherFailureCallback
  );
}

// DEFINE establishmentSagas
export default function* teachersSagas() {
  yield takeEvery(GET_ALL_TEACHERS_REQUEST, getAllTeachers);
  yield takeEvery(GET_TEACHERS_REQUEST, getTeachers);
  yield takeEvery(POST_TEACHER_REQUEST, postTeacher);
  yield takeEvery(UPDATE_TEACHER_REQUEST, updateTeacher);
  yield takeEvery(DELETE_TEACHER_REQUEST, deleteTeacher);
  yield takeEvery(GET_SECTIONS_TEACHER_REQUEST, getSectionsTeacher);
}
