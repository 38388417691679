import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
  SubTitleH2,
  NavbarLeft,
  LoadSpinner,
  DefaultButton,
  ScoreTable,
  ScoreTableOral,
  ArrowLeftIndex,
  ModalAction
} from '../../components';
import {
  getOneQuizReviews,
  updateQuizReview,
  setDateQuizReview
} from '../../actions/score';

import { closeImportWindow } from '../../actions/student';

class AddScorePlatform extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      modalData: {
        title: ''
      }
    };
  }

  componentDidMount() {
    const {
      match: { params },
      dispatch
    } = this.props;
    dispatch(getOneQuizReviews(params.id));
  }

  handleUpdate = () => {
    const { dispatch, quizReview, history } = this.props;
    dispatch(
      updateQuizReview({
        id: quizReview.id,
        review: { ...quizReview }
      })
    );
    history.push('/score');
  };

  onChangeDate = date => {
    const { dispatch } = this.props;

    dispatch(setDateQuizReview(date));
  };

  setModalShow = state => {
    this.setState({
      modalShow: state
    });
  };

  handleUpload = () => {
    const { dispatch, quizReview } = this.props;
    dispatch(closeImportWindow());
    this.setState({
      modalShow: true,
      modalData: {
        id: quizReview.id,
        create: true,
        action: 'edit',
        form: 'upload-file',
        title: 'IMPORTAR PUNTAJES'
      }
    });
  };

  render() {
    const { ongoingRequest, onRequest, quizReview, time } = this.props;
    const { modalShow, modalData } = this.state;
    const date = quizReview.quiz_date || moment();

    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            {(ongoingRequest.getRegions ||
              onRequest.getOneQuizReviews ||
              onRequest.updateQuizReview) && <LoadSpinner />}
            <div className="section">
              <ArrowLeftIndex className="mb-4 text-light" path="/score" />
              <SubTitleH2 text="Ingreso de puntajes" />
              <Row className="mt-5">
                <Col>
                  <span>
                    <strong>
                      {quizReview.quiz ? quizReview.quiz.name : ''}
                    </strong>
                  </span>
                  <br />
                  <span>
                    {quizReview.section ? quizReview.section.full_name : ''}
                  </span>
                  <br />
                  <span>
                    {quizReview.section
                      ? quizReview.section.establishment_name
                      : ''}
                  </span>
                  <br />
                  <span>
                    {quizReview.section ? quizReview.section.year : ''}
                  </span>
                </Col>

                <Col xs={6}>
                  <Row>
                    <Col>
                      <DefaultButton
                        style={{ width: '100%' }}
                        text="Importar"
                        variant="secondary"
                        onClick={() => this.handleUpload()}
                      />
                    </Col>
                    <Col>
                      <DefaultButton
                        style={{ width: '100%' }}
                        text="Guardar"
                        variant="secondary"
                        onClick={() => this.handleUpdate()}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={2}>
                  <p className="mt-2">Fecha de revisión:</p>
                </Col>
                <Col xs={2}>
                  <DatePicker
                    style={{ width: 200 }}
                    dateFormat="dd-MM-yyyy"
                    required
                    selected={moment(date).toDate()}
                    onChange={e => this.onChangeDate(e)}
                  />
                </Col>
              </Row>
              <div>
                <p>
                  <b>
                    Nota: Los alumnos que no tengan una revisión completa (con
                    un ticket al final de su fila), serán dejados como ausentes
                    al momento de guardar.
                  </b>
                </p>
              </div>
              <div style={{ textAlign: 'right', opacity: 0.7 }}>
                {time ? (
                  <p>Tus cambios fueron guardados a las {time}</p>
                ) : (
                  <p> </p>
                )}
              </div>
              {!onRequest.getOneQuizReviews &&
                quizReview &&
                quizReview.reading && <ScoreTableOral data={quizReview} />}

              {!onRequest.getOneQuizReviews &&
                quizReview &&
                !quizReview.reading && <ScoreTable data={quizReview} />}
            </div>
          </Col>
        </Row>
        <ModalAction
          show={modalShow}
          data={modalData}
          title="edit"
          onHide={() => this.setModalShow(false)}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { ongoingRequest } = state.utils;
  const { ongoingRequest: onRequest, quizReview, time } = state.score;
  return {
    ongoingRequest,
    onRequest,
    quizReview,
    time
  };
};

export default connect(mapStateToProps)(AddScorePlatform);
