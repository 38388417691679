const typeQuestion = [
  {
    title: 'Alternativa',
    id: 1,
    key: 1
  },
  {
    title: 'Respuesta Única',
    id: 2,
    key: 2
  },
  {
    title: 'Respuesta Abierta',
    id: 3,
    key: 3
  }
];

const numberCategory = [
  {
    title: '1',
    key: 1,
    id: 1
  },
  {
    title: '2',
    key: 2,
    id: 2
  },
  {
    title: '3',
    key: 3,
    id: 3
  },
  {
    title: '4',
    key: 4,
    id: 4
  },
  {
    title: '5',
    key: 5,
    id: 5
  },
  {
    title: '6',
    key: 6,
    id: 6
  },
  {
    title: '7',
    key: 7,
    id: 7
  },
  {
    title: '8',
    key: 8,
    id: 8
  },
  {
    title: '9',
    key: 9,
    id: 9
  },
  {
    title: '10',
    key: 10,
    id: 10
  },
  {
    title: '11',
    key: 11,
    id: 11
  },
  {
    title: '12',
    key: 12,
    id: 12
  },
  {
    title: '13',
    key: 13,
    id: 13
  },
  {
    title: '14',
    key: 14,
    id: 14
  },
  {
    title: '15',
    key: 15,
    id: 15
  }
];

const typeScore = [
  {
    title: 'Porcentaje',
    key: 0,
    id: 0
  },
  {
    title: 'Puntaje',
    key: 1,
    id: 1
  }
];

export { typeQuestion, numberCategory, typeScore };
