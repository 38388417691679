import React from 'react';
import PropTypes from 'prop-types';

const Paragraph = ({ text, textColor, ...props }) => (
  <p style={{ color: textColor }} {...props}>
    {text}
  </p>
);

Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string
};

Paragraph.defaultProps = {
  textColor: '$dark'
};

export default Paragraph;
