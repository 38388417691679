import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  NavbarLeft,
  DefaultButton,
  SubTitleH2,
  ComponentDataTable,
  ModalConfirm,
  ModalAction
} from '../../components';
import { columns, columnsAdmin } from './columns';
import { getGuidelines, deleteGuideline } from '../../actions/guideline';
import { AbilityContext, Can } from '../../config/ability-context';

class Guidelines extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      modalShowConfirm: false,
      modalData: {}
    };
  }

  setModalShow = state => {
    this.setState({
      modalShow: state
    });
  };

  setModalShowConfirm = state => {
    this.setState({
      modalShowConfirm: state
    });
  };

  setData = guidelines => {
    const guidelineAux = guidelines.filter(item => item.title !== 'Seleccione');
    return guidelineAux.map(item => {
      return {
        id: item.id,
        name: item.name,
        minimum: item.minimum,
        question_types: item.question_types.map(qt => qt.name).join(' - '),
        questionTypes: item.question_types,
        guidelinePerformances: item.guideline_performances
      };
    });
  };

  queryDispatch = data => {
    const { dispatch, signedIn } = this.props;
    if (signedIn) dispatch(getGuidelines(data));
  };

  handleButtonClick = (item, action) => {
    const status = action === 'edit';
    if (status) {
      this.setState({
        modalShow: true,
        modalData: {
          item,
          action: 'edit',
          title: 'EDITAR TIPO DE EVALUACIÓN',
          form: 'guideline'
        }
      });
    } else {
      this.setState({
        modalShowConfirm: true,
        modalData: {
          id: item.id,
          action: 'delete',
          title: 'ELIMINAR TIPO DE EVALUACIÓN',
          message: '¿Estás seguro que deseas eliminar este tipo de evaluación?'
        }
      });
    }
  };

  handleDelete = guidelineId => {
    const { dispatch } = this.props;
    dispatch(deleteGuideline({ id: guidelineId }));
    this.setModalShowConfirm(false);
  };

  handleCreate = () => {
    this.setState({
      modalShow: true,
      modalData: {
        create: true,
        form: 'guideline',
        title: 'NUEVO TIPO DE EVALUACIÓN'
      }
    });
  };

  render() {
    const { totalRows, guidelines, ongoingRequest } = this.props;
    const { modalData, modalShowConfirm, modalShow } = this.state;
    const { context } = this;

    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            <div className="section">
              <Row>
                <Col md={8}>
                  <SubTitleH2 text="Tipos de Evaluación" />
                </Col>
                <Col md={4} style={{ textAlign: 'right' }}>
                  <Can I="create" a="Guideline">
                    <DefaultButton
                      onClick={() => this.handleCreate(true)}
                      text="Crear Tipo de Evaluación"
                    />
                  </Can>
                </Col>
              </Row>

              <ComponentDataTable
                columns={
                  context.can('update', 'Guideline') ||
                  context.can('delete', 'Guideline')
                    ? columnsAdmin(this.handleButtonClick)
                    : columns(this.handleButtonClick)
                }
                onRequest={ongoingRequest.getGuidelines}
                data={this.setData(guidelines)}
                totalRows={totalRows}
                reduxDispatch={this.queryDispatch}
              />
              <ModalAction
                size="lg"
                onLoad={
                  ongoingRequest.createGuidelines ||
                  ongoingRequest.updateGuidelines
                }
                show={modalShow}
                data={modalData}
                onHide={() => this.setModalShow(false)}
              />
              <ModalConfirm
                show={modalShowConfirm}
                data={modalData}
                onHide={() => this.setModalShowConfirm(false)}
                action={this.handleDelete}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

Guidelines.contextType = AbilityContext;

const mapStateToProps = state => {
  const { ongoingRequest, totalRows, guidelines } = state.guideline;
  const { user, signedIn } = state.auth;

  return {
    ongoingRequest,
    totalRows,
    guidelines,
    user,
    signedIn
  };
};

export default withRouter(connect(mapStateToProps)(Guidelines));
