import React from 'react';
import { memoize } from 'react-data-table-component';
import { Edit2, Trash } from 'react-feather';
import { Button } from 'react-bootstrap';

const columnsAdmin = memoize(clickHandler => [
  {
    name: 'NOMBRE',
    selector: 'name'
  },
  {
    name: 'MÌNIMO',
    selector: 'minimum'
  },
  {
    name: 'TIPO DE PREGUNTAS',
    selector: 'question_types'
  },
  {
    cell: a => (
      <div>
        <Button
          variant="outline-primary"
          size="sm"
          className="mr-2"
          onClick={() => clickHandler(a, 'edit')}
        >
          <Edit2 className="small-svg" />
        </Button>
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => clickHandler(a, 'delete')}
        >
          <Trash className="small-svg" />
        </Button>
      </div>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true
  }
]);

const columns = memoize(() => [
  {
    name: 'NOMBRE',
    selector: 'name'
  },
  {
    name: 'MÌNIMO',
    selector: 'minimum'
  },
  {
    name: 'TIPO DE PREGUNTAS',
    selector: 'question_types'
  }
]);

export { columns, columnsAdmin };
