import {
  SET_NOTICE,
  CLOSE_NOTICE,
  SET_NOTICE_ERROR,
  CLEAR_NOTICE
} from '../actions/alert';

const initialState = {
  show: false,
  title: '',
  kind: '',
  timeout: '',
  message: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTICE:
      return {
        ...state,
        show: true,
        kind: action.kind || action.params.kind,
        timeout: action.timeout || action.params.timeout,
        message: action.message || action.params.message,
        title: action.title || action.params.title
      };
    case SET_NOTICE_ERROR:
      return {
        ...state,
        show: true,
        kind: 'error',
        message: action.errorDetail
      };
    case CLOSE_NOTICE:
      return {
        ...state,
        show: false
      };
    case CLEAR_NOTICE:
      return {
        ...state,
        message: ''
      };
    default:
      return state;
  }
};

export default reducer;
