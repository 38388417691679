import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AbilityContext, Can } from '../../config/ability-context';

import {
  getEstablishment,
  deleteEstablishment
} from '../../actions/establishment';
import {
  NavbarLeft,
  SubTitleH2,
  ComponentDataTable,
  ModalAction,
  DefaultButton,
  ModalConfirm
} from '../../components';
import SelectAlt from '../../components/Input/SelectAlt';
import { createArrayYears, setDataYear } from '../../services/utils';
import { columns, columnsAdmin } from './columns';

class EstablishmentAndCourses extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      modalShowConfirm: false,
      modalData: {},
      year: new Date().getFullYear(),
      finished: false
    };
  }

  componentDidUpdate(_prevProps, prevState) {
    const { year } = this.state;

    if (year !== prevState.year) {
      this.reloadDatatable();
    }
  }

  reloadDatatable = () => {
    const { finished } = this.state;
    this.setState({ finished: !finished });
  };

  queryDispatch = data => {
    const { dispatch, signedIn } = this.props;
    if (signedIn) {
      dispatch(getEstablishment(data));
    }
  };

  setData = establishment => {
    return establishment.map(item => {
      return {
        id: item.id,
        name: item.name,
        students: item.students_number,
        region: item.region.code,
        regionId: item.region.id,
        commune: item.commune.name,
        communeId: item.commune.id,
        grades_info: item.grades_info,
        rbd: item.rbd,
        programStart: item.beginning_year
      };
    });
  };

  setModalShow = state => {
    this.setState({
      modalShow: state
    });
  };

  setModalShowConfirm = state => {
    this.setState({
      modalShowConfirm: state
    });
  };

  handleCreate = () => {
    const { history } = this.props;

    history.push('/create-establishment');
  };

  handleButtonClick = (item, action) => {
    const status = action === 'edit';
    const { history } = this.props;
    if (status) {
      history.push(`./create-establishment/${item.id}`);
    } else {
      this.setState({
        modalShowConfirm: true,
        modalData: {
          id: item.id,
          action: 'edit',
          title: 'ELIMINAR INSTITUCIÓN',
          message: '¿Estás seguro que deseas eliminar esta institución?'
        }
      });
    }
  };

  handleDelete = establishmentId => {
    const { dispatch } = this.props;
    dispatch(deleteEstablishment({ id: establishmentId }));
    this.setModalShowConfirm(false);
  };

  handleYear = year => {
    this.setState({
      year
    });
  };

  render() {
    const { ongoingRequest, establishment, totalRows } = this.props;
    const {
      modalShow,
      modalData,
      modalShowConfirm,
      year,
      finished
    } = this.state;
    const { context } = this;

    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            <div className="section">
              <Row>
                <Col md={8}>
                  <SubTitleH2 text="Establecimiento y cursos" />
                </Col>
                <Col md={4} style={{ textAlign: 'right' }}>
                  <Can I="create" a="Establishment">
                    <DefaultButton
                      onClick={() => this.handleCreate(true)}
                      text="Crear Establecimiento"
                    />
                  </Can>
                </Col>
              </Row>
              <Row>
                <Col md={3} className="mt-2">
                  <SelectAlt
                    value={year}
                    onChangeSelect={e => this.handleYear(e.value)}
                    data={setDataYear(
                      createArrayYears(5, 2020, new Date().getFullYear())
                    )}
                    title="Filtrar por año"
                  />
                </Col>
              </Row>
              <ComponentDataTable
                columns={
                  context.can('update', 'Establishment')
                    ? columnsAdmin(this.handleButtonClick)
                    : columns()
                }
                onRequest={ongoingRequest.getEstablishmentRequest}
                data={this.setData(establishment)}
                totalRows={totalRows}
                reduxDispatch={this.queryDispatch}
                moreData={{ filter_by_year: year, finished }}
              />
            </div>
            <ModalAction
              show={modalShow}
              data={modalData}
              onHide={() => this.setModalShow(false)}
            />
            <ModalConfirm
              show={modalShowConfirm}
              data={modalData}
              onHide={() => this.setModalShowConfirm(false)}
              action={this.handleDelete}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
EstablishmentAndCourses.contextType = AbilityContext;

const mapStateToProps = state => {
  const { ongoingRequest, establishment, totalRows } = state.establishment;
  const { user, signedIn } = state.auth;
  return {
    ongoingRequest,
    establishment,
    totalRows,
    user,
    signedIn
  };
};
export default connect(mapStateToProps)(EstablishmentAndCourses);
