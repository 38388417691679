export const GET_OBJETIVES_LEARNING_REQUEST = 'GET_OBJETIVES_LEARNING_REQUEST';
export const GET_OBJETIVES_LEARNING_SUCCESS = 'GET_OBJETIVES_LEARNING_SUCCESS';
export const GET_OBJETIVES_LEARNING_FAILURE = 'GET_OBJETIVES_LEARNING_FAILURE';

export const getObjectivesLearning = params => ({
  type: GET_OBJETIVES_LEARNING_REQUEST,
  params
});

export const GET_SKILLS_REQUEST = 'GET_SKILLS_REQUEST';
export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS';
export const GET_SKILLS_FAILURE = 'GET_SKILLS_FAILURE';

export const getSkills = () => ({
  type: GET_SKILLS_REQUEST
});

export const GET_QUESTION_TYPE_REQUEST = 'GET_QUESTION_TYPE_REQUEST';
export const GET_QUESTION_TYPE_SUCCESS = 'GET_QUESTION_TYPE_SUCCESS';
export const GET_QUESTION_TYPE_FAILURE = 'GET_QUESTION_TYPE_FAILURE';

export const getQuestionType = () => ({
  type: GET_QUESTION_TYPE_REQUEST
});

export const GET_QUESTION_TYPE_PER_QUESTION_REQUEST =
  'GET_QUESTION_TYPE_PER_QUESTION_REQUEST';
export const GET_QUESTION_TYPE_PER_QUESTION_SUCCESS =
  'GET_QUESTION_TYPE_PER_QUESTION_SUCCESS';
export const GET_QUESTION_TYPE_PER_QUESTION_FAILURE =
  'GET_QUESTION_TYPE_PER_QUESTION_FAILURE';

export const getQuestionTypePerQuestion = id => ({
  type: GET_QUESTION_TYPE_PER_QUESTION_REQUEST,
  id
});
