const onReload = (onRequest, onload, onLoadTeacher) => {
  return (
    onRequest.createGradesEstablishment ||
    onRequest.createEstablishmentRequest ||
    onRequest.updateEstablishmentRequest ||
    onload.getRegions ||
    onload.getGrades ||
    onLoadTeacher.getTeachers
  );
};

export default onReload;
