import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { X } from 'react-feather';
import { DefaultButton } from '../Button/index';

const ConfirmDelete = props => {
  const {
    keyIndex,
    deleteItemSelected,
    handleDelete,
    handleCancel,
    handleConfirm,
    disabled
  } = props;
  const deleteAction = keyIndex === deleteItemSelected;

  return (
    <div>
      {!deleteAction ? (
        <Row style={{ justifyContent: 'center' }}>
          <Col xs={5}>
            <Button
              text="Borrar"
              variant="outline-danger"
              disabled={disabled}
              onClick={() => handleDelete(keyIndex)}
            >
              <X className="small-svg" />
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={5}>
            <DefaultButton
              text="Cancelar"
              onClick={() => handleCancel()}
              style={{
                backgroundColor: '#AFAFAF',
                borderColor: '#AFAFAF'
              }}
            />
          </Col>
          <Col xs={5}>
            <DefaultButton
              text="Confirmar"
              onClick={() => handleConfirm(keyIndex)}
              style={{
                backgroundColor: '#3CC480',
                borderColor: '#3CC480'
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ConfirmDelete;
