import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { NavbarLeft, ShowFinish } from '../../components';
import { getQuizShow } from '../../actions/test';
import { LoadSpinner } from '../../components/Utils';

class QuizShow extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentWillMount() {
    const { dispatch, match } = this.props;
    dispatch(getQuizShow(match.params.id));
  }

  render() {
    const { quiz, ongoingRequest } = this.props;
    return (
      <Container fluid>
        {!quiz.id ? (
          <Row>
            <LoadSpinner />
            <NavbarLeft />
          </Row>
        ) : (
          <Row>
            <Col md={2} className="d-none d-md-block sidebar">
              <NavbarLeft />
            </Col>
            <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
              <div className="section">
                <ShowFinish quiz={quiz} ongoingRequest={ongoingRequest} />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const {
    ongoingRequest,
    quiz: { quiz }
  } = state.test;
  return {
    ongoingRequest,
    quiz
  };
};

export default connect(mapStateToProps)(QuizShow);
