import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavDropdown } from 'react-bootstrap';
import { Can } from '../../config/ability-context';

const BaseNav = ({ items, variant, children }) => (
  <Nav variant={variant}>
    {items.map(item => (
      <Nav.Item key={item.key}>{children}</Nav.Item>
    ))}
  </Nav>
);

BaseNav.propTypes = {
  variant: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired
};

BaseNav.defaultProps = {
  variant: 'tabs'
};

const VerticalNav = ({ items, variant, history }) => {
  return (
    <Nav variant={variant} className="flex-column">
      {items.map(item => {
        if (item.type === 'item') {
          const active =
            `/${history.location.pathname.split('/')[1]}` === item.path;
          if (item.title !== 'Usuarios') {
            return (
              <Nav.Link
                className={active ? 'active' : ''}
                onClick={() => history.push(item.path)}
                key={item.key}
              >
                {item.title}
              </Nav.Link>
            );
          }
          return (
            <Can I="read" a="User" key={item.key}>
              <Nav.Link
                className={active ? 'active' : ''}
                onClick={() => history.push(item.path)}
                key={item.key}
              >
                {item.title}
              </Nav.Link>
            </Can>
          );
        }
        return <Dropdown items={item} key={item.key} history={history} />;
      })}
    </Nav>
  );
};

VerticalNav.propTypes = {
  variant: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired
};

VerticalNav.defaultProps = {
  variant: 'tabs'
};

const Dropdown = ({ items, history }) => {
  const activeDropdown = items.data.some(
    item => item.path === history.location.pathname
  );

  const [show, setShow] = useState(false);
  return (
    <NavDropdown
      onToggle={isOpen => setShow(isOpen)}
      show={show || activeDropdown}
      title={items.title}
      id="basic-nav-dropdown"
      key={items.key}
    >
      {items.data.map(item => {
        const active = history.location.pathname === item.path;
        return (
          <NavDropdown.Item
            className={active ? 'active' : ''}
            key={item.key}
            onClick={() => history.push(item.path)}
          >
            {item.title}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
};

Dropdown.protoTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string.isRequired
    })
  ).isRequired
};

Dropdown.defaultProps = {};

export { BaseNav, VerticalNav, Dropdown };
