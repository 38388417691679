import React from 'react';
import { memoize } from 'react-data-table-component';
import { Edit2, Trash } from 'react-feather';
import { Button } from 'react-bootstrap';

const columnsAdmin = memoize((clickHandler, actUpdate, actDelete) => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: 2
  },
  {
    name: 'RUT',
    selector: 'rut',
    grow: 2
  },
  {
    name: 'CORREO',
    selector: 'email',
    grow: 2
  },
  {
    name: 'ROL',
    selector: 'role_name',
    grow: 2
  },
  {
    cell: infoItem => (
      <div>
        {actUpdate && (
          <Button
            variant="outline-primary"
            size="sm"
            className="mr-2"
            onClick={() => clickHandler(infoItem, 'edit')}
          >
            <Edit2 className="small-svg" />
          </Button>
        )}

        {actDelete && (
          <Button
            variant="outline-danger"
            size="sm"
            className="mr-2"
            onClick={() => clickHandler(infoItem, 'delete')}
          >
            <Trash className="small-svg" />
          </Button>
        )}
      </div>
    ),
    right: true
  }
]);

const columns = memoize(() => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: 1
  },
  {
    name: 'RUT',
    selector: 'rut',
    grow: 1
  },
  {
    name: 'CORREO',
    selector: 'email',
    grow: 1
  },
  {
    name: 'ROL',
    selector: 'role',
    grow: 1
  }
]);

export { columns, columnsAdmin };
