import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Dialog = ({ show, onHide, data, action, textCancel, textSuccess }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{data.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{data.message}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={() => onHide(false)}>
          {textCancel}
        </Button>
        <Button variant="primary" onClick={() => action(data)}>
          {textSuccess}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
Dialog.defaultProps = {
  textCancel: 'Cancelar',
  textSuccess: 'Aceptar'
};

Dialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
  }).isRequired,
  action: PropTypes.func.isRequired,
  textCancel: PropTypes.string,
  textSuccess: PropTypes.string
};

export default Dialog;
