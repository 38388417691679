import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeCurrentStep } from '../../actions/test';
import { SubTitleH2 } from '../Text';
import TypeTest from './TypeTest';
// eslint-disable-next-line import/no-cycle
import Questions from './Questions';
// eslint-disable-next-line import/no-cycle
import Score from './Score';
// eslint-disable-next-line import/no-cycle
import Finish from './Finish';
import { resetGuidelineData } from '../../actions/guideline';
import { ArrowLeftIndex } from '../Utils';

class StepProgressBar extends Component {
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetGuidelineData());
  }

  nextStep = () => {
    const { dispatch } = this.props;
    dispatch(changeCurrentStep({ stepType: 'NEXT' }));
  };

  prevStep = () => {
    const { dispatch } = this.props;
    dispatch(changeCurrentStep({ stepType: 'PREV' }));
  };

  renderStepContent = () => {
    const { currentStep, history, quiz } = this.props;
    switch (currentStep) {
      case 1:
        return <TypeTest nextStep={this.nextStep} quiz={quiz} />;
      case 2:
        return <Questions nextStep={this.nextStep} prevStep={this.prevStep} />;
      case 3:
        return <Score nextStep={this.nextStep} prevStep={this.prevStep} />;
      case 4:
        return <Finish prevStep={this.prevStep} history={history} />;
      default:
        return null;
    }
  };

  render() {
    const { currentStep, title } = this.props;
    const { steps } = this.props;
    const active = i => {
      if (i + 1 === currentStep) {
        return 'active done';
      }
      if (i + 1 <= currentStep) {
        return 'done';
      }
      return false.toString();
    };

    return (
      <div>
        <ArrowLeftIndex className="mb-5 text-light" path="/tests" />

        <SubTitleH2 text={title} />
        <div>
          {currentStep >= 1 && (
            <ul className="progressbar">
              {steps.map((item, i) => (
                <li key={item.key} className={active(i)}>
                  {item.step}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div>{this.renderStepContent()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    currentStep,
    quiz: { quiz }
  } = state.test;
  return {
    quiz,
    currentStep
  };
};

export default connect(mapStateToProps)(StepProgressBar);
