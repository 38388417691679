export const GET_GUIDELINE_REQUEST = 'GET_GUIDELINE_REQUEST';
export const GET_GUIDELINE_SUCCESS = 'GET_GUIDELINE_SUCCESS';
export const GET_GUIDELINE_FAILURE = 'GET_GUIDELINE_FAILURE';

export const getGuidelines = data => ({
  type: GET_GUIDELINE_REQUEST,
  data
});

export const DELETE_GUIDELINE_REQUEST = 'DELETE_GUIDELINE_REQUEST';
export const DELETE_GUIDELINE_SUCCESS = 'DELETE_GUIDELINE_SUCCESS';
export const DELETE_GUIDELINE_FAILURE = 'DELETE_GUIDELINE_FAILURE';

export const deleteGuideline = data => ({
  type: DELETE_GUIDELINE_REQUEST,
  data
});

export const CREATE_GUIDELINE_REQUEST = 'CREATE_GUIDELINE_REQUEST';
export const CREATE_GUIDELINE_SUCCESS = 'CREATE_GUIDELINE_SUCCESS';
export const CREATE_GUIDELINE_FAILURE = 'CREATE_GUIDELINE_FAILURE';

export const createGuideline = data => ({
  type: CREATE_GUIDELINE_REQUEST,
  data
});

export const UPDATE_GUIDELINE_REQUEST = 'UPDATE_GUIDELINE_REQUEST';
export const UPDATE_GUIDELINE_SUCCESS = 'UPDATE_GUIDELINE_SUCCESS';
export const UPDATE_GUIDELINE_FAILURE = 'UPDATE_GUIDELINE_FAILURE';

export const updateGuideline = data => ({
  type: UPDATE_GUIDELINE_REQUEST,
  data
});

export const RESET_GUIDELINE_DATA = 'RESET_GUIDELINE_DATA';
export const resetGuidelineData = () => ({
  type: RESET_GUIDELINE_DATA
});

export const SET_REDIRECT = 'SET_REDIRECT';
export const setRedirect = data => ({
  type: SET_REDIRECT,
  data
});
