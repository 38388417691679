import React, { Component } from 'react';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { cloneQuiz, setRedirect } from '../../actions/test';  

const CloneQuizSchema = Yup.object().shape({
  name: Yup.string()
    .min(5, 'Mínimo 5 caracteres')
    .max(100, 'Máximo 100 caracteres')
    .required('Campo requerido'),
  year: Yup.string()
    .required('Campo requerido')
});

class CloneQuizForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    const { redirect, onHide, dispatch } = nextProps;
    if (redirect.state) {
      dispatch(setRedirect({ path: '', state: false }));
      onHide();
    }
  }

  render() {
    const { onHide, dispatch, data } = this.props;
    return (
      <Formik
        initialValues={{
          id: '',
          name: '',
          year: ''
        }}
        validationSchema={CloneQuizSchema}
        onSubmit={values => {
          // same shape as initial values
          dispatch(
            cloneQuiz({
              id: data.id,
              name: values.name,
              year: values.year
            })
          );
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-group">
              <center>
                <Field
                  style={{ marginBottom: '10px' }}
                  name="name"
                  placeholder="Ingresa un nombre para la evaluación"
                  className={`form-control ${
                    errors.name && touched.name ? 'errors' : null
                  }`}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="warning-password"
                />
                <Field
                  name="year"
                  className={`form-control ${
                    errors.year && touched.year
                      ? 'errors'
                      : null
                  }`}
                  placeholder="Año evaluación"
                />

                <ErrorMessage
                  name="year"
                  component="div"
                  className="warning-password"
                />
              </center>
              <Row className="mt-3">
                <Col md={{ span: 3, offset: 4 }}>
                  <Button onClick={onHide} block variant="light">
                    Cancelar
                  </Button>
                </Col>
                <Col md={5}>
                  <Button type="submit" block variant="primary">
                    Aceptar
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  const { redirect } = state.auth;
  return {
    redirect
  };
};

export default connect(mapStateToProps)(CloneQuizForm);
