import React, { Component } from 'react';
import { Row, Col, Container, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  SubTitleH2,
  NavbarLeft,
  ComponentDataTable,
  DefaultButton,
  ModalConfirm,
  ModalAction
} from '../../components';
import {
  getQuizzesShow,
  deleteQuiz,
  editQuiz,
  clearTest,
  cloneQuiz
} from '../../actions/test';
import './styles.scss';
import { columns, columnsAdmin } from './columns';
import { AbilityContext, Can } from '../../config/ability-context';

class Tests extends Component {
  constructor() {
    super();
    this.state = {
      modalShowConfirm: false,
      modalData: {},
      isChecked: false,
      modalActionShow: false
    };
  }

  queryDispatch = data => {
    const { dispatch, signedIn } = this.props;
    if (signedIn) dispatch(getQuizzesShow(data));
  };

  setModalShowConfirm = state => {
    this.setState({
      modalShowConfirm: state
    });
  };

  setModalActionShow = state => {
    this.setState({
      modalActionShow: state
    });
  };

  handleDelete = quizId => {
    const { dispatch } = this.props;
    dispatch(deleteQuiz(quizId));
    this.setModalShowConfirm(false);
  };

  handleClone = quizId => {
    const { dispatch } = this.props;
    dispatch(cloneQuiz(quizId));
    this.setModalActionShow(false);
  };

  handleButtonClick = (item, action) => {
    const { history, dispatch } = this.props;
    if (action === 'delete') {
      this.setState({
        modalShowConfirm: true,
        modalData: {
          id: item.id,
          action: 'delete',
          title: 'ELIMINAR EVALUACIÓN',
          message: '¿Estás seguro que deseas eliminar esta evaluación?'
        }
      });
    } else if (action === 'show') {
      dispatch(clearTest());
      history.push(`/tests/${item.id}`);
    } else if (action === 'edit') {
      dispatch(editQuiz({ id: item.id }));
      history.push(`/tests/${item.id}/edit`);
    } else if (action === 'edit-score') {
      dispatch(editQuiz({ id: item.id }));
      history.push(`/tests/${item.id}/edit-score`);
    } else if (action === 'clone-quiz') {
      this.setState({
        modalActionShow: true,
        modalData: {
          id: item.id,
          create: true,
          form: 'clone-quiz',
          title: 'Clonar Evaluación'
        }
      });
    }
  };

  check = () => {
    const { isChecked } = this.state;
    this.setState({
      isChecked: !isChecked
    });
  };

  createTest = () => {
    const { history, dispatch } = this.props;
    dispatch(clearTest());
    history.push('/new-tests');
  };

  render() {
    const { ongoingRequest, totalRows, quizzes } = this.props;
    const { modalShowConfirm, modalData, isChecked, modalActionShow } = this.state;
    const { context } = this;

    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            <div className="section">
              <Row>
                <Col md={8}>
                  <SubTitleH2 text="Evaluaciones" />
                </Col>
                <Col md={4} style={{ textAlign: 'right' }}>
                  <Can I="create" a="Quiz">
                    <DefaultButton
                      onClick={() => this.createTest()}
                      text="Crear evaluación"
                    />
                  </Can>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    type="checkbox"
                    label="Reanudar"
                    onClick={this.check}
                  />
                </Col>
              </Row>
              <ComponentDataTable
                columns={
                  context.can('update', 'Quiz') || context.can('delete', 'Quiz')
                    ? columnsAdmin(this.handleButtonClick)
                    : columns(this.handleButtonClick)
                }
                onRequest={ongoingRequest.getQuizzes}
                data={quizzes}
                totalRows={totalRows}
                reduxDispatch={this.queryDispatch}
                moreData={isChecked ? { finished: !isChecked } : {}}
              />
            </div>
            <ModalConfirm
              show={modalShowConfirm}
              data={modalData}
              onHide={() => this.setModalShowConfirm(false)}
              action={this.handleDelete}
            />
            <ModalAction
              show={modalActionShow}
              data={modalData}
              onHide={() => this.setModalActionShow(false)}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

Tests.contextType = AbilityContext;

const mapStateToProps = state => {
  const { ongoingRequest, quizzes, totalRows } = state.test;
  const { user, signedIn } = state.auth;
  return {
    ongoingRequest,
    quizzes,
    totalRows,
    signedIn,
    user
  };
};
export default connect(mapStateToProps)(Tests);
