/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';
import { BasicInput } from '../../Input';
import './styles.scss';
import { updateInfoQuestionAlternatives } from '../../../actions/test';

class Alternative extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxAlternative:
        props.quiz.axes_attributes[props.axeIndex].sub_axes_attributes[
          props.subAxeIndex
        ].indicators_attributes[props.indicatorIndex].questions_attributes[
          props.itemIndex
        ].number,
      radioAlternative:
        props.quiz.axes_attributes[props.axeIndex].sub_axes_attributes[
          props.subAxeIndex
        ].indicators_attributes[props.indicatorIndex].questions_attributes[
          props.itemIndex
        ].answer
    };
  }

  updateScore = (value, clicked, number) => {
    const {
      dispatch,
      itemIndex,
      indicatorIndex,
      subAxeIndex,
      axeIndex
    } = this.props;
    dispatch(
      updateInfoQuestionAlternatives({
        value,
        clicked,
        number,
        itemIndex,
        indicatorIndex,
        subAxeIndex,
        axeIndex
      })
    );
  };

  render() {
    const {
      itemIndex,
      indicatorIndex,
      subAxeIndex,
      axeIndex,
      quiz
    } = this.props;
    const { checkboxAlternative, radioAlternative } = this.state;
    const currentItem =
      quiz.axes_attributes[axeIndex].sub_axes_attributes[subAxeIndex]
        .indicators_attributes[indicatorIndex].questions_attributes[itemIndex];

    return (
      <Col className="align-col">
        <Row>
          <Col md={3}>
            <BasicInput
              type="number"
              placeholder="Puntaje"
              className="secondary special-m-t"
              value={currentItem.score}
              onChange={e =>
                this.updateScore(
                  e.target.value,
                  currentItem.answer,
                  currentItem.number
                )
              }
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={3}>
            <p className="mb-0 mt-1 text-muted">Seleccionar alternativas</p>
          </Col>
          <Col>
            <Form.Group>
              <Form.Check
                inline
                type="checkbox"
                label="A"
                value="1"
                id={`A-${currentItem.position}`}
                className="custom-box"
                onClick={e => {
                  this.setState({
                    checkboxAlternative: e.target.value,
                    radioAlternative: 0
                  });
                  this.updateScore(
                    currentItem.score,
                    currentItem.answer,
                    e.target.value
                  );
                }}
                checked={checkboxAlternative >= 1}
                disabled={quiz.with_reviews}
              />
              <Form.Check
                inline
                type="checkbox"
                label="B"
                value="2"
                id={`B-${currentItem.position}`}
                className="custom-box"
                onClick={e => {
                  this.setState({
                    checkboxAlternative: e.target.value,
                    radioAlternative: 0
                  });
                  this.updateScore(
                    currentItem.score,
                    currentItem.answer,
                    e.target.value
                  );
                }}
                checked={checkboxAlternative >= 2}
                disabled={quiz.with_reviews}
              />
              <Form.Check
                inline
                type="checkbox"
                label="C"
                value="3"
                id={`C-${currentItem.position}`}
                className="custom-box"
                onClick={e => {
                  this.setState({
                    checkboxAlternative: e.target.value,
                    radioAlternative: 0
                  });
                  this.updateScore(
                    currentItem.score,
                    currentItem.answer,
                    e.target.value
                  );
                }}
                checked={checkboxAlternative >= 3}
                disabled={quiz.with_reviews}
              />
              <Form.Check
                inline
                type="checkbox"
                label="D"
                value="4"
                id={`D-${currentItem.position}`}
                className="custom-box"
                onClick={e => {
                  this.setState({
                    checkboxAlternative: e.target.value,
                    radioAlternative: 0
                  });
                  this.updateScore(
                    currentItem.score,
                    currentItem.answer,
                    e.target.value
                  );
                }}
                checked={checkboxAlternative >= 4}
                disabled={quiz.with_reviews}
              />
              <Form.Check
                inline
                type="checkbox"
                label="E"
                value="5"
                id={`E-${currentItem.position}`}
                className="custom-box"
                onClick={e => {
                  this.setState({
                    checkboxAlternative: e.target.value,
                    radioAlternative: 0
                  });
                  this.updateScore(
                    currentItem.score,
                    currentItem.answer,
                    e.target.value
                  );
                }}
                checked={checkboxAlternative >= 5}
                disabled={quiz.with_reviews}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <p className="mb-1 text-muted">Seleccionar clave</p>
          </Col>
          <Col>
            <Form.Group controlId={`control-${itemIndex}`}>
              <Form.Check
                className="custom-box"
                inline
                type="radio"
                name={`alternativeCorrectAnwser${currentItem.position}`}
                label="A"
                value="1"
                onClick={e => {
                  this.updateScore(
                    currentItem.score,
                    e.target.value,
                    currentItem.number
                  );
                  this.setState({ ...this.state, radioAlternative: 1 });
                }}
                id="Aanwser"
                disabled={checkboxAlternative < 1}
                checked={radioAlternative === 1}
              />
              <Form.Check
                className="custom-box"
                inline
                type="radio"
                name={`alternativeCorrectAnwser${currentItem.position}`}
                label="B"
                value="2"
                onClick={e => {
                  this.updateScore(
                    currentItem.score,
                    e.target.value,
                    currentItem.number
                  );
                  this.setState({ ...this.state, radioAlternative: 2 });
                }}
                id="Banwser"
                disabled={checkboxAlternative < 2}
                checked={radioAlternative === 2}
              />
              <Form.Check
                className="custom-box"
                inline
                type="radio"
                name={`alternativeCorrectAnwser${currentItem.position}`}
                label="C"
                value="3"
                onClick={e => {
                  this.updateScore(
                    currentItem.score,
                    e.target.value,
                    currentItem.number
                  );
                  this.setState({ ...this.state, radioAlternative: 3 });
                }}
                id="Canwser"
                disabled={checkboxAlternative < 3}
                checked={radioAlternative === 3}
              />
              <Form.Check
                className="custom-box"
                inline
                type="radio"
                name={`alternativeCorrectAnwser${currentItem.position}`}
                label="D"
                value="4"
                onClick={e => {
                  this.updateScore(
                    currentItem.score,
                    e.target.value,
                    currentItem.number
                  );
                  this.setState({ ...this.state, radioAlternative: 4 });
                }}
                id="Danwser"
                disabled={checkboxAlternative < 4}
                checked={radioAlternative === 4}
              />
              <Form.Check
                className="custom-box"
                inline
                type="radio"
                name={`alternativeCorrectAnwser${currentItem.position}`}
                label="E"
                value="5"
                onClick={e => {
                  this.updateScore(
                    currentItem.score,
                    e.target.value,
                    currentItem.number
                  );
                  this.setState({ ...this.state, radioAlternative: 5 });
                }}
                id="Eanwser"
                disabled={checkboxAlternative < 5}
                checked={radioAlternative === 5}
              />
            </Form.Group>
          </Col>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = state => {
  const {
    quiz: { quiz }
  } = state.test;
  return {
    quiz
  };
};

export default connect(mapStateToProps)(Alternative);
