import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_OBJETIVES_LEARNING_REQUEST,
  GET_OBJETIVES_LEARNING_SUCCESS,
  GET_OBJETIVES_LEARNING_FAILURE,
  GET_SKILLS_REQUEST,
  GET_SKILLS_SUCCESS,
  GET_SKILLS_FAILURE,
  GET_QUESTION_TYPE_REQUEST,
  GET_QUESTION_TYPE_SUCCESS,
  GET_QUESTION_TYPE_FAILURE,
  GET_QUESTION_TYPE_PER_QUESTION_REQUEST,
  GET_QUESTION_TYPE_PER_QUESTION_SUCCESS,
  GET_QUESTION_TYPE_PER_QUESTION_FAILURE
} from '../actions/quiz';
import API from '../services/api';
import runDefaultSaga from './default';

const getObjectivesLearningRequest = params => {
  return API.get(`/learning_objectives`, params);
};

// OBJECTIVES LEARNING

function* getObjectivesLearningSuccessCallback(result) {
  yield put({
    type: GET_OBJETIVES_LEARNING_SUCCESS,
    result: result.data
  });
}

function* getObjectivesLearningFailureCallback() {
  yield put({
    type: GET_OBJETIVES_LEARNING_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getObjectivesLearning(action) {
  yield runDefaultSaga(
    { request: getObjectivesLearningRequest, params: action.params },
    getObjectivesLearningSuccessCallback,
    getObjectivesLearningFailureCallback
  );
}

const getSkillsRequest = () => {
  return API.get('/skills?active=true');
};

// SKILLS

function* getSkillsSuccessCallback(result) {
  yield put({
    type: GET_SKILLS_SUCCESS,
    result: result.data
  });
}

function* getSkillsFailureCallback() {
  yield put({
    type: GET_SKILLS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getSkills() {
  yield runDefaultSaga(
    { request: getSkillsRequest },
    getSkillsSuccessCallback,
    getSkillsFailureCallback
  );
}

const getQuestionTypeRequest = () => {
  return API.get(`/question_types`);
};

// QUESTION TYPE

function* getQuestionTypeSuccessCallback(result) {
  yield put({
    type: GET_QUESTION_TYPE_SUCCESS,
    result: result.data
  });
}

function* getQuestionTypeFailureCallback() {
  yield put({
    type: GET_QUESTION_TYPE_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getQuestionType() {
  yield runDefaultSaga(
    { request: getQuestionTypeRequest },
    getQuestionTypeSuccessCallback,
    getQuestionTypeFailureCallback
  );
}

const getQuestionTypePerQuestionRequest = params => {
  return API.get(`/question_types?from_guideline=${params}`);
};

// QUESTION TYPE X QUESTION

function* getQuestionTypePerQuestionSuccessCallback(result) {
  yield put({
    type: GET_QUESTION_TYPE_PER_QUESTION_SUCCESS,
    result: result.data
  });
}

function* getQuestionTypePerQuestionFailureCallback() {
  yield put({
    type: GET_QUESTION_TYPE_PER_QUESTION_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getQuestionTypePerQuestion(action) {
  yield runDefaultSaga(
    { request: getQuestionTypePerQuestionRequest, params: action.id },
    getQuestionTypePerQuestionSuccessCallback,
    getQuestionTypePerQuestionFailureCallback
  );
}

// DEFINE quizSagas
export default function* utilsSagas() {
  yield takeEvery(GET_OBJETIVES_LEARNING_REQUEST, getObjectivesLearning);
  yield takeEvery(GET_SKILLS_REQUEST, getSkills);
  yield takeEvery(GET_QUESTION_TYPE_REQUEST, getQuestionType);
  yield takeEvery(
    GET_QUESTION_TYPE_PER_QUESTION_REQUEST,
    getQuestionTypePerQuestion
  );
}
