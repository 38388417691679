import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { DefaultButton } from '..';
// eslint-disable-next-line import/no-cycle
import { ModalAction } from '../Modal';

class Reports extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      modalData: {}
    };
  }

  setModalShow = state => {
    this.setState({
      modalShow: state
    });
  };

  handleCreate = () => {
    this.setState({
      modalShow: true,
      modalData: {
        create: true,
        form: 'report',
        title: 'GENERAR REPORTE'
      }
    });
  };

  render() {
    const { modalShow, modalData } = this.state;
    const { ongoingRequest } = this.props;
    return (
      <Row>
        <Col>
          <DefaultButton
            onClick={() => this.handleCreate(true)}
            text="Generar Reporte"
          />
        </Col>
        <ModalAction
          onLoad={ongoingRequest.getReport}
          show={modalShow}
          data={modalData}
          onHide={() => this.setModalShow(false)}
          size="lg"
        />
      </Row>
    );
  }
}

export default Reports;
