import React from 'react';
import { memoize } from 'react-data-table-component';
import { Trash, UploadCloud, Download } from 'react-feather';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const renderButtons = (item, clickHandler) => {
  return (
    <div className="buttonRow">
      <OverlayTrigger overlay={<Tooltip>Descargar</Tooltip>}>
        <Button
          variant="outline-primary"
          size="sm"
          className="mr-2"
          onClick={() => clickHandler(item, 'download')}
          disabled={!item.original_filename}
        >
          <Download className="small-svg" />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip>Subir</Tooltip>}>
        <Button
          variant="outline-warning"
          size="sm"
          className="mr-2"
          onClick={() => clickHandler(item, 'upload')}
        >
          <UploadCloud className="small-svg" />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip>Borrar</Tooltip>}>
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => clickHandler(item, 'delete')}
        >
          <Trash className="small-svg" />
        </Button>
      </OverlayTrigger>
    </div>
  );
};
const columnsAdmin = memoize(clickHandler => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    left: true,
    grow: 4
  },
  {
    name: 'TIPO DE INFORME',
    selector: 'document_type_translate',
    sortable: true,
    left: true,
    grow: 2
  },
  {
    name: 'ESTABLECIMIENTO',
    selector: 'establishment',
    cell: item => item.establishment?.name,
    sortable: true,
    left: true,
    grow: 4
  },
  {
    name: 'ÚLTIMA ACTUALIZACIÓN',
    selector: 'last_update',
    sortable: true,
    left: true,
    grow: 2
  },
  {
    name: 'USUARIO',
    selector: 'user_name',
    sortable: true,
    left: true,
    grow: 2
  },
  {
    name: 'ARCHIVO',
    selector: 'original_filename',
    sortable: true,
    left: true,
    grow: 2
  },
  {
    cell: infoItem => <div>{renderButtons(infoItem, clickHandler)}</div>,
    ignoreRowClick: true,
    allowOverflow: true,
    right: true,
    grow: 4
  }
]);

const columns = memoize(() => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    left: true,
    grow: 5
  },
  {
    name: 'ÚLTIMA  ACTUALIZACIÓN',
    selector: 'last_update',
    sortable: true,
    left: true,
    grow: 1
  },
  {
    name: 'USUARIO',
    selector: 'user_name',
    sortable: true,
    left: true,
    grow: 1
  },
  {
    name: 'ARCHIVO',
    selector: 'original_filename',
    sortable: true,
    left: true,
    grow: 1
  }
]);

export { columns, columnsAdmin };
