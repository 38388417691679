import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
// eslint-disable-next-line import/no-cycle
import { BasicInput } from '../index';

class DAEM extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { clearSelectData, clearState, typeOfTest } = this.props;
    clearSelectData();
    clearState();
    typeOfTest();
  }

  render() {
    const {
      name,
      years,
      regions,
      communes,
      grades,
      quizzes,
      ongoingRequest,
      sendData,
      values
    } = this.props;
    const { region, commune, grade, year } = values;
    return (
      <Container>
        <Row>
          <Col>
            <p className="mb-2">Nombre Informe</p>
            <BasicInput
              onChange={e => sendData(e.target.value, 'Name')}
              placeholder="Nombre Informe"
            />
          </Col>
          <Col>
            <p className="mb-2">Año</p>
            <Select
              options={
                ongoingRequest.getYearChart
                  ? [{ value: 0, label: 'Cargando..' }]
                  : years
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={name.length === 0 || name === 0}
              onChange={e => sendData(e.value, 'Year')}
              placeholder="Seleccione"
            />
          </Col>
          <Col>
            <p className="mb-2">Región</p>
            <Select
              options={
                ongoingRequest.getRegionChart
                  ? [{ value: 0, label: 'Cargando..' }]
                  : regions
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={year === 0}
              onChange={e => sendData(e.value, 'Region')}
              placeholder="Seleccione"
            />
          </Col>
          <Col>
            <p className="mb-2">Comuna</p>
            <Select
              options={
                ongoingRequest.getCommuneChart
                  ? [{ value: 0, label: 'Cargando..' }]
                  : communes
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={region === 0}
              onChange={e => sendData(e.value, 'Commune-DAEM')}
              placeholder="Seleccione"
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <p className="mb-2">Nivel</p>
            <Select
              options={
                ongoingRequest.getGradeChart
                  ? [{ value: 0, label: 'Cargando..' }]
                  : grades
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={commune === 0}
              onChange={e => sendData(e.value, 'Grade-DAEM')}
              placeholder="Seleccione"
            />
          </Col>
          <Col>
            <p className="mb-2">Evaluación</p>
            <Select
              options={
                ongoingRequest.getQuizChart
                  ? [{ value: 0, label: 'Cargando..' }]
                  : quizzes
              }
              defaultValue={{ value: 0, label: 'Seleccione' }}
              isDisabled={grade === 0}
              onChange={e => sendData(e.value, 'Quiz-DAEM')}
              placeholder="Seleccione"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  const { availableYears, ongoingRequest } = state.utils;
  return {
    availableYears,
    ongoingRequest
  };
};

export default connect(mapStateToProps)(DAEM);
