import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import test from './test';
import establishment from './establishment';
import guideline from './guideline';
import utils from './utils';
import alert from './alert';
import quiz from './quiz';
import teacher from './teacher';
import student from './student';
import dashboard from './dashboard';
import score from './score';
import user from './users';
import reports from './reports';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    test,
    establishment,
    utils,
    guideline,
    alert,
    quiz,
    teacher,
    student,
    dashboard,
    score,
    user,
    reports
  });

export default createRootReducer;
