import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withFormik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
// import chileanRut from 'chilean-rut';
import {
  createStudent,
  updateStudent,
  setRedirect,
  getStudentDescription
} from '../../actions/student';
import {
  getUtilsEstablishments,
  getGrades,
  getSections
} from '../../actions/utils';
import SelectAlt from '../Input/SelectAlt';
import { BasicInput, CheckBox } from '../Input/index';
import { validateRut, createArrayYears } from '../../services/utils';
import {
  setDataFormStudent,
  getYearStudent,
  setDataStudentRequest
} from './utils';

const genderOptions = [
  {
    id: 'other',
    key: 1,
    title: 'Otro',
    option: 'other'
  },
  {
    id: 'female',
    key: 2,
    title: 'Femenino',
    option: 'female'
  },
  {
    id: 'male',
    key: 3,
    title: 'Masculino',
    option: 'male'
  }
];
class StudentForm extends Component {
  constructor() {
    super();
    this.state = {
      years: createArrayYears(3, 2020, new Date().getFullYear()).map(
        (item, i) => ({
          key: i,
          id: item,
          title: item
        })
      )
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getUtilsEstablishments());
    dispatch(getStudentDescription());
  }

  componentWillReceiveProps(nextProps) {
    const {
      dispatch,
      student,
      ongoingRequest,
      setValues,
      values,
      redirect,
      onHide,
      data: { action }
    } = nextProps;
    if (redirect.state) {
      dispatch(setRedirect({ path: '', state: false }));
      onHide();
    }
    if (
      !ongoingRequest.getOneStudent &&
      action === 'edit' &&
      values.id === ''
    ) {
      const years = getYearStudent(student);
      const studentSet = setDataFormStudent(student, years);
      const yearSelected = this.getIndexYears();
      // console.log(studentSet);
      setValues(studentSet);
      const section = studentSet.sections_detail[yearSelected];
      const currentSection = studentSet.sections_detail.find(
        item => item.year === new Date().getFullYear()
      );

      if (section.establishment) {
        dispatch(
          getGrades({
            select: true,
            year: currentSection ? currentSection.year : section.year,
            establishmentId: currentSection
              ? currentSection.establishment
              : section.establishment
          })
        );
        dispatch(
          getSections({
            year: currentSection ? currentSection.year : section.year,
            establishmentId: currentSection
              ? currentSection.establishment
              : section.establishment,
            gradeId: currentSection ? currentSection.grade : section.grade
          })
        );
      }

      if (section.grade) {
        dispatch(
          getSections({
            year: currentSection ? currentSection.year : section.year,
            establishmentId: currentSection
              ? currentSection.establishment
              : section.establishment,
            gradeId: currentSection ? currentSection.grade : section.grade
          })
        );
      }

      this.setState({
        years
      });
    }
  }

  onChangeRut = value => {
    const { setFieldValue, values } = this.props;
    const formatRut = validateRut(value, values.rut);
    // console.log(chileanRut.validate(formatRut));
    setFieldValue('rut', formatRut);
  };

  onChangeSelects = (value, year, type) => {
    // console.log(value);
    const {
      dispatch,
      setFieldValue,
      // eslint-disable-next-line camelcase
      values: { sections_detail }
    } = this.props;
    const state = Number(value) !== 0;
    switch (type) {
      case 'year':
        // eslint-disable-next-line no-case-declarations
        const yearSelected = this.getIndexYears(value);
        setFieldValue('year', value);
        if (sections_detail[yearSelected].establishment) {
          if (sections_detail[yearSelected].grade) {
            dispatch(
              getGrades({
                select: true,
                year: value,
                establishment_id: sections_detail[yearSelected].establishment
              })
            );

            dispatch(
              getSections({
                year: value,
                establishmentId: sections_detail[yearSelected].establishment,
                gradeId: sections_detail[yearSelected].grade
              })
            );
          }
        }
        break;
      case 'establishment':
        if (state) {
          dispatch(
            getGrades({
              select: true,
              year: sections_detail[year].year,
              establishment_id: value.value
            })
          );
        }
        setFieldValue(`sections_detail[${year}].establishment`, value.value);
        setFieldValue(
          `sections_detail[${year}].establishmentName`,
          value.label
        );
        setFieldValue(`sections_detail[${year}].grade`, null);
        break;
      case 'grade':
        if (state) {
          dispatch(
            getSections({
              year: sections_detail[year].year,
              establishmentId: sections_detail[year].establishment,
              gradeId: value
            })
          );
        }
        setFieldValue(`sections_detail[${year}].grade`, value);
        setFieldValue(`sections_detail[${year}].section`, null);
        break;
      case 'section':
        setFieldValue(`sections_detail[${year}].section`, value);
        break;
      default:
        break;
    }
  };

  getIndexYears = (year = false) => {
    const { values } = this.props;
    const yearSelect = year || Number(values.year);
    const DateAux = values.sections_detail.findIndex(
      item => Number(item.year) === yearSelect
    );
    return DateAux !== -1 ? DateAux : 0;
  };

  stateDataselect = (data, onLoad, statePrevious) => {
    if (Number(statePrevious) === 0) {
      return [{ key: 0, title: 'Seleccione' }];
    }
    if (onLoad) {
      return [{ key: 0, title: 'Cargando..' }];
    }
    if (data.length === 1 && data[0].id === 0) {
      return [{ key: 0, title: 'Sin datos..' }];
    }
    return [{ key: null, title: 'Seleccione' }, ...data];
  };

  requestEstablishments = data => {
    const { dispatch } = this.props;
    dispatch(
      getUtilsEstablishments({
        ...data,
        display_start: 0,
        display_length: 25,
        sort_direction: 'asce',
        role: 2
      })
    );
  };

  render() {
    const {
      data,
      onHide,
      isValid,
      errors,
      touched,
      values,
      sections,
      onLoadUtils,
      grades,
      establishments,
      setFieldValue,
      studentDescription
    } = this.props;
    const { years } = this.state;
    const yearSelected = this.getIndexYears(values.year);

    const currentSection = sections.find(
      section => section.key === values.sections_detail[yearSelected].section
    );
    // const currentEstablishment = establishments.find(
    //   establishment =>
    //     establishment.key === values.sections_detail[yearSelected].establishment
    // );
    const currentGrade = grades.find(
      grade => grade.key === values.sections_detail[yearSelected].grade
    );
    return (
      <Form>
        <Row className="inputRow">
          <Col xs={12} md={6}>
            <Field
              name="names"
              render={({ field }) => (
                <BasicInput
                  {...field}
                  text={errors.names && touched.names && errors.names}
                  className={errors.names && touched.names ? 'is-invalid' : ''}
                  type="text"
                  title="Nombre"
                  placeholder="Escriba nombre"
                />
              )}
            />
          </Col>
          <Col xs={12} md={6}>
            <Field
              name="last_names"
              render={({ field }) => (
                <BasicInput
                  {...field}
                  text={
                    errors.last_names && touched.last_names && errors.last_names
                  }
                  className={
                    errors.last_names && touched.last_names ? 'is-invalid' : ''
                  }
                  type="text"
                  title="Apellido"
                  placeholder="Escriba apellido"
                />
              )}
            />
          </Col>
        </Row>
        <Row className="inputRow">
          <Col xs={12} md={6}>
            <Field
              name="rut"
              render={({ field }) => (
                <BasicInput
                  {...field}
                  className={errors.rut && touched.rut ? 'is-invalid' : ''}
                  text={errors.rut && touched.rut && errors.rut}
                  onChange={e => this.onChangeRut(e.target.value)}
                  type="text"
                  title="RUT"
                  placeholder="9.999.999-9"
                />
              )}
            />
          </Col>
          <Col xs={12} md={6}>
            <Field
              name="gender"
              render={() => (
                // <Select {...field} items={genderOptions} title="Genero" />
                <SelectAlt
                  data={genderOptions}
                  value={values.gender}
                  title="Genero"
                  onChangeSelect={value => setFieldValue('gender', value.value)}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="inputRow">
          <Col xs={12} md={12}>
            <Field
              name="description"
              render={() => (
                <SelectAlt
                  data={studentDescription}
                  value={values.description || 'no_description'}
                  title="Descripción"
                  onChangeSelect={value =>
                    setFieldValue('description', value.value)
                  }
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={5}>
            <Field
              name="active"
              render={({ field, form }) => (
                <CheckBox
                  {...field}
                  custom
                  controlId="asd"
                  onChange={e =>
                    form.setFieldValue('active', !e.target.checked)
                  }
                  label="Congelar alumno"
                  checked={!values.active}
                />
              )}
            />
          </Col>
        </Row>
        <hr />
        <Row className="inputRow">
          <Col>
            <Field
              name="year"
              render={() => (
                <SelectAlt
                  data={years}
                  value={values.year}
                  title="Año"
                  onChangeSelect={value => {
                    this.onChangeSelects(value.value, yearSelected, 'year');
                  }}
                />
              )}
            />
          </Col>
        </Row>

        <FieldArray
          name="sections_detail"
          render={() => (
            <div>
              <Row className="mb-4">
                <Col>
                  <Field
                    name={`sections_detail[${yearSelected}].establishment`}
                    render={field => (
                      <SelectAlt
                        {...field}
                        placeholder="Seleccione"
                        data={establishments}
                        initialValue={
                          values.sections_detail[yearSelected].establishment
                            ? {
                                value:
                                  values.sections_detail[yearSelected]
                                    .establishment,
                                label:
                                  values.sections_detail[yearSelected]
                                    .establishmentName
                              }
                            : { value: null, label: 'Seleccione' }
                        }
                        server
                        isSearchable
                        dispatchRequest={this.requestEstablishments}
                        onChangeSelect={e => {
                          this.onChangeSelects(
                            e,
                            yearSelected,
                            'establishment'
                          );
                        }}
                        onLoad={onLoadUtils.getUtilsEstablishments}
                        title="Establecimiento"
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <Field
                    name={`sections_detail[${yearSelected}].grade`}
                    render={() => (
                      <SelectAlt
                        title="Curso"
                        initialValue={
                          currentGrade
                            ? {
                                value: currentGrade.key,
                                label: currentGrade.title
                              }
                            : { value: null, label: 'Seleccione' }
                        }
                        server
                        dispatchRequest={() => true}
                        onChangeSelect={e =>
                          this.onChangeSelects(e.value, yearSelected, 'grade')
                        }
                        data={this.stateDataselect(
                          grades,
                          onLoadUtils.getGrades,
                          values.sections_detail[yearSelected].establishment
                        )}
                        disabled={
                          Number(
                            values.sections_detail[yearSelected].establishment
                          ) === 0
                        }
                      />
                    )}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Field
                    name={`sections_detail[${yearSelected}].section`}
                    render={() => (
                      <SelectAlt
                        title="Sección"
                        server
                        dispatchRequest={() => true}
                        initialValue={
                          currentSection
                            ? {
                                value: currentSection.key,
                                label: currentSection.title
                              }
                            : { value: null, label: 'Seleccione' }
                        }
                        onChangeSelect={e =>
                          this.onChangeSelects(e.value, yearSelected, 'section')
                        }
                        data={this.stateDataselect(
                          sections,
                          onLoadUtils.getSections,
                          values.sections_detail[yearSelected].grade
                        )}
                        disabled={
                          Number(values.sections_detail[yearSelected].grade) ===
                          0
                        }
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
          )}
        />
        <Row>
          <Col md={{ span: 3, offset: 6 }}>
            <Button onClick={onHide} block variant="light">
              Cancelar
            </Button>
          </Col>
          <Col md={3}>
            <Button type="submit" disabled={!isValid} block>
              {data.create ? 'Crear' : 'Guardar'}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const {
    establishments,
    ongoingRequest: onLoadUtils,
    grades,
    sections
  } = state.utils;
  const {
    ongoingRequest,
    oneStudent: student,
    redirect,
    studentDescription
  } = state.student;
  return {
    ongoingRequest,
    student,
    establishments,
    onLoadUtils,
    grades,
    sections,
    redirect,
    studentDescription
  };
};

export default connect(mapStateToProps)(
  withFormik({
    mapPropsToValues() {
      const currentYear = new Date().getFullYear();
      const years = createArrayYears(3, currentYear);
      return {
        id: '',
        names: '',
        last_names: '',
        rut: '',
        gender: '',
        active: true,
        description: 0,
        year: 0,
        establishment: '',
        establishment_id: 0,
        sections_detail: years.map((item, i) => ({
          yearSelected: i,
          year: item,
          establishment: 0,
          grade: 0,
          section: 0
        }))
      };
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      names: Yup.string()
        .min(3, 'Mínimo 3 caracteres')
        .required('Campo requerido'),
      last_names: Yup.string().required('Campo requerido'),
      rut: Yup.string()
        .min(9, 'Rut inválido')
        .required('Rut es un campo requerido'),

      sections_detail: Yup.array().of(
        Yup.object().shape({
          section: Yup.string().required('Required') // these constraints take precedence
        })
      )
    }),
    handleSubmit(values, { props }) {
      const { dispatch, data } = props;
      if (data.create) {
        dispatch(createStudent({ student: setDataStudentRequest(values) }));
      } else {
        dispatch(updateStudent({ student: setDataStudentRequest(values) }));
      }
    }
  })(StudentForm)
);
