const dataNav = [
  {
    title: 'Dashboard',
    type: 'item',
    path: '/dashboard',
    key: '0',
    action: 'read',
    subject: 'Page'
  },
  {
    title: 'Establecimientos ',
    type: 'collapse',
    path: '/',
    key: '2',
    action: 'read',
    subject: 'Establishment',
    data: [
      {
        title: 'Establecimientos y Cursos',
        path: '/establishment-and-courses',
        key: '20'
      },
      {
        title: 'Profesores',
        path: '/teachers',
        key: '21'
      }
    ]
  },
  {
    title: 'Evaluaciones',
    type: 'collapse',
    path: '/new-tests',
    action: 'read',
    subject: 'Quiz',
    data: [
      {
        title: 'Tipos de evaluación',
        path: '/guidelines',
        key: '31'
      },
      {
        title: 'Evaluaciones',
        path: '/tests',
        key: '30'
      }
    ],
    key: '3'
  },
  {
    title: 'Estudiantes',
    type: 'item',
    path: '/students',
    key: '4',
    action: 'read',
    subject: 'Student'
  },
  {
    title: 'Ingreso Puntajes',
    type: 'item',
    path: '/score',
    key: '5',
    action: 'update',
    subject: 'QuizReview'
  },
  {
    title: 'Estadísticas',
    type: 'item',
    path: '/reports',
    key: '7'
  },
  {
    title: 'Usuarios',
    type: 'item',
    path: '/users',
    key: '9',
    action: 'update',
    subject: 'User'
  },
  {
    title: 'Tutoriales',
    type: 'item',
    path: '/tutorials',
    key: '10'
  },
  {
    title: 'Mi Perfil',
    type: 'item',
    path: '/profile',
    key: '11',
    action: 'read',
    subject: 'Page'
  }
];

export default dataNav;
