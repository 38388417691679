import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VerticalNav } from '../Nav/index';
import { MainLogoWhite } from '../MainLogo/index';
import dataNav from './data';
import { history } from '../../config/store';

class NavbarLeft extends Component {
  componentDidMount() {
    const { signedIn } = this.props;
    if (!signedIn) {
      history.push('/login');
    }
  }

  componentDidUpdate() {
    const { signedIn } = this.props;
    if (!signedIn) {
      history.push('/login');
    }
  }

  render() {
    const { user } = this.props;

    return (
      <div className="sideNavbar sidebar-sticky">
        <div className="nav-logo">
          <MainLogoWhite />
        </div>
        <VerticalNav history={history} role={user.role} items={dataNav} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { signedIn, user } = state.auth;
  return {
    signedIn,
    user
  };
};
export default connect(mapStateToProps)(NavbarLeft);
