import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  DefaultButton,
  BasicInput,
  Paragraph,
  Title,
  LoadSpinner
} from '../../components';
import { requestSignIn } from '../../actions/auth';
import '../../assets/stylesheets/styles.scss';

const inicioImg = require('../../assets/images/Inicio.png');

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: ''
    };
  }

  handleSingIn = () => {
    const { email, password } = this.state;
    if (email === '') {
      alert('Ingrese email');
    }
    if (password === '') {
      alert('Ingrese contraseña');
    } else {
      this.signInAsync(email, password);
    }
  };

  signInAsync = async (email, password) => {
    const { dispatch } = this.props;
    dispatch(requestSignIn({ user: { email, password } }));
  };

  componentWillMount = () => {
    const { signedIn, history } = this.props;
    if (signedIn) {
      history.push('/dashboard');
    }
  };

  onFormSubmit = e => {
    e.preventDefault();
    this.handleSingIn();
  };

  render() {
    const { email, password } = this.state;
    const { history, ongoingRequest } = this.props;
    return (
      <div>
        {ongoingRequest.signIn && <LoadSpinner />}
        <Container fluid>
          <Row>
            <Col xs={6} className="bannerLogin flexFullHeight">
              <Image src={inicioImg} alt="Fundacion Crecer" />
            </Col>
            <Col xs={6} className="flexFullHeight">
              <div style={{ width: '300px' }}>
                <form onSubmit={this.onFormSubmit}>
                  <Title
                    title="INICIAR SESIÓN"
                    style={{ textAlign: 'center' }}
                    className="text-primary"
                  />
                  <Paragraph
                    text="Programa PRIMERO LEE - Fundación Crecer"
                    style={{ textAlign: 'center' }}
                  />
                  <BasicInput
                    placeholder="Correo"
                    size="md"
                    styleClass="borderInput"
                    value={email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                  <BasicInput
                    placeholder="Contraseña"
                    size="md"
                    styleClass="borderInput"
                    type="password"
                    value={password}
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                  <DefaultButton
                    text="Olvidé mi contraseña"
                    variant="link"
                    style={{ textAlign: 'right' }}
                    onClick={() => history.push('/password/forgot')}
                    block
                  />
                  <center>
                    <DefaultButton
                      text="Ingresar"
                      size="md"
                      type="submit"
                      style={{ width: '140px', marginTop: '30px' }}
                    />
                  </center>
                </form>
                <div style={{ position: 'absolute', bottom: '0' }}>
                  <DefaultButton
                    text="Términos de uso"
                    variant="link"
                    style={{ color: 'gray' }}
                  />
                  <DefaultButton
                    text="Politicas de Privacidad"
                    variant="link"
                    style={{ color: 'gray' }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

export default withRouter(connect(mapStateToProps)(Login));
