import React, { Component } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import SelectAlt from '../Input/SelectAlt';
import {
  getAvailableYear,
  getAvailableGrade,
  getAvailableGuideline,
  getAvailableQuiz,
  getAvailableRegion,
  getAvailableCommune,
  getAvailableEstablishment,
  getAvailableSection,
  getReport
} from '../../actions/utils';

class Report extends Component {
  constructor() {
    super();
    this.state = {
      region: null,
      commune: 0,
      establishment: 0,
      year: 0,
      grade: 0,
      section: 0,
      quiz: 0,
      guideline: 0,
      state: false,
      yearChange: false,
      regionChange: false,
      communeChange: false,
      establishmentChange: false,
      sectionChange: false
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAvailableYear());
  }

  componentDidUpdate() {
    const {
      availableYears,
      availableRegions,
      availableCommunes,
      availableEstablishments,
      availableSections
    } = this.props;
    const {
      yearChange,
      regionChange,
      communeChange,
      establishmentChange,
      sectionChange
    } = this.state;
    if (!yearChange && availableYears.length > 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ yearChange: true });
      this.onChangeSelects(availableYears[0].key, 'Year');
    } else if (!regionChange && availableRegions.length > 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ regionChange: true });
      this.onChangeSelects(availableRegions[0].key, 'Region');
    } else if (!communeChange && availableCommunes.length > 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ communeChange: true });
      this.onChangeSelects(availableCommunes[0].key, 'Commune');
    } else if (!establishmentChange && availableEstablishments.length > 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ establishmentChange: true });
      this.onChangeSelects(availableCommunes[0].key, 'Establishment');
    } else if (!sectionChange && availableSections.length > 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ sectionChange: true });
      this.onChangeSelects(availableSections[0].key, 'Section');
    }
  }

  onChangeSelects = (value, type, title = false) => {
    const { dispatch } = this.props;
    const { year, grade, quiz } = this.state;
    const state = Number(value) !== 0;
    switch (type) {
      case 'Year':
        if (state) {
          dispatch(getAvailableGrade({ at_least_one_review_in_year: value }));
        }
        this.setState({
          region: 0,
          commune: 0,
          establishment: 0,
          year: state ? value : 0,
          grade: 0,
          section: 0,
          quiz: 0,
          guideline: 0,
          state: false
        });
        break;
      case 'Grade':
        if (state) {
          dispatch(
            getAvailableGuideline({
              at_least_one_review_with_grade: value,
              at_least_one_review_in_year: year
            })
          );
        }
        this.setState({
          region: 0,
          commune: 0,
          establishment: 0,
          grade: state ? value : 0,
          section: 0,
          quiz: 0,
          guideline: 0,
          state: false
        });
        break;
      case 'Guideline':
        if (state) {
          dispatch(
            getAvailableQuiz({
              at_least_one_review_with_grade: grade,
              at_least_one_review_in_year: year,
              at_least_one_review_with_guideline: value
            })
          );
        }
        this.setState({
          region: 0,
          commune: 0,
          establishment: 0,
          section: 0,
          quiz: 0,
          guideline: state ? value : 0,
          state: false
        });
        break;
      case 'Quiz':
        if (state) {
          dispatch(getAvailableRegion({ at_least_one_quiz_review_for: value }));
        }
        this.setState({
          region: 0,
          commune: 0,
          establishment: 0,
          section: 0,
          quiz: state ? value : 0,
          nameQuiz: title,
          state: true
        });
        break;
      case 'Region':
        if (state) {
          dispatch(
            getAvailableCommune({
              at_least_one_quiz_review_for: quiz,
              region_id: value
            })
          );
        }
        this.setState({
          region: state ? value : 0,
          commune: 0,
          establishment: 0,
          section: 0,
          state: true
        });
        break;
      case 'Commune':
        if (state) {
          dispatch(
            getAvailableEstablishment({
              at_least_one_quiz_review_for: quiz,
              commune_id: value
            })
          );
        }
        this.setState({
          commune: state ? value : 0,
          establishment: 0,
          section: 0,
          state: true
        });
        break;
      case 'Establishment':
        if (state) {
          dispatch(
            getAvailableSection({
              at_least_one_quiz_review_for: quiz,
              establishment_id: value
            })
          );
        }
        this.setState({
          section: 0,
          establishment: state ? value : 0,
          state: true
        });
        break;
      case 'Section':
        this.setState({
          section: state ? value : 0,
          state: true
        });
        break;
      default:
        break;
    }
  };

  resetAll = (ongoingRequest, arr, value) => {
    if (ongoingRequest) {
      return [{ key: 0, title: 'Cargando..' }];
    }
    if (value === 0) {
      return [{ key: null, id: null, title: 'Todos' }];
    }
    return arr;
  };

  generateReport = () => {
    const { dispatch } = this.props;
    const {
      quiz,
      region,
      commune,
      establishment,
      section,
      year,
      nameQuiz
    } = this.state;
    dispatch(
      getReport({
        quiz,
        report: {
          region_id: region,
          commune_id: commune,
          establishment_id: establishment,
          section_id: section
        },
        year,
        nameQuiz,
        file: true
      })
    );
  };

  render() {
    const {
      onHide,
      ongoingRequest,
      availableYears,
      availableGrades,
      availableGuidelines,
      availableQuizzes,
      availableRegions,
      availableCommunes,
      availableEstablishments,
      availableSections
    } = this.props;
    const {
      state,
      region,
      commune,
      establishment,
      year,
      grade,
      quiz,
      guideline
    } = this.state;
    return (
      <Container>
        <Row style={{ marginBottom: '20px' }}>
          <Col>
            <SelectAlt
              data={
                ongoingRequest.getAvailableYear
                  ? [{ key: 0, title: 'Cargando..' }]
                  : availableYears
              }
              value={availableYears[0] ? availableYears[0].id : 0}
              onChangeSelect={e => this.onChangeSelects(e.value, 'Year')}
              title="Año *"
            />
          </Col>
          <Col>
            <SelectAlt
              data={
                ongoingRequest.getAvailableGrade
                  ? [{ key: 0, title: 'Cargando..' }]
                  : availableGrades
              }
              disabled={!year}
              onChangeSelect={e => this.onChangeSelects(e.value, 'Grade')}
              title="Nivel *"
            />
          </Col>
          <Col>
            <SelectAlt
              data={
                ongoingRequest.getAvailableGuideline
                  ? [{ key: 0, title: 'Cargando..' }]
                  : availableGuidelines
              }
              value={
                grade !== 0 ? guideline : [{ key: 0, title: 'Cargando..' }]
              }
              disabled={!grade}
              onChangeSelect={e => this.onChangeSelects(e.value, 'Guideline')}
              title="Tipo de Evaluación *"
            />
          </Col>
          <Col>
            <SelectAlt
              data={
                ongoingRequest.getAvailablequiz
                  ? [{ key: 0, title: 'Cargando..' }]
                  : availableQuizzes
              }
              value={guideline !== 0 ? quiz : [{ key: 0, title: 'Cargando..' }]}
              disabled={!guideline}
              onChangeSelect={e =>
                this.onChangeSelects(e.value, 'Quiz', e.label)
              }
              title="Evaluaciones *"
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '20px' }}>
          <Col>
            <SelectAlt
              data={this.resetAll(
                ongoingRequest.getAvailableRegion,
                availableRegions,
                quiz
              )}
              value={availableRegions[0] ? availableRegions[0].key : 0}
              placeholder="Todos"
              disabled={!quiz}
              onChangeSelect={e => this.onChangeSelects(e.value, 'Region')}
              title="Region"
            />
          </Col>
          <Col>
            <SelectAlt
              data={this.resetAll(
                ongoingRequest.getAvailableCommune,
                availableCommunes,
                region
              )}
              value={availableCommunes[0] ? availableCommunes[0].key : 0}
              disabled={!quiz}
              onChangeSelect={e => this.onChangeSelects(e.value, 'Commune')}
              title="Comuna"
            />
          </Col>
          <Col>
            <SelectAlt
              data={this.resetAll(
                ongoingRequest.getAvailableEstablishment,
                availableEstablishments,
                commune
              )}
              value={
                availableEstablishments[0] ? availableEstablishments[0].key : 0
              }
              disabled={!quiz}
              onChangeSelect={e =>
                this.onChangeSelects(e.value, 'Establishment')
              }
              title="Establecimiento"
            />
          </Col>
          <Col>
            <SelectAlt
              data={this.resetAll(
                ongoingRequest.getAvailableSection,
                availableSections,
                establishment
              )}
              value={availableSections[0] ? availableSections[0].key : 0}
              disabled={!quiz}
              onChangeSelect={e => this.onChangeSelects(e.value, 'Section')}
              title="Letra"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={{ span: 3, offset: 4 }}>
            <Button onClick={onHide} block variant="light">
              Cancelar
            </Button>
          </Col>
          <Col md={5}>
            <Button
              type="submit"
              title="Generar Reporte"
              disabled={!state}
              block
              onClick={() => this.generateReport()}
            >
              Generar Reporte
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const {
    ongoingRequest,
    regions,
    communes,
    establishments,
    grades,
    sections,
    studentsTransition: students,
    quizzes,
    yearsEstablishment,
    availableYears,
    availableGrades,
    availableGuidelines,
    availableQuizzes,
    availableRegions,
    availableCommunes,
    availableEstablishments,
    availableSections
  } = state.utils;
  return {
    regions,
    communes,
    ongoingRequest,
    grades,
    establishments,
    sections,
    students,
    yearsEstablishment,
    quizzes,
    availableYears,
    availableGrades,
    availableGuidelines,
    availableQuizzes,
    availableRegions,
    availableCommunes,
    availableEstablishments,
    availableSections
  };
};

export default connect(mapStateToProps)(Report);
