import { takeEvery, put } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
import {
  GET_YEAR_CHART_REQUEST,
  GET_YEAR_CHART_SUCCESS,
  GET_YEAR_CHART_FAILURE,
  GET_REGION_CHART_REQUEST,
  GET_REGION_CHART_SUCCESS,
  GET_REGION_CHART_FAILURE,
  GET_COMMUNE_CHART_REQUEST,
  GET_COMMUNE_CHART_SUCCESS,
  GET_COMMUNE_CHART_FAILURE,
  GET_ESTABLISHMENT_CHART_REQUEST,
  GET_ESTABLISHMENT_CHART_SUCCESS,
  GET_ESTABLISHMENT_CHART_FAILURE,
  GET_GRADE_CHART_REQUEST,
  GET_GRADE_CHART_SUCCESS,
  GET_GRADE_CHART_FAILURE,
  GET_SECTION_CHART_REQUEST,
  GET_SECTION_CHART_SUCCESS,
  GET_SECTION_CHART_FAILURE,
  GET_QUIZ_CHART_REQUEST,
  GET_QUIZ_CHART_SUCCESS,
  GET_QUIZ_CHART_FAILURE,
  GET_UNIT_CHART_REQUEST,
  GET_UNIT_CHART_SUCCESS,
  GET_UNIT_CHART_FAILURE,
  CREATE_DOCUMENT_REQUEST,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_FAILURE,
  GENERATE_DOCUMENT_REQUEST,
  GENERATE_DOCUMENT_SUCCESS,
  GENERATE_DOCUMENT_FAILURE,
  GET_DOCUMENTS_REQUEST,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAILURE,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE,
  CHECK_DOCUMENT_REQUEST,
  CHECK_DOCUMENT_SUCCESS,
  CHECK_DOCUMENT_FAILURE,
  SHOW_DOCUMENT_REQUEST,
  SHOW_DOCUMENT_SUCCESS,
  SHOW_DOCUMENT_FAILURE,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  SET_REDIRECT,
  DOWNLOAD_DOCUMENT_REQUEST,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DOWNLOAD_DOCUMENT_FAILURE
} from '../actions/reports';
import API from '../services/api';
import { SET_NOTICE } from '../actions/alert';

import runDefaultSaga from './default';
// import { serializeJsonToString } from '../services/utils';

// GET YEARS

const getYearChartsRequest = () => {
  return API.get(`/quiz_reviews/available_years`);
};

function* getYearChartsRequestSuccessCallback(result) {
  yield put({
    type: GET_YEAR_CHART_SUCCESS,
    result
  });
}

function* getYearChartsRequestFailureCallback() {
  yield put({
    type: GET_YEAR_CHART_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getChartsYear() {
  yield runDefaultSaga(
    { request: getYearChartsRequest },
    getYearChartsRequestSuccessCallback,
    getYearChartsRequestFailureCallback
  );
}

// GET REGION

const getRegionChartsRequest = data => {
  return API.get(`/regions`, data);
};

function* getRegionChartsRequestSuccessCallback(result) {
  yield put({
    type: GET_REGION_CHART_SUCCESS,
    result
  });
}

function* getRegionChartsRequestFailureCallback() {
  yield put({
    type: GET_REGION_CHART_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getRegionCharts(action) {
  yield runDefaultSaga(
    { request: getRegionChartsRequest, params: action.data },
    getRegionChartsRequestSuccessCallback,
    getRegionChartsRequestFailureCallback
  );
}

// GET COMMUNE

const getCommuneChartsRequest = data => {
  return API.get(`/communes`, data);
};

function* getCommuneChartsRequestSuccessCallback(result) {
  yield put({
    type: GET_COMMUNE_CHART_SUCCESS,
    result
  });
}

function* getCommuneChartsRequestFailureCallback() {
  yield put({
    type: GET_COMMUNE_CHART_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getCommuneCharts(action) {
  yield runDefaultSaga(
    { request: getCommuneChartsRequest, params: action.data },
    getCommuneChartsRequestSuccessCallback,
    getCommuneChartsRequestFailureCallback
  );
}

// GET AVAILABLE ESTABLISHMENT

const getEstablishmentChartsRequest = data => {
  return API.get(`/establishments`, data);
};

function* getEstablishmentChartsSuccessCallback(result) {
  yield put({
    type: GET_ESTABLISHMENT_CHART_SUCCESS,
    result
  });
}

function* getEstablishmentChartsFailureCallback() {
  yield put({
    type: GET_ESTABLISHMENT_CHART_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getEstablishmentCharts(action) {
  yield runDefaultSaga(
    { request: getEstablishmentChartsRequest, params: action.data },
    getEstablishmentChartsSuccessCallback,
    getEstablishmentChartsFailureCallback
  );
}

// GET AVAILABLE GRADE

const getGradeChartsRequest = data => {
  return API.get(`/grades`, data);
};

function* getGradeChartsSuccessCallback(result) {
  yield put({
    type: GET_GRADE_CHART_SUCCESS,
    result
  });
}

function* getGradeChartsFailureCallback() {
  yield put({
    type: GET_GRADE_CHART_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getGradeCharts(action) {
  yield runDefaultSaga(
    { request: getGradeChartsRequest, params: action.data },
    getGradeChartsSuccessCallback,
    getGradeChartsFailureCallback
  );
}

// GET SECTION

const getSectionChartsRequest = data => {
  return API.get(`/sections`, data);
};

function* getSectionChartsSuccessCallback(result) {
  yield put({
    type: GET_SECTION_CHART_SUCCESS,
    result
  });
}

function* getSectionChartsFailureCallback() {
  yield put({
    type: GET_SECTION_CHART_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getSectionCharts(action) {
  yield runDefaultSaga(
    { request: getSectionChartsRequest, params: action.data },
    getSectionChartsSuccessCallback,
    getSectionChartsFailureCallback
  );
}

// GET QUIZ

const getQuizChartsRequest = data => {
  return API.get(`/quizzes`, data);
};

function* getQuizChartsSuccessCallback(result) {
  yield put({
    type: GET_QUIZ_CHART_SUCCESS,
    result
  });
}

function* getQuizChartsFailureCallback() {
  yield put({
    type: GET_QUIZ_CHART_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}

const getUnitChartsRequest = data => {
  return API.get('/units', data.params);
};

function* getUnitChartsSuccessCallback(result) {
  yield put({
    type: GET_UNIT_CHART_SUCCESS,
    result
  });
}

function* getUnitChartsFailureCallback() {
  yield put({
    type: GET_UNIT_CHART_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getQuizCharts(action) {
  yield runDefaultSaga(
    { request: getQuizChartsRequest, params: action.data },
    getQuizChartsSuccessCallback,
    getQuizChartsFailureCallback
  );
}

function* getUnitCharts(action) {
  yield runDefaultSaga(
    { request: getUnitChartsRequest, params: action.data },
    getUnitChartsSuccessCallback,
    getUnitChartsFailureCallback
  );
}

// ---- CHECK IF DOCUMENT EXIST --------

const checkDocumentRequest = data => {
  return API.get('/documents', data.params);
};

function* checkDocumentSuccessCallback(result, _, params) {
  const { data } = params;
  if (result.data[0]) {
    yield put({
      type: SHOW_DOCUMENT_REQUEST,
      id: result.data[0].id
    });
  } else {
    yield put({
      type: CREATE_DOCUMENT_REQUEST,
      data
    });
  }
  yield put({
    type: CHECK_DOCUMENT_SUCCESS,
    result
  });
}
function* checkDocumentFailureCallback() {
  yield put({ type: CHECK_DOCUMENT_FAILURE });
}
function* checkDocument(action) {
  yield runDefaultSaga(
    { request: checkDocumentRequest, params: action.data },
    checkDocumentSuccessCallback,
    checkDocumentFailureCallback
  );
}
// ------- CREATE DOCUMENT --------------

const createDocumentRequest = data => {
  return API.post('/documents', data);
};

function* createDocumentSuccessCallback(result) {
  yield put({
    type: CREATE_DOCUMENT_SUCCESS,
    result
  });
}
function* createDocumentFailureCallback() {
  yield put({ type: CREATE_DOCUMENT_FAILURE });
}
function* createDocument(action) {
  yield runDefaultSaga(
    { request: createDocumentRequest, params: { document: action.data } },
    createDocumentSuccessCallback,
    createDocumentFailureCallback
  );
}

// ------- SHOW ONE DOCUMENTS  ----------------

const showDocumentRequest = params => {
  return API.get(`/documents/${params.id}`);
};

function* showDocumentSuccessCallback(result) {
  yield put({
    type: SHOW_DOCUMENT_SUCCESS,
    result
  });
}

function* showDocumentFailureCallback() {
  yield put({
    type: SHOW_DOCUMENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* showDocument(action) {
  yield runDefaultSaga(
    { request: showDocumentRequest, params: action, timeout: 500 * 1000 },
    showDocumentSuccessCallback,
    showDocumentFailureCallback
  );
}

// ------- GET DOCUMENTS DATA TABLE ----------------

const getDocumentsRequest = data => {
  return API.get(`/documents`, data);
};

function* getDocumentsSuccessCallback(result) {
  yield put({
    type: GET_DOCUMENTS_SUCCESS,
    result
  });
}

function* getDocumentsFailureCallback() {
  yield put({
    type: GET_DOCUMENTS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getDocuments(action) {
  yield runDefaultSaga(
    { request: getDocumentsRequest, params: action.data },
    getDocumentsSuccessCallback,
    getDocumentsFailureCallback
  );
}

// ------------  GENERATE DOCUMENT-------------

const generateDocumentRequest = data => {
  const extension = data.extension === 'pdf' ? 'pdf' : 'docx';

  return API.post(`/documents/${data.id}/generate_document.${extension}`, {
    document: data.document
  });
};

function* generateDocumentSuccessCallback(result, _, params) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({
      type: GENERATE_DOCUMENT_SUCCESS,
      document: result,
      params
    });
  }
}

function* generateDocumentFailureCallback() {
  yield put({
    type: GENERATE_DOCUMENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* generateDocument(action) {
  yield runDefaultSaga(
    {
      request: generateDocumentRequest,
      params: action.data,
      timeout: 500 * 1000
    },
    generateDocumentSuccessCallback,
    generateDocumentFailureCallback
  );
}

// ------ DELETE DOCUMENT --------

const deleteDocumentRequest = data => {
  return API.delete(`/documents/${data.id}`);
};

function* deleteDocumentSuccessCallback(result) {
  yield put({
    type: DELETE_DOCUMENT_SUCCESS,
    result
  });
  yield put({
    type: SET_NOTICE,
    title: 'Exito',
    message: 'El informe se ha borrado exitosamente',
    kind: 'success',
    timeout: 2000
  });
  yield put({
    type: GET_DOCUMENTS_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10,
      sort_direction: 'desc'
    }
  });
}

function* deleteDocumentFailureCallback() {
  yield put({
    type: DELETE_DOCUMENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* deleteDocument(action) {
  yield runDefaultSaga(
    { request: deleteDocumentRequest, params: action.data },
    deleteDocumentSuccessCallback,
    deleteDocumentFailureCallback
  );
}

// ------------------- UPLOAD DOCUMENT ---------------------

const uploadDocumentRequest = data => {
  return API.patch(`/documents/${data.id}/load_document`, data.body, true);
};

function* uploadDocumentSuccessCallback(result) {
  yield put({
    type: UPLOAD_DOCUMENT_SUCCESS,
    result
  });
  yield put({
    type: SET_REDIRECT,
    data: { path: '/reports', state: true }
  });
  yield put({
    type: SET_NOTICE,
    title: 'Exito',
    message: 'Documento cargado con éxito',
    kind: 'success',
    timeout: 2000
  });
  yield put({
    type: GET_DOCUMENTS_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10
    }
  });
}
function* uploadDocumentFailureCallback() {
  yield put({ type: UPLOAD_DOCUMENT_FAILURE });
}
function* uploadDocument(action) {
  yield runDefaultSaga(
    { request: uploadDocumentRequest, params: action.data },
    uploadDocumentSuccessCallback,
    uploadDocumentFailureCallback
  );
}

// ---------------- DOWNLOAD DOCUMENT ---------------

const downloadDocumentRequest = data => {
  return API.post(`/documents/${data.id}/download_document.docx`);
};

function* downloadDocumentSuccessCallback(result, _, params) {
  yield put({
    type: DOWNLOAD_DOCUMENT_SUCCESS,
    document: result,
    params
  });
  yield put({
    type: SET_NOTICE,
    title: 'Exito',
    message: `${params.name} descargado con éxito`,
    kind: 'success',
    timeout: 2000
  });
}
function* downloadDocumentFailureCallback() {
  yield put({ type: DOWNLOAD_DOCUMENT_FAILURE });
}
function* downloadDocument(action) {
  yield runDefaultSaga(
    { request: downloadDocumentRequest, params: action.data },
    downloadDocumentSuccessCallback,
    downloadDocumentFailureCallback
  );
}

// DEFINE utilsSagas
export default function* utilsSagas() {
  yield takeEvery(GET_YEAR_CHART_REQUEST, getChartsYear);
  yield takeEvery(GET_REGION_CHART_REQUEST, getRegionCharts);
  yield takeEvery(GET_COMMUNE_CHART_REQUEST, getCommuneCharts);
  yield takeEvery(GET_ESTABLISHMENT_CHART_REQUEST, getEstablishmentCharts);
  yield takeEvery(GET_GRADE_CHART_REQUEST, getGradeCharts);
  yield takeEvery(GET_SECTION_CHART_REQUEST, getSectionCharts);
  yield takeEvery(GET_QUIZ_CHART_REQUEST, getQuizCharts);
  yield takeEvery(GET_UNIT_CHART_REQUEST, getUnitCharts);
  yield takeEvery(CHECK_DOCUMENT_REQUEST, checkDocument);
  yield takeEvery(SHOW_DOCUMENT_REQUEST, showDocument);

  yield takeEvery(CREATE_DOCUMENT_REQUEST, createDocument);
  yield takeEvery(GENERATE_DOCUMENT_REQUEST, generateDocument);
  yield takeEvery(GET_DOCUMENTS_REQUEST, getDocuments);
  yield takeEvery(DELETE_DOCUMENT_REQUEST, deleteDocument);
  yield takeEvery(UPLOAD_DOCUMENT_REQUEST, uploadDocument);
  yield takeEvery(DOWNLOAD_DOCUMENT_REQUEST, downloadDocument);
}
