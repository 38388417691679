import jsonToFormData from 'json-form-data';
import FileSaver from 'file-saver';
import API_CONFIG from '../config/configurations';
import { serializeJsonToString } from './utils';

const { url } = API_CONFIG;

const headers = formData => {
  if (localStorage.jwt) {
    if (formData) {
      return {
        Authorization: `Bearer ${localStorage.jwt}`
      };
    }
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.jwt}`
    };
  }
  return {
    'Content-Type': 'application/json'
  };
};

const headersFile = formData => {
  if (localStorage.jwt) {
    if (formData) {
      return {
        'Content-Type': 'application/vnd.ms-excel',
        Authorization: `Bearer ${localStorage.jwt}`
      };
    }
    return {
      'Content-Type': 'application/vnd.ms-excel',
      Authorization: `Bearer ${localStorage.jwt}`
    };
  }
  return {
    'Content-Type': 'application/vnd.ms-excel'
  };
};

const bodyToFetch = (params, formData) => {
  if (formData) {
    return jsonToFormData(params, { showLeafArrayIndexes: false });
  }
  return JSON.stringify(params);
};

const getFilenameFromHeader = header => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(header);

  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }

  return null;
};

export default class API {
  static genericErrorMessage(status) {
    return status === 404 ? 'Recurso no encontrado' : 'Intentelo más tarde';
  }

  static get(route, params = {}, formData = false) {
    const paramsUrl = serializeJsonToString(params);
    const paramsGet = paramsUrl !== '' ? `?${paramsUrl}` : '';
    return fetch(`${url()}${route}${paramsGet}`, {
      method: 'GET',
      headers: headers(formData)
    });
  }

  static fileProcess(response) {
    return response.then(_response => {
      return _response
        .clone()
        .blob()
        .then(blob => {
          let filename = 'plantilla_importacion.xlsx';
          const header = _response.headers.get('Content-Disposition');
          try {
            const filenameFromHeader = getFilenameFromHeader(header);

            if (filenameFromHeader) filename = filenameFromHeader;
          } catch {
            //
          }

          FileSaver.saveAs(blob, filename);
        });
    });
  }

  static getFile(route, params = {}, formData = false) {
    const paramsUrl = serializeJsonToString(params);
    const paramsGet = paramsUrl !== '' ? `?${paramsUrl}` : '';
    const response = fetch(`${url()}${route}${paramsGet}`, {
      method: 'GET',
      headers: headersFile(formData)
    });
    this.fileProcess(response);
    return response;
  }

  static post(route, params = {}, formData = false) {
    return fetch(url() + route, {
      method: 'POST',
      cache: 'no-cache',
      body: bodyToFetch(params, formData),
      headers: headers(formData)
    });
  }

  static put(route, params = {}, formData = false) {
    return fetch(url() + route, {
      method: 'PUT',
      cache: 'no-cache',
      body: bodyToFetch(params, formData),
      headers: headers(formData)
    });
  }

  static patch(route, params = {}, formData = false) {
    return fetch(url() + route, {
      method: 'PATCH',
      cache: 'no-cache',
      body: bodyToFetch(params, formData),
      headers: headers(formData)
    });
  }

  static delete(route, params = {}, formData = false) {
    return fetch(url() + route, {
      method: 'DELETE',
      body: bodyToFetch(params, formData),
      headers: headers(formData)
    });
  }
}
