import React, { PureComponent } from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value.length >= 15
          ? `${payload.value.slice(0, 15)}...`
          : payload.value.slice(0, 15)}
      </text>
    </g>
  );
};

const renderLegend = (payload, styles2, styleChart = []) => {
  const custom = styles2 || [];
  const custom2 = custom.map(e => {
    return { value: e.key, type: e.type, color: e.colour };
  });
  const styleChart2 = styleChart.map(chartX => {
    return chartX.map(e => {
      return { value: e.key, type: e.type, color: e.colour };
    });
  });
  if (styleChart2[0]) {
    return (
      <div className="row">
        {styleChart2.map(chartX => {
          return (
            <div className="column">
              <Legend
                wrapperStyle={{
                  position: 'relative',
                  left: 100,
                  top: 20,
                  whiteSpace: 'nowrap'
                }}
                payload={chartX.reverse()}
                layout="vertical"
              />
            </div>
          );
        })}
        <div className="column">
          <Legend
            wrapperStyle={{
              position: 'relative',
              left: 400,
              top: 20,
              whiteSpace: 'nowrap'
            }}
            payload={custom2}
            layout="vertical"
          />
        </div>
      </div>
    );
  }
  return (
    <div className="row">
      <div className="column">
        <Legend
          wrapperStyle={{
            position: 'relative',
            left: 100,
            top: 20,
            whiteSpace: 'nowrap'
          }}
          payload={payload.reverse()}
          layout="vertical"
        />
      </div>
      <div className="column">
        <Legend
          wrapperStyle={{
            position: 'relative',
            left: 400,
            top: 20,
            whiteSpace: 'nowrap'
          }}
          payload={custom2}
          layout="vertical"
        />
      </div>
    </div>
  );
};

export default class ChartStackedBar extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/90v76x08/';

  render() {
    const {
      dataChart,
      children,
      ticks,
      name,
      styles2,
      styleChart
    } = this.props;

    return (
      <BarChart
        width={1000}
        height={563}
        data={dataChart}
        margin={{
          top: 50
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          interval={0}
          tick={<CustomizedAxisTick />}
          height={name}
        />
        <YAxis unit="%" ticks={ticks} />
        <Tooltip />
        <Legend
          content={({ payload }) => renderLegend(payload, styles2, styleChart)}
        />
        {children}
      </BarChart>
    );
  }
}
