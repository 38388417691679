import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SET_REDIRECT,
  GET_DIRECTORS_REQUEST,
  GET_DIRECTORS_SUCCESS,
  GET_DIRECTORS_FAILURE,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  GET_COMMUNAL_COORDINATORS_REQUEST,
  GET_COMMUNAL_COORDINATORS_SUCCESS,
  GET_COMMUNAL_COORDINATORS_FAILURE,
  GET_TUTORS_REQUEST,
  GET_TUTORS_SUCCESS,
  GET_TUTORS_FAILURE
} from '../actions/users';
import API from '../services/api';
import { SET_NOTICE, CLEAR_NOTICE } from '../actions/alert';

import runDefaultSaga from './default';
import { serializeJsonToString, translateRol } from '../services/utils';

const getUsersRequest = data => {
  const params = serializeJsonToString(data);
  return API.get(`/users?${params}`);
};

function* getUsersSuccessCallback(result) {
  yield put({
    type: GET_USERS_SUCCESS,
    result,
    property: translateRol(result.data)
  });
}

function* getUsersFailureCallback() {
  yield put({
    type: GET_USERS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getUsers(action) {
  yield runDefaultSaga(
    { request: getUsersRequest, params: action.data },
    getUsersSuccessCallback,
    getUsersFailureCallback
  );
}

const createUserRequest = data => {
  return API.post('/users', data);
};

function* createUserSuccessCallback(result) {
  yield put({
    type: CREATE_USER_SUCCESS,
    result
  });
  yield put({
    type: SET_REDIRECT,
    data: { path: '/user', state: true }
  });
  yield put({
    type: SET_NOTICE,
    title: 'Creacion exitosa',
    message: 'El usuario se ha creado exitosamente',
    kind: 'success',
    timeout: 2000
  });
  yield put({
    type: GET_USERS_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10
    }
  });
}

function* createUserFailureCallback() {
  yield put({
    type: CREATE_USER_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* createUser(action) {
  yield runDefaultSaga(
    { request: createUserRequest, params: action.data },
    createUserSuccessCallback,
    createUserFailureCallback
  );
}

const updateUserRequest = data => {
  return API.put(`/users/${data.id}`, { user: data });
};

function* updateUserSuccessCallback(result) {
  yield put({
    type: UPDATE_USER_SUCCESS,
    result
  });
  yield put({
    type: SET_REDIRECT,
    data: { path: '/user', state: true }
  });
  yield put({
    type: SET_NOTICE,
    title: 'Creación exitosa',
    message: 'El usuario se ha editado exitosamente',
    kind: 'success',
    timeout: 2000
  });
  yield put({
    type: GET_USERS_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10
    }
  });
}

function* updateUserFailureCallback() {
  yield put({
    type: UPDATE_USER_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* updateUser(action) {
  yield runDefaultSaga(
    { request: updateUserRequest, params: action.data },
    updateUserSuccessCallback,
    updateUserFailureCallback
  );
}

const deleteUserRequest = data => {
  return API.delete(`/users/${data}`);
};

function* deleteUserSuccessCallback(result) {
  yield put({
    type: DELETE_USER_SUCCESS,
    result
  });
  yield put({
    type: SET_NOTICE,
    title: 'Usuario eliminado exitosamente',
    message: 'El usuario se ha creado eliminado con exito',
    kind: 'success',
    timeout: 2000
  });
  yield put({
    type: CLEAR_NOTICE
  });
  yield put({
    type: GET_USERS_REQUEST,
    data: {
      name: '',
      display_start: 0,
      display_length: 10
    }
  });
}

function* deleteUserFailureCallback() {
  yield put({
    type: DELETE_USER_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* deleteUser(action) {
  yield runDefaultSaga(
    { request: deleteUserRequest, params: action.data },
    deleteUserSuccessCallback,
    deleteUserFailureCallback
  );
}
// GET DIRECTOR

const getDirectorsRequest = data => {
  let params = serializeJsonToString(data);
  if (data.roles) {
    let _roles = '';
    data.roles.forEach(r => {
      _roles += `&role[]=${r}`;
    });
    params = params.concat(_roles);
  }
  return API.get(`/users?${params}`);
};

function* getDirectorsSuccessCallback(result) {
  yield put({
    type: GET_DIRECTORS_SUCCESS,
    result
  });
}

function* getDirectorsFailureCallback() {
  yield put({
    type: GET_DIRECTORS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getDirectors(action) {
  yield runDefaultSaga(
    { request: getDirectorsRequest, params: action.data },
    getDirectorsSuccessCallback,
    getDirectorsFailureCallback
  );
}

// GET PROJECT

const getProjectsRequest = data => {
  let params = serializeJsonToString(data);
  if (data.roles) {
    let _roles = '';
    data.roles.forEach(r => {
      _roles += `&role[]=${r}`;
    });
    params = params.concat(_roles);
  }
  return API.get(`/users?${params}`);
};

function* getProjectsSuccessCallback(result) {
  yield put({
    type: GET_PROJECTS_SUCCESS,
    result
  });
}

function* getProjectsFailureCallback() {
  yield put({
    type: GET_PROJECTS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}

function* getProjects(action) {
  yield runDefaultSaga(
    { request: getProjectsRequest, params: action.data },
    getProjectsSuccessCallback,
    getProjectsFailureCallback
  );
}

// GET COMMUNAL COORDINATE

const getCommunalCoordinatorsRequest = data => {
  let params = serializeJsonToString(data);
  if (data.roles) {
    let _roles = '';
    data.roles.forEach(r => {
      _roles += `&role[]=${r}`;
    });
    params = params.concat(_roles);
  }
  return API.get(`/users?${params}`);
};

function* getCommunalCoordinatorsSuccessCallback(result) {
  yield put({
    type: GET_COMMUNAL_COORDINATORS_SUCCESS,
    result
  });
}

function* getCommunalCoordinatorsFailureCallback() {
  yield put({
    type: GET_COMMUNAL_COORDINATORS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getCommunalCoordinators(action) {
  yield runDefaultSaga(
    { request: getCommunalCoordinatorsRequest, params: action.data },
    getCommunalCoordinatorsSuccessCallback,
    getCommunalCoordinatorsFailureCallback
  );
}

// GET COMMUNAL COORDINATE

const getTutorsRequest = data => {
  let params = serializeJsonToString(data);
  if (data.roles) {
    let _roles = '';
    data.roles.forEach(r => {
      _roles += `&role[]=${r}`;
    });
    params = params.concat(_roles);
  }
  return API.get(`/users?${params}`);
};

function* getTutorsSuccessCallback(result) {
  yield put({
    type: GET_TUTORS_SUCCESS,
    result
  });
}

function* getTutorsFailureCallback() {
  yield put({
    type: GET_TUTORS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getTutors(action) {
  yield runDefaultSaga(
    { request: getTutorsRequest, params: action.data },
    getTutorsSuccessCallback,
    getTutorsFailureCallback
  );
}

export default function* userSagas() {
  yield takeEvery(GET_USERS_REQUEST, getUsers);
  yield takeEvery(CREATE_USER_REQUEST, createUser);
  yield takeEvery(UPDATE_USER_REQUEST, updateUser);
  yield takeEvery(DELETE_USER_REQUEST, deleteUser);
  yield takeEvery(GET_DIRECTORS_REQUEST, getDirectors);
  yield takeEvery(GET_PROJECTS_REQUEST, getProjects);
  yield takeEvery(GET_COMMUNAL_COORDINATORS_REQUEST, getCommunalCoordinators);
  yield takeEvery(GET_TUTORS_REQUEST, getTutors);
}
