import React, { PureComponent } from 'react';
import { PieChart, Pie, Legend } from 'recharts';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default class ChartPie extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/c9pL8k61/';

  render() {
    const { dataChart, children, color } = this.props;
    return (
      <PieChart width={400} height={370}>
        <Legend verticalAlign="bottom" layout="vertical" width={400} />
        <Pie
          data={dataChart}
          cx={190}
          cy={110}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill={color}
          dataKey="value"
          isAnimationActive={false}
        >
          {children}
        </Pie>
      </PieChart>
    );
  }
}
