import React, { Component } from 'react';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { passwordResetRequest, setRedirect } from '../../actions/auth';

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Mínimo 8 caracteres')
    .max(16, 'Máximo 16 caracteres')
    .required('Campo requerido'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben ser iguales')
    .min(8, 'Mínimo 8 caracteres')
    .max(16, 'Máximo 16 caracteres')
    .required('Campo requerido')
});

class ResetPasswordForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    const { redirect, onHide, dispatch } = nextProps;
    if (redirect.state) {
      dispatch(setRedirect({ path: '', state: false }));
      onHide();
    }
  }

  render() {
    const { onHide, dispatch } = this.props;
    return (
      <Formik
        initialValues={{
          password: '',
          repeatPassword: ''
        }}
        validationSchema={SignupSchema}
        onSubmit={values => {
          // same shape as initial values
          dispatch(
            passwordResetRequest({
              password: values.password,
              password_confirmation: values.repeatPassword
            })
          );
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-group">
              <center>
                <Field
                  style={{ marginBottom: '10px' }}
                  name="password"
                  placeholder="Ingresa tu contraseña nueva"
                  type="password"
                  className={`form-control ${
                    errors.password && touched.password ? 'errors' : null
                  }`}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="warning-password"
                />
                <Field
                  name="repeatPassword"
                  className={`form-control ${
                    errors.repeatPassword && touched.repeatPassword
                      ? 'errors'
                      : null
                  }`}
                  placeholder="Repite tu contraseña nueva"
                  type="password"
                />

                <ErrorMessage
                  name="repeatPassword"
                  component="div"
                  className="warning-password"
                />
              </center>
              <Row className="mt-3">
                <Col md={{ span: 3, offset: 4 }}>
                  <Button onClick={onHide} block variant="light">
                    Cancelar
                  </Button>
                </Col>
                <Col md={5}>
                  <Button type="submit" block variant="primary">
                    Cambiar contraseña
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  const { redirect } = state.auth;
  return {
    redirect
  };
};

export default connect(mapStateToProps)(ResetPasswordForm);
