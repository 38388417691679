import React, { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import SelectAlt from './SelectAlt';
import './style.scss';

const StudentItemForm = ({
  student,
  currentSections,
  nextSections,
  position,
  handleSelect,
  nextName,
  currentName,
  studentsDestination
}) => {
  const [check, setCheck] = useState(0);
  const setDataSelect = data => {
    if (data.length !== 0) {
      return data.map((item, i) => ({
        title: item.name,
        id: item.id,
        key: i
      }));
    }
    return [
      {
        title: '-',
        id: 0,
        key: 0
      }
    ];
  };

  const renderSelected = state => {
    const data = state ? currentSections : nextSections;

    return (
      <SelectAlt
        onChangeSelect={e => handleSelect(e.value, student.id, position)}
        data={setDataSelect(data)}
        value={studentsDestination && studentsDestination.sectionId}
        disabled={data.length === 0}
      />
    );
  };
  const renderNameGrade = state => {
    const data = state ? currentName : nextName;
    return data;
  };

  const onChangeCheck = checkState => {
    const data = !checkState ? currentSections : nextSections;
    if (data.length > 0) {
      handleSelect(data[0].id, student.id, position);
    } else {
      handleSelect(0, student.id, position);
    }
    setCheck(!checkState);
  };

  return (
    <Row>
      <Col>
        <p>{student.last_names} </p>
      </Col>
      <Col>
        <p>{student.names} </p>
      </Col>
      <Col>
        <p>{student.rut} </p>
      </Col>
      <Col className="text-center">
        <Form.Check
          inline
          checked={check}
          onChange={() => onChangeCheck(check)}
          type="checkbox"
          className="custom-box"
        />
      </Col>
      <Col className="text-center">
        <Form.Check
          inline
          checked={!check}
          onChange={() => onChangeCheck(check)}
          type="checkbox"
          className="custom-box"
        />
      </Col>

      <Col>
        <p>{renderNameGrade(check)}</p>
      </Col>
      <Col>{renderSelected(check)}</Col>
    </Row>
  );
};

StudentItemForm.defaultProps = {};
export default StudentItemForm;
