import React, { Component } from 'react';

import Select from 'react-select';

const customStyles = {
  control: style => ({
    ...style,
    minHeight: 19,
    height: 33
  }),
  valueContainer: style => ({
    ...style,
    marginTop: -3
  }),
  indicatorsContainer: style => ({
    ...style,
    marginTop: -3
  })
};

class SelectAlt extends Component {
  constructor(props) {
    super(props);
    const { value, initialValue, server } = this.props;
    const setData = value !== '' ? this.selectedSearch(value) : '';

    this.state = {
      inputValue: server ? initialValue : setData,
      focus: false
    };
  }

  componentDidUpdate(prevProps) {
    const { reset, value, data, server, initialValue } = this.props;
    if (reset !== prevProps.reset) {
      this.handleOnChangeSelect();
    }
    if (
      ((value && value !== prevProps.value) || data !== prevProps.data) &&
      !server
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        inputValue: this.selectedSearch(value)
      });
    }
    if (
      server &&
      initialValue &&
      prevProps.initialValue &&
      initialValue.value !== prevProps.initialValue.value
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        inputValue: initialValue
      });
    }
  }

  setData = data => {
    const { server } = this.props;
    if (server) {
      return data.map(item => ({
        value: item.id,
        label: item.name || item.title
      }));
    }
    return data.map(item => ({
      value: item.id,
      label: item.name || item.title
    }));
  };

  handleOnChangeInput = (value = '') => {
    const { server } = this.props;
    if (server) {
      const { dispatchRequest } = this.props;
      dispatchRequest({
        name: value
      });
    }
  };

  handleOnChangeSelect = (value = '') => {
    const { onChangeSelect } = this.props;
    if (onChangeSelect) {
      onChangeSelect(value);
    }
    this.setState({
      inputValue: value
    });
  };

  handleOnFocus = () => {
    this.handleOnChangeInput();
    this.setState({ focus: true });
  };

  selectedSearch = value => {
    const { data } = this.props;
    const selectedData = data.find(item => item.id === value);
    if (selectedData) {
      return { value: selectedData.id, label: selectedData.title };
    }
    return null;
  };

  render() {
    const {
      onLoad,
      data,
      title,
      placeholder,
      loadingMessage,
      noOptions,
      isSearchable,
      disabled,
      defaultValue,
      clearable
    } = this.props;
    const { inputValue, focus } = this.state;
    return (
      <div>
        {title && <p style={{ marginBottom: 8 }}>{title}</p>}

        <Select
          isClearable={clearable}
          clearValue={a => this.handleOnChangeInput(a)}
          styles={customStyles}
          isLoading={onLoad && focus}
          isDisabled={disabled}
          onFocus={this.handleOnFocus}
          onBlur={() => this.setState({ focus: false })}
          onInputChange={a => this.handleOnChangeInput(a)}
          value={inputValue}
          onChange={this.handleOnChangeSelect}
          options={this.setData(data)}
          placeholder={placeholder}
          loadingMessage={() => loadingMessage}
          noOptionsMessage={() => noOptions}
          isSearchable={isSearchable}
          defaultValue={defaultValue}
        />
      </div>
    );
  }
}
SelectAlt.defaultProps = {
  title: null,
  placeholder: 'Seleccione',
  loadingMessage: 'Cargando',
  noOptions: 'Sin datos',
  onLoad: false,
  server: false,
  isSearchable: false,
  disabled: false,
  reset: false,
  initialValue: '',
  value: '',
  clearable: false
};
export default SelectAlt;
