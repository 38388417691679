import React from 'react';
import PropTypes from 'prop-types';

const SubTitleH3 = ({ text, color, margin }) => (
  <h3 style={{ color, marginRight: margin }}>{text}</h3>
);

SubTitleH3.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string
};

SubTitleH3.defaultProps = {
  color: '$light'
};

export default SubTitleH3;
