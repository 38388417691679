import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import domtoimage from 'dom-to-image';
import {
  SubTitleH2,
  NavbarLeft,
  ArrowLeftIndex,
  SelectAlt,
  DefaultButton,
  Teacher,
  Managerial,
  DAEM,
  Reading,
  TeacherReport,
  DAEMReport,
  ManagerialReport,
  ReadingReport,
  OralReadingReport,
  LoaderText,
  NacionalReport,
  Nacional,
  ProjectReport,
  Project,
  OralReading
} from '../../components';
import {
  getYearChart,
  getRegionChart,
  getCommuneChart,
  getEstablishmentChart,
  getGradeChart,
  getSectionChart,
  getQuizChart,
  clearDataChart,
  generateDocument,
  getUnitChart,
  checkDocument
} from '../../actions/reports';
import { getProjects } from '../../actions/users';
import { sendAlert } from '../../actions/alert';
import { downloadFile } from '../../services/utils';

const data = [
  { key: 0, title: 'Docente', id: 0 },
  { key: 1, title: 'Directivo', id: 1 },
  { key: 2, title: 'DAEM', id: 2 },
  { key: 3, title: 'Lectura', id: 3 },
  { key: 4, title: 'Nacional', id: 4 },
  { key: 5, title: 'Proyecto', id: 5 },
  { key: 6, title: 'Lectura Oral Individual', id: 6 }
];

class WordPreview extends Component {
  constructor() {
    super();
    this.state = {
      typeReport: 0,
      name: '',
      region: 0,
      commune: 0,
      establishment: 0,
      year: 0,
      grade: 0,
      section: 0,
      quiz: 0,
      userId: 0,
      state: false,
      onCreateImage: false
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getYearChart());
  }

  componentDidUpdate(prevProps) {
    const { ongoingRequest: prevLoad, result: prevResult } = prevProps;
    const { ongoingRequest: load, document, result } = this.props;
    const { typeReport } = this.state;

    if (
      result !== prevResult &&
      result?.length === 0 &&
      !(load.showDocument || load.checkDocument || load.createDocument)
    ) {
      if (typeReport !== 6) {
        this.clearSelectData();
      }
    }
    if (
      prevLoad.generateDocumentRequest &&
      prevLoad.generateDocumentRequest !== load.generateDocumentRequest &&
      document &&
      document.data &&
      document.file
    ) {
      const extension = document.data.extension === 'pdf' ? 'pdf' : 'docx';
      downloadFile(`${document.data.name}`, extension, document.file);
    }
  }

  clearSelectData = () => {
    this.setState({
      // eslint-disable-next-line
      ...this.state,
      state: false
    });
  };

  typeOfTest = () => {
    const { dispatch, typeReport } = this.props;
    if (typeReport === 0) {
      dispatch(getYearChart({ reading: false }));
    }
    if (typeReport === 3) {
      dispatch(getYearChart({ reading: true }));
    } else {
      dispatch(getYearChart());
    }
  };

  clearState = () => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state,
      name: '',
      region: 0,
      commune: 0,
      establishment: 0,
      year: 0,
      grade: 0,
      section: 0,
      quiz: 0,
      unit: 0,
      state: false
    });
  };

  renderSelects = value => {
    const {
      yearsChart,
      regionsChart,
      communesChart,
      gradesChart,
      establishmentsChart,
      sectionsChart,
      quizzesChart,
      ongoingRequest,
      unitsChart,
      user,
      projects,
      projectOngoingRequest
    } = this.props;

    const { name } = this.state;
    switch (value) {
      case 0:
        return (
          <Teacher
            name={name}
            years={yearsChart}
            regions={regionsChart}
            communes={communesChart}
            establishments={establishmentsChart}
            grades={gradesChart}
            sections={sectionsChart}
            quizzes={quizzesChart}
            ongoingRequest={ongoingRequest}
            sendData={this.filterDataSelect}
            clearSelectData={this.clearSelectData}
            clearState={this.clearState}
            typeOfTest={this.typeOfTest}
            values={this.state}
          />
        );

      case 1:
        return (
          <Managerial
            name={name}
            years={yearsChart}
            regions={regionsChart}
            communes={communesChart}
            establishments={establishmentsChart}
            grades={gradesChart}
            quizzes={quizzesChart}
            ongoingRequest={ongoingRequest}
            sendData={this.filterDataSelect}
            clearSelectData={this.clearSelectData}
            clearState={this.clearState}
            typeOfTest={this.typeOfTest}
            values={this.state}
          />
        );
      case 2:
        return (
          <DAEM
            name={name}
            years={yearsChart}
            regions={regionsChart}
            communes={communesChart}
            grades={gradesChart}
            quizzes={quizzesChart}
            ongoingRequest={ongoingRequest}
            sendData={this.filterDataSelect}
            clearSelectData={this.clearSelectData}
            clearState={this.clearState}
            typeOfTest={this.typeOfTest}
            values={this.state}
          />
        );
      case 3:
        return (
          <Reading
            name={name}
            years={yearsChart}
            regions={regionsChart}
            communes={communesChart}
            establishments={establishmentsChart}
            grades={gradesChart}
            sections={sectionsChart}
            quizzes={quizzesChart}
            ongoingRequest={ongoingRequest}
            sendData={this.filterDataSelect}
            clearSelectData={this.clearSelectData}
            clearState={this.clearState}
            typeOfTest={this.typeOfTest}
            values={this.state}
          />
        );
      case 4:
        return (
          <Nacional
            name={name}
            years={yearsChart}
            regions={regionsChart}
            communes={communesChart}
            establishments={establishmentsChart}
            grades={gradesChart}
            sections={sectionsChart}
            quizzes={quizzesChart}
            ongoingRequest={ongoingRequest}
            sendData={this.filterDataSelect}
            clearSelectData={this.clearSelectData}
            clearState={this.clearState}
            typeOfTest={this.typeOfTest}
            values={this.state}
          />
        );
      case 5:
        return (
          <Project
            name={name}
            years={yearsChart}
            regions={regionsChart}
            communes={communesChart}
            establishments={establishmentsChart}
            grades={gradesChart}
            sections={sectionsChart}
            quizzes={quizzesChart}
            units={unitsChart}
            ongoingRequest={ongoingRequest}
            sendData={this.filterDataSelect}
            clearSelectData={this.clearSelectData}
            clearState={this.clearState}
            typeOfTest={this.typeOfTest}
            values={this.state}
            user={user}
            users={projects}
            usersOngoingRequest={projectOngoingRequest}
          />
        );
      case 6:
        return (
          <OralReading
            name={name}
            years={yearsChart}
            regions={regionsChart}
            communes={communesChart}
            establishments={establishmentsChart}
            grades={gradesChart}
            sections={sectionsChart}
            quizzes={quizzesChart}
            ongoingRequest={ongoingRequest}
            sendData={this.filterDataSelect}
            clearSelectData={this.clearSelectData}
            clearState={this.clearState}
            typeOfTest={this.typeOfTest}
            values={this.state}
          />
        );
      default:
        return 'ERROR';
    }
  };

  isUserAdmin = () => {
    const { user } = this.props;
    return user.role === 'superadmin' || user.role === 'admin';
  };

  filterDataSelect = (value, type) => {
    const { dispatch } = this.props;
    const {
      year,
      // eslint-disable-next-line
      region,
      commune,
      establishment,
      grade,
      section,
      name,
      // eslint-disable-next-line no-unused-vars
      quiz,
      typeReport,
      userId
    } = this.state;
    const state = Number(value) !== 0;
    switch (type) {
      case 'Name':
        this.setState({
          name: state ? value : 0,
          year: 0,
          region: 0,
          commune: 0,
          establishment: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Year':
        if (state) {
          dispatch(getRegionChart({ at_least_one_review_in_year: value }));
        }
        this.setState({
          year: state ? value : 0,
          region: 0,
          commune: 0,
          establishment: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Year-Nacional':
        if (state) {
          dispatch(
            getGradeChart({
              at_least_one_review_in_year: value
            })
          );
        }
        this.setState({
          year: state ? value : 0,
          grade: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Year-Reading':
        if (state) {
          dispatch(
            getRegionChart({
              at_least_one_review_in_year: value,
              at_least_one_review_with_reading_as: true
            })
          );
        }
        this.setState({
          year: state ? value : 0,
          region: 0,
          commune: 0,
          establishment: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Year-Teacher':
        if (state) {
          dispatch(
            getRegionChart({
              at_least_one_review_in_year: value,
              at_least_one_review_with_reading_as: false
            })
          );
        }
        this.setState({
          year: state ? value : 0,
          region: 0,
          commune: 0,
          establishment: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Region':
        if (state) {
          dispatch(
            getCommuneChart({
              at_least_one_review_in_year: year,
              region_id: value
            })
          );
        }
        this.setState({
          region: state ? value : 0,
          commune: 0,
          establishment: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Region-Reading':
        if (state) {
          dispatch(
            getCommuneChart({
              at_least_one_review_in_year: year,
              at_least_one_review_with_reading_as: true,
              region_id: value
            })
          );
        }
        this.setState({
          region: state ? value : 0,
          commune: 0,
          establishment: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Region-Teacher':
        if (state) {
          dispatch(
            getCommuneChart({
              at_least_one_review_in_year: year,
              at_least_one_review_with_reading_as: false,
              region_id: value
            })
          );
        }
        this.setState({
          region: state ? value : 0,
          commune: 0,
          establishment: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Commune':
        if (state) {
          dispatch(
            getEstablishmentChart({
              at_least_one_review_in_year: year,
              commune_id: value
            })
          );
        }
        this.setState({
          commune: state ? value : 0,
          establishment: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Commune-Reading':
        if (state) {
          dispatch(
            getEstablishmentChart({
              at_least_one_review_in_year: year,
              at_least_one_review_with_reading_as: true,
              commune_id: value
            })
          );
        }
        this.setState({
          commune: state ? value : 0,
          establishment: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Commune-DAEM':
        if (state) {
          dispatch(
            getGradeChart({
              at_least_one_review_in_year: year,
              at_least_one_review_in_commune: value
            })
          );
        }
        this.setState({
          commune: state ? value : 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Commune-Teacher':
        if (state) {
          dispatch(
            getEstablishmentChart({
              at_least_one_review_in_year: year,
              at_least_one_review_with_reading_as: false,
              commune_id: value
            })
          );
        }
        this.setState({
          commune: state ? value : 0,
          establishment: 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Establishment':
        if (state) {
          dispatch(
            getGradeChart({
              at_least_one_review_in_year: year,
              at_least_one_review_in_establishment: value
            })
          );
        }
        this.setState({
          establishment: state ? value : 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Establishment-Reading':
        if (state) {
          dispatch(
            getGradeChart({
              at_least_one_review_in_year: year,
              at_least_one_review_with_reading_as: true,
              at_least_one_review_in_establishment: value
            })
          );
        }
        this.setState({
          establishment: state ? value : 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Establishment-Teacher':
        if (state) {
          dispatch(
            getGradeChart({
              at_least_one_review_in_year: year,
              at_least_one_review_with_reading_as: false,
              at_least_one_review_in_establishment: value
            })
          );
        }
        this.setState({
          establishment: state ? value : 0,
          grade: 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Grade':
        if (state) {
          dispatch(
            getSectionChart({
              at_least_one_review_in_year: year,
              grade_id: value,
              establishment_id: establishment
            })
          );
        }
        this.setState({
          grade: state ? value : 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Grade-Nacional':
        if (state) {
          dispatch(
            getQuizChart({
              at_least_one_review_in_year: year,
              at_least_one_review_with_grade: value
            })
          );
        }
        this.setState({
          grade: state ? value : 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Grade-Reading':
        if (state) {
          dispatch(
            getSectionChart({
              at_least_one_review_with_reading_as: true,
              at_least_one_review_in_year: year,
              at_least_one_review_in_grade: value,
              at_least_one_review_in_establishment: establishment
            })
          );
        }
        this.setState({
          grade: state ? value : 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Grade-DAEM':
        dispatch(
          getQuizChart({
            at_least_one_review_in_year: year,
            grade_id: value,
            year_id: year
          })
        );
        this.setState({
          grade: state ? value : 0,
          state: false
        });
        break;
      case 'Grade-Teacher':
        if (state) {
          dispatch(
            getSectionChart({
              at_least_one_review_in_year: year,
              at_least_one_review_with_reading_as: false,
              at_least_one_review_in_grade: value,
              at_least_one_review_in_establishment: establishment
            })
          );
        }
        this.setState({
          grade: state ? value : 0,
          section: 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Grade-Managerial':
        if (state) {
          dispatch(
            getQuizChart({
              at_least_one_review_in_year: year,
              at_least_one_review_with_grade: value
            })
          );
        }
        this.setState({
          grade: state ? value : 0,
          state: false
        });
        break;
      case 'Quiz-Reading':
        dispatch(
          checkDocument({
            data: {
              document_type: typeReport,
              reading: true,
              quiz_id: value,
              section_id: section
            },
            params: {
              quiz_id: value,
              section_id: section,
              finished: true,
              document_type: typeReport
            }
          })
        );
        this.setState({
          quiz: state ? value : 0,
          state: true
        });
        break;
      case 'Sections':
        if (state) {
          dispatch(
            getQuizChart({
              at_least_one_review_with_section: value,
              at_least_one_review_in_year: year,
              section_id: value
            })
          );
        }
        this.setState({
          section: state ? value : 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Sections-Reading':
        if (state) {
          dispatch(
            getQuizChart({
              reading: true,
              at_least_one_review_with_section: value,
              at_least_one_review_in_year: year
            })
          );
        }
        this.setState({
          section: state ? value : 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Sections-Teacher':
        if (state) {
          dispatch(
            getQuizChart({
              at_least_one_review_with_section: value,
              at_least_one_review_in_year: year,
              reading: false
            })
          );
        }
        this.setState({
          section: state ? value : 0,
          quiz: 0,
          state: false
        });
        break;
      case 'Quiz-DAEM':
        dispatch(
          checkDocument({
            data: {
              name,
              document_type: typeReport,
              quiz_id: value,
              commune_id: commune
            },
            params: {
              quiz_id: value,
              finished: true,
              document_type: typeReport,
              commune_id: commune
            }
          })
        );
        this.setState({
          quiz: state ? value : 0,
          state: true
        });
        break;
      case 'Quiz':
        dispatch(
          checkDocument({
            data: {
              document_type: typeReport,
              quiz_id: value,
              section_id: section,
              name
            },
            params: {
              quiz_id: value,
              section_id: section,
              finished: true,
              document_type: typeReport
            }
          })
        );
        this.setState({
          quiz: state ? value : 0,
          state: true
        });
        break;
      case 'Quiz-Nacional':
        dispatch(
          checkDocument({
            data: {
              document_type: typeReport,
              quiz_id: value,
              grade_id: grade,
              name
            },
            params: {
              quiz_id: value,
              grade_id: grade,
              finished: true,
              document_type: typeReport
            }
          })
        );
        this.setState({
          quiz: state ? value : 0,
          state: true
        });
        break;
      case 'Quiz-Managerial':
        dispatch(
          checkDocument({
            data: {
              document_type: typeReport,
              quiz_id: value,
              name,
              grade_id: grade,
              establishment_id: establishment
            },
            params: {
              quiz_id: value,
              finished: true,
              document_type: typeReport,
              establishment_id: establishment
            }
          })
        );
        this.setState({
          quiz: state ? value : 0,
          state: true
        });
        break;
      case 'Project-Year':
        if (!this.isUserAdmin())
          dispatch(getUnitChart({ params: { year: value } }));
        if (this.isUserAdmin()) {
          dispatch(
            getProjects({
              display_start: 0,
              sort_direction: 'desc',
              roles: [6]
            })
          );
        }
        this.setState({
          year: state ? value : 0,
          unit: 0,
          state: false
        });
        break;
      case 'Project-User':
        dispatch(getUnitChart({ params: { year } }));
        this.setState({
          userId: state ? value : 0,
          unit: 0,
          state: false
        });
        break;
      case 'Project-Unit': {
        const params = {
          guideline_id: value,
          finished: true,
          document_type: typeReport
        };
        const reportData = {
          document_type: typeReport,
          guideline_id: value,
          name
        };
        if (this.isUserAdmin()) {
          params.user_id = userId;
          reportData.user_id = userId;
        }
        dispatch(
          checkDocument({
            data: reportData,
            params
          })
        );
        this.setState({
          unit: state ? value : 0,
          state: true
        });
        break;
      }
      default:
        break;
    }
  };

  renderInform = value => {
    const { result, ongoingRequest } = this.props;
    const results = !ongoingRequest.showDocument && result;
    switch (value) {
      case 0:
        return <TeacherReport data={results} ongoingRequest={ongoingRequest} />;
      case 1:
        return (
          <ManagerialReport data={results} ongoingRequest={ongoingRequest} />
        );
      case 2:
        return <DAEMReport data={results} ongoingRequest={ongoingRequest} />;
      case 3:
        return <ReadingReport data={results} ongoingRequest={ongoingRequest} />;
      case 4:
        return (
          <NacionalReport data={results} ongoingRequest={ongoingRequest} />
        );
      case 5:
        return <ProjectReport data={results} ongoingRequest={ongoingRequest} />;
      case 6:
        return <OralReadingReport />;
      default:
        return 'ERROR';
    }
  };

  generateDocumentWithoutImages = () => {
    const { dispatch, id } = this.props;
    const { name } = this.state;

    dispatch(
      generateDocument({
        id,
        file: true,
        name,
        extension: 'pdf'
      })
    );
  };

  sendImg = chart => {
    const { dispatch, id } = this.props;
    const { name } = this.state;
    dispatch(
      generateDocument({
        id,
        document: {
          images: { ...chart }
        },
        file: true,
        name
      })
    );
  };

  sendChart = async () => {
    const { result, dispatch } = this.props;
    const { typeReport } = this.state;
    const domChart = [];
    const charts = {};

    if (typeReport === 6) {
      this.generateDocumentWithoutImages();
      return;
    }

    if (result) {
      this.setState({
        onCreateImage: true
      });
      for (let i = 0; i < result.length; i += 1) {
        domChart[i] = document.getElementById(`chart-${i + 1}`);
        const img = domChart[i];
        const scale = 2400 / img.offsetWidth;

        // eslint-disable-next-line no-await-in-loop
        await domtoimage
          .toPng(domChart[i], {
            height: img.offsetHeight * scale,
            width: img.offsetWidth * scale,
            style: {
              transform: `scale(${scale})`,
              transformOrigin: 'top left',
              width: `${img.offsetWidth}px`,
              height: `${img.offsetHeight}px`
            }
          })
          .then(function pngBase64(image) {
            charts[`image${i + 1}`] = image;
          });
      }
      this.setState({
        onCreateImage: false
      });
      this.sendImg(charts);
    } else {
      dispatch(
        sendAlert({
          title: 'Error',
          message:
            'No es posible crear el informe sin datos para generar gráficos',
          kind: 'error',
          timeout: 2000
        })
      );
    }
  };

  onReload = ongoingRequest => {
    const {
      createDocument: create,
      showDocument: show,
      checkDocument: check
    } = ongoingRequest;
    return create || show || check;
  };

  typeOfReportOptions = () => {
    const { user } = this.props;

    if (this.isUserAdmin() || user.role === 'project') {
      return data;
    }

    return data.filter(el => el.key !== 5);
  };

  render() {
    const { typeReport, state, name, onCreateImage } = this.state;
    const { dispatch, ongoingRequest } = this.props;
    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            {ongoingRequest.generateDocumentRequest || onCreateImage ? (
              <LoaderText name={name} />
            ) : null}
            {this.onReload(ongoingRequest) && <LoaderText />}
            <div className="section">
              <ArrowLeftIndex className="mb-5 text-light" path="/reports" />
              <Row
                md={9}
                className="mr-1"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Col>
                  <SubTitleH2 text="Vista Previa Informe" />
                </Col>
                <Col md={3}>
                  <DefaultButton
                    text="Generar"
                    onClick={() => this.sendChart()}
                    style={{ width: '100%' }}
                    disabled={!state}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <p>
                    Usa los selectores para indicar el informe que deseas
                    generar
                  </p>
                </Col>
              </Row>
              <Row className="mb-4 mt-4">
                <Col md={2}>
                  <SelectAlt
                    data={this.typeOfReportOptions()}
                    value={
                      this.typeOfReportOptions().find(d => d.id === typeReport)
                        .id || 0
                    }
                    placeholder="Tipo de Informe"
                    onChangeSelect={e => {
                      this.setState({ typeReport: e.value });
                      dispatch(clearDataChart());
                    }}
                    title="Tipo de informe"
                  />
                </Col>
                <Col md={10}>{this.renderSelects(typeReport)}</Col>
              </Row>
              <Row>
                {state &&
                !ongoingRequest.showDocument &&
                !ongoingRequest.checkDocument &&
                !ongoingRequest.createDocument
                  ? this.renderInform(typeReport)
                  : null}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const {
    yearsChart,
    regionsChart,
    communesChart,
    establishmentsChart,
    gradesChart,
    sectionsChart,
    quizzesChart,
    unitsChart,
    id,
    ongoingRequest,
    document,
    result
  } = state.reports;

  const { projects, ongoingRequest: projectOngoingRequest } = state.user;
  const { user } = state.auth;
  return {
    yearsChart,
    regionsChart,
    communesChart,
    gradesChart,
    sectionsChart,
    quizzesChart,
    establishmentsChart,
    unitsChart,
    id,
    ongoingRequest,
    document,
    result,
    user,
    projects,
    projectOngoingRequest
  };
};

export default connect(mapStateToProps)(WordPreview);
