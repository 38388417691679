import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { Bar, LabelList } from 'recharts';
import { ChartStackedBar, AgroupedChart } from '../Charts';
import { NoImage, CustomizedLabel } from '../Utils';
import './style.scss';

class ManagerialReport extends Component {
  constructor() {
    super();
    this.state = {};
  }

  formatNumber = data => {
    if (data === 0) {
      return '';
    }
    return `${data}%`;
  };

  renderTypeOfChart = (chart, styleChart, data, styles2) => {
    if (data.graph_type === 0) {
      return styleChart.length ? (
        <ChartStackedBar dataChart={chart} ticks={[0, 50, 100]} name={75}>
          {styleChart.map(style => {
            return (
              <Bar
                key={style.key}
                dataKey={style.key}
                stackId="a"
                fill={style.colour}
                maxBarSize={40}
                isAnimationActive={false}
              >
                <LabelList
                  dataKey={style.key}
                  position="top"
                  angle={data.data.length >= 15 ? 90 : undefined}
                  formatter={value => this.formatNumber(value)}
                />
              </Bar>
            );
          })}
        </ChartStackedBar>
      ) : (
        <NoImage />
      );
    }
    if (data.graph_type === 3) {
      return styleChart.length ? (
        <ChartStackedBar dataChart={chart} ticks={[0, 50, 100]} name={75}>
          {styleChart.map(style => {
            return (
              <Bar
                dataKey={style.key}
                fill={style.colour}
                maxBarSize={40}
                key={style.key}
                isAnimationActive={false}
              >
                <LabelList
                  dataKey={style.key}
                  position="center"
                  angle={data.data.length >= 10 ? 90 : undefined}
                  formatter={value => this.formatNumber(value)}
                  content={<CustomizedLabel color={style.colour} />}
                />
              </Bar>
            );
          })}
        </ChartStackedBar>
      ) : (
        <NoImage />
      );
    }
    if (data.graph_type === 1 || data.graph_type === 2) {
      return styleChart.length !== 0 ? (
        <ChartStackedBar dataChart={chart} ticks={[0, 50, 100]} name={75}>
          {styleChart.map(style => {
            return (
              <Bar
                key={style.key}
                dataKey={style.key}
                stackId="a"
                fill={style.colour}
                maxBarSize={40}
                isAnimationActive={false}
              >
                <LabelList
                  dataKey={style.key}
                  position="center"
                  content={<CustomizedLabel color={style.colour} />}
                  formatter={value => this.formatNumber(value)}
                />
              </Bar>
            );
          })}
        </ChartStackedBar>
      ) : (
        <NoImage />
      );
    }
    if (data.graph_type === 5) {
      const doneStyles = new Set();
      return styleChart.length !== 0 ? (
        <ChartStackedBar
          styleChart={styleChart}
          styles2={styles2}
          dataChart={chart}
          width={600}
          ticks={[0, 50, 100]}
          name={75}
        >
          {styleChart.map(chartX => {
            return chartX.map(style => {
              if (!doneStyles.has(style.id)) {
                doneStyles.add(style.id);
                return (
                  <Bar
                    dataKey={style.id}
                    stackId="a"
                    fill={style.colour}
                    maxBarSize={40}
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey={style.id}
                      position="center"
                      content={<CustomizedLabel color={style.colour} />}
                      formatter={value => this.formatNumber(value)}
                    />
                  </Bar>
                );
              }
              return null;
            });
          })}
        </ChartStackedBar>
      ) : (
        <NoImage />
      );
    }
    if (data.graph_type === 6 || data.graph_type === 7) {
      return data ? (
        <AgroupedChart dataChart={chart} ticks={[0, 50, 100]} name={85}>
          {styleChart.map(style => {
            return (
              <Bar
                key={style.key}
                dataKey={style.key}
                stackId="a"
                fill={style.colour}
                maxBarSize={40}
                isAnimationActive={false}
              >
                <LabelList
                  dataKey={style.key}
                  position={data.graph_type === 7 ? 'center' : 'top'}
                  angle={
                    data.graph_type === 6 && data.data.length >= 10
                      ? 90
                      : undefined
                  }
                  content={
                    data.graph_type === 7 && (
                      <CustomizedLabel color={style.colour} />
                    )
                  }
                  formatter={value => this.formatNumber(value)}
                />
              </Bar>
            );
          })}
        </AgroupedChart>
      ) : (
        <NoImage />
      );
    }
    return <NoImage />;
  };

  renderChart = () => {
    const { data } = this.props;
    return data ? (
      data.map((chart, index) => {
        const dataForChart = chart.data;
        const styleForChart = chart.styles;
        const { styles2 } = chart;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div id={`chart-${index + 1}`} className="div-chart" key={index}>
            <p className="p-chart">
              <span className="title-chart"> {chart.title}</span>
              <br />
              <span className="subtitle-chart">{chart.subtitle}</span>
            </p>
            {this.renderTypeOfChart(
              dataForChart,
              styleForChart,
              chart,
              styles2
            )}
            <hr className="myhrline" />
          </div>
        );
      })
    ) : (
      <center>
        <NoImage />
      </center>
    );
  };

  render() {
    return (
      <Container className="container-charts mt-4">
        <h2 className="title-report">Informe Directivo</h2>
        {this.renderChart()}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { availableYears, ongoingRequest } = state.utils;
  return {
    availableYears,
    ongoingRequest
  };
};

export default connect(mapStateToProps)(ManagerialReport);
