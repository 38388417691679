import React, { Component } from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';

const renderQuarterTick = tickProps => {
  const { x, y, payload, index } = tickProps;
  const { value, offset } = payload;

  const result = [];

  if (index === 0) {
    const pathX = x - offset;
    result.push(<path d={`M${pathX},${y - 4}v${-35}`} stroke="black" />);
  }

  if (value.includes('uniq-')) {
    const name = value.split('-')[1];
    const pathX = x + offset;
    result.push(
      <text x={x} y={y - 4} textAnchor="middle">
        {name}
      </text>
    );
    result.push(<path d={`M${pathX},${y - 4}v${-35}`} stroke="black" />);
    return result;
  }

  if (value !== '' && value !== '|') {
    const splitValue = value.split('-');
    const isEven = splitValue[0] === 'even';
    const name = splitValue[1];
    result.push(
      <text x={x + offset * (isEven ? 1 : 2)} y={y - 4} textAnchor="middle">
        {name}
      </text>
    );
  }

  if (value === '|') {
    const pathX = x + offset;
    result.push(<path d={`M${pathX},${y - 4}v${-35}`} stroke="black" />);
  }

  return result;
};

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value.length >= 15
          ? `${payload.value.slice(0, 15)}...`
          : payload.value.slice(0, 15)}
      </text>
    </g>
  );
};

export default class AgroupedChart extends Component {
  constructor() {
    super();
    this.state = {
      mergedData: []
    };
  }

  componentDidMount() {
    const { dataChart } = this.props;
    const newData = [];
    dataChart.map(d => {
      const totalValues = d.values.length;
      const middleValue = Math.trunc(d.values.length / 2);
      const offset = totalValues % 2 === 0 ? 'even' : 'odd';
      return d.values.map((val, i) => {
        if (middleValue === 0) {
          return newData.push({ ...val, group: `uniq-${d.name}` });
        }
        if (i + 1 === middleValue) {
          return newData.push({ ...val, group: `${offset}-${d.name}` });
        }
        if (i + 1 === totalValues) {
          return newData.push({ ...val, group: '|' });
        }
        return newData.push({ ...val, group: '' });
      });
    });

    this.setState({
      mergedData: newData
    });
  }

  render() {
    const { mergedData } = this.state;
    const { children, ticks, name } = this.props;
    return (
      <BarChart
        width={1000}
        height={563}
        data={mergedData}
        margin={{
          top: 50
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          interval={0}
          tick={<CustomizedAxisTick />}
          height={name}
        />
        <XAxis
          dataKey="group"
          axisLine={false}
          tickLine={false}
          tick={renderQuarterTick}
          height={name} // antes estaba en 1, puede que cause problemas en el futuro
          xAxisId="quarter"
        />
        <YAxis unit="%" ticks={ticks} />
        <Tooltip />
        <Legend />
        {children}
      </BarChart>
    );
  }
}
