import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import GuidelineForm from '../Form/GuidelineForm';
import { LoadSpinner } from '../Utils';
// eslint-disable-next-line
import StudentForm from '../Form/StudentForm';
import TeacherForm from '../Form/TeacherForm';
import UserForm from '../Form/UserForm';
import ResetPasswordForm from '../Form/ResetPassword';
import Report from '../Form/Report';
import Upload from '../Form/Upload';
import CloneQuizForm from '../Form/CloneQuizForm';

const ModalAction = ({ show, data, onHide, size, onLoad, min }) => {
  const renderForm = modalData => {
    switch (modalData.form) {
      case 'guideline':
        return (
          <GuidelineForm
            data={modalData}
            onHide={onHide}
            type={modalData.action}
          />
        );
      case 'student':
        return (
          <StudentForm
            data={modalData}
            onHide={onHide}
            type={modalData.action}
          />
        );
      case 'teacher':
        return (
          <TeacherForm
            data={modalData}
            onHide={onHide}
            type={modalData.action}
          />
        );
      case 'user':
        return (
          <UserForm data={modalData} onHide={onHide} type={modalData.action} />
        );
      case 'reset-password':
        return (
          <ResetPasswordForm
            data={modalData}
            onHide={onHide}
            type={modalData.action}
          />
        );
      case 'report':
        return (
          <Report data={modalData} onHide={onHide} type={modalData.action} />
        );
      case 'upload-file':
        return (
          <Upload data={modalData} onHide={onHide} type={modalData.action} />
        );
      case 'clone-quiz':
        return (
          <CloneQuizForm data={modalData} onHide={onHide} type={modalData.action} />
        );
      default:
        return 'ERROR';
    }
  };
  return (
    <Modal
      size={size}
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
    >
      {onLoad && <LoadSpinner position="absolute" height="100%" min={min} />}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderForm(data)}</Modal.Body>
    </Modal>
  );
};
ModalAction.defaultProps = {
  size: 'md',
  onLoad: false,
  min: '270px'
};

ModalAction.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string
  }).isRequired,
  size: PropTypes.string,
  onLoad: PropTypes.bool,
  min: PropTypes.string
};
export default ModalAction;
