export const GET_COMMUNE_REQUEST = 'GET_COMMUNE';
export const GET_COMMUNE_SUCCESS = 'GET_COMMUNE_SUCCESS';
export const GET_COMMUNE_FAILURE = 'GET_COMMUNE_FAILURE';

export const getCommunes = data => ({
  type: GET_COMMUNE_REQUEST,
  data
});

export const GET_REGIONS_REQUEST = 'GET_REGIONS';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_FAILURE = 'GET_REGIONS_FAILURE';

export const getRegions = data => ({
  type: GET_REGIONS_REQUEST,
  data
});

export const GET_GRADES_REQUEST = 'GET_GRADES';
export const GET_GRADES_SUCCESS = 'GET_GRADES_SUCCESS';
export const GET_GRADES_FAILURE = 'GET_GRADES_FAILURE';

export const getGrades = data => ({
  type: GET_GRADES_REQUEST,
  data
});

export const GET_UTILS_ESTABLISHMENTS_REQUEST =
  'GET_UTILS_ESTABLISHMENTS_REQUEST';
export const GET_UTILS_ESTABLISHMENTS_SUCCESS =
  'GET_UTILS_ESTABLISHMENTS_SUCCESS';
export const GET_UTILS_ESTABLISHMENTS_FAILURE =
  'GET_UTILS_ESTABLISHMENTS_FAILURE';

export const getUtilsEstablishments = data => ({
  type: GET_UTILS_ESTABLISHMENTS_REQUEST,
  data
});

export const GET_SECTIONS_REQUEST = 'GET_SECTIONS_REQUEST';
export const GET_SECTIONS_SUCCESS = 'GET_SECTIONS_SUCCESS';
export const GET_SECTIONS_FAILURE = 'GET_SECTIONS_FAILURE';

export const getSections = data => ({
  type: GET_SECTIONS_REQUEST,
  data
});

export const GET_GRADE_TRANSITION_REQUEST = 'GET_GRADE_TRANSITION_REQUEST';
export const GET_GRADE_TRANSITION_SUCCESS = 'GET_GRADE_TRANSITION_SUCCESS';
export const GET_GRADE_TRANSITION_FAILURE = 'GET_GRADE_TRANSITION_FAILURE';

export const getGradeTransition = data => ({
  type: GET_GRADE_TRANSITION_REQUEST,
  data
});

export const RESET_STUDENTS = 'RESET_STUDENTS';

export const resetStudentsTransition = () => ({
  type: RESET_STUDENTS
});

export const POST_GRADE_TRANSITION_REQUEST = 'POST_GRADE_TRANSITION_REQUEST';
export const POST_GRADE_TRANSITION_SUCCESS = 'POST_GRADE_TRANSITION_SUCCESS';
export const POST_GRADE_TRANSITION_FAILURE = 'POST_GRADE_TRANSITION_FAILURE';

export const postGradeTransition = data => ({
  type: POST_GRADE_TRANSITION_REQUEST,
  data
});

export const GET_YEARS_ESTABLISHMENT_REQUEST =
  'GET_YEARS_ESTABLISHMENT_REQUEST';
export const GET_YEARS_ESTABLISHMENT_SUCCESS =
  'GET_YEARS_ESTABLISHMENT_SUCCESS';
export const GET_YEARS_ESTABLISHMENT_FAILURE =
  'GET_YEARS_ESTABLISHMENT_FAILURE';

export const getYearsEstablishment = data => ({
  type: GET_YEARS_ESTABLISHMENT_REQUEST,
  data
});

export const GET_QUIZ_SECTION_REQUEST = 'GET_QUIZ_SECTION_REQUEST';
export const GET_QUIZ_SECTION_SUCCESS = 'GET_QUIZ_SECTION_SUCCESS';
export const GET_QUIZ_SECTION_FAILURE = 'GET_QUIZ_SECTION_FAILURE';

export const getQuizzesSection = data => ({
  type: GET_QUIZ_SECTION_REQUEST,
  data
});

export const GET_AVAILABLE_YEAR_REQUEST = 'GET_AVAILABLE_YEAR_REQUEST';
export const GET_AVAILABLE_YEAR_SUCCESS = 'GET_AVAILABLE_YEAR_SUCCESS';
export const GET_AVAILABLE_YEAR_FAILURE = 'GET_AVAILABLE_YEAR_FAILURE';

export const getAvailableYear = data => ({
  type: GET_AVAILABLE_YEAR_REQUEST,
  data
});

export const GET_AVAILABLE_GRADE_REQUEST = 'GET_AVAILABLE_GRADE_REQUEST';
export const GET_AVAILABLE_GRADE_SUCCESS = 'GET_AVAILABLE_GRADE_SUCCESS';
export const GET_AVAILABLE_GRADE_FAILURE = 'GET_AVAILABLE_GRADE_FAILURE';

export const getAvailableGrade = data => ({
  type: GET_AVAILABLE_GRADE_REQUEST,
  data
});

export const GET_AVAILABLE_GUIDELINE_REQUEST =
  'GET_AVAILABLE_GUIDELINE_REQUEST';
export const GET_AVAILABLE_GUIDELINE_SUCCESS =
  'GET_AVAILABLE_GUIDELINE_SUCCESS';
export const GET_AVAILABLE_GUIDELINE_FAILURE =
  'GET_AVAILABLE_GUIDELINE_FAILURE';

export const getAvailableGuideline = data => ({
  type: GET_AVAILABLE_GUIDELINE_REQUEST,
  data
});

export const GET_AVAILABLE_QUIZ_REQUEST = 'GET_AVAILABLE_QUIZ_REQUEST';
export const GET_AVAILABLE_QUIZ_SUCCESS = 'GET_AVAILABLE_QUIZ_SUCCESS';
export const GET_AVAILABLE_QUIZ_FAILURE = 'GET_AVAILABLE_QUIZ_FAILURE';

export const getAvailableQuiz = data => ({
  type: GET_AVAILABLE_QUIZ_REQUEST,
  data
});

export const GET_AVAILABLE_REGION_REQUEST = 'GET_AVAILABLE_REGION_REQUEST';
export const GET_AVAILABLE_REGION_SUCCESS = 'GET_AVAILABLE_REGION_SUCCESS';
export const GET_AVAILABLE_REGION_FAILURE = 'GET_AVAILABLE_REGION_FAILURE';

export const getAvailableRegion = data => ({
  type: GET_AVAILABLE_REGION_REQUEST,
  data
});

export const GET_AVAILABLE_COMMUNE_REQUEST = 'GET_AVAILABLE_COMMUNE_REQUEST';
export const GET_AVAILABLE_COMMUNE_SUCCESS = 'GET_AVAILABLE_COMMUNE_SUCCESS';
export const GET_AVAILABLE_COMMUNE_FAILURE = 'GET_AVAILABLE_COMMUNE_FAILURE';

export const getAvailableCommune = data => ({
  type: GET_AVAILABLE_COMMUNE_REQUEST,
  data
});

export const GET_AVAILABLE_ESTABLISHMENT_REQUEST =
  'GET_AVAILABLE_ESTABLISHMENT_REQUEST';
export const GET_AVAILABLE_ESTABLISHMENT_SUCCESS =
  'GET_AVAILABLE_ESTABLISHMENT_SUCCESS';
export const GET_AVAILABLE_ESTABLISHMENT_FAILURE =
  'GET_AVAILABLE_ESTABLISHMENT_FAILURE';

export const getAvailableEstablishment = data => ({
  type: GET_AVAILABLE_ESTABLISHMENT_REQUEST,
  data
});

export const GET_AVAILABLE_SECTION_REQUEST = 'GET_AVAILABLE_SECTION_REQUEST';
export const GET_AVAILABLE_SECTION_SUCCESS = 'GET_AVAILABLE_SECTION_SUCCESS';
export const GET_AVAILABLE_SECTION_FAILURE = 'GET_AVAILABLE_SECTION_FAILURE';

export const getAvailableSection = data => ({
  type: GET_AVAILABLE_SECTION_REQUEST,
  data
});

// REPORT

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';

export const getReport = data => ({
  type: GET_REPORT_REQUEST,
  data
});

// ESTABLISHMENT TYPES

export const GET_ESTABLISHMENT_TYPES_REQUEST =
  'GET_ESTABLISHMENT_TYPES_REQUEST';
export const GET_ESTABLISHMENT_TYPES_SUCCESS =
  'GET_ESTABLISHMENT_TYPES_SUCCESS';
export const GET_ESTABLISHMENT_TYPES_FAILURE =
  'GET_ESTABLISHMENT_TYPES_FAILURE';

export const getEstablishmentTypes = data => ({
  type: GET_ESTABLISHMENT_TYPES_REQUEST,
  data
});

// PROJECT TYPES

export const GET_PROJECT_TYPES_REQUEST = 'GET_PROJECT_TYPES_REQUEST';
export const GET_PROJECT_TYPES_SUCCESS = 'GET_PROJECT_TYPES_SUCCESS';
export const GET_PROJECT_TYPES_FAILURE = 'GET_PROJECT_TYPES_FAILURE';

export const getProjectTypes = data => ({
  type: GET_PROJECT_TYPES_REQUEST,
  data
});
