import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './style.scss';

const LoadSpinner = props => {
  const { position, height, children, width, min } = props;
  return (
    <div>
      <div
        className="containerSpinnerLoad"
        style={{ position, height, width, minHeight: min }}
      >
        <Spinner {...props} />
      </div>
      <div>{children}</div>
    </div>
  );
};

LoadSpinner.propTypes = {
  animation: PropTypes.string,
  variant: PropTypes.string,
  position: PropTypes.string,
  height: PropTypes.string,
  min: PropTypes.string
};
LoadSpinner.defaultProps = {
  animation: 'border',
  variant: 'primary',
  position: 'absolute',
  height: '100vh',
  min: '300px'
};

export default LoadSpinner;
