const arrayCreate = count => {
  const arr = {
    arr: []
  };

  for (let i = 0; i < count; i += 1) {
    arr.arr.push(i);
  }
  return arr.arr;
};

const createArrayYears = (count, initY = null, endY = null) => {
  const startYear = initY === null ? new Date().getFullYear() : initY;
  if (count === null) {
    return arrayCreate(initY - endY + 1).map(item => endY + item);
  }
  if (initY === null && endY === null) {
    return arrayCreate(count).map(item => startYear - item);
  }
  if (endY === null) {
    return arrayCreate(count).map(item => startYear + item);
  }
  return arrayCreate(endY - startYear + count + 1)
    .map(item => startYear + item)
    .sort((a, b) => a - b);
};

const setDataYear = years => {
  return years.map(date => ({ key: date, id: date, title: String(date) }));
};

const validateRut = (nextRut, prevRut) => {
  let nextRutAux = nextRut.split('-');
  nextRutAux = nextRutAux.join('');
  const lastChar = nextRutAux[nextRutAux.length - 1];

  for (let i = 0; i < nextRutAux.length; i += 1) {
    const char = nextRutAux.charCodeAt(i);
    if (!((char > 47 && char < 58) || char === 107 || char === 75)) {
      return prevRut;
    }
  }

  if (nextRutAux.length > 10) {
    return prevRut;
  }
  if (nextRutAux.length === 0) {
    return nextRut;
  }
  if (nextRutAux.length > 2) {
    const bodyRut = nextRutAux.slice(0, nextRutAux.length - 1);
    return `${bodyRut}-${lastChar}`;
  }
  return nextRutAux;
};
const serializeJsonToString = obj => {
  if (!obj) return '';

  return Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
};

const downloadFile = (nameFile, typeFile, fileBlob) => {
  //  Create blob link to download
  const url = window.URL.createObjectURL(new Blob([fileBlob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${nameFile}.${typeFile}`);
  // Append to html page
  document.body.appendChild(link);
  // Force download
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

const filterEstablishment = result => {
  const response = {
    id: result.id,
    rut: result.rut,
    names: result.names,
    last_names: result.last_names,
    gender: result.gender,
    active: result.active,
    description: result.description,
    sections_detail: result.sections_detail
  };
  return response;
};

const setData = data => {
  const arrayList = data.map(d => {
    return { label: d.name || d.title || d, value: d.id || d.key || d };
  });
  return arrayList;
};

const setRol = data => {
  switch (data) {
    case 'regional_coordinator':
      return 'Coordinador Regional';
    case 'admin':
      return 'Administrador';
    case 'director':
      return 'Director';
    case 'tutor':
      return 'Tutor';
    case 'superadmin':
      return 'Super Admin';
    case 'communal_coordinator':
      return 'Mentora';
    case 'project':
      return 'Proyecto';
    default:
      return '';
  }
};

const translateRol = data => {
  const translate = data.map(elem => {
    return {
      id: elem.id,
      active: elem.active,
      email: elem.email,
      name: elem.name,
      rut: elem.rut,
      role: elem.role,
      role_name: setRol(elem.role_name),
      coordinated_commune_ids: elem.coordinated_commune_ids,
      coordinated_communes: elem.coordinated_communes,
      coordinated_establishment_ids: elem.coordinated_establishment_ids,
      coordinated_establishments: elem.coordinated_establishments
    };
  });
  return translate;
};

function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 140 ? darkColor : lightColor;
}

export {
  arrayCreate,
  createArrayYears,
  setDataYear,
  validateRut,
  serializeJsonToString,
  downloadFile,
  filterEstablishment,
  translateRol,
  pickTextColorBasedOnBgColorSimple,
  setData
};
