import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  SubTitleH2,
  NavbarLeft,
  DefaultButton,
  ComponentDataTable,
  ModalAction,
  ModalConfirm
} from '../../components';
import { columns, columnsAdmin } from './columns';
import {
  getTeachers,
  deleteTeacher,
  cleanSectionsTeacher
} from '../../actions/teacher';
import { AbilityContext, Can } from '../../config/ability-context';

class Teachers extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      modalShowConfirm: false,
      modalData: {}
    };
  }

  queryDispatch = data => {
    const { dispatch, signedIn } = this.props;
    if (signedIn) {
      dispatch(getTeachers(data));
    }
  };

  setData = teachers => {
    return teachers.map(item => ({
      ...item,
      active: item.active ? 'Si' : 'No'
    }));
  };

  setModalShow = state => {
    this.setState({
      modalShow: state
    });
  };

  setModalShowConfirm = state => {
    this.setState({
      modalShowConfirm: state
    });
  };

  handleCreate = () => {
    this.setState({
      modalShow: true,
      modalData: {
        create: true,
        form: 'teacher',
        title: 'NUEVO PROFESOR'
      }
    });
  };

  handleButtonClick = (item, action) => {
    const message = action === 'edit' ? 'EDITANDO' : 'ELIMINANDO';
    if (action === 'edit') {
      const { dispatch } = this.props;
      dispatch(cleanSectionsTeacher());
      this.setState({
        modalShow: true,
        modalData: {
          item,
          action,
          title: `${message} PROFESOR`,
          form: 'teacher'
        }
      });
    } else {
      this.setState({
        modalShowConfirm: true,
        modalData: {
          id: item.id,
          action,
          title: `${message} PROFESOR`,
          message: '¿Estás seguro que deseas eliminar a este profesor?'
        }
      });
    }
  };

  handleDelete = teacherId => {
    const { dispatch } = this.props;
    dispatch(deleteTeacher(teacherId));
    this.setModalShowConfirm(false);
  };

  render() {
    const { ongoingRequest, teachers, totalRows } = this.props;
    const { modalShow, modalData, modalShowConfirm } = this.state;
    const { context } = this;

    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            <div className="section">
              <Row>
                <Col md={7}>
                  <SubTitleH2 text="Profesores" />
                </Col>
                <Col md={5} style={{ textAlign: 'right' }}>
                  <Can I="create" a="Teacher">
                    <Row>
                      <Col>
                        <DefaultButton
                          onClick={() => this.handleCreate(true)}
                          text="Crear Profesor"
                        />
                      </Col>
                    </Row>
                  </Can>
                </Col>
              </Row>
              <ComponentDataTable
                columns={
                  context.can('update', 'Teacher') ||
                  context.can('delete', 'Teacher')
                    ? columnsAdmin(this.handleButtonClick)
                    : columns(this.handleButtonClick)
                }
                onRequest={ongoingRequest.getTeacher}
                data={this.setData(teachers)}
                totalRows={totalRows}
                reduxDispatch={this.queryDispatch}
              />
            </div>
            <ModalAction
              show={modalShow}
              data={modalData}
              onLoad={
                ongoingRequest.getTeachers ||
                ongoingRequest.postTeacher ||
                ongoingRequest.updateTeacher ||
                ongoingRequest.getSectionsTeacher
              }
              onHide={() => this.setModalShow(false)}
            />
            <ModalConfirm
              show={modalShowConfirm}
              data={modalData}
              onHide={() => this.setModalShowConfirm(false)}
              action={this.handleDelete}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

Teachers.contextType = AbilityContext;

const mapStateToProps = state => {
  const { ongoingRequest, indexTeachers: teachers, totalRows } = state.teacher;
  const { user, signedIn } = state.auth;
  return {
    ongoingRequest,
    teachers,
    user,
    signedIn,
    totalRows
  };
};

export default connect(mapStateToProps)(Teachers);
