import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { ArrowRight } from 'react-feather';
import { history } from '../../config/store';
// import chileanRut from 'chilean-rut';
import {
  setRedirect,
  postTeacher,
  updateTeacher,
  getSectionsTeacher
} from '../../actions/teacher';
import { Select } from '../Dropdowm';
import { BasicInput, CheckBox } from '../Input/index';
import { validateRut } from '../../services/utils';

import './style.scss';

class StudentForm extends Component {
  constructor() {
    super();

    this.state = {
      years: []
    };
  }

  componentDidMount() {
    const { dispatch, data } = this.props;
    if (data.action === 'edit') {
      dispatch(getSectionsTeacher(data.item.id));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { redirect, onHide, dispatch, sectionsTeacher } = nextProps;
    const { years } = this.state;
    if (redirect.state) {
      dispatch(setRedirect({ path: '', state: false }));
      onHide();
    }
    if (sectionsTeacher.length > 0 && years.length === 0) {
      const currentYear = new Date().getFullYear();
      const yearsArrray = this.setDataYears(sectionsTeacher);
      this.setState({
        years: yearsArrray,
        yearSelected: yearsArrray.find(item => item.id === currentYear)
          ? yearsArrray.find(item => item.id === currentYear).id
          : yearsArrray[0].id
      });
    }
  }

  onChangeRut = value => {
    const { setFieldValue, values } = this.props;
    const formatRut = validateRut(value, values.rut);

    // console.log(chileanRut.validate(formatRut));
    setFieldValue('rut', formatRut);
  };

  renderSectionsTeacher = (data, yearSelected) => {
    const header = (
      <Row>
        <Col xs={4}>
          <p>
            <strong>Establecimiento</strong>
          </p>
        </Col>
        <Col xs={3}>
          <p>
            <strong>Curso</strong>
          </p>
        </Col>
        <Col xs={2}>
          <p>
            <strong>Sección</strong>
          </p>
        </Col>
      </Row>
    );

    const dataYearSelected = data.find(
      item => Number(item.year) === Number(yearSelected)
    );
    const dataYears = dataYearSelected || data[0];

    const dataStruct = dataYears.establishments.map(establishment => {
      return establishment.grades.map(grade => {
        return grade.sections.map(section => (
          <Row clasName="mb-4" style={{ alignItems: 'center' }}>
            <Col xs={4}>
              <p className="mb-0">{establishment.establishment_name}</p>
            </Col>
            <Col xs={3}>
              <p className="mb-0">{grade.grade_name}</p>
            </Col>
            <Col xs={2}>
              <p className="mb-0">{section.section_name}</p>
            </Col>
            <Col xs={3}>
              <Button
                variant="link"
                className="text-primary"
                size="sm"
                onClick={() =>
                  history.push(
                    `/create-establishment/${establishment.establishment_id}`
                  )
                }
              >
                <ArrowRight className="small-svg" />
              </Button>
            </Col>
          </Row>
        ));
      });
    });

    return [header, ...dataStruct];
  };

  setDataYears = data => {
    return data.map((item, i) => ({
      key: i,
      id: item.year,
      title: String(item.year)
    }));
  };

  render() {
    const {
      data,
      onHide,
      isValid,
      errors,
      touched,
      values,
      sectionsTeacher,
      ongoingRequest
    } = this.props;
    const { years, yearSelected } = this.state;
    return (
      <Form>
        <Row className="inputRow">
          <Col xs={12} md={12}>
            <Field
              name="name"
              render={({ field }) => (
                <BasicInput
                  {...field}
                  text={errors.name && touched.name && errors.name}
                  className={errors.name && touched.name ? 'is-invalid' : ''}
                  type="text"
                  title="Nombre"
                  placeholder="Escriba nombre"
                />
              )}
            />
          </Col>
        </Row>
        <Row className="inputRow">
          <Col xs={12} md={12}>
            <Field
              name="rut"
              render={({ field }) => (
                <BasicInput
                  {...field}
                  className={errors.rut && touched.rut ? 'is-invalid' : ''}
                  text={errors.rut && touched.rut && errors.rut}
                  onChange={e => this.onChangeRut(e.target.value)}
                  type="text"
                  title="RUT"
                  placeholder="9.999.999-9"
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={5}>
            <Field
              name="active"
              render={({ field, form }) => (
                <CheckBox
                  {...field}
                  custom
                  controlId="asd"
                  onChange={e =>
                    form.setFieldValue('active', !e.target.checked)
                  }
                  label="Congelar profesor"
                  checked={!values.active}
                />
              )}
            />
          </Col>
        </Row>
        {!ongoingRequest.getSectionsTeacher &&
          sectionsTeacher.length !== 0 &&
          !data.create && (
            <div>
              <hr />
              <h3>Detalle de establecimiento</h3>
              <Row>
                <Col xs={3}>
                  <Select
                    items={years || [{ key: 1, title: 'No hay datos' }]}
                    value={yearSelected}
                    onChange={e =>
                      this.setState({ yearSelected: e.target.value })
                    }
                    title="Año"
                  />
                </Col>
              </Row>
              {this.renderSectionsTeacher(sectionsTeacher, yearSelected)}
            </div>
          )}

        <Row className="mt-4">
          <Col md={{ span: 3, offset: 6 }}>
            <Button onClick={onHide} block variant="light">
              Cancelar
            </Button>
          </Col>
          <Col md={3}>
            <Button type="submit" disabled={!isValid} block>
              {data.create ? 'Crear' : 'Guardar'}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const { ongoingRequest, redirect, sectionsTeacher } = state.teacher;
  return {
    ongoingRequest,
    redirect,
    sectionsTeacher
  };
};

export default connect(mapStateToProps)(
  withFormik({
    mapPropsToValues(props) {
      const {
        data: { item }
      } = props;

      return {
        id: item ? item.id : '',
        name: item ? item.name : '',
        rut: item ? item.rut : '',
        active: item ? item.active === 'Si' : true
      };
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Mínimo 3 caracteres')
        .required('Campo requerido'),
      rut: Yup.string()
        .min(9, 'Rut inválido')
        .required('Rut es un campo requerido')
    }),
    handleSubmit(values, { props }) {
      const { dispatch, data } = props;

      if (data.create) {
        dispatch(postTeacher({ teacher: values }));
      } else {
        dispatch(updateTeacher({ teacher: values }));
      }
    }
  })(StudentForm)
);
