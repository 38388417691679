import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import {
  SubTitleH2,
  NavbarLeft,
  ComponentDataTable,
  DefaultButton,
  ModalAction,
  ModalConfirm
} from '../../components';
import { columns, columnsAdmin } from './columns';
import { getUsers, deleteUser } from '../../actions/users';
import { AbilityContext } from '../../config/ability-context';

class Users extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      modalShowConfirm: false,
      modalData: {
        title: ''
      }
    };
  }

  queryDispatch = data => {
    const { dispatch } = this.props;
    dispatch(getUsers(data));
  };

  setModalShow = state => {
    this.setState({
      modalShow: state
    });
  };

  setModalShowConfirm = state => {
    this.setState({
      modalShowConfirm: state
    });
  };

  handleDelete = id => {
    const { dispatch } = this.props;
    dispatch(deleteUser(id));
    this.setModalShowConfirm(false);
  };

  handleButtonClick = (item, action) => {
    const status = action === 'edit';

    if (status) {
      this.setState({
        modalShow: true,
        modalData: {
          item,
          action: 'edit',
          title: 'EDITAR USUARIO',
          form: 'user'
        }
      });
    } else {
      this.setState({
        modalShowConfirm: true,
        modalData: {
          id: item.id,
          action: 'delete',
          title: 'ELIMINAR USUARIO',
          message: '¿Estás seguro que deseas eliminar a este usuario?'
        }
      });
    }
  };

  handleCreate = () => {
    this.setState({
      modalShow: true,
      modalData: {
        create: true,
        form: 'user',
        title: 'NUEVO USUARIO'
      }
    });
  };

  render() {
    const { user, usersTranslate, ongoingRequest, totalRows } = this.props;
    const { modalShow, modalData, modalShowConfirm } = this.state;
    const { context } = this;
    // console.log(users);
    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            <div className="section">
              <Row>
                <Col md={8}>
                  <SubTitleH2 text="Usuarios" />
                </Col>
                <Col md={4} style={{ textAlign: 'right' }}>
                  {(user.role === 'superadmin' || user.role === 'admin') && (
                    <DefaultButton
                      onClick={() => this.handleCreate(true)}
                      text="Crear Usuario"
                    />
                  )}
                </Col>
              </Row>

              <ComponentDataTable
                columns={
                  context.can('update', 'User')
                    ? columnsAdmin(
                        this.handleButtonClick,
                        context.can('update', 'User'),
                        context.can('delete', 'User')
                      )
                    : columns(this.handleButtonClick)
                }
                onRequest={ongoingRequest.getUsers}
                data={usersTranslate}
                totalRows={totalRows}
                reduxDispatch={this.queryDispatch}
              />
              <ModalAction
                onLoad={ongoingRequest.createUser || ongoingRequest.updateUser}
                show={modalShow}
                data={modalData}
                onHide={() => this.setModalShow(false)}
              />
              <ModalConfirm
                show={modalShowConfirm}
                data={modalData}
                onHide={() => this.setModalShowConfirm(false)}
                action={this.handleDelete}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

Users.contextType = AbilityContext;
const mapStateToProps = state => {
  const { user, signedIn } = state.auth;
  const { users, usersTranslate, totalRows, ongoingRequest } = state.user;

  return {
    user,
    signedIn,
    users,
    usersTranslate,
    totalRows,
    ongoingRequest
  };
};

export default connect(mapStateToProps)(Users);
