import { takeEvery, put } from 'redux-saga/effects';
import {
  AMOUNT_ESTABLISHMENT_REQUEST,
  AMOUNT_ESTABLISHMENT_SUCCESS,
  AMOUNT_ESTABLISHMENT_FAILURE,
  AMOUNT_SECTIONS_REQUEST,
  AMOUNT_SECTIONS_SUCCESS,
  AMOUNT_SECTIONS_FAILURE,
  AMOUNT_STUNDETS_REQUEST,
  AMOUNT_STUNDETS_SUCCESS,
  AMOUNT_STUNDETS_FAILURE
} from '../actions/dashboard';
import API from '../services/api';
// import { SET_NOTICE } from '../actions/alert';
import runDefaultSaga from './default';

// ----- ESTABLISHMENTS AMOUNT -------

const getAmountEstablishmentRequest = () =>
  API.get(`/establishments`, { display_start: 0, display_length: 1 });

function* getAmountEstablishmentSuccessCallback(result) {
  yield put({
    type: AMOUNT_ESTABLISHMENT_SUCCESS,
    result: result.metadata.amount
  });
}

function* getAmountEstablishmentFailureCallback() {
  yield put({
    type: AMOUNT_ESTABLISHMENT_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getAmountEstablishment() {
  yield runDefaultSaga(
    { request: getAmountEstablishmentRequest },
    getAmountEstablishmentSuccessCallback,
    getAmountEstablishmentFailureCallback
  );
}

// ----- SECTIONS AMOUNT -------

const getAmountSectionsRequest = () =>
  API.get(`/sections`, { display_start: 0, display_length: 1 });

function* getAmountSectionsSuccessCallback(result) {
  yield put({
    type: AMOUNT_SECTIONS_SUCCESS,
    result: result.metadata.amount
  });
}

function* getAmountSectionsFailureCallback() {
  yield put({
    type: AMOUNT_SECTIONS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getAmountSections() {
  yield runDefaultSaga(
    { request: getAmountSectionsRequest },
    getAmountSectionsSuccessCallback,
    getAmountSectionsFailureCallback
  );
}
// ----- STUDENTS AMOUNT -------
const getAmountStudentsRequest = () =>
  API.get(`/students`, { display_start: 0, display_length: 1 });

function* getAmountStudentsSuccessCallback(result) {
  yield put({
    type: AMOUNT_STUNDETS_SUCCESS,
    result: result.metadata.amount
  });
}

function* getAmountStudentsFailureCallback() {
  yield put({
    type: AMOUNT_STUNDETS_FAILURE,
    result: { result: { res: 'Error', ok: false } }
  });
}
function* getAmountStudents() {
  yield runDefaultSaga(
    { request: getAmountStudentsRequest },
    getAmountStudentsSuccessCallback,
    getAmountStudentsFailureCallback
  );
}

// DEFINE establishmentSagas
export default function* dashboardSagas() {
  yield takeEvery(AMOUNT_ESTABLISHMENT_REQUEST, getAmountEstablishment);
  yield takeEvery(AMOUNT_SECTIONS_REQUEST, getAmountSections);
  yield takeEvery(AMOUNT_STUNDETS_REQUEST, getAmountStudents);
}
