import React from 'react';
import { memoize } from 'react-data-table-component';
import { Trash, Eye, Edit, Sliders, ArrowRight, Copy } from 'react-feather';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const renderButtons = (item, clickHandler) => {
  if (!item.finished) {
    return (
      <>
      <OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
        <Button
          variant="outline-action"
          size="sm"
          className="mr-2"
          onClick={() => clickHandler(item, 'edit')}
        >
          <ArrowRight className="small-svg" />
        </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>Clonar Evaluación</Tooltip>}>
        <Button
          variant="outline-success"
          size="sm"
          className="mr-2"
          onClick={() => clickHandler(item, 'clone-quiz')}
        >
          <Copy className="small-svg" />
        </Button>
        </OverlayTrigger>
      </>
    );
  }
  if (item.with_reviews) {
    return (
      <div className="buttonRow">
        <OverlayTrigger overlay={<Tooltip>Ver</Tooltip>}>
          <Button
            variant="outline-primary"
            size="sm"
            className="mr-2"
            onClick={() => clickHandler(item, 'show')}
          >
            <Eye className="small-svg" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>Editar Puntaje</Tooltip>}>
          <Button
            variant="outline-warning"
            size="sm"
            className="mr-2"
            onClick={() => clickHandler(item, 'edit-score')}
          >
            <Sliders className="small-svg" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>Clonar Evaluación</Tooltip>}>
        <Button
          variant="outline-success"
          size="sm"
          className="mr-2"
          onClick={() => clickHandler(item, 'clone-quiz')}
        >
          <Copy className="small-svg" />
        </Button>
      </OverlayTrigger>
      </div>
    );
  }
  return (
    <div className="buttonRow">
      <OverlayTrigger overlay={<Tooltip>Ver</Tooltip>}>
        <Button
          variant="outline-primary"
          size="sm"
          className="mr-2"
          onClick={() => clickHandler(item, 'show')}
        >
          <Eye className="small-svg" />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip>Editar Evaluación</Tooltip>}>
        <Button
          variant="outline-warning"
          size="sm"
          className="mr-2"
          onClick={() => clickHandler(item, 'edit-score')}
        >
          <Edit className="small-svg" />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger overlay={<Tooltip>Clonar Evaluación</Tooltip>}>
        <Button
          variant="outline-success"
          size="sm"
          className="mr-2"
          onClick={() => clickHandler(item, 'clone-quiz')}
        >
          <Copy className="small-svg" />
        </Button>
      </OverlayTrigger>
    </div>
  );
};
const columnsAdmin = memoize(clickHandler => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    left: true,
    grow: 4
  },
  {
    name: 'AÑO',
    selector: 'year',
    sortable: true,
    left: true,
    grow: 2
  },
  {
    name: 'CURSO',
    selector: 'grade.name',
    sortable: true,
    left: true,
    grow: 2
  },
  {
    name: 'TIPO DE EVALUACIÓN',
    selector: 'guideline.name',
    sortable: true,
    left: true,
    grow: 2
  },
  {
    cell: infoItem => (
      <div>
        {renderButtons(infoItem, clickHandler)}
        <OverlayTrigger overlay={<Tooltip>Borrar</Tooltip>}>
          <Button
            variant="outline-danger"
            size="sm"
            onClick={() => clickHandler(infoItem, 'delete')}
          >
            <Trash className="small-svg" />
          </Button>
        </OverlayTrigger>
      </div>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    right: true,
    grow: 4
  }
]);

const columns = memoize(() => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    left: true,
    grow: 5
  },
  {
    name: 'AÑO',
    selector: 'year',
    sortable: true,
    left: true,
    grow: 1
  },
  {
    name: 'CURSO',
    selector: 'grade.name',
    sortable: true,
    left: true,
    grow: 1
  },
  {
    name: 'TIPO DE EVALUACIÓN',
    selector: 'guideline.name',
    sortable: true,
    left: true,
    grow: 1
  }
]);

export { columns, columnsAdmin };
