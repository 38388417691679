import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import { getGrades, getRegions } from '../../actions/utils';
import { getOneEstablishment } from '../../actions/establishment';
import {
  SubTitleH2,
  NavbarLeft,
  EstablishmentForm,
  LoadSpinner,
  SelectAlt
} from '../../components';
import { getAllTeachers } from '../../actions/teacher';
import { getTutors } from '../../actions/users';
import onReload from './utils';

import './style.scss';
import { AbilityContext } from '../../config/ability-context';
import { createArrayYears, setDataYear } from '../../services/utils';
import StudentDatatable from '../Students';

class CreateEstablishment extends Component {
  constructor() {
    super();

    this.state = {
      year: new Date().getFullYear()
    };
  }

  componentDidMount() {
    const { dispatch, signedIn, match } = this.props;

    if (signedIn) {
      dispatch(getGrades({ select: false }));
      dispatch(getAllTeachers(1));
      dispatch(
        getTutors({
          display_start: 0,
          display_length: 5,
          sort_direction: 'desc',
          role: 0
        })
      );
      if (match.params.id !== undefined) {
        dispatch(getOneEstablishment(match.params.id));
      }
      dispatch(getRegions());
    }
  }

  redirect = path => {
    const { history } = this.props;
    history.push(path);
  };

  handleYear = year => {
    this.setState({
      year
    });
  };

  render() {
    const {
      onLoad,
      match,
      onLoadEstablishment,
      establishmentSelected,
      onLoadTeacher,
      onLoadUser
    } = this.props;
    const { year } = this.state;

    const action = match.params.id === undefined ? 'create' : 'edit';
    const state =
      action === 'edit'
        ? !onLoadEstablishment.getOneEstablishmentRequest
        : true;

    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            {onReload(
              onLoadEstablishment,
              onLoad,
              onLoadTeacher,
              onLoadUser
            ) && <LoadSpinner />}
            <div className="section">
              <SubTitleH2
                text={`${
                  action === 'create' ? 'Crear' : 'Editar'
                } Establecimiento`}
              />
              {!onLoad.getGrades && state && (
                <>
                  <EstablishmentForm
                    redirect={this.redirect}
                    data={{
                      action,
                      create: action !== 'edit',
                      item: establishmentSelected
                    }}
                    currentYear={new Date().getFullYear()}
                  />
                  {action === 'edit' &&
                    Object.entries(establishmentSelected).length > 0 && (
                      <>
                        <hr className="mt-n5" />
                        <Row>
                          <Col>
                            <h2 className="mt-4">Estudiantes</h2>
                          </Col>
                          <Col md={3} className="mt-2 mb-3">
                            <SelectAlt
                              value={year}
                              onChangeSelect={e => this.handleYear(e.value)}
                              data={setDataYear(
                                createArrayYears(
                                  5,
                                  Number(establishmentSelected.beginning_year),
                                  new Date().getFullYear()
                                )
                              )}
                              title="Año"
                            />
                          </Col>
                        </Row>
                        <div className="mb-5">
                          <StudentDatatable
                            fromEstablishmentForm
                            establishmentId={establishmentSelected?.id}
                            yearSelected={year}
                          />
                        </div>
                      </>
                    )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
CreateEstablishment.contextType = AbilityContext;

const mapStateToProps = state => {
  const { ongoingRequest, signedIn, user } = state.auth;
  const { ongoingRequest: onLoad } = state.utils;
  const { ongoingRequest: onLoadTeacher } = state.teacher;
  const { ongoingRequest: onLoadUser } = state.user;

  const {
    ongoingRequest: onLoadEstablishment,
    establishmentSelected
  } = state.establishment;
  return {
    ongoingRequest,
    signedIn,
    user,
    onLoad,
    onLoadEstablishment,
    establishmentSelected,
    onLoadTeacher,
    onLoadUser
  };
};

export default withRouter(connect(mapStateToProps)(CreateEstablishment));
