import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { BasicInput } from '../Input';
import { LoadSpinner } from '../Utils';

const Debounce = AwesomeDebouncePromise(value => value, 500);

class ComponentDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textInput: '',
      page: 0,
      RowsPage: props.RowsPage,
      column: '',
      direction: 'desc'
    };
  }

  componentDidMount() {
    this.setRequest();
  }

  componentDidUpdate(prevProps) {
    const { moreData } = this.props;
    if (moreData) {
      if (moreData.finished !== prevProps.moreData.finished) {
        this.handleRequest();
      }
    }
  }

  setRequest = params => {
    const { reduxDispatch, moreData } = this.props;
    const { RowsPage, page, textInput, direction, column } = this.state;

    reduxDispatch({
      name: textInput,
      display_start: page * RowsPage,
      display_length: RowsPage,
      sort_direction: direction,
      sort_column: column,
      ...moreData,
      ...params
    });
  };

  onChangePage = page => {
    const { RowsPage } = this.state;
    this.setState({
      page: page - 1
    });

    this.setRequest({
      display_start: (page - 1) * RowsPage
    });
  };

  handleRequest = () => {
    this.setRequest({
      display_start: 0
    });
  };

  onChangeRowsPage = RowsPage => {
    this.setState({
      RowsPage
    });

    this.setRequest({
      display_start: 0,
      display_length: RowsPage
    });
  };

  handleSearchRequest = async name => {
    this.setState({
      textInput: name,
      page: 0
    });
    const text = await Debounce(name);

    this.setRequest({
      name: text,
      display_start: 0
    });
  };

  handleSort = (column, direction) => {
    this.setState({
      direction,
      column: column.selector,
      page: 0
    });

    this.setRequest({
      display_start: 0,
      sort_direction: direction,
      sort_column: column.selector
    });
  };

  render() {
    const {
      columns,
      onRequest,
      data,
      totalRows,
      RowsPage,
      expandable
    } = this.props;
    const { textInput } = this.state;
    return (
      <div className="mt-4" style={{ position: 'relative' }}>
        {onRequest && <LoadSpinner position="absolute" height="100%" />}
        <DataTable
          className="custom-table"
          pagination
          noDataComponent={onRequest ? '' : 'NO SE ENCONTRARON DATOS'}
          paginationServer
          expandableRows={expandable}
          paginationTotalRows={totalRows}
          paginationPerPage={RowsPage}
          paginationRowsPerPageOptions={[5, 10, 15]}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPage}
          paginationComponentOptions={{
            rowsPerPageText: 'Filas por pagina:',
            rangeSeparatorText: 'de'
          }}
          sortServer
          onSort={(column, direction) => this.handleSort(column, direction)}
          sortFunction={a => a}
          noHeader
          subHeader
          disabled={onRequest}
          columns={columns}
          data={data}
          subHeaderComponent={
            <Col xs={5}>
              <BasicInput
                placeholder="Buscar"
                value={textInput}
                onChange={text => this.handleSearchRequest(text.target.value)}
                onFocus={() => this.setRequest()}
                styles="input-form-control"
              />
            </Col>
          }
        />
      </div>
    );
  }
}

ComponentDataTable.defaultProps = {
  onRequest: false,
  totalRows: 0,
  RowsPage: 10,
  modalShow: false,
  expandable: false,
  moreData: ''
};

export default ComponentDataTable;
