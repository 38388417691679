import React, { Component } from 'react';
import Switch from 'react-switch';

class SwitchButton extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: props.checked };
  }

  render() {
    const { handleChange, params } = this.props;
    const { checked } = this.state;
    return (
      <Switch
        onChange={() => {
          this.setState({ checked: !checked });
          handleChange(!checked, params);
        }}
        checked={checked}
        onColor="03acec"
        height={18}
        width={35}
      />
    );
  }
}

export default SwitchButton;
