import React from 'react';
import { Container } from 'react-bootstrap';
import './style.scss';

const OralReadingReport = () => {
  return (
    <Container className="container-charts mt-4">
      <h2 className="title-report">Informe de Lectura oral</h2>
    </Container>
  );
};

export default OralReadingReport;
