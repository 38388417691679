import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  DefaultButton,
  SubTitleH2,
  NavbarLeft,
  ResetPassword
} from '../../components';
import { requestSignOut } from '../../actions/auth';

class Profile extends Component {
  constructor() {
    super();
    this.state = {};
  }

  signOut = () => {
    const { dispatch } = this.props;
    dispatch(requestSignOut());
  };

  resetPassword = pass => {
    const { accessToken, dispatch } = this.props;
    dispatch({ token: accessToken, password: pass });
  };

  render() {
    const { user } = this.props;
    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            <div className="section">
              <Row>
                <Col>
                  <SubTitleH2 text="Mi Perfil" />
                </Col>
                <Col xs={4} xm={3} md={4} lg={2}>
                  <ResetPassword />
                </Col>
                <Col xs={4} xm={3} md={4} lg={2}>
                  <DefaultButton
                    variant="outline-info"
                    text="Cerrar Sesión"
                    onClick={this.signOut}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <strong>Usuario:</strong> {user.name}
                  </p>
                  <p>
                    <strong>RUT:</strong> {user.rut}
                  </p>
                  <p>
                    <strong>Correo:</strong> {user.email}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const {
    ongoingRequest,
    signedIn,
    headers: { accessToken },
    user
  } = state.auth;
  return {
    ongoingRequest,
    signedIn,
    accessToken,
    user
  };
};

export default withRouter(connect(mapStateToProps)(Profile));
