/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import './styles.scss';

class UploadDocuments extends Component {
  constructor() {
    super();
    this.state = {};
  }

  _handleImageChange(e) {
    const { onChange } = this.props;
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {};
      reader.readAsDataURL(file);
      onChange(file);
    }
  }

  render() {
    const { xlsx } = this.props;
    let formats = '.pdf, .doc, .docx';
    if (xlsx) {
      formats = '.xlsx';
    }
    return (
      <div>
        <div>
          <div>
            <input
              type="file"
              accept={formats}
              aria-describedby="inputGroupFileAddon01"
              onChange={e => this._handleImageChange(e)}
            />
            <label htmlFor="inputGroupFile01" />
          </div>
        </div>
      </div>
    );
  }
}

UploadDocuments.defaultProps = {
  onChange: () => {},
  formats: '.pdf, .doc, .docx'
};

export default UploadDocuments;
