import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { numberCategory, typeScore } from './data';
import { Select } from '../../Dropdowm';
import { BasicInput } from '../../Input';
import {
  addCategoryToQuestionPerformances,
  updateInfoQuestionOpen
} from '../../../actions/test';

class Open extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // eslint-disable-next-line
  addItems = (quantity, performance_type) => {
    const {
      dispatch,
      itemIndex,
      indicatorIndex,
      subAxeIndex,
      axeIndex
    } = this.props;
    dispatch(
      addCategoryToQuestionPerformances({
        quantity,
        performance_type,
        itemIndex,
        indicatorIndex,
        subAxeIndex,
        axeIndex
      })
    );
  };

  updateInfoQuestionsOpen = (
    tag,
    minimum,
    maximum,
    score,
    questionIndex,
    id
  ) => {
    const {
      dispatch,
      itemIndex,
      indicatorIndex,
      subAxeIndex,
      axeIndex
    } = this.props;
    dispatch(
      updateInfoQuestionOpen({
        tag,
        minimum,
        maximum,
        score,
        questionIndex,
        itemIndex,
        indicatorIndex,
        subAxeIndex,
        axeIndex,
        id
      })
    );
  };

  render() {
    const {
      itemIndex,
      indicatorIndex,
      subAxeIndex,
      axeIndex,
      quiz
    } = this.props;

    const questionPerformances =
      quiz.axes_attributes[axeIndex].sub_axes_attributes[subAxeIndex]
        .indicators_attributes[indicatorIndex].questions_attributes[itemIndex];
    const renderQuestionPerformancesAttributes = quiz.axes_attributes[
      axeIndex
    ].sub_axes_attributes[subAxeIndex].indicators_attributes[
      indicatorIndex
    ].questions_attributes[itemIndex].question_performances_attributes.map(
      (question, questionIndex) => {
        return question._destroy || question.tag === 'N/R' ? null : (
          <Row>
            <Col md={5}>
              <BasicInput
                key={question.id}
                className="secondary"
                value={question.tag}
                placeholder=" Escribe una categoría"
                onChange={e =>
                  this.updateInfoQuestionsOpen(
                    e.target.value,
                    question.minimum,
                    question.maximum,
                    question.score,
                    questionIndex,
                    question.id
                  )
                }
              />
            </Col>
            {questionPerformances.performance_type === 1 ? (
              <Col md={3}>
                <BasicInput
                  type="number"
                  className="secondary"
                  value={question.score}
                  placeholder="0"
                  onChange={e =>
                    this.updateInfoQuestionsOpen(
                      question.tag,
                      question.minimum,
                      question.maximum,
                      e.target.value,
                      questionIndex,
                      question.id
                    )
                  }
                />
              </Col>
            ) : (
              <Col md={7}>
                <Row>
                  <Col md={3}>
                    <BasicInput
                      type="number"
                      className="secondary"
                      placeholder="Mínimo"
                      value={question.minimum}
                      onChange={e =>
                        this.updateInfoQuestionsOpen(
                          question.tag,
                          e.target.value,
                          question.maximum,
                          question.score,
                          questionIndex,
                          question.id
                        )
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <p className="mt-2">-</p>
                  </Col>
                  <Col md={3}>
                    <BasicInput
                      type="number"
                      className="secondary"
                      placeholder="Máximo"
                      value={question.maximum}
                      onChange={e =>
                        this.updateInfoQuestionsOpen(
                          question.tag,
                          question.minimum,
                          e.target.value,
                          question.score,
                          questionIndex,
                          question.id
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <BasicInput
                      type="number"
                      className="secondary"
                      placeholder="Puntaje"
                      value={question.score}
                      onChange={e => {
                        this.updateInfoQuestionsOpen(
                          question.tag,
                          question.minimum,
                          question.maximum,
                          e.target.value,
                          questionIndex,
                          question.id
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        );
      }
    );
    return (
      <Col>
        <Row>
          <Col md={5}>
            <p className="mb-1 text-muted">N Categorías</p>
            <Select
              items={numberCategory}
              className="secondary"
              value={
                questionPerformances.question_performances_attributes.filter(
                  question => !question._destroy
                ).length
              }
              onChange={e => {
                // this.updateInfoQuestionsOpen(
                //   question.tag,
                //   question.minimum,
                //   question.maximum,
                //   e.target.value,
                //   questionIndex,
                //   question.id,

                // );
                this.addItems(
                  Number(e.target.value),
                  questionPerformances.performance_type
                );
              }}
              disabled={quiz.with_reviews}
            />
          </Col>
          <Col md={3}>
            <p className="mb-1 text-muted">Tipo</p>
            <Select
              items={typeScore}
              className="secondary"
              value={questionPerformances.performance_type}
              onChange={e => {
                this.addItems(
                  questionPerformances.question_performances_attributes.length,
                  Number(e.target.value)
                );
              }}
              disabled={quiz.with_reviews}
            />
          </Col>
        </Row>
        <div>{renderQuestionPerformancesAttributes}</div>
      </Col>
    );
  }
}

const mapStateToProps = state => {
  const {
    quiz: { quiz }
  } = state.test;
  return {
    quiz
  };
};

export default connect(mapStateToProps)(Open);
