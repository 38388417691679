/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Button, Row, Col, Spinner } from 'react-bootstrap';
import UploadDocument from '../Upload/UploadDocument';
import {
  importStudents,
  downloadTemplate as downloadStudentTemplate
} from '../../actions/student';
import { importScores, downloadScoresTemplate } from '../../actions/score';
import { uploadDocument, setRedirect } from '../../actions/reports';
import './style.scss';

const successStringMap = {
  'IMPORTAR ESTUDIANTES': 'Alumnos cargados con éxito.',
  'IMPORTAR PUNTAJES': 'Puntajes cargados con éxito.'
};

const Upload = ({
  data,
  loaderDocuments,
  importMessage,
  dispatch,
  redirect,
  onHide
}) => {
  const [file, setFile] = useState('');
  const [modalStyles, setModalStyles] = useState({
    height: '100px',
    zoomHeight: '0px'
  });

  const xlsx = ['IMPORTAR ESTUDIANTES', 'IMPORTAR PUNTAJES'].includes(
    data.title
  );

  const handleOnChange = newFile => {
    setFile(newFile);
  };

  const handleTemplateClick = () => {
    const { id } = data;

    if (data.title === 'IMPORTAR ESTUDIANTES') {
      dispatch(downloadStudentTemplate());
      return;
    }

    if (data.title === 'IMPORTAR PUNTAJES') {
      dispatch(downloadScoresTemplate({ id }));
    }
  };

  const handleUploadDocument = () => {
    const { id, quizReviewId } = data;
    if (data.title === 'IMPORTAR ESTUDIANTES') {
      dispatch(importStudents({ id, body: { students_file: file } }));
    } else if (data.title === 'IMPORTAR PUNTAJES') {
      dispatch(
        importScores({
          id,
          body: { scores_file: file, quizReviewId }
        })
      );
    } else {
      dispatch(uploadDocument({ id, body: { word_document: file } }));
    }
  };

  useEffect(() => {
    if (
      data.title === 'IMPORTAR ESTUDIANTES' ||
      data.title === 'IMPORTAR PUNTAJES'
    ) {
      if (
        importMessage.length > 0 &&
        importMessage[0] !== successStringMap[data.title]
      ) {
        setModalStyles({ height: '300px', zoomHeight: '160px' });
      } else if (importMessage[0] === successStringMap[data.title]) {
        setModalStyles({ height: '100px', zoomHeight: '0px' });
        setFile('');
        onHide();
      } else if (redirect.state) {
        dispatch(setRedirect({ path: '', state: false }));
        onHide();
      }
    } else if (redirect.state) {
      dispatch(setRedirect({ path: '', state: false }));
      onHide();
    }
  }, [importMessage, redirect, onHide, data.title, dispatch]);

  return (
    <Container style={{ height: modalStyles.height, position: 'relative' }}>
      <Row>
        <Col>
          <UploadDocument
            document={file}
            xlsx={xlsx}
            onChange={handleOnChange}
          />
        </Col>
        <Col lg={3} className="d-flex align-items-center flex-column">
          {loaderDocuments ? (
            <Spinner variant="primary" animation="border" size="sm" />
          ) : (
            <Button disabled={!file} onClick={() => handleUploadDocument()}>
              Confirmar
            </Button>
          )}
          {xlsx ? (
            <a
              className="downloadButton"
              href="#"
              onClick={() => handleTemplateClick()}
            >
              Descargar plantilla
            </a>
          ) : null}
        </Col>
      </Row>

      <div className="new-scrollbar" style={{ height: '100px' }}>
        {importMessage.length !== 0 &&
        importMessage[0] !== successStringMap[data.title] ? (
          <div>
            <p>
              No se pudo importar el archivo, debes corregir los siguientes
              errores:
            </p>
            <div
              style={{ height: modalStyles.zoomHeight, overflowY: 'scroll' }}
            >
              {importMessage.map((item, i) => (
                <li key={`n-${i.toString()}`}>{item}</li>
              ))}
            </div>
          </div>
        ) : null}
        {importMessage[0] === successStringMap[data.title] ? (
          <p>{successStringMap[data.title]}</p>
        ) : null}
      </div>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    data: { title }
  } = ownProps;
  const { ongoingRequest, redirect } = state.reports;
  const {
    importMessage: studentImportMessage,
    loaderDocuments: studentLoaderDocuments,
    importFailure: studentImportFailure
  } = state.student;
  const {
    importMessage: scoresImportMessage,
    loaderDocuments: scoresLoaderDocuments,
    importFailure: scoresImportFailure
  } = state.score;

  const importMessageMap = {
    'IMPORTAR ESTUDIANTES': studentImportMessage,
    'IMPORTAR PUNTAJES': scoresImportMessage
  };

  const importLoaderDocumentsMap = {
    'IMPORTAR ESTUDIANTES': studentLoaderDocuments,
    'IMPORTAR PUNTAJES': scoresLoaderDocuments
  };

  return {
    ongoingRequest,
    redirect,
    scoresImportMessage,
    studentImportMessage,
    scoresLoaderDocuments,
    studentLoaderDocuments,
    scoresImportFailure,
    studentImportFailure,
    loaderDocuments: importLoaderDocumentsMap[title] || '',
    importMessage: importMessageMap[title] || ''
  };
};

export default connect(mapStateToProps)(Upload);
