import {
  GET_ALL_TEACHERS_REQUEST,
  GET_ALL_TEACHERS_SUCCESS,
  GET_ALL_TEACHERS_FAILURE,
  GET_TEACHERS_REQUEST,
  GET_TEACHERS_SUCCESS,
  GET_TEACHERS_FAILURE,
  SET_REDIRECT,
  POST_TEACHER_REQUEST,
  POST_TEACHER_SUCCESS,
  POST_TEACHER_FAILURE,
  UPDATE_TEACHER_REQUEST,
  UPDATE_TEACHER_SUCCESS,
  UPDATE_TEACHER_FAILURE,
  DELETE_TEACHER_REQUEST,
  DELETE_TEACHER_SUCCESS,
  DELETE_TEACHER_FAILURE,
  GET_SECTIONS_TEACHER_REQUEST,
  GET_SECTIONS_TEACHER_SUCCESS,
  GET_SECTIONS_TEACHER_FAILURE,
  CLEAN_SECTIONS_TEACHER
} from '../actions/teacher';

const initialState = {
  ongoingRequest: {
    getAllTeacher: false,
    getTeacher: false,
    postTeacher: false,
    updateTeacher: false,
    deleteTeacher: false,
    getSectionsTeacher: false
  },
  sectionsTeacher: [],
  totalRows: 0,
  techaers: [],
  indexTeachers: [],
  redirect: {
    path: '',
    state: false
  }
};

const setData = data => {
  const arrayList = data.map(d => {
    return { ...d, title: d.name, key: d.id, id: d.id };
  });
  return [{ key: 0, title: 'Seleccione', id: 0 }, ...arrayList];
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TEACHERS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAllTeacher: true
        }
      };
    case GET_ALL_TEACHERS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAllTeacher: false
        },
        teachers: setData(action.result.data)
      };

    case GET_ALL_TEACHERS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getAllTeacher: false
        }
      };
    case GET_TEACHERS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getTeacher: true
        }
      };
    case GET_TEACHERS_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getTeacher: false
        },
        indexTeachers: action.result.data,
        totalRows: action.result.metadata.amount
      };
    case GET_TEACHERS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getTeacher: false
        }
      };
    case SET_REDIRECT:
      return {
        ...state,
        redirect: action.data
      };
    case POST_TEACHER_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          postTeacher: true
        }
      };
    case POST_TEACHER_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          postTeacher: false
        }
      };
    case POST_TEACHER_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          postTeacher: false
        }
      };
    case UPDATE_TEACHER_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateTeacher: true
        }
      };
    case UPDATE_TEACHER_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateTeacher: false
        }
      };
    case UPDATE_TEACHER_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          updateTeacher: false
        }
      };
    case DELETE_TEACHER_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteTeacher: true
        }
      };
    case DELETE_TEACHER_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteTeacher: false
        }
      };
    case DELETE_TEACHER_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          deleteTeacher: false
        }
      };
    case GET_SECTIONS_TEACHER_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getSectionsTeacher: true
        },
        sectionsTeacher: []
      };
    case GET_SECTIONS_TEACHER_SUCCESS:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getSectionsTeacher: false
        },
        sectionsTeacher: action.result.sections_distribution
      };
    case GET_SECTIONS_TEACHER_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getSectionsTeacher: false
        }
      };
    case CLEAN_SECTIONS_TEACHER:
      return {
        ...state,
        sectionsTeacher: []
      };
    default:
      return state;
  }
};

export default reducer;
