const dataStepProgress = [
  {
    step: 'Tipo de Prueba',
    key: 1
  },
  {
    step: 'Ejes y Preguntas',
    key: 2
  },
  {
    step: 'Puntajes',
    key: 3
  },
  {
    step: 'Finalizar',
    key: 4
  }
];

const dataTypeTest = [
  {
    title: 'Tipo de evaluación 1',
    key: 1,
    variant: 'link'
  },
  {
    title: 'Tipo de evaluación 2',
    key: 2,
    variant: 'link'
  }
];

const dataCourses = [
  {
    title: '1A',
    key: 1
  },
  {
    title: '1B',
    key: 2
  }
];

export { dataStepProgress, dataCourses, dataTypeTest };
