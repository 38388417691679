import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ title, color, ...props }) => (
  <h1 className="font-weight-bold" style={{ color }} {...props}>
    {title}
  </h1>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string
};

Title.defaultProps = {
  color: '$dark'
};

export default Title;
