import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Select2 from 'react-select';
import { validateRut } from '../../services/utils';
import { getCommunes } from '../../actions/utils';
import { getEstablishment } from '../../actions/establishment';
import { Select } from '../Dropdowm';
import SelectAlt from '../Input/SelectAlt';
import { updateUser, setRedirect, createUser } from '../../actions/users';
import { BasicInput } from '../Input/index';
import './style.scss';

const roles = [
  {
    id: 1,
    title: 'mentora',
    title2: 'communal_coordinator',
    key: 1
  },
  { id: 2, title: 'director', title2: 'director', key: 2 },
  { id: 4, title: 'admin', title2: 'admin', key: 4 },
  { id: 5, title: 'superadmin', title2: 'superadmin', key: 5 },
  { id: 6, title: 'proyecto', title2: 'project', key: 6 }
];

const adminRolesIds = [4, 5];

const customStyles = {
  multiValueLabel: provided => ({
    ...provided,
    fontSize: 13
  })
};
class UserForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidUpdate() {
    const { redirect, onHide, dispatch } = this.props;

    if (redirect.state) {
      dispatch(setRedirect({ path: '', state: false }));

      onHide();
    }
  }

  onChangeRut = value => {
    const { setFieldValue, values } = this.props;
    const formatRut = validateRut(value, values.rut);

    // console.log(chileanRut.validate(formatRut));
    setFieldValue('rut', formatRut);
  };

  validatePass = value => {
    const { setFieldValue, setFieldError } = this.props;
    if (value.length < 6) {
      setFieldError('password', 'Mínimo 6 carácteres');
    } else {
      setFieldError('password', '');
    }
    setFieldValue('password', value, false);
  };

  validatePassBlur = () => {
    const { setFieldTouched, setFieldError, errors } = this.props;

    if (errors.password) {
      setFieldError('password', errors.password);
    }
    setFieldTouched('password', true, false);
  };

  filterRoles = () => {
    const { user } = this.props;

    if (user.role === 'superadmin') {
      return roles;
    }
    return roles.filter(item => !adminRolesIds.includes(item.id));
  };

  setData = data => {
    return data.map(item => ({ value: item.id, label: item.title }));
  };

  requestGetCommune = value => {
    const { dispatch } = this.props;
    dispatch(getCommunes({ name: value, display_length: 5 }));
  };

  requestGetEstablishments = value => {
    const { dispatch } = this.props;
    dispatch(getEstablishment({ name: value, display_length: 5 }));
  };

  onChangeCommune = communes => {
    const { setFieldValue } = this.props;
    const ids = communes ? communes.map(item => item.value) : [];
    const data = communes
      ? communes.map(item => ({ name: item.label, id: item.value }))
      : [];
    setFieldValue('coordinated_commune_ids', ids);
    setFieldValue('coordinated_communes', data);
  };

  setCommunes = communes => {
    return communes.map(item => ({ label: item.name, value: item.id }));
  };

  setEstablishments = establishments => {
    return establishments.map(item => ({ label: item.name, value: item.id }));
  };

  onChangeEstablishment = establishments => {
    const { setFieldValue } = this.props;
    const ids = establishments ? establishments.map(item => item.value) : [];
    const data = establishments
      ? establishments.map(item => ({ name: item.label, id: item.value }))
      : [];
    setFieldValue('coordinated_establishment_ids', ids);
    setFieldValue('coordinated_establishments', data);
  };

  render() {
    const {
      data,
      onHide,
      isValid,
      errors,
      touched,
      values,
      communes,
      ongoingRequest,
      dispatch,
      establishmentOngoingRequest,
      establishments
    } = this.props;

    return (
      <Form>
        <Row className="inputRow">
          <Col xs={12} md={12}>
            <Field
              name="name"
              render={({ field }) => (
                <BasicInput
                  {...field}
                  text={errors.name && touched.name && errors.name}
                  className={errors.name && touched.name ? 'is-invalid' : ''}
                  type="text"
                  title="Nombre"
                  placeholder="Escriba nombre"
                />
              )}
            />
          </Col>
          <Col xs={12} md={12}>
            <Field
              name="email"
              render={({ field }) => (
                <BasicInput
                  {...field}
                  text={errors.email && touched.email && errors.email}
                  className={errors.email && touched.email ? 'is-invalid' : ''}
                  type="text"
                  title="Email"
                  placeholder="ejemplo@correo.cl"
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            {data.create && (
              <Field
                name="password"
                render={({ field }) => (
                  <BasicInput
                    {...field}
                    className={
                      errors.password && touched.password ? 'is-invalid' : ''
                    }
                    text={
                      errors.password && touched.password && errors.password
                    }
                    onChange={e => this.validatePass(e.target.value)}
                    onBlur={() => this.validatePassBlur()}
                    type="password"
                    title="Contraseña"
                  />
                )}
              />
            )}
          </Col>
        </Row>
        <Row className="inputRow">
          <Col xs={12} md={6}>
            <Field
              name="rut"
              render={({ field }) => (
                <BasicInput
                  {...field}
                  className={errors.rut && touched.rut ? 'is-invalid' : ''}
                  text={errors.rut && touched.rut && errors.rut}
                  onChange={e => this.onChangeRut(e.target.value)}
                  type="text"
                  title="RUT"
                  placeholder="9.999.999-9"
                />
              )}
            />
          </Col>
          <Col xs={12} md={6}>
            {data.create ? (
              <Field
                name="role"
                render={({ form }) => (
                  <SelectAlt
                    data={this.filterRoles()}
                    value={values.role}
                    title="Tipo de Usuario"
                    onChangeSelect={e =>
                      form.setFieldValue('role', Number(e.value))
                    }
                  />
                )}
              />
            ) : (
              <Select
                items={roles}
                title="Rol del usuario"
                disabled
                value={values.role}
              />
            )}
          </Col>
        </Row>
        {values.role === 3 && (
          <Row>
            <Col>
              <p>Comunas</p>
              <Select2
                options={this.setData(communes)}
                placeholder="Seleccione las Comunas"
                isLoading={ongoingRequest.getCommune}
                onInputChange={e => this.requestGetCommune(e)}
                isMulti
                onFocus={() =>
                  dispatch(getCommunes({ name: '', display_length: 5 }))
                }
                defaultValue={
                  values.coordinated_communes &&
                  this.setCommunes(values.coordinated_communes)
                }
                loadingMessage={() => 'Cargando..'}
                noOptionsMessage={() => 'No se encuentran resultados'}
                styles={customStyles}
                onChange={e => this.onChangeCommune(e)}
              />
            </Col>
          </Row>
        )}
        {values.role === 6 && (
          <Row>
            <Col>
              <p>Establecimientos</p>
              <Select2
                options={this.setEstablishments(establishments)}
                placeholder="Seleccione los establecimientos"
                isLoading={establishmentOngoingRequest.getEstablishmentRequest}
                onInputChange={e => this.requestGetEstablishments(e)}
                isMulti
                onFocus={() =>
                  dispatch(getEstablishment({ name: '', display_length: 5 }))
                }
                defaultValue={
                  values.coordinated_establishments &&
                  this.setEstablishments(values.coordinated_establishments)
                }
                loadingMessage={() => 'Cargando..'}
                noOptionsMessage={() => 'No se encuentran resultados'}
                styles={customStyles}
                onChange={e => this.onChangeEstablishment(e)}
              />
            </Col>
          </Row>
        )}
        <Row className="mt-4">
          <Col md={{ span: 3, offset: 6 }}>
            <Button onClick={onHide} block variant="light">
              Cancelar
            </Button>
          </Col>
          <Col md={3}>
            <Button type="submit" disabled={!isValid} block>
              {data.create ? 'Crear' : 'Guardar'}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const { redirect } = state.user;
  const { user } = state.auth;
  const { ongoingRequest, communes } = state.utils;
  const {
    ongoingRequest: establishmentOngoingRequest,
    establishment: establishments
  } = state.establishment;

  return {
    redirect,
    user,
    communes,
    ongoingRequest,
    establishmentOngoingRequest,
    establishments
  };
};

export default connect(mapStateToProps)(
  withFormik({
    mapPropsToValues(props) {
      const { item } = props.data;

      return {
        name: item ? item.name : '',
        rut: item ? item.rut : '',
        email: item ? item.email : '',
        role: item ? item.role : 0,
        password: '',
        coordinated_commune_ids:
          item && item.coordinated_commune_ids
            ? item.coordinated_commune_ids
            : [],
        coordinated_communes:
          item && item.coordinated_communes ? item.coordinated_communes : [],
        coordinated_establishment_ids:
          item && item.coordinated_establishment_ids
            ? item.coordinated_establishment_ids
            : [],
        coordinated_establishments:
          item && item.coordinated_establishments
            ? item.coordinated_establishments
            : []
      };
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Mínimo 3 caracteres')
        .required('Campo requerido'),
      email: Yup.string()
        .email()
        .required('Campo requerido'),
      rut: Yup.string()
        .min(9, 'Rut inválido')
        .required('Rut es un campo requerido')
    }),
    handleSubmit(values, { props }) {
      const { dispatch, data } = props;
      if (data.create) {
        dispatch(createUser({ user: values }));
      } else {
        const datas = values;
        delete datas.password;
        dispatch(updateUser({ id: data.item.id, ...datas }));
      }
    }
  })(UserForm)
);
