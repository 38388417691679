import React, { Component } from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
import { Check as IconCheck } from 'react-feather';
import { Spinner } from 'react-bootstrap';
import { CheckBoxM, CheckBoxIcon, InputPrependRight } from '../Input/index';
import { SelectPrependRight, Select } from '../Dropdowm';

class ScoreRow extends Component {
  setDataOptions = options => {
    const itemsData = options;
    return [...itemsData];
  };

  validateState = () => {
    const {
      student: { question_reviews_attributes: values }
    } = this.props;
    if (values) {
      return !values.some(question => {
        if (question.question_type === 'choices') {
          return !question.answer;
        }

        if (
          question.question_type === 'long' ||
          question.question_type === 'reading'
        ) {
          if (question.answer === 0) {
            return false;
          }
          return !(question.answer || question.percentage);
        }

        return false;
      });
    }
    return false;
  };

  handleOnChange = (value, type, position) => {
    const {
      student,
      index,
      handleSetValues,
      updateData,
      quizReviewId
    } = this.props;
    const valueAux = value === '' ? null : value;
    if (type === 'percentage') {
      if (Number(valueAux) <= 100 && Number(valueAux) >= 0) {
        student.question_reviews_attributes[position].percentage = valueAux;
      }
    } else if (type === 'score') {
      if (valueAux === 'N/R') {
        student.question_reviews_attributes[position].answer = 0;
      } else {
        student.question_reviews_attributes[position].answer =
          valueAux !== null ? Number(valueAux) : valueAux;
      }
    } else if (type === 'choices') {
      student.question_reviews_attributes[position].answer = valueAux;
    } else if (type === 'lee_reinforcement') {
      student.lee_reinforcement = valueAux;
    } else if (type === 'rl_reinforcement') {
      student.rl_reinforcement = valueAux;
    } else if (type === 'attended') {
      student.attended = valueAux;
    } else {
      if (valueAux.checked) {
        student.question_reviews_attributes[position].answer = 'Correcta';
      } else if (valueAux.indeterminate) {
        student.question_reviews_attributes[position].answer = 'N/R';
      } else {
        student.question_reviews_attributes[position].answer = 'Incorrecta';
      }
      student.question_reviews_attributes[position].indeterminate =
        valueAux.indeterminate;
      student.question_reviews_attributes[position].correct = valueAux.checked;
    }
    if (this.validateState()) {
      updateData(quizReviewId, student);
    }

    handleSetValues(student, index);
  };

  renderQuestionsInput = (questions, disabled) => {
    const classStyle = disabled ? 'disabledRow' : '';
    const {
      student: { question_reviews_attributes: values }
    } = this.props;
    return questions.map((question, i) => {
      if (question.question_type === 'long') {
        if (question.performance_type_as_string === 'score') {
          const val = values[i].answer !== null ? values[i].answer : '';
          return (
            <td key={question.id} className={`${classStyle} long`}>
              <SelectPrependRight
                value={val}
                disabled={disabled}
                text="Ptos"
                items={this.setDataOptions(question.options)}
                onChange={e => this.handleOnChange(e.target.value, 'score', i)}
              />
            </td>
          );
        }
        return (
          <td key={question.id} className={`${classStyle} long`}>
            <InputPrependRight
              value={values[i].percentage || ''}
              type="number"
              disabled={disabled}
              className="Percentage"
              text="%"
              min={0}
              max={100}
              onChange={e =>
                this.handleOnChange(e.target.value, 'percentage', i)
              }
            />
          </td>
        );
      }

      if (question.question_type === 'reading') {
        if (question.performance_type_as_string === 'score') {
          const val = values[i].answer !== null ? values[i].answer : '';
          return (
            <td key={question.id} className={`${classStyle} long-reading`}>
              <SelectPrependRight
                value={val}
                disabled={disabled}
                text="Ptos"
                items={this.setDataOptions(question.options)}
                onChange={e => this.handleOnChange(e.target.value, 'score', i)}
              />
            </td>
          );
        }
        return (
          <td key={question.id} className={`${classStyle} long-reading`}>
            <InputPrependRight
              value={values[i].percentage || ''}
              type="number"
              disabled={disabled}
              className="Percentage"
              text="%"
              min={0}
              max={100}
              onChange={e =>
                this.handleOnChange(e.target.value, 'percentage', i)
              }
            />
          </td>
        );
      }
      if (question.question_type === 'choices') {
        return (
          <td key={question.id} className={`${classStyle} choices`}>
            <Select
              value={values[i].answer || ''}
              disabled={disabled}
              items={this.setDataOptions(question.options)}
              onChange={e => this.handleOnChange(e.target.value, 'choices', i)}
            />
          </td>
        );
      }
      if (values[i].answer === null) {
        values[i].answer = 'Incorrecta';
      }
      return (
        <td key={question.id} className={`${classStyle} short`}>
          <CheckBoxIcon
            id={question.id}
            onChange={() => {
              const { correct, indeterminate } = values[i];
              let _indeterminate;
              let _checked;
              if (indeterminate) {
                _indeterminate = false;
                _checked = true;
              } else if (correct) {
                _indeterminate = false;
                _checked = !correct;
              } else if (correct === false) {
                _indeterminate = true;
                _checked = false;
              } else {
                _indeterminate = false;
                _checked = true;
              }
              this.handleOnChange(
                { checked: _checked, indeterminate: _indeterminate },
                'short',
                i
              );
            }}
            // controlId={question.id}
            disabled={disabled}
            // style={{ backgroundColor: 'red' }}
            checked={values[i].correct}
            indeterminate={
              values[i].indeterminate !== undefined
                ? values[i].indeterminate
                : values[i].answer === 'N/R'
            }
          />
        </td>
      );
    });
  };

  calculateScore = variant => {
    const { student, totalScore } = this.props;
    let score = 0;
    const questionReviewsAtributes = student.question_reviews_attributes.sort(
      (a, b) => {
        return a.position - b.position;
      }
    );

    // hablar con benja para que traiga esto ordenado
    questionReviewsAtributes.forEach((question, i) => {
      score += this.calcScore(question, i);
    });

    const percentage = (score / totalScore) * 100;

    return variant ? score : percentage.toFixed(0);
  };

  calcScorePercentage = (questionSelect, question) => {
    let scorePercentage = 0;
    questionSelect.question_performances.forEach(item => {
      if (
        item.minimum < Number(question.percentage) &&
        item.maximum >= Number(question.percentage)
      ) {
        scorePercentage = item.score;
      }
    });
    return scorePercentage;
  };

  calcScore = (question, index) => {
    const { questions } = this.props;
    const questionSelect = questions[index];

    if (
      questionSelect.question_type === 'long' ||
      questionSelect.question_type === 'reading'
    ) {
      if (questionSelect.performance_type_as_string === 'score') {
        return Number(question.answer);
      }

      return this.calcScorePercentage(questionSelect, question);
    }
    if (questionSelect.question_type === 'choices') {
      return questionSelect.answer === question.answer
        ? questionSelect.score
        : 0;
    }
    return question.correct ? questionSelect.score : 0;
  };

  render() {
    const { student, questions, onRequest, oral } = this.props;

    const state = this.validateState();

    return (
      <tr>
        <td className="col1 center fixed">
          <CheckBoxM
            custom
            id={`row-${student.id}`}
            label=""
            controlId={student.id}
            onChange={e => this.handleOnChange(e.target.checked, 'attended')}
            checked={student.attended}
          />
        </td>
        <td className="col2 fixed2">{student.student.last_names}</td>
        <td className="col3 shadow-right fixed3">{student.student.names}</td>
        <td className="col1 center">
          <CheckBoxM
            custom
            id={`row-${student.id}-lee-reinforcement`}
            label=""
            controlId={`${student.id}-lee_reinforcement`}
            onChange={e =>
              this.handleOnChange(e.target.checked, 'lee_reinforcement')
            }
            checked={student.lee_reinforcement}
          />
        </td>
        <td className="col1 center">
          <CheckBoxM
            custom
            id={`row-${student.id}-rl-reinforcement`}
            label=""
            controlId={`${student.id}-rl-reinforcement`}
            onChange={e =>
              this.handleOnChange(e.target.checked, 'rl_reinforcement')
            }
            checked={student.rl_reinforcement}
          />
        </td>
        {this.renderQuestionsInput(questions, !student.attended)}

        {!oral ? (
          <td className="col5 fixed-right3">{this.calculateScore(true)}</td>
        ) : null}
        {!oral ? (
          <td className="col6 fixed-right2">{this.calculateScore(false)}%</td>
        ) : null}

        <td className="col7 fixed-right">
          {state &&
            (onRequest ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <IconCheck />
            ))}
        </td>
      </tr>
    );
  }
}

export default ScoreRow;
