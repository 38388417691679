export const GET_QUIZ_REVIEWS_REQUEST = 'GET_QUIZ_REVIEWS_REQUEST';
export const GET_QUIZ_REVIEWS_SUCCESS = 'GET_QUIZ_REVIEWS_SUCCESS';
export const GET_QUIZ_REVIEWS_FAILURE = 'GET_QUIZ_REVIEWS_FAILURE';

export const getQuizReviews = data => ({
  type: GET_QUIZ_REVIEWS_REQUEST,
  data
});

export const GET_ONE_QUIZ_REVIEWS_REQUEST = 'GET_ONE_QUIZ_REVIEWS_REQUEST';
export const GET_ONE_QUIZ_REVIEWS_SUCCESS = 'GET_ONE_QUIZ_REVIEWS_SUCCESS';
export const GET_ONE_QUIZ_REVIEWS_FAILURE = 'GET_ONE_QUIZ_REVIEWS_FAILURE';

export const getOneQuizReviews = data => ({
  type: GET_ONE_QUIZ_REVIEWS_REQUEST,
  data
});

export const UPDATE_QUIZ_REVIEW_REQUEST = 'UPDATE_QUIZ_REVIEW_REQUEST';
export const UPDATE_QUIZ_REVIEW_SUCCESS = 'UPDATE_QUIZ_REVIEW_SUCCESS';
export const UPDATE_QUIZ_REVIEW_FAILURE = 'UPDATE_QUIZ_REVIEW_FAILURE';

export const updateQuizReview = data => ({
  type: UPDATE_QUIZ_REVIEW_REQUEST,
  data
});

export const UPDATE_ONE_QUIZ_REVIEW_REQUEST = 'UPDATE_ONE_QUIZ_REVIEW_REQUEST';
export const UPDATE_ONE_QUIZ_REVIEW_SUCCESS = 'UPDATE_ONE_QUIZ_REVIEW_SUCCESS';
export const UPDATE_ONE_QUIZ_REVIEW_FAILURE = 'UPDATE_ONE_QUIZ_REVIEW_FAILURE';

export const updateOneQuizReview = data => ({
  type: UPDATE_ONE_QUIZ_REVIEW_REQUEST,
  data
});

export const SET_VALUES_REVIEWS = 'SET_VALUES_REVIEWS';

export const setValuesReviews = data => ({
  type: SET_VALUES_REVIEWS,
  data
});

export const CREATE_QUIZ_REVIEW_REQUEST = 'CREATE_QUIZ_REVIEW_REQUEST';
export const CREATE_QUIZ_REVIEW_SUCCESS = 'CREATE_QUIZ_REVIEW_SUCCESS';
export const CREATE_QUIZ_REVIEW_FAILURE = 'CREATE_QUIZ_REVIEW_FAILURE';

export const createQuizReview = data => ({
  type: CREATE_QUIZ_REVIEW_REQUEST,
  data
});

export const DELETE_QUIZ_REVIEW_REQUEST = 'DELETE_QUIZ_REVIEW_REQUEST';
export const DELETE_QUIZ_REVIEW_SUCCESS = 'DELETE_QUIZ_REVIEW_SUCCESS';
export const DELETE_QUIZ_REVIEW_FAILURE = 'DELETE_QUIZ_REVIEW_FAILURE';

export const deleteQuizReview = data => ({
  type: DELETE_QUIZ_REVIEW_REQUEST,
  data
});

export const SET_DATE_QUIZ_REVIEW = 'SET_DATE_QUIZ_REVIEW';

export const setDateQuizReview = date => ({
  type: SET_DATE_QUIZ_REVIEW,
  date
});

export const IMPORT_SCORES_REQUEST = 'IMPORT_SCORES_REQUEST';
export const IMPORT_SCORES_SUCCESS = 'IMPORT_SCORES_SUCCESS';
export const IMPORT_SCORES_FAILURE = 'IMPORT_SCORES_FAILURE';

export const importScores = data => ({
  type: IMPORT_SCORES_REQUEST,
  data
});

export const CLOSE_IMPORT_WINDOW = 'CLOSE_IMPORT_WINDOW';

export const closeImportWindow = () => ({
  type: CLOSE_IMPORT_WINDOW
});

export const DOWNLOAD_TEMPLATE_SCORES_REQUEST =
  'DOWNLOAD_TEMPLATE_SCORES_REQUEST';
export const DOWNLOAD_TEMPLATE_SCORES_SUCCESS =
  'DOWNLOAD_TEMPLATE_SCORES_SUCCESS';
export const DOWNLOAD_TEMPLATE_SCORES_FAILURE =
  'DOWNLOAD_TEMPLATE_SCORES_FAILURE';

export const downloadScoresTemplate = data => ({
  type: DOWNLOAD_TEMPLATE_SCORES_REQUEST,
  data
});
