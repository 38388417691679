import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap';
import {
  currentTabClicked,
  updateAxesName,
  deleteAxes
} from '../../../actions/test';
// eslint-disable-next-line import/no-cycle
import AxesTab from './AxesTab';

class Axes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShowConfirm: false,
      modalData: {}
    };
  }

  sendData = () => {
    const { parentCallback, quiz } = this.props;
    // retorna un arr con los boolean
    const valid = [];
    quiz.axes_attributes.map(axe => {
      if (axe.name.length !== 0) {
        return valid.push(true);
      }
      return valid.push(false);
    });

    // retorna cada uno por separado
    let validIsValid = false;
    const isValid = valid.map(val => {
      if (val) {
        // eslint-disable-next-line
        return (validIsValid = true);
      }
      // eslint-disable-next-line
      return (validIsValid = false);
    });

    // si existe o no
    const isLength = !(isValid.length <= 0);

    if (validIsValid && isLength) {
      parentCallback(true);
    } else {
      parentCallback(false);
    }
  };

  actualTab = id => {
    const { dispatch } = this.props;
    dispatch(currentTabClicked(id));
  };

  updateAxeName = (newName, axesIndex) => {
    const { dispatch } = this.props;
    dispatch(updateAxesName({ newName, axesIndex }));
  };

  handleDelete = values => {
    const { dispatch } = this.props;
    dispatch(deleteAxes(values));
    this.setModalShowConfirm(false);
  };

  setModalShowConfirm = state => {
    this.setState({
      modalShowConfirm: state
    });
  };

  handleButtonDelete = (index, axe) => {
    this.setState({
      modalShowConfirm: true,
      modalData: {
        id: { index, axe },
        action: 'delete',
        title: 'ELIMINAR EJE',
        message: '¿Deseas eliminar este eje?'
      }
    });
  };

  render() {
    this.sendData();
    const { quiz } = this.props;
    const { modalData, modalShowConfirm } = this.state;
    const renderAxes = quiz.axes_attributes.map((axe, index) =>
      axe._destroy ? null : (
        <Nav.Item key={axe.id}>
          <Nav.Link eventKey={index}>
            <AxesTab
              quiz={quiz}
              updateAxeName={this.updateAxeName}
              handleButtonDelete={this.handleButtonDelete}
              modalShowConfirm={modalShowConfirm}
              modalData={modalData}
              setModalShowConfirm={this.setModalShowConfirm}
              handleDelete={this.handleDelete}
              actualTab={this.actualTab}
              axe={axe}
              index={index}
            />
          </Nav.Link>
        </Nav.Item>
      )
    );
    return renderAxes;
  }
}

const mapStateToProps = state => {
  const {
    activeTab,
    quiz: { quiz }
  } = state.test;
  return {
    activeTab,
    quiz
  };
};

export default connect(mapStateToProps)(Axes);
