import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { NavbarLeft, MainLogo } from '../../components';

class NotFound extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Col md={2} className="d-none d-md-block sidebar">
            <NavbarLeft />
          </Col>
          <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
            <div className="section">
              <div style={{ textAlign: 'center ', marginTop: '10%' }}>
                <h1>Esta página no está disponible</h1>
                <h3>
                  Es posible que el enlace que has seguido sea incorrecto o que
                  se haya eliminado la página.
                </h3>
                <div>
                  <MainLogo
                    width={200}
                    style={{ padding: 20, marginTop: '5%' }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  const { user } = state.auth;
  return {
    user
  };
};

export default connect(mapStateToProps)(NotFound);
