import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AbilityContext, Can } from '../../config/ability-context';
import SelectAlt from '../../components/Input/SelectAlt';
import { getGrades, getUtilsEstablishments } from '../../actions/utils';
import { createArrayYears } from '../../services/utils';
import { fullArray, charRange } from '../../components/Form/utils';

import {
  getStudents,
  resetStudents,
  getOneStudent,
  closeImportWindow,
  deleteStudent
} from '../../actions/student';
import {
  NavbarLeft,
  ComponentDataTable,
  ModalAction,
  ModalConfirm,
  DefaultButton
} from '../../components';
import columns from './columns';

class Students extends Component {
  availableYears = createArrayYears(3, 2020, new Date().getFullYear()).map(
    (item, i) => ({
      key: i,
      id: item,
      title: item
    })
  );

  availableSections = fullArray(6)
    .map(charRange('A'))
    .map((item, i) => ({
      key: i,
      id: item,
      title: item
    }));

  constructor() {
    super();
    this.state = {
      modalShow: false,
      modalData: {
        title: ''
      },
      finished: false,
      establishmentId: '',
      gradeId: '',
      yearIndex: '',
      sectionName: ''
    };
  }

  componentDidMount() {
    const { dispatch, fromEstablishmentForm } = this.props;
    dispatch(resetStudents());
    if (!fromEstablishmentForm) {
      dispatch(
        getGrades({
          select: true
        })
      );
      dispatch(getUtilsEstablishments());
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { yearSelected } = this.props;
    const { establishmentId, gradeId, yearIndex, sectionName } = this.state;

    if (
      yearSelected !== prevProps.yearSelected ||
      establishmentId !== prevState.establishmentId ||
      gradeId !== prevState.gradeId ||
      yearIndex !== prevState.yearIndex ||
      sectionName !== prevState.sectionName
    ) {
      this.reloadDatatable();
    }
  }

  reloadDatatable = () => {
    const { finished } = this.state;
    this.setState({ finished: !finished });
  };

  queryDispatch = data => {
    const { dispatch, signedIn } = this.props;

    if (signedIn) {
      dispatch(getStudents(data));
    }
  };

  setDataGender = gender => {
    if (gender === 'female') {
      return 'Femenino';
    }
    if (gender === 'other') {
      return 'Otro';
    }
    return 'Masculino';
  };

  setData = students => {
    return students.map(item => {
      return {
        ...item,
        active: item.active ? 'Si' : 'No',
        gender: this.setDataGender(item.gender)
      };
    });
  };

  setModalShow = state => {
    this.setState({
      modalShow: state
    });
  };

  handleCreate = () => {
    this.setState({
      modalShow: true,
      modalData: {
        create: true,
        form: 'student',
        title: 'NUEVO ESTUDIANTE'
      }
    });
  };

  handleUpload = () => {
    const { dispatch } = this.props;
    dispatch(closeImportWindow());
    this.setState({
      modalShow: true,
      modalData: {
        create: true,
        action: 'edit',
        form: 'upload-file',
        title: 'IMPORTAR ESTUDIANTES'
      }
    });
  };

  handleButtonClick = (item, type) => {
    const { dispatch } = this.props;
    if (type === 'edit') {
      if (item.id !== undefined) {
        dispatch(getOneStudent(item.id));
      }
      this.setState({
        modalShow: true,
        modalData: {
          item,
          action: 'edit',
          title: 'EDITANDO ALUMNO',
          form: 'student'
        }
      });
    } else {
      this.setState({
        modalShowConfirm: true,
        modalData: {
          id: item.id,
          action: 'delete',
          title: 'ELIMINAR ESTUDIANTE',
          message: `¿Estás seguro que deseas eliminar al estudiante, ${item.names} ${item.last_names}?`
        }
      });
    }
  };

  setModalShowConfirm = state => {
    this.setState({
      modalShowConfirm: state
    });
  };

  handleDelete = id => {
    const { dispatch } = this.props;
    dispatch(deleteStudent({ student: id }));
    this.setModalShowConfirm(false);
  };

  requestEstablishments = data => {
    const { dispatch } = this.props;
    dispatch(
      getUtilsEstablishments({
        ...data,
        display_start: 0,
        display_length: 25,
        sort_direction: 'asce',
        role: 2
      })
    );
  };

  handleEstablishmentSelect = value => {
    this.setState({ establishmentId: value });
  };

  handleGradeSelect = value => {
    this.setState({ gradeId: value });
  };

  handleYearIndexSelect = option => {
    this.setState({ yearIndex: option?.value || '' });
  };

  handleSectionSelect = option => {
    this.setState({ sectionName: option?.value || '' });
  };

  renderDatatable = () => {
    const {
      students,
      totalRows,
      history,
      ongoingRequest,
      fromEstablishmentForm,
      establishmentId,
      yearSelected,
      grades,
      establishments,
      onLoadUtils
    } = this.props;

    const {
      finished,
      gradeId,
      establishmentId: selectedEstablishmentId,
      yearIndex,
      sectionName
    } = this.state;
    const { context } = this;

    const canUpdate = context.can('update', 'Student');
    const canDestroy = context.can('destroy', 'Student');

    const fromEstablishment = {
      from_establishment: establishmentId,
      on_section_in: yearSelected,
      finished
    };

    const filterParams = {
      in_grade: gradeId,
      from_establishment: selectedEstablishmentId,
      on_section_in: yearIndex,
      in_section_with_name: sectionName,
      finished
    };

    return (
      <>
        {!fromEstablishmentForm && (
          <Can I="create" a="Student">
            <Row style={{ justifyContent: 'flex-end' }}>
              <Col>
                <SelectAlt
                  data={this.availableYears}
                  value={yearIndex}
                  title="Año"
                  onChangeSelect={e => this.handleYearIndexSelect(e)}
                  clearable
                />
              </Col>
              <Col>
                <SelectAlt
                  value={selectedEstablishmentId}
                  onChangeSelect={e =>
                    this.handleEstablishmentSelect(e?.value || '')
                  }
                  data={establishments}
                  title="Filtrar por establecimiento"
                  server
                  isSearchable
                  dispatchRequest={this.requestEstablishments}
                  onLoad={onLoadUtils.getUtilsEstablishments}
                  clearable
                />
              </Col>
              <Col>
                <SelectAlt
                  value={gradeId}
                  onChangeSelect={e => this.handleGradeSelect(e?.value || '')}
                  data={grades}
                  title="Filtrar por curso"
                  isSearchable
                  clearable
                />
              </Col>
              <Col>
                <SelectAlt
                  value={gradeId}
                  onChangeSelect={e => this.handleSectionSelect(e)}
                  data={this.availableSections}
                  title="Filtrar por sección"
                  isSearchable
                  clearable
                />
              </Col>
              <Col
                xs={4}
                xm={3}
                md={3}
                lg={2}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <DefaultButton
                  style={{ width: '100%' }}
                  onClick={() => history.push('/advance-course')}
                  text="Avanzar Curso"
                />
              </Col>
              <Col
                xs={4}
                xm={3}
                md={3}
                lg={2}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <DefaultButton
                  style={{ width: '100%' }}
                  onClick={() => this.handleCreate(true)}
                  text="Crear Estudiante"
                />
              </Col>
            </Row>
            <Row className="mt-2" />
          </Can>
        )}
        <ComponentDataTable
          columns={columns(
            this.handleButtonClick,
            canUpdate,
            canDestroy,
            fromEstablishmentForm,
            yearIndex
          )}
          onRequest={ongoingRequest.getStudents}
          data={this.setData(students)}
          totalRows={totalRows}
          reduxDispatch={this.queryDispatch}
          moreData={fromEstablishmentForm ? fromEstablishment : filterParams}
        />
      </>
    );
  };

  render() {
    const { ongoingRequest, fromEstablishmentForm, onLoadUtils } = this.props;
    const { modalShow, modalData, modalShowConfirm } = this.state;

    return (
      <Container fluid>
        <Row>
          {fromEstablishmentForm ? (
            <Col md={12} role="main" className="p-0">
              {this.renderDatatable()}
            </Col>
          ) : (
            <>
              <Col md={2} className="d-none d-md-block sidebar">
                <NavbarLeft />
              </Col>
              <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
                <div className="section">
                  <Row style={{ justifyContent: 'space-between' }}>
                    <h2 className="mb-3">Estudiantes</h2>
                    {!fromEstablishmentForm && (
                      <Can I="create" a="Student">
                        <Col
                          xs={3}
                          xm={3}
                          md={2}
                          lg={2}
                          style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                          <DefaultButton
                            style={{ width: '100%' }}
                            onClick={() => this.handleUpload(true)}
                            text="Importar"
                          />
                        </Col>
                      </Can>
                    )}
                  </Row>
                  {this.renderDatatable()}
                </div>
              </Col>
            </>
          )}
        </Row>
        <ModalAction
          show={modalShow}
          data={modalData}
          title="edit"
          onLoad={
            ongoingRequest.createStudent ||
            ongoingRequest.updateStudent ||
            ongoingRequest.getOneStudent ||
            onLoadUtils.getGrades ||
            onLoadUtils.getSections
          }
          onHide={() => this.setModalShow(false)}
        />
        <ModalConfirm
          show={modalShowConfirm}
          data={modalData}
          onHide={() => this.setModalShowConfirm(false)}
          action={this.handleDelete}
        />
      </Container>
    );
  }
}

Students.contextType = AbilityContext;
const mapStateToProps = state => {
  const { ongoingRequest, students, totalRows } = state.student;
  const { grades, establishments } = state.utils;
  const { signedIn } = state.auth;
  const { ongoingRequest: onLoadUtils } = state.utils;
  return {
    ongoingRequest,
    students,
    totalRows,
    signedIn,
    onLoadUtils,
    grades,
    establishments
  };
};
export default connect(mapStateToProps)(Students);
