import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { NavbarLeft } from '../../components';

const Tutorials = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={2} className="d-none d-md-block sidebar">
          <NavbarLeft />
        </Col>
        <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
          <div className="section">
            <h2 className="mb-3">Tutoriales</h2>
            <div className="d-flex justify-content-center">
              <Row>
                <Col md={6}>
                  <h6 className="title-tutorials">Ingreso de estudiantes</h6>
                  <iframe
                    width="530"
                    height="250"
                    src="https://www.youtube.com/embed/u_574tymNWw"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Col>
                <Col md={6}>
                  <h6 className="title-tutorials">
                    Subida de informe Lectura oral
                  </h6>
                  <iframe
                    width="530"
                    height="250"
                    src="https://www.youtube.com/embed/Dvy272PWU6g"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Col>
                <Col md={6} className="mt-5">
                  <h6 className="title-tutorials">Descarga de informes</h6>
                  <iframe
                    width="530"
                    height="250"
                    src="https://www.youtube.com/embed/0LtneizqNCk"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Col>
                <Col md={6} className="mt-5">
                  <h6 className="title-tutorials">Ingreso de puntajes</h6>
                  <iframe
                    width="530"
                    height="250"
                    src="https://www.youtube.com/embed/OfcVhdkAnKY"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Tutorials;
