const routes = [
  {
    path: '/dashboard',
    actions: 'read',
    subject: 'Page',
    redirect: '/dashboard'
  },
  {
    path: '/establishment-and-courses',
    actions: 'read',
    subject: 'Establishment',
    redirect: '/dashboard'
  },
  {
    path: '/create-establishment',
    actions: 'create',
    subject: 'Establishment',
    redirect: '/establishment-and-courses'
  },
  {
    path: '/create-establishment/',
    actions: 'update',
    subject: 'Establishment',
    redirect: '/establishment-and-courses'
  },
  {
    path: '/teachers',
    actions: 'read',
    subject: 'Teacher',
    redirect: '/dashboard'
  },
  {
    path: '/guidelines',
    actions: 'read',
    subject: 'Guideline',
    redirect: '/dashboard'
  },
  {
    path: '/students',
    actions: 'read',
    subject: 'Student',
    redirect: '/dashboard'
  },
  {
    path: '/advance-course',
    actions: 'transition',
    subject: 'Section',
    redirect: '/stundets'
  },
  {
    path: '/score',
    actions: 'create',
    subject: 'QuizReview',
    redirect: '/dashboard'
  },
  {
    path: '/reports',
    actions: 'read',
    subject: 'Page',
    redirect: '/reports'
  },
  {
    path: '/material-fundation',
    actions: 'read',
    subject: 'Page',
    redirect: '/material-fundation'
  },
  {
    path: '/profile',
    actions: 'read',
    subject: 'Page',
    redirect: '/profile'
  },

  {
    path: '/tests/',
    actions: 'read',
    subject: 'Quiz',
    redirect: '/dashboard'
  },
  {
    path: '/tests/id/edit-score',
    actions: 'update',
    subject: 'Quiz',
    redirect: '/dashboard'
  },
  {
    path: '/tests/id/edit',
    actions: 'update',
    subject: 'Quiz',
    redirect: '/dashboard'
  },
  {
    path: '/users',
    actions: 'read',
    subject: 'User',
    redirect: '/dashboard'
  }
];

const pathWithParams = path => {
  if (path.includes('/edit-score') && path.includes('/tests/')) {
    const array = path.split('/');
    if (array.length === 4) {
      array[2] = 'id';
      return array.join('/');
    }
  }
  if (path.includes('/edit') && path.includes('/tests/')) {
    const array = path.split('/');
    if (array.length === 4) {
      array[2] = 'id';
      return array.join('/');
    }
  }
  if (path.includes('/create-establishment/') || path.includes('/tests/')) {
    const array = path.split('/');
    array.pop();
    return `/${array.join('')}/`;
  }

  return '/dashboard';
};
const getMatch = path => {
  const page = routes.find(item => item.path === path);
  if (!page) {
    return routes.find(item => item.path === pathWithParams(path));
  }
  return page;
};
const permissions = ability => {
  const route = getMatch(window.location.pathname);
  return {
    permission: ability.can(route.actions, route.subject),
    redirect: route.redirect
  };
};
export { routes };
export default permissions;
