import React from 'react';
import { Nav } from 'react-bootstrap';
import { Plus } from 'react-feather';

const AddAxes = ({ addAxe }) => {
  return (
    <Nav.Item>
      <Nav.Link onClick={addAxe} className="new-btn">
        Nuevo Eje
        <Plus />
      </Nav.Link>
    </Nav.Item>
  );
};

export default AddAxes;
