import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
// eslint-disable-next-line
import { DefaultButton } from '../index';
// eslint-disable-next-line
import { ModalAction } from '../Modal';

import '../../assets/stylesheets/styles.scss';

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      modalData: {}
    };
  }

  setModalShow = state => {
    this.setState({
      modalShow: state
    });
  };

  handleCreate = () => {
    this.setState({
      modalShow: true,
      modalData: {
        create: true,
        form: 'reset-password',
        title: 'CAMBIAR CONTRASEÑA'
      }
    });
  };

  render() {
    const { modalShow, modalData } = this.state;
    return (
      <Row>
        <Col>
          <div style={{ width: '300px', marginBottom: '10px' }}>
            <DefaultButton
              onClick={() => this.handleCreate(true)}
              text="Cambiar Contraseña"
            />
          </div>
        </Col>
        <ModalAction
          show={modalShow}
          data={modalData}
          onHide={() => this.setModalShow(false)}
        />
      </Row>
    );
  }
}

export default ResetPassword;
