import React from 'react';

const NoImage = () => {
  return (
    <div className="no-image">
      <p className="noInformation">No hay información para este gráfico</p>
    </div>
  );
};

export default NoImage;
