import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withFormik, Field, Form, FieldArray } from 'formik';
import { Plus } from 'react-feather';
import * as Yup from 'yup';
import { BasicInput, InputPrependRight, CheckBox } from '../Input/index';
import {
  createGuideline,
  updateGuideline,
  setRedirect
} from '../../actions/guideline';
// eslint-disable-next-line
import { ConfirmDelete } from '../index';
import generateScaledPalette from '../../services/colours';

const questionTypes = [
  {
    id: 1,
    name: 'Alternativas',
    question_type: 'choices'
  },
  {
    id: 2,
    name: 'Respuesta Única',
    question_type: 'short'
  },
  {
    id: 3,
    name: 'Abierta',
    question_type: 'long'
  },
  {
    id: 4,
    name: 'Lectura Oral',
    question_type: 'reading'
  }
];

class GuidelineForm extends Component {
  constructor() {
    super();
    this.state = {
      indexDeleteItem: null
    };
  }

  componentWillReceiveProps(nextProps) {
    const { redirect, onHide, dispatch } = nextProps;
    if (redirect.state) {
      dispatch(setRedirect({ path: '', state: false }));
      onHide();
    }
  }

  handleDeleteItem = index => {
    this.setState({
      indexDeleteItem: index
    });
  };

  handleCancelItem = () => {
    this.setState({
      indexDeleteItem: null
    });
  };

  handleConfirmDelete = index => {
    const { values } = this.props;

    const dataFilter = values.guidelinePerformances.map((x, i) => {
      if (i === index) {
        return { ...x, state: false, maximum: 10, minimum: 6, tag: 'borrado' };
      }

      return x;
    });

    this.setState({
      indexDeleteItem: null
    });

    this.onChangeColoursGenerateGuideline(dataFilter);
  };

  onChageQuestionTypes = (target, index) => {
    const { values, setFieldValue } = this.props;
    if (index === 3) {
      // checkBox from Lectura Oral

      const QuestionTypes = values.questionTypes.map(item => ({
        ...item,
        state: false
      }));
      QuestionTypes[3].state = true;

      setFieldValue('questionTypes', QuestionTypes);
    } else {
      // checkBox no Lectura Oral

      const QuestionTypes = values.questionTypes;
      QuestionTypes[3].state = false;
      QuestionTypes[index].state = target.checked;

      setFieldValue('questionTypes', QuestionTypes);
    }
  };

  onChangeGuidelinePercentage = (target, index, val) => {
    const { setFieldValue } = this.props;
    if (Number(target.value) > 100) {
      setFieldValue(`guidelinePerformances[${index}].${val}`, '100');
    } else if (Number(target.value) >= 0) {
      setFieldValue(`guidelinePerformances[${index}].${val}`, target.value);
    } else if (Number(target.value) < 0) {
      setFieldValue(`guidelinePerformances[${index}].${val}`, '0');
    }
  };

  handleCreateGuidelinePerformance = () => {
    const { values } = this.props;

    const data = values.guidelinePerformances;
    data.push({
      tag: '',
      minimum: '0',
      maximum: '0',
      id: null,
      state: true
    });

    this.onChangeColoursGenerateGuideline(data);
  };

  onChangeColoursGenerateGuideline = (dataFilter = []) => {
    const { setFieldValue, values } = this.props;
    const data = dataFilter || values.guidelinePerformances;
    const guidelinePerformanceCount = data.filter(item => item.state).length;

    const colours = generateScaledPalette(guidelinePerformanceCount).reverse();
    let countColor = 0;
    const newData = data.map(item => {
      if (item.state) {
        const num = countColor;
        countColor += 1;
        return {
          ...item,
          colour: colours[num]
        };
      }
      return item;
    });
    setFieldValue('guidelinePerformances', newData);
  };

  onChangeMinimum = value => {
    const { setFieldValue } = this.props;
    if (Number(value) <= 100) setFieldValue('minimum', value);
  };

  render() {
    const { onHide, values, data, errors, touched, isValid } = this.props;
    const { indexDeleteItem } = this.state;

    return (
      <Form>
        <Field
          name="name"
          render={({ field }) => (
            <BasicInput
              text={errors.name && touched.name && errors.name}
              {...field}
              title="Ingresa el nombre del tipo de evaluación"
              placeholder="Nombre de evaluación"
            />
          )}
        />

        <Field
          name="minimum"
          render={({ field }) => (
            <InputPrependRight
              {...field}
              text="%"
              onChange={e => this.onChangeMinimum(e.target.value)}
              type="number"
              maximum="100"
              placeholder="00"
              title="Indica % logrado"
            />
          )}
        />
        <hr />
        <p>Porcentajes de Desempeño</p>
        <Row>
          <Col md={12}>
            <FieldArray
              name="guidelinePerformances"
              render={() => (
                <div>
                  <Row>
                    <Col xs={2}>Minimo</Col>
                    <Col xs={2}>Maximo</Col>

                    <Col xs={2}>Nombre</Col>
                    <Col xs={2}>Color</Col>
                  </Row>
                  {values.guidelinePerformances.map((item, index) => {
                    if (item.state) {
                      return (
                        // eslint-disable-next-line
                        <Row key={index}>
                          <Col xs={2}>
                            <Field
                              name={`guidelinePerformances[${index}].minimum`}
                              render={({ field }) => (
                                <InputPrependRight
                                  {...field}
                                  onChange={e =>
                                    this.onChangeGuidelinePercentage(
                                      e.target,
                                      index,
                                      'minimum'
                                    )
                                  }
                                  text="%"
                                  type="number"
                                  placeholder="00"
                                />
                              )}
                            />
                          </Col>
                          <Col xs={2}>
                            <Field
                              name={`guidelinePerformances[${index}].maximum`}
                              render={({ field }) => (
                                <InputPrependRight
                                  {...field}
                                  onChange={e =>
                                    this.onChangeGuidelinePercentage(
                                      e.target,
                                      index,
                                      'maximum'
                                    )
                                  }
                                  text="%"
                                  type="number"
                                  placeholder="0"
                                />
                              )}
                            />
                          </Col>
                          <Col xs={2}>
                            <Field
                              name={`guidelinePerformances[${index}].tag`}
                              render={({ field }) => (
                                <BasicInput
                                  // text={errors.name}
                                  {...field}
                                  placeholder="Nombre Tag"
                                  // disabled={isSubmitting}
                                />
                              )}
                            />
                          </Col>
                          <Col xs={2}>
                            <Field
                              name={`guidelinePerformances[${index}].colour`}
                              render={({ field }) => (
                                <BasicInput
                                  // text={errors.name}
                                  {...field}
                                  type="color"
                                  placeholder="Color"
                                  // disabled={isSubmitting}
                                />
                              )}
                            />
                          </Col>
                          <Col xs={4}>
                            <ConfirmDelete
                              keyIndex={index}
                              disabled={
                                index ===
                                values.guidelinePerformances.length - 1
                              }
                              handleDelete={this.handleDeleteItem}
                              handleConfirm={this.handleConfirmDelete}
                              handleCancel={this.handleCancelItem}
                              deleteItemSelected={indexDeleteItem}
                            />
                          </Col>
                        </Row>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            />
          </Col>
          <Col xs={12}>
            <Button
              onClick={() => this.handleCreateGuidelinePerformance()}
              variant="outline-primary"
              size="sm"
              className="mt-3"
            >
              <Plus className="small-svg" />
            </Button>
          </Col>
        </Row>
        <hr />
        <p>Indica los tipos de preguntas que permite</p>

        <FieldArray
          name="grades"
          render={() => (
            <div>
              {questionTypes.map((item, index) => (
                // eslint-disable-next-line
                <Row key={index}>
                  <Col xs={10}>
                    <Field
                      name={`questionTypes[${index}]`}
                      render={({ field }) => (
                        <CheckBox
                          {...field}
                          custom
                          controlId={index}
                          onChange={e =>
                            this.onChageQuestionTypes(e.target, index)
                          }
                          label={item.name}
                          checked={field.value.state}
                          placeholder="Color"
                          title="Indica % logrado"
                          // disabled={isSubmitting}
                        />
                      )}
                    />
                  </Col>
                </Row>
              ))}
            </div>
          )}
        />
        <Row>
          {errors.minimum && touched.minimum ? (
            <div>{errors.minimum}</div>
          ) : null}
          <Col>
            {values.questionTypes[3].state && (
              <p className="text_disclaimer_oral_reading">
                Las evaluaciones de Lectura Oral solo permiten su estructura
                predefinida, y no pueden tener otros tipos de pregunta.
              </p>
            )}
          </Col>
          <Col md={3}>
            <Button onClick={onHide} block variant="light">
              Cancelar
            </Button>
          </Col>
          <Col md={3}>
            <Button type="submit" block disabled={!isValid}>
              {data.create ? 'Crear' : 'Guardar'}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const { ongoingRequest, communes, regions } = state.utils;
  const { redirect } = state.guideline;
  return {
    ongoingRequest,
    communes,
    regions,
    redirect
  };
};

export default connect(mapStateToProps)(
  withFormik({
    mapPropsToValues(props) {
      const datos = props.data.item || false;
      const QuestionTypesAux = questionTypes.map(item => {
        return {
          state: datos
            ? datos.questionTypes.some(d => d.id === item.id)
            : false,
          ...item
        };
      });

      const guidelinePerformance = datos.guidelinePerformances
        ? datos.guidelinePerformances.map(item => {
            return { ...item, state: true };
          })
        : [
            {
              tag: '',
              colour: generateScaledPalette(1)[0],
              minimum: '0',
              maximum: '0',
              state: true
            }
          ];
      const guidelines = guidelinePerformance.sort(
        (a, b) => Number(a.minimum) - Number(b.minimum)
      );
      return {
        name: datos.name || '',
        minimum: datos.minimum || '',
        guidelinePerformances: guidelines,
        questionTypes: QuestionTypesAux
      };
    },
    validationSchema: Yup.object({
      name: Yup.string().min(3, 'Minimo 3 caracteres'),
      minimum: Yup.number().max(100, 'Maximo 100%'),
      guidelinePerformances: Yup.array().of(
        Yup.object().shape({
          tag: Yup.string()
            .min(1, 'mínimo 1 caracter')
            .required('Required'),
          maximum: Yup.number().min(1, 'Porcentaje de item maximo de 100%'),
          minimum: Yup.number().min(0, 'Porcentaje de item minimo de 1%')
        })
      )
    }),
    handleSubmit(values, { props, setSubmitting }) {
      setSubmitting(false);
      const questionTypeAux = values.questionTypes.filter(item => item.state);
      const { dispatch, data } = props;

      // set data guideline form and destroy
      const guidelinePerformance = values.guidelinePerformances.map(item => {
        if (!item.state) {
          return { ...item, _destroy: true };
        }
        return {
          ...item,
          id: item.id || null,
          minimum: Number(item.minimum),
          maximum: Number(item.maximum)
        };
      });

      if (data.create) {
        dispatch(
          createGuideline({
            guideline: {
              name: values.name,
              minimum: values.minimum,
              guidelinePerformancesAttributes: guidelinePerformance,
              question_type_ids: questionTypeAux.map(item => item.id)
            }
          })
        );
      } else {
        dispatch(
          updateGuideline({
            guideline: {
              name: values.name,
              minimum: Number(values.minimum),
              guideline_performances_attributes: guidelinePerformance,
              question_type_ids: questionTypeAux.map(item => item.id)
            },
            id: data.item.id
          })
        );
      }
    }
  })(GuidelineForm)
);
