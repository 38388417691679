import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavbarLeft, DefaultButton, SubTitleH2 } from '../../components';

const Test = () => (
  <Container fluid>
    <Row>
      <Col md={2} className="d-none d-md-block sidebar">
        <NavbarLeft />
      </Col>
      <Col xs={12} md={{ span: 10, offset: 2 }} role="main">
        <div className="section">
          <SubTitleH2 text="Evaluaciones" />
          <Row style={{ borderBottom: '3px', borderColor: 'red' }}>
            <Col>
              <DefaultButton name="Crear Evaluación" />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  </Container>
);

export default Test;
