import React from 'react';
import { memoize } from 'react-data-table-component';
import { Edit2, Trash } from 'react-feather';
import { Button } from 'react-bootstrap';

const columnsAdmin = memoize(clickHandler => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true,
    grow: 1
  },
  {
    name: 'RUT',
    selector: 'rut',
    right: true,
    grow: 1
  },
  {
    name: 'ACTIVO',
    selector: 'active',
    right: true,
    grow: 1
  },
  {
    cell: infoItem => (
      <div>
        <Button
          variant="outline-primary"
          size="sm"
          className="mr-2"
          onClick={() => clickHandler(infoItem, 'edit')}
        >
          <Edit2 className="small-svg" />
        </Button>
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => clickHandler(infoItem, 'delete')}
        >
          <Trash className="small-svg" />
        </Button>
      </div>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    right: true,
    grow: 1
  }
]);

const columns = memoize(() => [
  {
    name: 'NOMBRE',
    selector: 'name',
    sortable: true
  },
  {
    name: 'RUT',
    selector: 'rut',
    right: true
  },
  {
    name: 'ACTIVO',
    selector: 'active',
    right: true
  }
]);

export { columns, columnsAdmin };
