const bestColour = '#23D953';
const mediumColour = '#FFFF00';
const worstColour = '#FF422A';
const shallowFactor = 1.7;

const hexWithOneNumber = hex => {
  if (hex.length === 1) return `0${hex}`;

  return hex;
};

const numberToHexadecimal = (red, green, blue, withHashtag = true) => {
  const hexadecimalRed = hexWithOneNumber(red.toString(16));
  const hexadecimalGreen = hexWithOneNumber(green.toString(16));
  const hexadecimalBlue = hexWithOneNumber(blue.toString(16));
  const hashTag = withHashtag ? '#' : '';

  return `${hashTag}${hexadecimalRed}${hexadecimalGreen}${hexadecimalBlue}`;
};

const hexadecimalToNumber = (hexadecimalString, withHashtag = true) => {
  const hexadecimalStringAux = withHashtag
    ? hexadecimalString.slice(1)
    : hexadecimalString;
  const redHex = hexadecimalStringAux.slice(0, 2);
  const greenHex = hexadecimalStringAux.slice(2, 4);
  const blueHex = hexadecimalStringAux.slice(4, 6);
  const redNumber = parseInt(redHex, 16);
  const greenNumber = parseInt(greenHex, 16);
  const blueNumber = parseInt(blueHex, 16);

  return { red: redNumber, green: greenNumber, blue: blueNumber };
};

const processedColour = (colour, shallowed) => {
  const shallowFactorAux = shallowed ? shallowFactor : 1;
  const { red, green, blue } = hexadecimalToNumber(colour);

  const newRed = Math.min(...[255, red * shallowFactorAux]);
  const newGreen = Math.min(...[255, green * shallowFactorAux]);
  const newBlue = Math.min(...[255, blue * shallowFactorAux]);

  return numberToHexadecimal(newRed, newGreen, newBlue);
};

const generateNewColour = (colour1, colour2, separation) => {
  const partialColour1 = separation * colour2;
  const partialColour2 = (100 - separation) * colour1;
  const number = parseInt((partialColour1 + partialColour2) / 100, 10);

  return number;
};

const betweenColours = (colour1, colour2, quantity) => {
  if (quantity === 0) return [];

  const { red: red1, green: green1, blue: blue1 } = hexadecimalToNumber(
    colour1
  );
  const { red: red2, green: green2, blue: blue2 } = hexadecimalToNumber(
    colour2
  );

  const stretch = 100 / (quantity + 1);
  const result = [];
  for (let i = 1; i <= quantity; i += 1) {
    const currentSeparation = i * stretch;
    const newRed = generateNewColour(red1, red2, currentSeparation);
    const newGreen = generateNewColour(green1, green2, currentSeparation);
    const newBlue = generateNewColour(blue1, blue2, currentSeparation);
    const newColourHexadecimal = numberToHexadecimal(newRed, newGreen, newBlue);

    result.push(newColourHexadecimal);
  }

  return result;
};

const generateScaledPalette = (colourQuantity, shallowed = false) => {
  if (colourQuantity < 1) return [];

  const mediumColourAux = processedColour(mediumColour, shallowed);

  if (colourQuantity === 1) return [mediumColourAux];

  const isEven = colourQuantity % 2 === 0;
  const difference = isEven ? 2 : 3;
  const betweenQuantity = (colourQuantity - difference) / 2;
  const bestColourAux = processedColour(bestColour, shallowed);
  const worstColourAux = processedColour(worstColour, shallowed);

  const result = [];
  result.push(bestColour);
  if (betweenQuantity) {
    result.push(
      ...betweenColours(bestColourAux, mediumColourAux, betweenQuantity)
    );
  }

  // si es par no se pone el de al medio
  if (!isEven) {
    result.push(mediumColourAux);
  }
  if (betweenQuantity) {
    result.push(
      ...betweenColours(mediumColourAux, worstColourAux, betweenQuantity)
    );
  }
  result.push(worstColourAux);

  return result;
};

export default generateScaledPalette;
